<template src="./main.html"></template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import prompts from '@/core/tools/notifications/notifications';
import agGridGetters from '@/core/components/common/grid/getters/getters.js';
import { ReceivingStatus } from '@/backoffice/modules/purchase/domain/purchase.js';
import receivingManangement from '@/backoffice/modules/purchase/components/sidePanel/receivings/receiving-management/receiving-management.vue';
import creationButton from '@/core/components/common/creation-button/creation-button.vue';
import { GridId, AgFilter, AgFilterType, AgAllowedAggFuncs, MultiFilterParams, FilterParams } from '@/core/components/common/grid/grid.const.js'
import sliderList from '@/core/components/common/slider-list/slider-list.vue'
import information from '@/core/components/common/slider-list/templates/information/information.vue'
import searchBar from '@/core/components/common/search-bar/search-bar.vue'
import grid from  '@/core/components/common/grid/grid.vue'
import persistedGrid  from '@/core/components/common/grid/persisted-grid.vue';
import { OrderStatus, calculateOrdersProductCount } from '@/backoffice/modules/purchase/domain/purchase.js';
import tabs from '@/core/components/common/tabs/tabs.vue';
import { permissionsDictionary } from '@/core/security/permissions-dictionary.js';

export default {
  name: '',
  components: {
    sliderList,
    information,
    searchBar,
    grid,
    persistedGrid, 
    creationButton,
    tabs
  },
  data() {
    return {
      receivingsGridOptions: {
        headerHeight: 50,
        rowHeight: 50,
        defaultColDef: {
          resizable: true
        },
        columnDefs: [],
        rowSelection: 'single',
        overlayNoRowsTemplate: '<span>' + this.$t('Purchase.Messages.NoOrderData') + '</span>',
        onGridReady: () => {
          this.setColumnDefs();
          this.getAggregatedReceivings();
        },
        onGridSizeChanged: () => {
          if(this.isMobile){
            this.receivingsGridOptions.columnApi.autoSizeAllColumns(false);
          }
          else{
            this.receivingsGridOptions.api.sizeColumnsToFit();
          }
        }
      },

      ordersGridOptions: {
        getRowId: (params) => params.data?.id,
        rowSelection: 'multiple',
        enableRangeSelection: true
      },
      ordersDefaultFilters: {
        orderStatus: {
          filterType: AgFilterType.Set,
          values: [
            OrderStatus.OPEN,
            OrderStatus.PENDING,
            OrderStatus.PARTIALLYRECEIVED
          ]
        }
      },
      receivingsFilters: [],
      ordersFilters: []
    }
  },

  watch: {
    getCurrentTargetLayerId() {
      this.getAggregatedReceivings();
      this.initOrders();
    },
    isMobile(){
      this.setColumnDefs();
    },
  },
  beforeDestroy(){
    this.resetAggregatedReceivings()
    this.resetOrders()
  },
  computed: {
    ...mapGetters('App', ['isMobile']),
    ...mapGetters('Account', ['getCurrentTargetLayerId', 'hasPermission']),
    ...mapGetters('Receiving', ['getAggregateReceivings']),
    ...mapGetters('Order', ['getOrders']),

    canCreateReceiving() {
      return this.hasPermission([permissionsDictionary.RECEIVING_CUD])
    },

    canReadOrders() {
      return this.hasPermission([permissionsDictionary.ORDER_READ])
    },

    openReceivings(){
      if(!this.getAggregateReceivings || this.getAggregateReceivings.length == 0)
        return []

      return this.getAggregateReceivings?.filter(r => r.receivingStatus === ReceivingStatus.OPEN)
    },
    orderTabs(){
      return [this.$t('Purchase.Receiving.ReceivingList'), this.$t('Purchase.Order.OrderList')]
    },
    selectedTab: {
      get() {
        return this.getOrderGridTabIndex
      },
      set(newValue){
        this.setReceivingsGridTabIndex(newValue)
      }
    },

    getGridId() {
      return GridId.OrdersReceivings
    },

    ordersWithProductCount(){
      return calculateOrdersProductCount(this.getOrders)
    },

    ordersColumnDefs() {
      return [
        {
          field: 'orderNO',
          filterParams: FilterParams.NumberFilter,
          sort: 'desc', 
          headerName: !this.isMobile ? this.$t('Purchase.Order.Columns.OrderNO') : '#',
          headerTooltip: this.$t('Purchase.Order.Columns.OrderNO'),
          enableRowGroup: false,
          allowedAggFuncs: AgAllowedAggFuncs.Text,
          cellRenderer: 'linkRenderer',
          cellRendererParams: (params) => {
            return {
              route: { name: 'order', params: { id: params?.data?.id } }
            }
          },
          headerCheckboxSelection: true, 
          checkboxSelection: true, 
          headerCheckboxSelectionFilteredOnly: true
        },
        { hide: false, field: 'vendorName', headerName: this.$t('Purchase.Order.Columns.VendorName'), headerTooltip: this.$t('Purchase.Order.Columns.VendorName'), valueGetter: (params) => params?.data?.vendorName, filterParams: MultiFilterParams.NormalizeTextFilters, allowedAggFuncs: AgAllowedAggFuncs.Text },
        { hide: false, field: 'createdDate', filter: AgFilter.DateColumnFilter, valueGetter: agGridGetters.dateWithMonthNamed, filterValueGetter: (params) => params?.data?.createdDate && new Date(params.data.createdDate), headerName: this.$t('Purchase.Order.Columns.CreatedDate'), headerTooltip: this.$t('Purchase.Order.Columns.CreatedDate') },
        { hide: this.isMobile, field: 'receivedDate', filter: AgFilter.DateColumnFilter, valueGetter: agGridGetters.dateWithMonthNamed, filterValueGetter: (params) => params?.data?.receivedDate && new Date(params.data.receivedDate), headerName: this.$t('Purchase.Order.Columns.ReceivedDate'), headerTooltip: this.$t('Purchase.Order.Columns.ReceivedDate') },
        { hide: false, field: 'orderStatus', filter: AgFilter.SetColumnFilter, filterParams: { valueFormatter: (params) => this.$t(`Purchase.Order.Status.${params.value}`) }, valueGetter: params => { return agGridGetters.valueTranslateGetter(params, 'Purchase.Order.Status'); }, filterValueGetter: (params) => params?.data?.orderStatus, headerName: this.$t('Purchase.Order.Columns.OrderStatus'), headerTooltip: this.$t('Purchase.Order.Columns.OrderStatus'), allowedAggFuncs: AgAllowedAggFuncs.Text },
        { hide: this.isMobile, field: 'productCount', filterParams: FilterParams.NumberFilter, headerName: this.$t('Purchase.Order.Columns.OrderBodySums'), headerTooltip: this.$t('Purchase.Order.Columns.OrderBodySums') },
        { hide: this.isMobile, field: 'userUsername', filterParams: MultiFilterParams.NormalizeTextFilters, headerName: this.$t('Purchase.Order.Columns.UserUsername'), headerTooltip: this.$t('Purchase.Order.Columns.UserUsername'), allowedAggFuncs: AgAllowedAggFuncs.Text },
      ]
    }
  },

  methods: {
    ...mapActions('Receiving', ['fetchAggregateReceivings']),
    ...mapActions('Order', ['fetchTargetLayerPartiallyReceivedAndPendingOrdersByNotOpenReceptions']),
    ...mapMutations('Receiving',{
      resetAggregatedReceivings: 'RESET_AGGREGATE_RECEIVINGS',   
      setReceivingsGridTabIndex: 'SET_RECEIVINGS_GRID_TAB_INDEX'
    }),
    ...mapMutations('Order',{
      resetOrders: 'RESET_ORDERS'
    }),

     onOrdersGridReady(params) {
      this.ordersGridOptions.api = params.api
      this.initOrders()
    },

    async initOrders(){
      try {
        await this.fetchTargetLayerPartiallyReceivedAndPendingOrdersByNotOpenReceptions();
      } 
      catch (error) {
        prompts.error({
          text: this.$t('Purchase.Messages.GetOrdersError')
        });
      }
    },

    async getAggregatedReceivings(){
      try{
        await this.fetchAggregateReceivings();
      }
      catch(error){
        const codeTranslate = error?.status===403?'Purchase.Messages.ForbiddenAction':'Purchase.Messages.GetReceivingsError'
        prompts.error({
          text: this.$t(codeTranslate)
        });
      }

      this.receivingsGridOptions.api.setRowData(this.getAggregateReceivings);

      this.$nextTick(() => {
        this.setDefaultFilters();
      });
    },

    setColumnDefs() {
      this.receivingsGridOptions.api.setColumnDefs([
        { 
          field: 'receivingNO', 
          sort: 'desc', 
          headerName: !this.isMobile ? this.$t('Purchase.Receiving.Columns.ReceivingNO') : '#',
          headerTooltip: this.$t('Purchase.Receiving.Columns.ReceivingNO'),
          cellRenderer: 'linkRenderer',
          cellRendererParams: (params) => {
            return {
              route: { name: 'receiving', params: { id: params.data.receivingID } }
            }
          },
        },
        { hide: false, field: 'vendorName', headerName: this.$t('Purchase.Receiving.Columns.VendorName'), headerTooltip: this.$t('Purchase.Receiving.Columns.VendorName'),
          valueGetter: params => this.vendorNameGetter(params.data)
        },
        { hide: false, field: 'nbOfBodies', headerName: this.$t('Purchase.Receiving.Columns.ReceivingBodySums'), headerTooltip: this.$t('Purchase.Receiving.Columns.ReceivingBodySums') },
        { hide: false, field: 'createdDate', valueGetter: agGridGetters.dateWithMonthNamed, headerName: this.$t('Purchase.Receiving.Columns.CreatedDate'), headerTooltip: this.$t('Purchase.Receiving.Columns.CreatedDate') },
        { hide: this.isMobile, field: 'userUsername', headerName: this.$t('Purchase.Receiving.Columns.UserUsername'), headerTooltip: this.$t('Purchase.Receiving.Columns.UserUsername') },
        { hide: this.isMobile, field: 'receivingStatus', filter: 'agTextColumnFilter', valueGetter: params => { return this.$t(`Purchase.Receiving.Status.${params.data.receivingStatus}`); }, headerName: this.$t('Purchase.Receiving.Columns.ReceivingStatus'), headerTooltip: this.$t('Purchase.Receiving.Columns.ReceivingStatus') }
      ]);
    },
    newReceiving(){

      const selectedRows = this.ordersGridOptions.api.getSelectedRows()
      let data = null
      
      if(selectedRows && selectedRows.length > 0){
        data = { selectedRows } ;
      }
      
      this.$sidePanel.show([{ component: receivingManangement }], data, { backgroundIsDisabled: true });
    },
    receivingsFiltersChanged(filters){
      this.receivingsFilters = filters;
    },

    ordersFiltersChanged(filters){
      this.ordersFilters = filters;
    },

    setDefaultFilters(){
      const instance = this.receivingsGridOptions.api.getFilterInstance('receivingStatus');
      const voidStatusTranslation = this.$t(`Purchase.Receiving.Status.${ReceivingStatus.VOID}`);
      instance.setModel({ type: 'notEqual', filter: voidStatusTranslation});
      this.receivingsGridOptions.api.onFilterChanged();
    },

    vendorNameGetter(receivingBody){
      if(receivingBody.nbOfVendors == 1)
        return receivingBody.firstVendor;
      else if(receivingBody.nbOfVendors > 1)
        return this.$t('Purchase.Receiving.Columns.MultipleVendors');
      else
        return this.$t('General.NotAvailable')
    }
  }
}
</script>