import Vue from 'vue'
Vue.mixin({
  methods: {
    capitalizeFirstLetter(string) {
      if(string){
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
    },
    lowerFirstLetter(string) {
      if(string){
        return string.charAt(0).toLowerCase() + string.slice(1);
      }
    },
    isEmptyOrWhiteSpace(str) {
      if (typeof str === 'undefined' || str == null){
        return true;
      }
      return str.replace(/\s/g, '').length < 1;
    },
    compareCI(string1, string2){
      if(string1 && string2){
        return string1.toLowerCase() == string2.toLowerCase();
      }
      return false;
    }
  }
});
