import Api from '@/core/services/_api.config'
import { mapDtoToBrand, mapBrandToDto } from '@/backoffice/modules/brand/domain/brand.js'

const brandUrl = '/api/brand'

export default {
    getBrandById: async (id) => {
        const response = await Api().get(`${brandUrl}/${id}`)
        return mapDtoToBrand(response.data)
    },
    getBrands: async () => {
        const response = await Api().get(brandUrl)
        return response.data.map(d => mapDtoToBrand(d))
    },
    getCreationScope: async () => {
        const response = await Api().get(`${brandUrl}/getCreationScope`)
        return response.data
    },
    createBrand: async (brand) => {
        const dto = mapBrandToDto(brand)
        const response = await Api().post(brandUrl, dto)
        return mapDtoToBrand(response.data)
    },
    updateBrand: async (brand) => {
        const dto = mapBrandToDto(brand)
        const response = await Api().put(brandUrl, dto)
        return mapDtoToBrand(response.data)
    },
    deleteBrand: async (id) => {
        const response = await Api().delete(brandUrl, { data: id })
        return response.status === 200
    }
}