<template src="./scan.html"></template>

<style lang="scss">
    @import './scan.scss';
</style>

<script>
export default {
    name: 'scan-template',
    props:{
        label:{
            type: String,
            default: 'Scan.ScanProduct'
        }
    },
    data() {
        return {
            input: ''
        }
    },

    created: function () {
        window.addEventListener('keydown', this.handleNonPrintable);
        window.addEventListener('keypress', this.validateInput);
    },

    beforeDestroy: function () {
        window.removeEventListener('keydown', this.handleNonPrintable);
        window.removeEventListener('keypress', this.validateInput);
    },

    methods: {
        handleNonPrintable(evt) {
            const backspace = 8;
            if (evt.keyCode == backspace) {
                this.input = this.input.slice(0, -1)
                evt.preventDefault();
            }
        },

        async scan(){
            if (!this.input) {
                return;
            }

            this.$emit('onScan', this.input);
            this.input = '';
        },

        validateInput(evt) {
            const theEvent = evt || window.event;

            const enter = 13;
            if(theEvent.keyCode == enter) {
                this.scan();
                return;
            }

            let key;
            // Handle paste
            if (theEvent.type === 'paste') {
                key = evt.clipboardData.getData('text/plain');
            }
            // Handle key press
            else {
                key = theEvent.keyCode || theEvent.which;
                key = String.fromCharCode(key);
            }

            if (/[a-z0-9]/i.test(key)) {
                this.input += key
            }
            else {
                theEvent.returnValue = false;
                if(theEvent.preventDefault) {
                    theEvent.preventDefault();
                }
            }
        }
    }
}
</script>