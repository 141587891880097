export class Inventory {
  constructor() {
    this.targetLayerId = null;
    this.itemSku = null;
    this.itemUpc = null;
    this.qty = null;
    this.averageUnitCost = null;
  }
}

export const convertApiInventoryToInventory = (apiInventory) => {
  const inventory = new Inventory();

  if (!apiInventory) return inventory;

  inventory.targetLayerId = apiInventory.targetLayerID;
  inventory.itemSku = apiInventory.itemSKU;
  inventory.itemUpc = apiInventory.itemUPC;
  inventory.qty = apiInventory.qty;
  inventory.averageUnitCost = apiInventory.averageUnitCost || null;

  return inventory;
};
