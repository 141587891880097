<template src="./direct-receiving-product-search.html"></template>

<script>
import { mapGetters, mapActions } from 'vuex';
import receivingProduct from '@/backoffice/modules/purchase/components/sidePanel/direct-receivings/direct-receiving-product/direct-receiving-product.vue';
import receivingScan from '@/backoffice/modules/purchase/components/sidePanel/direct-receivings/direct-receiving-product-scan/direct-receiving-product-scan.vue';
import ProductSearch from '@/core/components/layout/side-panel/product-search/product-search.vue'
import SearchError from '@/backoffice/modules/purchase/components/sidePanel/orders/scan-search-error/error.vue'

export default {
    name: 'receiving-product-search',

    components: {
        ProductSearch,
        SearchError
    },

    computed:{
        ...mapGetters('Receiving', ['getReceiving']),
        ...mapGetters('Product', ['getProductWithVendorCosts']),
        vendorCode() {
            return this.getReceiving.vendorCode
        }
    },

    data() {
        return {
            invalid: false
        }
    },

    methods: {
        ...mapActions('Product', ['fetchProductCostsByVendor']),

        cancel(){
            this.$sidePanel.close();
        },

        closeError() {
            this.invalid = false;
        },

        back(){
            this.$sidePanel.show([{ component: receivingScan }], null, { backgroundIsDisabled: true });
        },

        scan() {
            this.invalid = false;
        },


        async select(product) {
            try {
                await this.fetchProductCostsByVendor({product: product, vendorCode: this.getReceiving.vendorCode});
            }
            catch (error) {
                return;
            }

            if (this.getProductWithVendorCosts && this.getProductWithVendorCosts.costs && this.getProductWithVendorCosts.costs.length > 0) {
                this.$sidePanel.show([{ component: receivingProduct }]);
            }
            else {
                this.invalid = true;
            }
        }
    }
}
</script>