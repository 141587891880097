import { widgetsServices } from '@/backoffice/modules/dashboard/services';
import errors from '@/core/tools/errors/errors';

const getDefaultState = () => {
  return {
    range: null,
    widgetClient: null,
    widgetGetStats: null,
    widgetGetStatsPer: null,
    widgetSalesPerRange: null,
    widgetSimple: null,
    widgetTopSales: null
  }
}

export const Widgets = {
  namespaced: true,
  name: 'Widgets',
  state: getDefaultState(),
  getters: {
    getClient: (state) => { 
      return state.widgetClient; 
    },
    getStats: (state) => { 
      return state.widgetGetStats; 
    },
    getStatsPer: (state) => { 
      return state.widgetGetStatsPer; 
    },
    salesPerRange: (state) => { 
      return state.widgetSalesPerRange; 
    },
    simple: (state) => { 
      return state.widgetSimple; 
    },
    topSales: (state) => { 
      return state.widgetTopSales; 
    },
    getRange: (state) => { 
      return state.range; 
    }
  },
  mutations: {
    //Reset store
    RESET_STORE(state){
      Object.assign(state, getDefaultState());
    },

    SET_WIDGET_CLIENT(state, widget) {
      state.widgetClient = widget;
    },
    SET_WIDGET_GET_STATS(state, widget) {
      state.widgetGetStats = widget;
    },
    SET_WIDGET_GET_STATS_PER(state, widget) {
      state.widgetGetStatsPer = widget;
    },
    SET_WIDGET_SALES_PER_RANGE(state, widget) {
      state.widgetSalesPerRange = widget;      
    },
    SET_WIDGET_SIMPLE(state, widget) {
      state.widgetSimple = widget;
    },
    SET_WIDGET_TOP_SALES(state, widget) {
      state.widgetTopSales = widget;
    },
    SET_RANGE(state, range){
      state.range = range;
    }
  },
  actions: {
    async getWidgetClient({ commit }, request){
      const response = await widgetsServices.getWidgetClient(request.params, request.cancelToken, request.targetIDSelected);
  
      if(response.status == 200) {
        commit('SET_WIDGET_CLIENT', response.data);
        return response.data;
      }
      else {
        errors.displayApiError(response);
        commit('SET_WIDGET_CLIENT', null);
        throw new Error(response);
      }
    },
    async getWidgetGetStats({ commit }, request){
      const response = await widgetsServices.getWidgetGetStats(request.params, request.cancelToken, request.targetIDSelected);
  
      if(response.status == 200) {
        commit('SET_WIDGET_GET_STATS', response.data);
        return response.data;
      }
      else {
        errors.displayApiError(response);
        commit('SET_WIDGET_GET_STATS', null);
        throw new Error(response);
      }
    },
    async getWidgetGetStatsPer({ commit }, request){
      const response = await widgetsServices.getWidgetGetStatsPer(request.params, request.cancelToken, request.targetIDSelected);
  
      if(response.status == 200) {
        commit('SET_WIDGET_GET_STATS_PER', response.data);
        return response.data;
      }
      else {
        errors.displayApiError(response);
        commit('SET_WIDGET_GET_STATS_PER', null);
        throw new Error(response);
      }
    },
    async getWidgetSalesPerRange({ commit }, request){
      const response = await widgetsServices.getWidgetSalesPerRange(request.params, request.cancelToken, request.targetIDSelected);
      commit('SET_RANGE', request.params.range);
      if(response.status == 200) {
        commit('SET_WIDGET_SALES_PER_RANGE', response.data);
        return response.data;
      }
      else {
        errors.displayApiError(response);
        commit('SET_WIDGET_SALES_PER_RANGE', null);
        throw new Error(response);
      }
    },
    async getWidgetSimple({ commit }, request){
      const response = await widgetsServices.getWidgetSimple(request.params, request.cancelToken, request.targetIDSelected);
  
      if(response.status == 200) {
        commit('SET_WIDGET_SIMPLE', response.data);
        return response.data;
      }
      else {
        errors.displayApiError(response);
        commit('SET_WIDGET_SIMPLE', null);
        throw new Error(response);
      }
    },
    async getWidgetTopSales({ commit }, request){
      const response = await widgetsServices.getWidgetTopSales(request.params, request.cancelToken, request.targetIDSelected);
  
      if(response.status == 200) {
        commit('SET_WIDGET_TOP_SALES', response.data);
        return response.data;
      }
      else {
        errors.displayApiError(response);
        commit('SET_WIDGET_TOP_SALES', null);
        throw new Error(response);
      }
    }
  }
}
