<template src="./purchase.html"></template>

<style lang="scss">
@import './purchase.scss';
</style>

<script>
export default {
  name: 'Purchase',
  components: {}
}
</script>
