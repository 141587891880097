<template src="./collapse.html"></template>
<style lang="scss" scoped src="./collapse.scss"></style>

<script>
export default {
  name: 'collapse',
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
        show: this.open
    }
  },
  methods: {
    toggle() {
        this.show = !this.show
    }
  }
}
</script>