import Api from '@/core/services/_api.config';

async function getReportList(modelName, getChildren, filters) {
  try {
    const response = await Api().get(`/api/Report/${modelName}/?getChildren=${getChildren}&filters=${encodeURIComponent(JSON.stringify(filters))}`)
    return { status: response.status, data: response.data }
  } catch (e) {
    if (e.response) {
      return { status: e.response.status, message: e.response.data, data: null };
    }
    else {
      return { message: e.message, data: null };
    }
  }
}

async function getReport(_ReportDataModelID, getChildren) {
  try {
    const response = await Api().get(`/api/Report/${_ReportDataModelID}?getChildren=${getChildren}`)
    return { status: response.status, data: response.data }
  } catch (e) {
    if (e.response) {
      return { status: e.response.status, message: e.response.data, data: null };
    }
    else {
      return { message: e.message, data: null };
    }
  }
}


async function deleteReport(ids) {
  try {
    const response = await Api().delete('/api/Report', {data: ids})
    return { status: response.status, data: null }
  } catch (e) {
    if (e.response) {
      return { status: e.response.status, message: e.response.data, data: null };
    }
    else {
      return { message: e.message, data: null };
    }
  }
}


async function updateReport(model) {
  try {
    const response = await Api().put('/api/Report', model)
    return { status: response.status, data: response.data }
  } catch (e) {
    if (e.response) {
      return { status: e.response.status, message: e.response.data, data: null };
    }
    else {
      return { message: e.message, data: null };
    }
  }
}

async function createReport(model) {
  try {
    const response = await Api().post('/api/Report', model)
    return { status: response.status, data: response.data }
  } catch (e) {
    if (e.response) {
      return { status: e.response.status, message: e.response.data, data: null };
    }
    else {
      return { message: e.message, data: null };
    }
  }
}

async function getReportFavorite(modelName) {
  try {
    const response = await Api().get(`/api/ReportDataModelFavorite/${modelName}`)
    return { status: response.status, data: response.data }
  } catch (e) {
    if (e.response) {
      return { status: e.response.status, message: e.response.data, data: null };
    }
    else {
      return { message: e.message, data: null };
    }
  }
}

async function manageReportFavorite(favoriteReport) {
  try {
    const response = await Api().post(`/api/ReportDataModelFavorite/${favoriteReport.type}/${favoriteReport.reportDataModelID}`)
    return {status: response.status, data: response.data}
  } catch (e) {
    if (e.response) {
      return { status: e.response.status, message: e.response.data, data: null };
    }
    else {
      return { message: e.message, data: null };
    }
  }
}

async function deleteReportFavorite(favoriteReport) {
  try {
    const response = await Api().delete(`/api/ReportDataModelFavorite/${favoriteReport.type}`)
    return { status: response.status, data: response.data }
  } catch (e) {
    if (e.response) {
      return { status: e.response.status, message: e.response.data, data: null };
    }
    else {
      return { message: e.message, data: null };
    }
  }
}

async function getDailyProducts(startDate, endDate, targetLayerId) {
  const response = await Api().get(`/api/Report/Movements/?startDate=${startDate}&endDate=${endDate}&targetLayerId=${targetLayerId}`) 
  return { status: response.status, data: response.data }
}

async function getCurrentInventories(targetLayerId) {
  const response = await Api().get(`/api/Report/CurrentInventories/?targetLayerId=${targetLayerId}`) 
  return { status: response.status, data: response.data }
}

async function getPromotionsReport(startDate, endDate, targetLayerId) {
  const response = await Api().get(`/api/Report/Promotions/?startDate=${startDate}&endDate=${endDate}&targetLayerId=${targetLayerId}`) 
  return { status: response.status, data: response.data }
}

async function getExceptionsReport(startDate, endDate, targetLayerId) {
  const response = await Api().get(`/api/Report/Exceptions/?startDate=${startDate}&endDate=${endDate}&targetLayerId=${targetLayerId}`) 
  return { status: response.status, data: response.data }
}

async function getTaxExemptionReport(startDate, endDate, targetLayerId) {
  const response = await Api().get(`/api/Report/taxexemptions/?startDate=${startDate}&endDate=${endDate}&targetLayerId=${targetLayerId}`) 
  return response.data
}

export const reportClient = {
  deleteReport,
  updateReport,
  createReport,
  getReportList,
  getReport,
  getReportFavorite,
  manageReportFavorite,
  deleteReportFavorite,
  getDailyProducts,
  getCurrentInventories,
  getPromotionsReport,
  getExceptionsReport,
  getTaxExemptionReport
};