
<template src="./main.html"></template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import creationButton from '@/core/components/common/creation-button/creation-button.vue';
import roleComponent from '@/backoffice/modules/role/components/role/role.vue';
import rolePermissionsComponent from '@/backoffice/modules/role/components/permissions/permissions.vue';
import posPermissionsComponent from '@/backoffice/modules/role/components/pos-permissions/pos-permissions.vue';
import searchBar from '@/core/components/common/search-bar/search-bar.vue';
import grid from '@/core/components/common/grid/grid.vue';
import prompts from '@/core/tools/notifications/notifications';

export default {
    name: 'roles',
    components: {
        creationButton,
        roleComponent,
        rolePermissionsComponent,
        posPermissionsComponent,
        searchBar,
        grid
    },

    data() {
        return {
            gridOptions: {
                headerHeight: 50,
                rowHeight: 50,
                defaultColDef: {
                    resizable: true,
                    sortable: true
                },
                columnDefs: [],
                rowSelection: 'single',
                overlayNoRowsTemplate: '<span>' + this.$t('Role.Messages.NoRole') + '</span>',
                onGridReady: () => {
                    this.init();
                }
            },
            filters: []
        }
    },
    computed: {
        ...mapGetters('App', ['isMobile']),
        ...mapGetters('Account', ['getCurrentTargetLayerId', 'hasPermission']),
        ...mapGetters('Role', ['getRoles']),

        canCreateRole() {
            return this.hasPermission(['RL3'])
        },

        columnDefs() {
            const targetCellRenderer = this.isMobile ? null : 'menuRenderer';
            const targetCellRendererParams = this.isMobile ? null : () => {
                return {
                    editEntity: params => this.openRolePermissionSP(params.data),
                    delete: params => this.deleteRolePrompt(params.data)
                }
            }
            const descriptioncellRenderer = this.isMobile ? 'dropdownRenderer' : 'clickCallbackRenderer';
            const descriptionCellRendererParams = this.isMobile ? (params) => {
                return {
                    openSidePanel: this.openRolePermissionSP,
                    canDelete: () => params.data.canDelete,
                    onDelete: this.deleteRolePrompt
                }
            } : {
                callback: this.openRolePermissionSP
            }            
            return [
                {
                    hide: this.isMobile, field: 'targetDescription', headerName: this.$t('Role.Columns.TargetDescription'), headerTooltip: this.$t('Role.Columns.TargetDescription'),
                    cellClass: 'ag-cell-overflow',
                    cellRenderer: targetCellRenderer,
                    cellRendererParams: targetCellRendererParams
                },                                
                {
                    field: 'description', headerName: this.$t('Role.Columns.Description'), headerTooltip: this.$t('Role.Columns.Description'),
                    cellClass: ['ag-cell-overflow', 'dropdown-cellrenderer'],
                    cellRenderer: descriptioncellRenderer,
                    cellRendererParams: descriptionCellRendererParams
                }
            ]
        },
        rowData() {
            return this.getRoles
        }
    },
    watch: {
        getCurrentTargetLayerId() {
            this.init();
        }
    },
    methods: {
        ...mapActions('Role', ['fetchRoles', 'deleteRole']),
        ...mapMutations('Role', ['SET_ROLE', 'SET_ROLE_PERMISSIONS', 'SET_POS_PERMISSIONS']),
        async init() {
            try {
                await this.fetchRoles()
            }
            catch (e) {
                prompts.error({
                    text: this.$t('Role.Messages.FetchRolesError')
                });
            }
        },
        async openRolePermissionSP(role) {            
            this.SET_ROLE_PERMISSIONS([])
            this.SET_POS_PERMISSIONS([])
            this.SET_ROLE(role)
            const success = await this.$sidePanel.show([{ component: roleComponent, tab:'Role.Roles' },
                                                        { component: rolePermissionsComponent, tab:'Role.Permissions' },
                                                        { component: posPermissionsComponent, tab:'Role.Pdv' }]);
            if(success)
                this.init()
        },
        async deleteRolePrompt(role) {
            const result = await prompts.warning({ html: this.$t('Role.Messages.DeleteRolePrompt') });
            if (result.isConfirmed) {
                
                try {
                    await this.deleteRole(role);
                    prompts.toast({
                        icon: 'success',
                        title: this.$t('Role.Messages.DeleteRoleSuccessfully', { role: role.description }),
                        position: 'bottom-end'
                    });
                }
                catch (e) {   
                    if(e.response.status == 409){
                        prompts.error({
                            text: this.$t('Role.Messages.DeleteRoleConflictError')
                        });
                    }

                    else{
                        prompts.error({
                            text: this.$t('Role.Messages.DeleteRoleError')
                        });
                    }
                }
            }
        },
        filtersChanged(filters) {
            this.filters = filters;
        },
    }
}
</script>