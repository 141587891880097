<template inline-template v-cloak src="./update-block.html"></template>

<style scoped lang="scss">
@import "./update-block.scss";
</style>

<script>
import NotificationBanner from "@/core/components/layout/notification-banner/notification-banner.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import moment from "moment";

export default {
  name: "NotificationBannerList",
  mounted(){
    this.fetchReleaseNotes();
  },
  components: {
    NotificationBanner,
  },
  computed: {
    ...mapGetters("App", [
      "getIsNewVersionServiceWorker"
    ]),
    ...mapGetters("ReleaseNote", [
      "getPublicationDate",
      "showUpcomingRelease",
      "showInProgressRelease"
    ]),
    isUpdateAvailable() {
      return !!this.getIsNewVersionServiceWorker;
    },
    publicationDate() {
      return moment(this.getPublicationDate).format("YYYY/MM/DD HH:mm");
    },
    inProgressReleaseEndTime() {
      return moment(this.getPublicationDate)
        .add(2, "h")
        .format("HH:mm");
    },
    mustShowUpcomingRelease() {
      return this.showUpcomingRelease
    },
    mustShowInProgressRelease() {
      return this.showInProgressRelease
    }
  },
  methods: {
    ...mapActions("ReleaseNote", ["fetchReleaseNotes"]),
    ...mapMutations("ReleaseNote", {
      closeUpcomingReleaseNotification: "CLOSE_UPCOMING_RELEASE_NOTIFICATION",
    }),
    ...mapActions("App", [
      "resetRegisteredModules",
      "setNewVersionServiceWorker",
    ]),
    ...mapActions('Account', ['logout']),
    updateServiceWorker() {
      if (window.navigator && navigator.serviceWorker) {
        navigator.serviceWorker
          .getRegistrations()
          .then(function (registrations) {
            for (const registration of registrations) {
              registration.unregister();
            }
          });
      }

      if (navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage("ForceUpdate");
      }

      //Clear all registered modules on service worker update (we don't want to keep old out-of-date data)
      this.resetRegisteredModules();

      setTimeout(() => {
        this.logout();
        this.setNewVersionServiceWorker(false);
        window.location.reload();
      }, 1000);
    },
    handleFutureReleaseBannerClosed() {
      this.closeUpcomingReleaseNotification();
    },
  }
};
</script>