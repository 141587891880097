<template src="./boolean-renderer.html"></template>

<style scoped lang="scss">
@import './boolean-renderer.scss';
</style>

<script>

export default{
  name: 'booleanRenderer'
};
</script>
