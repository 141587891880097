<template src="./tax-exemption-report.html"></template>
<style lang="scss" src="./tax-exemption-report.scss"></style>

<script>
import { mapGetters } from 'vuex';
import prompts from '@/core/tools/notifications/notifications';
import searchBar from '@/core/components/common/search-bar/search-bar.vue';
import targetDropdown from '@/core/components/common/drop-down/target-dropdown/target-dropdown.vue';
import periodPicker from '@/backoffice/modules/reporting/components/period-picker/period-picker.vue';
import grid from '@/core/components/common/grid/persisted-grid.vue'
import extractTime from '@/backoffice/modules/reporting/mixins/extract-time.js';
import formatters from '@/core/components/common/grid/formatters/formatters.js';
import { GridId } from '@/core/components/common/grid/grid.const.js'
import { reportClient } from '@/backoffice/modules/reporting/services/report.client.js'

export default {
    name: 'TaxExemptionReport',
    mixins: [extractTime],
    components: {
        searchBar,
        targetDropdown,
        periodPicker,
        grid,
    },

    data() {
        return {
            filters: null,
            period: '-0w',
            startDate: null,
            endDate: null,
            targetLayerId: null,
            targetDropdownKey: 0,
            taxExemptions: [],
            gridOptions: {
                groupIncludeTotalFooter: true,
                rowHeight: 30,
                headerHeight: 30
            },
            periodPickerOptions: [
                { name: "RangePicker.today", value: "-0d", selected: false },
                { name: "RangePicker.yesterday", value: "-1d", selected: false },
                {
                    name: "RangePicker.this", selected: false, choices: [
                        { name: "RangePicker.thisWeek", value: "-0w", selected: false },
                        { name: "RangePicker.thisMonth", value: "-0m", selected: false },
                        { name: "RangePicker.thisQuarter", value: "-0q", selected: false },
                        { name: "RangePicker.thisYear", value: "-0y", selected: false }
                    ]
                },
                {
                    name: "RangePicker.last", selected: false, choices: [
                        { name: "RangePicker.lastWeek", value: "-1w", selected: false },
                        { name: "RangePicker.lastMonth", value: "-1m", selected: false },
                        { name: "RangePicker.lastQuarter", value: "-1q", selected: false },
                        { name: "RangePicker.lastYear", value: "-1y", selected: false }
                    ]
                },
                { name: "RangePicker.custom", dates: "", selected: false, isDateRangePicker: true, value: "" }
            ],
            gridId: GridId.TaxExemptions
        }
    },

    computed: {
        ...mapGetters('Account', ['getCurrentTargetLayerId']),
        rowData() {
            return this.taxExemptions
        },
        columnDefs(){
            return [
                    {
                        headerName: this.$t('TaxExemption.Report.Site'), headerTooltip: this.$t('TaxExemption.Report.Site'),
                        children: [
                            { field: 'enterprise', headerName: this.$t('TaxExemption.Report.Enterprise'), headerTooltip: this.$t('TaxExemption.Report.Enterprise'), enableRowGroup: true },
                            { field: 'store', headerName: this.$t('TaxExemption.Report.Store'), headerTooltip: this.$t('TaxExemption.Report.Store'), enableRowGroup: true },
                        ]
                    },
                    {
                        headerName: this.$t('TaxExemption.Report.Client'), headerTooltip: this.$t('TaxExemption.Report.Client'),
                        children: [
                            { field: 'clientName', hide: true, headerName: this.$t('TaxExemption.Report.ClientName'), headerTooltip: this.$t('TaxExemption.Report.ClientName'), enableRowGroup: true, rowGroupIndex: 0 },
                            { field: 'licenceNumber', headerName: this.$t('TaxExemption.Report.LicenceNumber'), headerTooltip: this.$t('TaxExemption.Report.LicenceNumber') },
                            { field: 'taxExemptionNumber', headerName: this.$t('TaxExemption.Report.TaxExemptionNumber'), headerTooltip: this.$t('TaxExemption.Report.TaxExemptionNumber') }
                        ]
                    },
                    {
                        headerName: this.$t('TaxExemption.Report.Tax'), headerTooltip: this.$t('TaxExemption.Report.Tax'),
                        children: [
                            { field: 'taxDescription', headerName: this.$t('TaxExemption.Report.TaxName'), headerTooltip: this.$t('TaxExemption.Report.TaxName'), enableRowGroup: true, rowGroupIndex: 0 },
                            { field: 'amount', headerName: this.$t('TaxExemption.Report.ExemptedAmount'), headerTooltip: this.$t('TaxExemption.Report.ExemptedAmount'), enableValue: true, aggFunc: 'sum', valueFormatter: formatters.currencyFormatter, filter: 'agNumberColumnFilter' },
                        ]
                    }
                ]
        }
    },

    watch: {
        getCurrentTargetLayerId() {
            this.targetDropdownKey++;
        }
    },

    methods: {
        async fetchTaxExemptions() {
            const dates = this.convert(this.period)
            this.startDate = dates.start
            this.endDate = dates.end

            try {
                this.taxExemptions = await reportClient.getTaxExemptionReport(this.startDate, this.endDate, this.targetLayerId);
            }
            catch (e) {
                this.taxExemptions = []
                prompts.error({
                    text: this.$t('TaxExemption.Message.GetTaxExemptionReportError')
                });
            }

            
        },
        periodChanged(period) {
            this.period = period;
            this.fetchTaxExemptions();
        },
        onTargetChanged(target) {
            this.targetLayerId = target.targetLayerId;
            this.fetchTaxExemptions();
        },
        filtersChanged(filters) {
            this.filters = filters;
        }
    }
}
</script>