<template inline-template v-cloak src="./login.html"></template>

<style scoped lang="scss">
@import '@/core/pages/account/account.scss';
</style>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'basic-login',
  data() {
    return {
      username: '',
      password: '',
      authType: '',
      rememberLogin: false,
    }
  },

  computed: {
    ...mapGetters('App', ['getRememberMe'])
  },

  mounted(){
    this.resetStoreAndModules();
    const rememberMe = this.getRememberMe;
    if(rememberMe && rememberMe.rememberLogin){
      this.rememberLogin = rememberMe.rememberLogin;
      this.username = rememberMe.username;
    }
  },
  methods: {
    ...mapActions('App', ['setRememberMe']),
    ...mapActions('Account', ['login', 'resetStoreAndModules']),
    ...mapActions('ReleaseNote', ['fetchReleaseNotes']),
    rememberMe(){
      this.setRememberMe({ rememberLogin: this.rememberLogin, username: this.username });
    },
    executelogin(e){
      e.preventDefault();
      const user = {
        username: this.username,
        password: this.password,
        authType: this.authType
      }
      this.setRememberMe({ rememberLogin: this.rememberLogin, username: this.username });
      this.login(user);
      this.fetchReleaseNotes();
    },
  }
}
</script>