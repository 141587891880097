<template src="./toggle.html"></template>

<style scoped lang="scss">
@import './toggle.scss';
</style>

<script>
import { TOGGLE_STATE } from './toggle.const.js'

export default{
    name: 'toggle',

    props: {
        value: [Boolean, Number],
        disabled: Boolean,
        smallToggle: Boolean
    },

    computed: {
        checked: {
            get() {
                const isChecked = (this.value || this.value === TOGGLE_STATE.ON) || this.value === TOGGLE_STATE.INDETERMINATE;
                return isChecked;
            },
            set(checked) {
                this.$emit('input', checked);
            }
        },
        isIndeterminate() {
            return this.value === TOGGLE_STATE.INDETERMINATE;
        }
    },

    watch: {
        isIndeterminate: {
            handler() {
                if (this.$refs.checkbox)
                    this.$refs.checkbox.indeterminate = this.isIndeterminate;
            },
            immediate: true
        }
    },

    mounted() {
        this.$refs.checkbox.indeterminate = this.isIndeterminate;
    }
};
</script>
