<template src="./order-product-renderer.html"></template>

<style scoped lang="scss">
@import './order-product-renderer.scss';
</style>

<script>
export default {
    name: 'Order-Product-Renderer',
    props: ['params']
}
</script>