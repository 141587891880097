<template inline-template v-cloak src="./creation-button.html"></template>

<style scoped lang="scss">
  @import "./creation-button.scss";
</style>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'creation-button',

  props: {
    label: String,
    onClick: Function
  },

  data() {
    return {
    };
  },

  computed: {
    ...mapGetters('App', ['isMobile'])
  }
};
</script>
