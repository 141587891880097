<template src="./priceLevel-management.html"></template>

<script>
import { mapGetters } from 'vuex'
import DescriptionInput from '@/core/components/common/description-input/description-input.vue'
import { ValidationObserver, ValidationProvider, localize } from "vee-validate"
import * as PriceLevelServices from '@/backoffice/modules/priceLevel/services/priceLevel.services'
import { permissionsDictionary } from '@/core/security/permissions-dictionary.js'
import { PriceLevel } from '@/backoffice/modules/priceLevel/domain/priceLevel'
import prompts from '@/core/tools/notifications/notifications'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'

export default {
    name: 'PriceLevelManagement',

    components: {
        DescriptionInput,
        ValidationObserver,
        ValidationProvider,
        localize,
    },

    data() {
        return {
            priceLevel: null,
            preventSave: false
        }
    },

    computed: {
        ...mapGetters('PriceLevel', ['getPriceLevel']),
        ...mapGetters('Account', ['hasPermission', 'getCurrentTarget']),

        isReadOnly() {
            return this.priceLevel?.targetId !== this.getCurrentTarget._TargetID || !this.hasPermission([permissionsDictionary.ITEM_PRICE_UPDATE])
        },

        isCreation() {
            return !this.priceLevel.id
        },

        isEdited() {
            if (this.isCreation)
                return true

            return !isEqual(this.priceLevel, this.getPriceLevel)
        }
    },

    mounted() {
        localize(this.$i18n.locale)
        this.setPriceLevel()
    },

    methods: {
        setPriceLevel() {
            if (!this.getPriceLevel) {
                this.priceLevel = new PriceLevel(null, this.getCurrentTarget._TargetID, null, false, [], this.getCurrentTarget.description)
                return
            }

            this.priceLevel = cloneDeep(this.getPriceLevel)
        },

        async save() {
            let priceLevel = null
            try {
                priceLevel = await PriceLevelServices.savePriceLevel(this.priceLevel)
            } catch (error) {
                prompts.error({
                    text: this.$t('PriceLevel.Messages.SavePriceLevelError')
                })
            }

            if (priceLevel) {
                this.$sidePanel.submit(priceLevel)
                prompts.toast({
                    icon: 'success',
                    title: this.$t('PriceLevel.Messages.SavePriceLevelSuccess'),
                    position: 'bottom-end'
                })
            }
        },

        cancel() {
            this.$sidePanel.close()
        }
    }
}
</script>