export const TargetDropdownJs = {
  removeAccent(text) {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  },

  normalizedContains(search, description) {
    const searchNormalized = this.removeAccent(search).toLowerCase();
    const descriptionNormalized = this.removeAccent(description).toLowerCase();
    const result = descriptionNormalized.includes(searchNormalized);
    return result;
  },

  // Sorting mechanism on option list 
  specificSort(optionX, optionY) {
    if (optionX.targetLayerPriority < optionY.targetLayerPriority) return -1;
    if (optionX.targetLayerPriority > optionY.targetLayerPriority) return 1;
    if (optionX.targetPriority < optionY.targetPriority) return -1;
    if (optionX.targetPriority > optionY.targetPriority) return 1;
    if (optionX.description < optionY.description) return -1;
    if (optionX.description > optionY.description) return 1;
    return 0;
 }

}
