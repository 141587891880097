
const getDefaultState = () => {
  return {
    context: {}
  };
};

export default {
  namespaced: true,
  name: 'Breadcrumb',
  state: getDefaultState(),
  getters: {
    getContext: state => {
      return state.context;
    }
  },
  mutations: {
    RESET_STORE(state){
      Object.assign(state, getDefaultState());
    },
    
    SET_CONTEXT(state, context){
      state.context = context;
    }
  }
}