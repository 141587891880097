<template src="./target-dropdown.html"></template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>

import multiselect from 'vue-multiselect';
import { TargetDropdownJs } from './target-dropdown.js';
import { mapGetters } from 'vuex'

export default {
  name: 'targetDropdown',
  props: {
    classes: [String, Object],
    targetId: Number
  },
  components: {
    multiselect
  },
  data() {
    return {
      multiSelect: {
        value: null,
        options: [],
        trackBy: 'targetId',
        label: 'description',
        tag: false,
        showLabels: false,
        placeholder: this.$t('Target.TargetDescriptionPh'),
        allowEmpty: false,
        onSelect: this.emitTargetChanged
      },
    };
  },

  computed: {
    ...mapGetters('Account', ['getTargets'])
  },

  mounted() {
    this.setList();
  },

  methods: {
    setList() {
      let selectedTargetIdx = null
      this.getTargets.sort(TargetDropdownJs.specificSort);
      this.getTargets.forEach((target, idx) => {
        if (this.targetId == target._TargetID) {
          selectedTargetIdx = idx
        }
        this.multiSelect.options.push({
          targetId: target._TargetID,
          targetLayerId: target._TargetLayerID,
          description: target.description
        });
      });

      const idx = selectedTargetIdx || 0
      this.multiSelect.value = this.multiSelect.options[idx];

      if (this.multiSelect.value){
        this.emitTargetChanged(this.multiSelect.value);
      }
    },

    emitTargetChanged(target) {
      this.$emit('target-changed', target);
    }
  }
}
</script>
