import { descriptionLanguages } from '@/core/domain/language/language.const.js';

const DocumentType = {
	Count: 'Count',
	AdjustmentPlus: 'Adjustment+',
	AdjustmentMinus: 'Adjustment-'
};

const DocumentStatus = {
	Open: 'OPEN',
	Suspended: 'SUSPENDED',
	Closed: 'CLOSED'
};

const UnitUOM = {
	canDelete: true,
	canUpdate: true,
	description: "Unité",
	descriptions: [
		{
			id: 1, 
			shortDescription: "U", 
			longDescription: "Unité", 
			_DescriptionID: 1, 
			language: descriptionLanguages.FR
		},
		{
			id: 1, 
			shortDescription: "U", 
			longDescription: "Unit", 
			_DescriptionID: 2, 
			language: descriptionLanguages.EN
		}
	],
	id: 1,
	targetDescription: "SIR",
	targetID: 1,
	uomCode: "EA"
}
export {
	DocumentType,
	DocumentStatus,
	UnitUOM
}