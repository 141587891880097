export class Client {
    //TODO: Refactor this constructor
    constructor({id, targetId, targetDescription, addressId, accountNumber, email, 
                creditLimit, isChargeToAccountEnabled, isExemptedFromTaxation, taxExemptionNumber, licenseNumber, balance, accountStatementLastSentDate, lastPayment,
                firstName, lastName, phone, company, fileNumber, language, priceLevelID, isActive, creationDate, 
                creationStoreId, creationUserId, streetNumber, street, apartment, town, state, zipCode, country}) {
        this.id = id
        this.targetId = targetId
        this.targetDescription = targetDescription
        this.addressId = addressId
        this.accountNumber = accountNumber
        this.email = email
        this.creditLimit = creditLimit === undefined ? null : creditLimit
        this.isChargeToAccountEnabled = isChargeToAccountEnabled
        this.isExemptedFromTaxation = isExemptedFromTaxation
        this.taxExemptionNumber = taxExemptionNumber
        this.licenseNumber = licenseNumber
        this.balance = balance === undefined ? null : balance
        this.accountStatementLastSentDate = accountStatementLastSentDate
        this.lastPayment = lastPayment
        this.firstName = firstName
        this.lastName = lastName
        this.fullName = `${firstName} ${lastName}`
        this.phone = phone
        this.company = company
        this.fileNumber = fileNumber
        this.language = language
        this.priceLevelID = priceLevelID || null
        this.creationDate = creationDate
        this.creationStoreId = creationStoreId
        this.creationUserId = creationUserId
        this.isActive = isActive
        this.streetNumber = streetNumber
        this.street = street
        this.apartment = apartment
        this.town = town
        this.state = state
        this.zipCode = zipCode
        this.country = country
    }
}

export class ClientDto {
    //TODO: Refactor this constructor
    constructor({ _ClientID, _TargetID, targetDescription, _AddressID, accountNumber, email, creditLimit, chargeToAccount, isExemptedFromTaxation, taxExemptionNumber, licenseNumber, accountBalance,
                 accountStatementLastSentDate, lastPayment, firstName, lastName, phone, cieName, fileNumber, language, priceLevelID, creationDate, _CreationStoreID, 
                 _CreationUserID, isActive, streetNumber, street, apartment, town, state, zipCode, country }) {
        this._ClientID = _ClientID
        this._TargetID = _TargetID
        this.targetDescription = targetDescription
        this._AddressID = _AddressID
        this.accountNumber = accountNumber
        this.email = email
        this.creditLimit = creditLimit        
        this.chargeToAccount = chargeToAccount
        this.isExemptedFromTaxation = isExemptedFromTaxation
        this.taxExemptionNumber = taxExemptionNumber
        this.licenseNumber = licenseNumber
        this.accountBalance = accountBalance
        this.accountStatementLastSentDate = accountStatementLastSentDate
        this.lastPayment = lastPayment
        this.firstName = firstName
        this.lastName = lastName
        this.phone = phone
        this.cieName = cieName
        this.fileNumber = fileNumber
        this.language = language
        this.priceLevelID = priceLevelID || null
        this.creationDate = creationDate
        this._CreationStoreID = _CreationStoreID
        this._CreationUserID = _CreationUserID
        this.isActive = isActive
        this.streetNumber = streetNumber
        this.street = street
        this.apartment = apartment
        this.town = town
        this.state = state
        this.zipCode = zipCode
        this.country = country
    }
}

//TODO: Move to clientAccount.client
export function mapDtoToClient(clientDto) {
    if (!clientDto)
        return null

    return new Client({
        id: clientDto._ClientID,
        targetId: clientDto._TargetID,
        targetDescription: clientDto.targetDescription,
        addressId: clientDto._AddressID,
        accountNumber: clientDto.accountNumber,
        email: clientDto.email,
        creditLimit: clientDto.creditLimit,
        isChargeToAccountEnabled: clientDto.chargeToAccount,
        isExemptedFromTaxation: clientDto.isExemptedFromTaxation,
        taxExemptionNumber: clientDto.taxExemptionNumber,
        licenseNumber: clientDto.licenseNumber,
        balance: clientDto.accountBalance,
        accountStatementLastSentDate: clientDto.accountStatementLastSentDate,
        lastPayment: clientDto.lastPayment,
        firstName: clientDto.firstName,
        lastName: clientDto.lastName,
        phone: clientDto.phone,
        company: clientDto.cieName,
        fileNumber: clientDto.fileNumber,
        language: clientDto.language,  
        priceLevelID: clientDto.priceLevelID,
        creationDate: clientDto.creationDate,    
        creationStoreId: clientDto._CreationStoreID,
        creationUserId: clientDto._CreationUserID,
        isActive: clientDto.isActive,
        streetNumber: clientDto.streetNumber,
        street: clientDto.street,
        apartment: clientDto.apartment,
        town: clientDto.town,
        state: clientDto.state,
        zipCode: clientDto.zipCode,
        country: clientDto.country
    })
}

//TODO: Move to clientAccount.client
export function mapClientToDto(client){
    if (!client)
        return null

    return new ClientDto({
        _ClientID: client.id,
        _TargetID: client.targetId,
        targetDescription: client.targetDescription,
        _AddressID: client.addressId,
        accountNumber: client.accountNumber,
        email: client.email,
        creditLimit: client.creditLimit,
        chargeToAccount: client.isChargeToAccountEnabled,
        accountBalance: (client.isChargeToAccountEnabled && !client.balance) ? 0 : client.balance,
        isExemptedFromTaxation: client.isExemptedFromTaxation,
        taxExemptionNumber: client.taxExemptionNumber,
        licenseNumber: client.licenseNumber,
        accountStatementLastSentDate: client.accountStatementLastSentDate,
        lastPayment: client.lastPayment,
        firstName: client.firstName,
        lastName: client.lastName,
        phone: client.phone,
        cieName: client.company,
        fileNumber: client.fileNumber,
        language: client.language,
        priceLevelID: client.priceLevelID,
        creationDate: client.creationDate,
        _CreationStoreID: client.creationStoreId,
        _CreationUserID: client.creationUserId,
        isActive: client.isActive,
        streetNumber: client.streetNumber,
        street: client.street,
        apartment: client.apartment,
        town: client.town,
        state: client.state,
        zipCode: client.zipCode,
        country: client.country
    })
}

export const Languages = {
    FR: 'FR',
    EN: 'EN'
}

export const Countries = {
    USA: 'USA',
    Canada: 'Canada'
}

export const CanadianProvinces = {
    NL: 'NL',
    PE: 'PE',
    NS: 'NS',
    NB: 'NB',
    QC: 'QC',
    ON: 'ON',
    MB: 'MB',
    SK: 'SK',
    AB: 'AB',
    BC: 'BC',
    NU: 'NU',
    NT: 'NT',
    YT: 'YT'
}   
export const USAStates = {
    AL: 'AL',
    AK: 'AK',
    AZ: 'AZ',
    AR: 'AR',
    CA: 'CA',
    CO: 'CO',
    CT: 'CT',
    DE: 'DE',
    FL: 'FL',
    GA: 'GA',
    HI: 'HI',
    ID: 'ID',
    IL: 'IL',
    IN: 'IN',
    IA: 'IA',
    KS: 'KS',
    KY: 'KY',
    LA: 'LA',
    ME: 'ME',
    MD: 'MD',
    MA: 'MA',
    MI: 'MI',
    MN: 'MN',
    MS: 'MS',
    MO: 'MO',
    MT: 'MT',
    NE: 'NE',
    NV: 'NV',
    NH: 'NH',
    NJ: 'NJ',
    NM: 'NM',
    NY: 'NY',
    NC: 'NC',
    ND: 'ND',
    OH: 'OH',
    OK: 'OK',
    OR: 'OR',
    PA: 'PA',
    RI: 'RI',
    SC: 'SC',
    SD: 'SD',
    TN: 'TN',
    TX: 'TX',
    UT: 'UT',
    VT: 'VT',
    VA: 'VA',
    WA: 'WA',
    WV: 'WV',
    WI: 'WI',
    WY: 'WY',
}