import api from '@/core/services/_api.config'

const url = '/api/priceLevel/'

export default {
    getPriceLevels: async () => {
        return await api()
                    .get(url)
                    .catch((error) => {
                        throw error.response
                    })
    },
    createPriceLevel: async (priceLevelDto) => {
        return await api()
                    .post(url, priceLevelDto)
                    .catch((error) => {
                        throw error.response
                    })
    },
    updatePriceLevel: async (priceLevelDto) => {
        return await api()
                    .put(url, priceLevelDto)
                    .catch((error) => {
                        throw error.response
                    })
    },
    deletePriceLevel: async (id) => {
        return await api()
            .delete(`${url}${id}`)
            .catch((error) => {
                throw error.response
            })
    }
}