import { render, staticRenderFns } from "./checkbox.html?vue&type=template&id=796858b5&scoped=true&"
import script from "./checkbox.vue?vue&type=script&lang=js&"
export * from "./checkbox.vue?vue&type=script&lang=js&"
import style0 from "./checkbox.vue?vue&type=style&index=0&id=796858b5&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "796858b5",
  null
  
)

export default component.exports