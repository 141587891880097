import axios from 'axios';
import vuex from '@/core/store';
export default (token = vuex.getters['Account/getToken']) => {
  return axios.create({
    baseURL: vuex.getters['App/getWSConfig'].baseUrl,
    headers: {
      'Access-Control-Allow-Origin': '*',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token
    }
  })
}
