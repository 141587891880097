<template src="./input-button.html"></template>

<style scoped lang="scss">@import "./input-button.scss";</style>

<script>

import iconButton from '@/core/components/common/icon-button/icon-button.vue'

export default {
  name: 'input-button',
  props: {
    isBlue: {
      type: Boolean,
      default: true
    },
    name: {
      type: String,
      default: 'defaultName'
    },
    id: {
      type: String,
      default: 'applyQuantity-input'
    },
    placeholder: {
      type: String,
      default: ''
    },
    buttonDisabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: 'fa-regular fa-check'
    },
    inputType: {
      type: String,
      defautl: 'text'
    },
    minValue: {
      type: String,
      defautl: 0
    },
    maxValue: {
      type: String,
      defautl: Number.MAX_SAFE_INTEGER
    },
    maxLength: {
      type: String,
      default: '150'
    }
  },
  components: {
    iconButton
  },
  data() {
    return {
      sValue: 1
    }
  },
  watch: {
    queryStringFilters: {
      handler() {
        this.emitFilterChanged();
      }
    },
  },
  methods: {
    commitValue(){
      this.$emit("commitValue", this.sValue)
    },
    change(e){
      if(e.target.value.length > parseInt(this.maxLength)){
        const trimedValue = e.target.value.substring(0, parseInt(this.maxLength))
        if(this.inputType === 'number'){
          const convertedValue = parseInt(trimedValue)
          this.sValue = convertedValue > parseInt(this.maxValue) ? this.maxValue: convertedValue
        }else{
          this.sValue = trimedValue
        }
      }
    }
  }
}
</script>