<template src="./icon-picker.html"></template>
<style lang="scss" scoped src="./icon-picker.scss"></style>

<script>
export default {
  name: "iconPicker",
  model: {
    prop: 'icon'
  },
  props: {
    icon: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      localIcon: this.icon,
      fontAwesomeUrl: 'https://fontawesome.com/icons'
    };
  },
  methods: {
    updateValue: function (evt) {
      this.$emit("input", evt.target.value);
    }
  }
}
</script>