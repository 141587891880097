<template inline-template v-cloak src="./side-nav.html"></template>

<style scoped lang="scss">
  @import './side-nav.scss';
</style>

<script>
import { mapGetters, mapActions } from 'vuex';
import { languageMapping } from '@/core/domain/language/language.const.js';

export default{
  name: 'SideNav',
  data() {
    return {
      loaded: false,
      selectedParent: null,
      sideNavs: {},
      isUserMenuOpen: false,
      profileRoute: { name: 'Profil' },
      releaseNotesRoute: { name: 'releaseNotes' }
    }
  },
  computed: {
    ...mapGetters('Account', ['hasPermission', 'getCurrentTargetLayerId', 'getFirstname', 'getLastname']),
    ...mapGetters('App', ['getSideNavStatus', 'isMobile', 'getLanguage']),
    open: {
      get() {
        return this.getSideNavStatus;
      },
      set (value) {
        this.setSideNavStatus(value);
      }
    },
    isMobileMode(){
      return this.isMobile
    },
    userName(){
      return `${this.getFirstname ?? ''} ${this.getLastname ?? ''}`
    },
    language(){
      return this.getLanguage
    },
    sideNavClasses(){
      return this.language === languageMapping.en ? 'capitalized' : ''
    }
  },

  watch: {
    getCurrentTargetLayerId(){
      this.buildSideNavs();
    }
  },

  created() {
    this.buildSideNavs();
  },

  methods:{
    ...mapActions('App', ['setSideNavStatus', 'setAppLanguage']),
    ...mapActions('Account', ['logout']),

    toggleLanguage(){
      if(this.language === languageMapping.fr){
        this.setAppLanguage(languageMapping.en);
      }
      else{
        this.setAppLanguage(languageMapping.fr)
      }
    },

    toggleUserMenu(){
      this.isUserMenuOpen = !this.isUserMenuOpen
    },

    buildSideNavs() {
      const routes = this.$router.options.routes;
      const builtSideNav = this.parseSideNav(routes);
      this.sideNavs = this.orderSideNav(builtSideNav);
      this.loaded = true;
    },
    
    parseSideNav(routes) {
      let sideNavs = {};

      routes.forEach(route => {
        const meta = route.meta || {};
        
        if (meta.sideNav) {
          sideNavs[route.name] = {
              ...meta.sideNav,
              show: this.hasPermission(meta.permissions) && !meta.disabled,
              routeName: route.name,
              children: null
            };

          if (route.children) {
            sideNavs[route.name].children = this.parseSideNav(route.children);
          }
        }
        else if (route.children) {
          const sideNavsFromChildren = this.parseSideNav(route.children);
          sideNavs = {
            ...sideNavs,
            ...sideNavsFromChildren
          };
        }
      });

      return sideNavs;
    },

    orderSideNav(sideNavs) {
      const sideNavsSorted = Object.keys(sideNavs).sort((keyA, keyB) => {
        if (sideNavs[keyA].orderIndex < sideNavs[keyB].orderIndex) { return -1; }
        if (sideNavs[keyA].orderIndex > sideNavs[keyB].orderIndex) { return 1; }
        return 0;
      }).reduce((obj, key) => {
        obj[key] = sideNavs[key];
        return obj;
      }, {});

      return sideNavsSorted;
    },

    highlight(nav) {
      return this.$route.matched.some(m => m.name == nav.routeName) || nav.routeName == this.$route.meta.associatedParentRouteName;
    },

    manageNav(nav) {
      if (nav !== this.selectedParent && nav.children) {
        this.openSideNav(nav);
      }
      else {
        this.closeSideNav();
      }
    },

    openSideNav(nav) {
      this.open = true;
      this.selectedParent = nav;
    },

    closeSideNav() {
      this.open = false;
      this.selectedParent = null;
    },

    toggleSideNav() {
      this.open = !this.open;
      this.selectedParent = null;
    },

    closeNavigation(event) {
      if (event && !event.target.id === 'mobile-menu-icon') {
        this.closeSideNav();
      }
    }
  }
}
</script>
