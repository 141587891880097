import i18n from "@/i18n";
import { toWeight, toCurrency, toPercent, toNumber, toDate, toDateTime, toLongDate } from "@/core/functions/format-value.js"

const agGridFormatters = {
  weightFormatter(params) {
    return toWeight(params.value);
  },
  quantityFormatter(params){
    if(params.value && Math.floor(params.value) !== params.value && params.value.toString().split(".")[1].length >= 3){
      return params.value.toFixed(3);
    }
    else{ 
      return params.value;
    }
  },
  dateFormatter(params) {
    if (params.data && params.node && !params.node.rowPinned && params.value) {
      return toDate(params.value);
    }
    else {
      return null;
    }
  },
  datetimeFormatter(params) {
    if (params.data && params.node && !params.node.rowPinned && params.value) {
      return toDateTime(params.value);
    }
    else {
      return null;
    }
  },
  currencyFormatter(params) {
    if (!isNaN(params?.value)) {
      return toCurrency(params.value);
    }
  },
  percentageFormatter(params) {
    return toPercent(params.value);
  },
  numberFormatter(params) {
    return toNumber(params.value);
  },
  checkFormatter(params) {
    if (params.data && params.node && !params.node.rowPinned && params.value) {
      return "&#10003;";
    }
  },
  translateFormatter(params) {
    if (params.node && !params.node.group && !params.node.rowPinned && (params.value === "" || !params.value)) {
      return i18n.t('Reporting.DataDef.NotAvailable');
    }
    else {
      if (params.value) {
        const translation = i18n.t('Reporting.DataDef.' + params.value);
        return translation == 'Reporting.DataDef.' + params.value ? params.value : translation;
      }
    }
  },
  totalizerFormatter(params) {
    if (params.node && !params.node.group && !params.node.rowPinned && (params.value === "" || !params.value)) {
      return i18n.t('Reporting.DataDef.NotAvailable');
    }
    else {
      if (params.value) {
        const translation = i18n.t('Reporting.DataDef.Tlz_' + params.value);
        return translation == 'Reporting.DataDef.Tlz_' + params.value ? params.value : translation;
      }
    }
  },
  promoCurrencyFormatter(params) {
    if (params?.value == 0 && params?.data?.hasPromotion) {
      return toCurrency(params.value);
    }
    if (params?.value) {
      return toCurrency(params.value);
    }
    return ''
  },
  multiplePromoDateFormatter(params) {
    if (params.data && params.node && !params.node.rowPinned) {
      if (params.data.hasMultiplePromotion) {
        return i18n.t('LabelBatch.Multiples');
      }
      else if (params.value) {
        return toLongDate(params.value);
      }
      else {
        return i18n.t('LabelBatch.NA');
      }
    }
  }
}

export default agGridFormatters;

