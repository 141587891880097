<template src="./tooltip.html"></template>

<style scoped lang="scss">
@import './tooltip.scss';
</style>

<script>
const defaultDescription = 'Tooltip text';
export default{
    name: 'tooltip',
    data(){
        return {
            showDescription:false
        }
    },
    props: {
        infoText: String
    },
    computed:{
        description(){
            return this.infoText ? this.$t(this.infoText) : defaultDescription
        }
    },
    methods:{
        toggleDescription(){
            this.showDescription = !this.showDescription
        }
    }
};
</script>
