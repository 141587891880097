export class ItemWithCost {
    _ItemID
    _TargetID
    itemUPC
    itemSKU
    description
    sdpCode
    _SdpID
    subDepartmentDescription
    departmentCode
    _DepartmentID
    departmentDescription
    format

    constructor({ 
        itemID, targetID, itemUPC, itemSKU, description, format, sdpCode, sdpID, 
        subDepartmentDescription, departmentCode, departmentID, departmentDescription,
    }){
        this._ItemID = itemID
        this._TargetID = targetID
        this.itemUPC = itemUPC
        this.itemSKU = itemSKU
        this.description = description
        this.sdpCode = sdpCode
        this._SdpID = sdpID
        this.subDepartmentDescription = subDepartmentDescription
        this.departmentCode = departmentCode
        this._DepartmentID = departmentID
        this.departmentDescription = departmentDescription
        this.format = format
    }
}