<template src="./pos-permissions.html"></template>

<style lang="scss">
@import './pos-permissions.scss';
</style>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import axios from 'axios';
import prompts from '@/core/tools/notifications/notifications'
import treeDataGrid from '@/core/components/common/grid/tree-data-grid/tree-data-grid.vue'
import spinLoader from '@/core/components/common/spin-loader/spin-loader.vue'

export default {
    name: 'pos-permissions',
    components: {
        treeDataGrid,
        spinLoader
    },

    data() {
        return {
            preventSave: false,
            isEdited: false,
            posPermissions: [],
            loading: false,            
            cancelToken: null,
            source: null
        }
    },

    computed: {
        ...mapGetters('Role', ['getRolePermissions', 'getPosPermissions', 'getRole']),
        isCreate() {
            return this.getRole?.roleId == -1
        },
        isEdit() {
            return this.getRole?.roleId > 0
        },
        readOnly() {
            return this.isEdit && !this.getRole?.canUpdate
        },
        permissionsLoaded(){
            return this.getPosPermissions?.length > 0
        }
    },

    mounted() {
        this.loadComponent()
    },

    beforeDestroy(){
        if (this.cancelToken) {
            this.source.cancel();
        }
    },

    methods: {
        ...mapActions('Role', ['saveRole', 'fetchRolePermissions']),        
        ...mapMutations('Role', ['SET_POS_PERMISSIONS']),

        async loadComponent() {
            if(this.permissionsLoaded)
                return;

            const roleId = this.getRole?.roleId ?? 0

            this.loading = true;

            this.cancelToken = axios.CancelToken;
            this.source = this.cancelToken.source();

            try {
                await this.fetchRolePermissions({ roleId: roleId, cancelToken: { cancelToken: this.source.token } })
            }
            catch(e) {
                if(axios.isCancel(e))
                    return;

                prompts.error({
                    text: this.$t('Role.Messages.FetchPermissionsError')
                });
            }

            this.loading = false;
        },

        onSelectionChanged(permissions) {
            this.posPermissions = permissions;
            this.SET_POS_PERMISSIONS(this.posPermissions)
        },
        
        async save() {
            const wasEdit = this.isEdit;
            let savedRole = null;
            this.preventSave = true;

            const posPermissions = this.posPermissions.filter(rp => rp.selected || rp.remove)
            const rolePermissions = this.getRolePermissions.filter(rp => rp.selected || rp.remove)
            const role = { ...this.getRole, permissions: posPermissions.concat(rolePermissions) }

            try {
                savedRole = await this.saveRole(role);
            }
            catch {                    
                prompts.error({
                    text: this.$t('Role.Messages.SaveRolePermissionsError')
                });
            }

            this.preventSave = false;

            if (savedRole?.roleId > 0) {
                this.$sidePanel.submit(true);

                prompts.toast({
                    icon: 'success',
                    title: this.$t(`Role.Messages.${wasEdit ? 'UpdateRoleSuccessfully' : 'CreateRoleSuccessfully'}`),
                    position: 'bottom-end'
                });
            }
        },

        onEdit(edited) {
            this.$sidePanel.isEdit(edited)
            this.isEdited = edited
        },

        cancel() {
            this.$sidePanel.close()
        },
    }
}
</script>