import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./core/router";
import store from "./core/store";
import i18n from "./i18n";
import Vue2TouchEvents from 'vue2-touch-events';
import VueMask from 'v-mask';
import VueProgressBar from 'vue-progressbar';
import {LicenseManager} from "@ag-grid-enterprise/core";
import VueGtag from "vue-gtag";
import {getEnv}  from '@/core/functions';

/*GLOBAL PLUGIN*/
import "@/core/plugins/vee-validate.js";
/*GLOBAL PLUGIN*/

/*GLOBAL MIXINS*/
import "@/core/mixins/alphanumerical-sort.js";
import "@/core/mixins/data-factory.js";
import "@/core/mixins/string-factory.js";
/*GLOBAL MIXINS*/

/*GLOBAL FILTERS*/
import "@/core/filters/filters.js";
/*GLOBAL FILTERS*/

/*GLOBAL DIRECTIVES*/
import "@/core/directives/auto-focus.js";
import "@/core/directives/click-outside.js";
import "@/core/directives/has-permissions.js";
import "@/core/directives/numbers-only.js";
import "@/core/directives/submit-data.js";
/*GLOBAL DIRECTIVES*/

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import SidePanel from '@/core/plugins/side-panel/side-panel';

LicenseManager.setLicenseKey("CompanyName=9013-6573 Qubec Inc,LicensedApplication=De Gestion Client,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=3,LicensedProductionInstancesCount=2,AssetReference=AG-020968,ExpiryDate=14_December_2022_[v2]_MTY3MDk3NjAwMDAwMA==cdd26617d3fad0abaad1352c7f95b221");

const VueProgressBarOptions = {
  color: '#005394',
  failedColor: '#ff0000',
  thickness: '5px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300
  },
  autoRevert: true,
  location: 'top',
  inverse: false,
  autoFinish: true
}

Vue.use(VueSweetalert2);
Vue.use(VueProgressBar,VueProgressBarOptions);
Vue.use(Vue2TouchEvents);
Vue.use(VueMask);
Vue.use(SidePanel, { plugins: {router, store, i18n}, sidePanelOptions: { containerId: 'SidePanelContainerId' } });

const gtag = getEnv('VUE_APP_GTAG');
  if(gtag){
    Vue.use(VueGtag, {
      config: { 
        id: gtag || '',
        params: {
          send_page_view: false // eslint-disable-line
        }
      }
    }, router);
}

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
