import PriceLevel from '@/backoffice/modules/priceLevel/priceLevel.vue'

import { permissionsDictionary } from '@/core/security/permissions-dictionary.js';

export default {
  path: 'priceLevels',
  name: 'priceLevels',
  component: PriceLevel,
  meta: {
    requiresAuth: true,
    associatedParentRouteName: null,
    sideNav: {
      image: null,
      label: 'SideNav.Management.PriceLevels'
    },
    permissions: [permissionsDictionary.ITEM_PRICE_READ],
    breadcrumb: {
      linkDescription: 'Breadcrumbs.Management.PriceLevels',
      routeNameTo: null,
      explicitTail: null
    }
  }
}