<template src="./product-costs-body.html"></template>

<style lang="scss">
    @import './product-costs-body.scss';
</style>

<script>
export default {
    name: 'product-costs-body',
    props :{
        elements: {
            type: Array,
            default: function () {
                return [];
            }
        },
        single:{
            type: Boolean,
            default: false
        }
    }
}
</script>