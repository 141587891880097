import promotion from '@/backoffice/modules/promotion/promotion.vue'
import main from '@/backoffice/modules/promotion/pages/main.vue'
import promotionProduct from '@/backoffice/modules/promotion/pages/promotion-product/promotion-product.vue'

const promotionRouter = {
  path: '/promotions',
  component: promotion,
  children:[
    {
      path: '',
      name: 'promotions',
      component: main,
      meta: {
        requiresAuth: true,
        associatedParentRouteName: null,
        sideNav: {
          image: 'fal fa-tag',
          label: 'SideNav.Promotion.Promotions',
          orderIndex: 3
        },
        permissions: ['PROMOTION_READ', 'PROMOTION_CUD'],
        breadcrumb: {
          linkDescription: 'Breadcrumbs.Promotion.Promotions',
          routeNameTo: null,
          explicitTail: null
        }
      }
    },
    {
      path: ':id',
      name: 'promotion',
      component: promotionProduct,
      props: true,
      meta: {
        requiresAuth: true,
        associatedParentRouteName: 'promotions',
        permissions: ['PROMOTION_READ', 'PROMOTION_CUD'],
        breadcrumb: {
          linkDescription: 'Breadcrumbs.Promotion.Promotions',
          routeNameTo: 'promotions',
          explicitTail:{
            fromParam: 'id'
          }
        }
      }
    }   
  ]
 };

 export default promotionRouter