<template src="./dashboard-management.html"></template>

<style lang="scss">
    @import './dashboard-management.scss';
</style>

<script>
import { mapGetters, mapActions } from 'vuex';
import prompts from '@/core/tools/notifications/notifications';
import cloneDeep from 'lodash/cloneDeep';
import descriptionInput from '@/core/components/common/description-input/description-input.vue'
import scopeDropdown from '@/core/components/common/drop-down/scope-dropdown/scope-dropdown.vue'
import roleDropdown from '@/core/components/common/drop-down/role-dropdown/role-dropdown.vue'

export default {
    name: 'dashboard-template',
    components: {
        descriptionInput,
        scopeDropdown,
        roleDropdown
    },
    props:{
        templatePayload: Object
    },
    data() {
        return {
            dashboardKey: 0,
            dashboard: null,
            dashboardBak: null
        }
    },
    watch: {
        templatePayload: {
            handler(newVal, oldVal) {
                if(newVal && newVal != oldVal) {
                    this.initializeDashboard();
                    this.dashboardKey++;
                }
            }
        },
        edited: {
            handler(newVal) {
                this.$sidePanel.isEdit(newVal);
            }
        }
    },
    computed:{
        ...mapGetters('Account', ['hasPermission']),
        ...mapGetters('Account', ['getCurrentTargetLayerId']),
        ...mapGetters('Scope', ['getSelectedScope']),
        edited(){
            return JSON.stringify(this.dashboard) !== JSON.stringify(this.dashboardBak);
        },
        getScopeTargetLayeID(){
            return this.getSelectedScope && this.getSelectedScope.tlid || 0;
        },
    },
    mounted(){
        this.initializeDashboard();
    },
    methods: {
        ...mapActions('Dashboard', ['saveDashboard']),
        initializeDashboard(){
            if (this.templatePayload.id > 0) {
                this.dashboard = cloneDeep(this.templatePayload);
            }
            else {

                const defaultWidgets = [{ i: 0, "x": 0, "y": 0, "w": 12, "h": 12, type: 'chart', configs: [] },
                                        { i: 1, "x": 0, "y": 15, "w": 3, "h": 4, type: 'simple', configs: [] },
                                        { i: 2, "x": 3, "y": 15, "w": 3, "h": 4, type: 'simple', configs: [] },
                                        { i: 3, "x": 0, "y": 20, "w": 3, "h": 4, type: 'simple', configs: [] },
                                        { i: 4, "x": 3, "y": 20, "w": 3, "h": 4, type: 'simple', configs: [] },
                                        { i: 5, "x": 0, "y": 25, "w": 3, "h": 4, type: 'simple', configs: [] },
                                        { i: 6, "x": 3, "y": 25, "w": 3, "h": 4, type: 'simple', configs: [] },
                                        { i: 7, "x": 6, "y": 15, "w": 6, "h": 12, type: 'top', configs: [] }];
                this.dashboard = {
                    id: null, 
                    targetID: 0, 
                    widgets: defaultWidgets,
                    descriptions: [], 
                    dashboardRoles: [],
                    canUpdate: this.hasPermission(['DASHBOARD_CUD'])
                };
            }

            this.dashboardBak = cloneDeep(this.dashboard);
        },
        async save(){
            let dashboardFromServer;

            try {
                dashboardFromServer = await this.saveDashboard(this.dashboard);
            }
            catch(e){
                prompts.error({
                    text: this.$t('Dashboard.Messages.SaveDashboardError')
                });
            }

            if (dashboardFromServer) {
                this.$sidePanel.submit({ 'save': true, 'row': dashboardFromServer });
            }
        },
        onScopeChanged(target) {
            this.dashboard.targetID = target ? target.id : 0;
        },
        onRolesChanged(roles){
            this.dashboard.dashboardRoles = roles.filter(r => {
                    const isNotNewRoleRemoved = this.dashboardBak.dashboardRoles.find(dashboardRole => dashboardRole.roleID == r.roleID);
                    return isNotNewRoleRemoved || (!isNotNewRoleRemoved && !r.remove)
                }).map(r => ({
                    dashboardID: this.dashboard.id,
                    roleID: r.iD,
                    role: r
                }));
        },
        cancel(){
            this.$sidePanel.close();
        }
    }
}
</script>