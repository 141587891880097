import { DocumentType } from '../domain/document.js';
import i18n from "@/i18n";

const DocumentService = {
    getIcon: (documentType) => {
        switch(documentType){
            case DocumentType.Count:
                return 'fal fa-tally';
            case DocumentType.AdjustmentPlus:
            case DocumentType.AdjustmentMinus:
                return 'fal fa-sliders-h';
        }
    },
    getDocumentTypeTranslate: (documentType) => {
        return i18n.t(`Inventory.InventoryAdjustment.${documentType}`);
    },
    getSidePanelTemplate: (documentType) => {
        switch(documentType){
            case DocumentType.Count:
                return 'InventoryProductCount';
            case DocumentType.AdjustmentPlus:
            case DocumentType.AdjustmentMinus:
                return 'InventoryProductAdjustment';
        }
    },
    // dirt, should be moved to backend
    getNewItemsUpsForSale: (documentBodies) => {
        return documentBodies.reduce((upcs, item) => {
            if (item.varianceQty > 0)
                return [ ...upcs, item.itemUPC]

            return upcs
        }, [])
    }
}

export default DocumentService;