<template src="./order-product.html"></template>

<style lang="scss">
    @import './order-product.scss';
</style>

<script>
import { OrderStatus } from '@/backoffice/modules/purchase/domain/purchase.js';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { toCurrency } from '@/core/functions';
import prompts from '@/core/tools/notifications/notifications';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import ProductInfoHeader from '@/core/components/layout/side-panel/product-info-header/product-info-header.vue';
import ProductCostsBody from '@/core/components/layout/side-panel/product-costs-body/product-costs-body.vue';
import ProductResultFooter from '@/core/components/layout/side-panel/product-result-footer/product-result-footer.vue';
import orderProductScan from '@/backoffice/modules/purchase/components/sidePanel/orders/order-product-scan/order-product-scan.vue';
import { OrderGridTabs } from '@/backoffice/modules/purchase/pages/orders/order/orderGridTabs.js';
import { permissionsDictionary } from '@/core/security/permissions-dictionary.js';

export default {
    name: 'order-product',

    components:{
        ProductInfoHeader,
        ProductCostsBody,
        ProductResultFooter
    },

    data() {
        return {
            edited: false,
            focusedInput: null,
            productInventory: {},
            costs: [],
            costsCopy: [],
            totals: { qty: 0, cost: 0 },
            newOrderBodyTemplate: {},
            averageLast4WeeksQty: 0
        }
    },

    computed:{
        ...mapGetters('Account', ['getCurrentTargetLayerId', 'hasPermission']),
        ...mapGetters('Order', ['getOrder']),
        ...mapGetters('Product', ['getProductWithVendorCosts']),
        canUpdate(){
            return this.getOrder.canUpdate && this.getOrder.orderStatus == OrderStatus.OPEN;
        },
        readProductPermission(){
            return this.hasPermission([permissionsDictionary.PRODUCT_READ])
        },
        readInventoryPermission(){
            return this.hasPermission([permissionsDictionary.INVENTORY_READ])
        }
    },

    watch: {
        'getProductWithVendorCosts.itemUPC'() {
            this.initializeProduct();
            this.fetchAverage();
            this.fetchInventory();
        }
    },

    mounted(){
        this.initializeProduct();
        this.fetchAverage();
        this.fetchInventory();
    },

    methods: {
        ...mapActions('Order', ['saveOrder' ]),
        ...mapActions('Product', ['fetchProductsAverageLastWeeks' ]),
        ...mapActions('Inventory', ['fetchProductInventory']),
        ...mapMutations('Order', {
            setOrderGridTabIndex: 'SET_ORDER_GRID_TAB_INDEX'
        }),

        async initializeProduct() {
            this.newOrderBodyTemplate = {
                itemID: this.getProductWithVendorCosts._ItemID,
                itemUPC: this.getProductWithVendorCosts.itemUPC,
                itemSKU: this.getProductWithVendorCosts.itemSKU,
                itemDescription: this.getProductWithVendorCosts.description,
                subDepartmentID: this.getProductWithVendorCosts._SdpID,
                subDepartmentCode: this.getProductWithVendorCosts.sdpCode,
                subDepartmentDescription: this.getProductWithVendorCosts.subDepartmentDescription,
                departmentID: this.getProductWithVendorCosts._DepartmentID,
                departmentCode: this.getProductWithVendorCosts.departmentCode,
                departmentDescription: this.getProductWithVendorCosts.departmentDescription,
                format: this.getProductWithVendorCosts.format
            }

            this.costs = cloneDeep(this.getProductWithVendorCosts.costs);
            this.initializeOrderBody();
        },

        initializeOrderBody(){
            this.linkCostsToBodies();
            this.recalculateTotals();
            this.selectFirstInput();

            this.costsCopy = cloneDeep(this.costs);
        },

        selectFirstInput(){
            if (this.costs.length > 0) {
                this.$nextTick(() => {
                    this.focusedInput = this.$refs['qty-0'];
                    this.focusedInput.focus();
                    this.focusedInput.select();
                });
            }
            else
                this.$refs.scan.focus();
        },

        scan(){
            if(this.canUpdate && !this.edited){
                this.setOrderGridTabIndex(OrderGridTabs.orderBodiesTabIndex);
                this.$sidePanel.show([{ component: orderProductScan }], null, { backgroundIsDisabled: true });
            }
        },

        changeInput(index){
            if (this.costs.length > index + 1) {
                this.focusedInput = this.$refs[`qty-${index + 1}`];
                this.focusedInput.focus();
                this.focusedInput.select();
            }
            else if(this.costs.length == index + 1){
                this.focusedInput = this.$refs[`qty-${index}`];
                this.focusedInput.blur();
                this.$refs['scan'].focus();
            }
            else{
                this.focusedInput = null
                this.$refs['scan'].focus();
            }
        },

        setQty(cost, qty){
            cost.qty = qty || undefined;
            this.validateEdit();
        },

        validateEdit(){
            this.edited = false;
            for (let i = 0; i < this.costs.length; i++) {
                const cost = this.costs[i];
                if(cost.qty != this.costsCopy[i].qty){
                    this.edited = true;
                }
            }

            this.$sidePanel.isEdit(this.edited);
        },

        async validateQty(cost){
            if (!(cost.qty >= 0))
                cost.qty = undefined;

            cost.ignore = false;

            this.saveProduct();
        },

        recalculateTotals(){
            this.totals = { qty: 0, cost: 0 };

            for (let i = 0; i < this.costs.length; i++){
                if (this.costs[i].qty > 0){
                    if (this.costs[i].costUnitQty > 0)
                        this.totals.qty += parseInt(this.costs[i].qty) * this.costs[i].costUnitQty;
                    else
                        this.totals.qty += parseInt(this.costs[i].qty);

                    if (this.costs[i].costValue)
                        this.totals.cost += parseInt(this.costs[i].qty) * this.costs[i].costValue;
                }
            }

            this.totals.cost = toCurrency(this.totals.cost);
        },

        linkCostsToBodies(){
            if (this.costs.length > 0 && this.getOrder.orderBodies.length > 0){
                for (let i = 0; i < this.costs.length; i++){
                    this.linkCostToBody(i);
                }
            }
        },

        linkCostToBody(index){
            const costBody = this.getOrder.orderBodies.find(b => b.id == this.costs[index].id || (b.itemUPC == this.costs[index].itemUPC && b.uomID == this.costs[index].uomid && b.unitQty == this.costs[index].costUnitQty));
            if (costBody){
                this.costs[index].id = costBody.id;
                this.costs[index].qty = costBody.qty;
            }
        },

        async saveProduct() {
            const order = cloneDeep(this.getOrder);

            let executeSave = false;
            let newBodies = false;
            for (let i = 0; i < this.costs.length; i++){
                if ((this.costs[i].qty > 0 || this.costs[i].remove) && !this.costs[i].ignore){
                    executeSave = true;

                    if (this.costs[i].id){
                        const costBody = order.orderBodies.find(b => b.id == this.costs[i].id);
                        if (costBody){
                            costBody.qty = this.costs[i].qty;
                            costBody.unitQty = this.costs[i].costUnitQty;
                            costBody.unitCost = this.costs[i].costValue;
                            costBody.cost = this.costs[i].costValue ? this.costs[i].qty * this.costs[i].costValue : 0;
                            costBody.orderCode = this.costs[i].orderCode;
                            costBody.ignore = false;
                        }
                    }
                    else{
                        newBodies = true;

                        const newBody = cloneDeep(this.newOrderBodyTemplate);
                        newBody.createOn = moment();
                        newBody.uomID = this.costs[i].uomid;
                        newBody.uomDescription = this.costs[i].uomDescription;
                        newBody.qty = this.costs[i].qty;
                        newBody.unitQty = this.costs[i].costUnitQty;
                        newBody.unitCost = this.costs[i].costValue;
                        newBody.cost = this.costs[i].costValue ? this.costs[i].qty * this.costs[i].costValue : 0;
                        newBody.orderCode = this.costs[i].orderCode;
                        newBody.ignore = this.costs[i].ignore;
                        order.orderBodies.push(newBody);
                    }

                    this.costs[i].remove = false;
                    this.costs[i].ignore = true;
                }
            }

            if (executeSave){
                executeSave = false;

                try {
                    await this.saveOrder(order);
                }
                catch(e) {
                    prompts.error({
                        text: this.$tc('Purchase.Messages.SaveOrderError')
                    });
                }

                if (newBodies)
                    this.linkCostsToBodies();

                this.costsCopy = cloneDeep(this.costs);
                this.validateEdit();
            }
        },
        async fetchAverage(){
            const productsAverage = await this.fetchProductsAverageLastWeeks({ itemUpcs: [this.getProductWithVendorCosts.itemUPC], nbOfWeeks: 4 });
            this.averageLast4WeeksQty = productsAverage[this.getProductWithVendorCosts.itemUPC] ? productsAverage[this.getProductWithVendorCosts.itemUPC].quantity : 0
        },
        async fetchInventory(){
            if(this.readInventoryPermission)
                this.productInventory = await this.fetchProductInventory(this.getProductWithVendorCosts.itemUPC);
        },

        cancel(){
            this.$sidePanel.close();
        }
    }
}
</script>