<template src="./tree-data-grid.html"></template>

<style lang="scss">
@import './tree-data-grid.scss';
</style>

<script>
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';

import searchBar from '@/core/components/common/search-bar/search-bar.vue'
import grid from '@/core/components/common/grid/grid.vue'

export default {
    name: 'tree-data-grid',
    components: {
        searchBar,
        grid 
    },

    props: {
        dataSet: Array,
        trackBy: String,
        isEdit: Boolean,
        readOnly: Boolean,
        title: String
    },

    data() {
        return {
            gridOptions: {
                rowHeight: 40,
                rowSelection: 'multiple',
                treeData: true,
                groupSelectsChildren: true,
                suppressCellFocus: false,
                rowMultiSelectWithClick: true,
                suppressRowClickSelection: true,
                groupDefaultExpanded: -1,
                columnDefs: [
                    {
                        field: 'selected',
                        hide: true,
                        filter: 'agNumberColumnFilter',
                        valueGetter: (row) => {
                            return row.node.selected ? 1 : 0;
                        }
                    }
                ],
                autoGroupColumnDef: {
                    minWidth: 300,
                    cellClass: ['tree-data-cell', this.readOnly ? 'disabled' : ''],
                    cellRendererParams: {
                        suppressCount: true,
                        checkbox: true
                    }
                },
                getDataPath: (data) => {
                    return data.hierarchy
                },
                onFirstDataRendered: () => {
                    this.loadComponent()
                },
                onRowSelected: (params) => {
                    if (params.node.data)
                        this.updateRowData(params.node)
                }
            },
            rowDataCopy: {},
            dataSetCopy: [],
            isActiveFilter: false,
            filters: [],
            selectAllState: false
        }
    },

    watch: {
        isEdited(val) {
            this.$emit('onEdit', val)
        }
    },
    computed: {
        rowData() {
            return this.dataSetCopy.reduce((obj, item) => {
                if (item[this.trackBy])
                    obj[item[this.trackBy]] = item;

                return obj;
            }, {});
        },
        agRows() {
            return Object.values(this.rowDataCopy)
        },
        isEdited() {
            return this.isEdit && !isEqual(this.rowData, this.rowDataCopy)
        },
        btnSelectAllText() {
            return this.selectAllState ? "Button.UnselectAll" : "Button.SelectAll"
        },
        hasSelectedRows() {
            return this.agRows.filter(p => p.selected).length > 0
        }
    },

    mounted() {
        this.dataSetCopy = cloneDeep(this.dataSet);
        this.rowDataCopy = cloneDeep(this.rowData);
    },

    methods: {
        loadComponent() {
            this.gridOptions.api.forEachNode((node) => {
                if (node.data)
                    node.setSelected(node.data.selected);
            })

            this.gridOptions.api.refreshCells({ force: true, suppressFlash: true })
            this.filterActive(this.isEdit && this.hasSelectedRows);
        },

        updateRowData(node) {
            if (node.data) {
                const dataToUpdate = this.rowDataCopy[node.data[this.trackBy]];
                const originalData = this.rowData[node.data[this.trackBy]]

                dataToUpdate.selected = node.selected
                if (originalData.selected)
                    dataToUpdate.remove = !node.selected

                dataToUpdate.ignore = (dataToUpdate.selected == originalData.selected && dataToUpdate.remove == originalData.remove)
            }

            this.$emit('onSelectionChanged', Object.values(this.rowDataCopy))
        },

        selectAllToggle() {
            this.selectAllState = !this.selectAllState;
            for (const prop in this.rowDataCopy) {
                this.rowDataCopy[prop].selected = this.selectAllState
                if(!this.selectAllState){
                    const originalData = this.rowData[prop]
                    if (originalData.selected)
                        this.rowDataCopy[prop].remove = true
                }
            }
            this.gridOptions.api.forEachNode((node) => node.setSelected(node.data?.selected));
            this.gridOptions.api.refreshCells({ force: true, suppressFlash: true })
        },

        filterActive(active) {
            this.isActiveFilter = active
            if (this.isActiveFilter) {
                const filterInstance = this.gridOptions.api.getFilterInstance('selected')
                filterInstance?.setModel({ type: 'equals', filter: 1 })
                this.gridOptions.api.onFilterChanged();
            }
            else {
                this.gridOptions.api.setFilterModel(null)
                this.gridOptions.api.onFilterChanged()
            }
        },

        filtersChanged(filters) {
            this.filters = filters;
        }
    }
}
</script>