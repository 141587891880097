<template src="./currency-input-renderer.html"></template>

<style scoped lang="scss">
@import './currency-input-renderer.scss';
</style>

<script>
import { CurrencyInput  } from 'vue-currency-input'

export default{
  name: 'currencyInputRenderer',
  components:{  
    CurrencyInput
  },
  computed: {
    disabled() {
      return this.params.canUserInteractWithUI && !this.params.canUserInteractWithUI();
    },
    decimalConfigs() {
      return this.params.decimalConfigs || {}
    },
    secondInfo() {
      if (!this.params.getSecondInfo) {
        return;
      }

      return this.params.getSecondInfo(this.params.data);
    },
    hasSecondInfo() {
      return this.secondInfo != undefined
    }
  },
  methods: {
    change(e){
      const newValue = e && Number.parseFloat(e) || 0;
      if (e == this.params.value) {
        this.$refs.input.$el.blur();
        return;
      }
      this.params.node.setDataValue(this.params.colDef, newValue)
    }
  }
};
</script>
