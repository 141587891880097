<template src="./inventory-adjustment.html"></template>

<style lang="scss">
    @import './inventory-adjustment.scss';
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import { DocumentStatus, DocumentType } from '@/backoffice/modules/inventory/domain/document.js';
import documentServices from '@/backoffice/modules/inventory/services/document.services.js';
import multiselect from 'vue-multiselect';
import { mapGetters, mapActions } from 'vuex';
import prompts from '@/core/tools/notifications/notifications';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { ValidationObserver, ValidationProvider, localize } from "vee-validate";
import inventoryScan from '@/backoffice/modules/inventory/components/side-panel/inventory-product-scan/inventory-product-scan.vue';

export default {
    name: 'inventory-adjustment-template',
    components: {
        multiselect,
        ValidationObserver,
        ValidationProvider
    },
    
    props:{
        template: undefined,
        templatePayload: undefined
    },

    data() {
        return {
            preventSave: false,
            reasonList: [],
            selectedReason: null,
            document: {
                iD: null, 
                targetLayerID: null, 
                documentNO: null,
                documentType: null, 
                documentStatus: null, 
                documentDate: null, 
                documentDatetime: null, 
                processDate: null, 
                userID: null, 
                userUsername: null, 
                userEmail: null, 
                userName: null, 
                terminalID: null, 
                comment: "", 
                description: "",
                reasonCode: null,
                reason: null,
                documentBodies: []
            },
            documentCopy: null
        }
    },
    computed:{
        ...mapGetters('Inventory', ['getDocument', 'getAdjustmentReason']),
        ...mapGetters('Account', ['getUser', 'getCurrentTargetLayerId']),
        ...mapGetters('App', ['isMobile']),
        edited(){
            return this.documentCopy && !isEqual(this.documentCopy, this.document);
        }
    },

    watch:{
        edited(){
            this.$sidePanel.isEdit(this.edited);
        }
    },

    created(){
        this.getAdjustmentReasonData();
    },
    mounted(){
        localize(this.$i18n.locale);

        this.document.documentType = '';
        this.document.reasonCode = '';
        this.document.reason = '';
        this.document.documentStatus = DocumentStatus.Open;
        this.document.userID = this.getUser._UserID;
        this.document.targetLayerID = this.getCurrentTargetLayerId;
        this.document.userUsername = this.getUser.username;
        this.document.username = `${this.getUser.firstName} ${this.getUser.lastName}`;
        this.document.userEmail = this.getUser.email;

        this.documentCopy = cloneDeep(this.document);
    },

    methods: {
        ...mapActions('Inventory', [
            'fetchAdjustmentReason',
            'addDocument',
        ]),
        async getAdjustmentReasonData(){
            try {
                await this.fetchAdjustmentReason();
                this.setReasonList();
            }
            catch (error) {
                prompts.error({
                    text: this.$t('Inventory.Messages.GetDocumentError')
                });
            }
        },
        async saveDocument(){
            try{
                this.preventSave = true;
                await this.addDocument(this.document);

                this.$sidePanel.show([{ component: inventoryScan }], null, {backgroundIsDisabled: true, isPreventAutoClose: true });
                this.$router.push({ name: 'document', params: { id: this.getDocument.id } });
            }
            catch(e){
                prompts.error({
                    text: this.$t('Inventory.Messages.SaveDocumentError')
                });
            }

            this.preventSave = false;
        },
        setReasonList(){
            const reasonByAdjustment = [];

            reasonByAdjustment.push({
                adjustment: documentServices.getDocumentTypeTranslate(DocumentType.AdjustmentPlus),
                reasons: (this.getAdjustmentReason || []).filter(r => r.code == DocumentType.AdjustmentPlus)
            });
            reasonByAdjustment.push({
                adjustment: documentServices.getDocumentTypeTranslate(DocumentType.AdjustmentMinus),
                reasons: (this.getAdjustmentReason || []).filter(r => r.code == DocumentType.AdjustmentMinus)
            });

            reasonByAdjustment.forEach(g => {
                g.reasons.forEach(r => {
                    r.description = r[this.$i18n.locale];
                });
            })

            this.reasonList = reasonByAdjustment;
        },
        selectReason(reason){
            this.document.documentType = reason.code;
            this.document.reasonCode = reason.value;
            this.document.reason = reason.description;
        },
        validateReason() {
            this.$refs.reasonValidator.validate();
        },
        cancel(){
            this.$sidePanel.close();
        }
    }
}
</script>