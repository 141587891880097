import { SearchLimitOptionEnum } from '@/core/components/layout/side-panel/product-search/product-search.const.js';

export class ItemWithCostSearchCriteria {
    itemUpc
    itemDescription
    subdepartmentCode
    subdepartmentDescription
    departmentCode
    departmentDescription
    vendorCode

    constructor({ itemUpc, itemDescription, subdepartmentCode, subdepartmentDescription, departmentCode, departmentDescription, vendorCode }) {
        this.itemUpc = itemUpc
        this.itemDescription = itemDescription
        this.subdepartmentCode = subdepartmentCode
        this.subdepartmentDescription = subdepartmentDescription
        this.departmentCode = departmentCode
        this.departmentDescription = departmentDescription
        this.vendorCode = vendorCode
    }
}

export function createItemWithCostSearchCriteriaByScope(scope, vendorCode, searchCriteria) {
    switch (scope) {
        case SearchLimitOptionEnum.ITEM:
            return new ItemWithCostSearchCriteria({ 
                itemUpc: searchCriteria,
                itemDescription: searchCriteria,
                vendorCode: vendorCode
            })
        case SearchLimitOptionEnum.SUBDEPARTMENT:
            return new ItemWithCostSearchCriteria({ 
                subdepartmentCode: searchCriteria,
                subdepartmentDescription: searchCriteria,
                vendorCode: vendorCode
            })
        case SearchLimitOptionEnum.DEPARTMENT:
            return new ItemWithCostSearchCriteria({ 
                departmentCode: searchCriteria,
                departmentDescription: searchCriteria,
                vendorCode: vendorCode
            })
        default:
            return new ItemWithCostSearchCriteria({ 
                itemUpc: searchCriteria,
                itemDescription: searchCriteria,
                subdepartmentCode: searchCriteria,
                subdepartmentDescription: searchCriteria,
                departmentCode: searchCriteria,
                departmentDescription: searchCriteria,
                vendorCode: vendorCode
            })
    }
}