<template src="./side-panel.html"></template>

<style lang="scss">
    @import './side-panel.scss';
</style>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'side-panel',

    props: {
        components: Array,
        payload: {
            type: Object,
            default: () => { return {}; }
        },
        options: {
            type: Object,
            default: () => { return {}; }
        }
    },

    data() {
        return {
            isEdited: false,
            backgroundIsDisabled: false,
            tabsIsDisabled: false,
            currentTabIndex: 0,
            templatePayload: this.payload,
        }
    },

    computed: {
        ...mapGetters('App', ['isMobile']),
        tabs() {
            return (this.components && this.components.reduce((arr, c) => {
                 if (c.tab) {
                    arr.push(c.tab);
                 }

                 return arr;
            }, [])) || [];
        },
        isLoaded() {
            return this.components && this.loadedComponent;
        },
        classSidePanelModal() {
            return [ 'side-panel__modal--right', 'side-panel__modal--25',  ...(this.options.classes || []) ];
        },
        loadedComponent() {
            return (this.components && this.components[this.currentTabIndex].component) || null;
        }
    },

    watch: {
        $route(to, from) {
            if (!this.options.isPreventAutoClose && !this.compareCI(to.name, from.name)) {
                this.$sidePanel.close();
            }
            else {
                this.options.isPreventAutoClose = false;
            }
        }
    },

    mounted(){
        this.backgroundIsDisabled = !!this.options?.backgroundIsDisabled;
    },

    methods: {
        setTab(index) {
            this.currentTabIndex = index;
        },

        close() {
            this.$sidePanel.close();
        },

        setIsEdited(isEdited) {
            this.isEdited = isEdited;
        },

        disabledTabs(value) {
            this.tabsIsDisabled = value;
        },

        disabledBackground(value) {
            this.backgroundIsDisabled = value;
        },

        templatePayloadUpdated(newData) {
            this.templatePayload = newData
        }
    }
}
</script>