import Api from '../_api.config';
 
async function getEntity(request){
  const encodedFilters = encodeURIComponent(JSON.stringify(request.filters));
  const extraParams = request.params ? '&' + request.params : '';

  const endpoint = `/api/Generic/${request.modelName}`
  const queryString = `?getChildren=${request.getChildren}&filters=${encodedFilters}${extraParams}`
  try {
    const { status, data } = await Api().get(`${endpoint}${queryString}`);
    return {
      status,
      data
    };
  }
  catch (e) {
    return {
      data: null 
    };
  }
}

async function getEntities(request, cancelToken ){
  const params = (request.params ? '&' + request.params : '');
  const reportDataModelID = (request.reportDataModelID ? '/' + request.reportDataModelID : '');
  const getChildrenParam = request.getChildren ? `&getChildren=${request.getChildren}` : '';
  const targetLayerIDParam = request.targetLayerID ? `&targetLayerID=${request.targetLayerID}` : '';
  const directionParam = request.direction ? `&direction=${request.direction}` : '';

  return await Api()
              .get(`/api/Generic/${request.modelName}/Entities${reportDataModelID}?filters=${encodeURIComponent(JSON.stringify(request.filters))}${getChildrenParam}${targetLayerIDParam}${directionParam}${params}`, cancelToken)
              .then(response => {
                return { status: response.status, data: response.data };
              })
              .catch((e) => {
                if (e.response) {
                  return { status: e.response.status, message: e.response.data, data: null };
                }
                else {
                  return { message: e.message, data: null };
                }
              });
}

async function getEntitiesFromRdm(params, cancelToken){
  return await Api()
              .post(`/api/Generic/${params.modelName}/Entities/Rdm?filters=${params.filters}`, params.reportDataModel, cancelToken)
              .then(response => {
                return { status: response.status, data: response.data };
              })
              .catch((e) => {
                if (e.response) {
                  return { status: e.response.status, message: e.response.data, data: null };
                }
                else {
                  return { message: e.message, data: null };
                }
              });
}

async function getModel(modelName){
  return await Api()
              .get(`/api/Generic/${modelName}/Metadata`)
              .then(response => {
                return { status: response.status, data: response.data };
              })
              .catch((e) => {
                if (e.response) {
                  return { status: e.response.status, message: e.response.data, data: null };
                }
                else {
                  return { message: e.message, data: null };
                }
              });
}

async function saveEntities(request){

  return await Api()
              .put(`/api/${request.targetModel}`, request.entities)
              .then(response => {
                return { status: response.status, data: response.data };
              })
              .catch((e) => {
                if (e.response) {
                  return { status: e.response.status, message: e.response.data, data: null };
                }
                else {
                  return { message: e.message, data: null };
                }
              });
}

async function createEntities(request){

  return await Api()
              .post(`/api/${request.targetModel}`, request.entities)
              .then(response => {
                return { status: response.status, data: response.data };
              })
              .catch((e) => {
                if (e.response) {
                  return { status: e.response.status, message: e.response.data, data: null };
                }
                else {
                  return { message: e.message, data: null };
                }
              });
}

async function deleteEntities(request){

  return await Api()
              .delete(`/api/${request.targetModel}`, { data: request.ids })
              .then(response => {
                return { status: response.status, data: response.data };
              })
              .catch((e) => {
                if (e.response) {
                  return { status: e.response.status, message: e.response.data, data: null };
                }
                else {
                  return { message: e.message, data: null };
                }
              });
}



async function getCreationScope(request){

  return await Api()
              .get('/api/'+request.model+'/GetCreationScope' + (request.dataSource ? '/' + request.dataSource : ''))
              .then(response => {
                return { status: response.status, data: response.data };
              })
              .catch((e) => {
                if (e.response) {
                  return { status: e.response.status, message: e.response.data, data: null };
                }
                else {
                  return { message: e.message, data: null };
                }
              });
}

async function getGenericApiEntities(modelName, getChildren, reportDataModelId, filters){

  return await Api()
              .get(`/api/${modelName}?reportDataModelId=${reportDataModelId}&getChildren=${getChildren}&filters=${encodeURIComponent(JSON.stringify(filters))}`)
              .then(response => {
                return ({status: response.status, data: response.data});
              })
              .catch((e) => {
                if (e.response) {
                  return { status: e.response.status, message: e.response.data, data: null };
                }
                else {
                  return { message: e.message, data: null };
                }
              });
}

async function getApiEntities(apiController, getChildren, reportDataModelId, filters){

  const reportDataModelIdParams = reportDataModelId ? `&reportDataModelId=${reportDataModelId}` : '';
  return await Api()
              .get(`/api/${apiController}?getChildren=${getChildren}&filters=${encodeURIComponent(JSON.stringify(filters))}${reportDataModelIdParams}`)
              .then(response => {
                return ({status: response.status, entities: response.data});
              })
              .catch((e) => {
                if (e.response) {
                  return { status: e.response.status, message: e.response.data, entities: null };
                }
                else {
                  return { message: e.message, entities: null };
                }
              });
}

async function getApiEntityByID(apiController, id, getChildren){
  return await Api()
    .get(`/api/${apiController}/${id}?getChildren=${getChildren}`)
    .then(response => {
      return ({ status: response.status, entity: response.data });
    })
    .catch((e) => {
      if (e.response) {
        return { status: e.response.status, message: e.response.data, entity: null };
      }
      else {
        return { message: e.message, entity: null };
      }
    });
}

async function getTimeSeries(modelName, params, cancelToken){

  return await Api()
              .post(`/api/Generic/TimeSeries/${modelName}`, params, cancelToken)
              .then(response => {
                return { status: response.status, data: response.data };
              })
              .catch((e) => {
                if (e.response) {
                  return { status: e.response.status, message: e.response.data, data: null };
                }
                else {
                  return { message: e.message, data: null };
                }
              });
}

async function getEntityListWithNoChildren(modelName, filters, cancelToken){
  return await Api()
              .get(`/api/Generic/List/false/${modelName}?filters=${encodeURIComponent(JSON.stringify(filters))}`, cancelToken)
              .then(response => {
                return { status: response.status, data: response.data };
              })
              .catch((e) => {
                if (e.response) {
                  return { status: e.response.status, message: e.response.data, data: null };
                }
                else {
                  return { message: e.message, data: null };
                }
              });
}

async function getSimpleWidgetData(modelName, params, cancelToken){
  return await Api()
              .post(`/api/Generic/SimpleWidgetData/${modelName}`, params, cancelToken)
              .then(response => {
                return { status: response.status, data: response.data };
              })
              .catch((e) => {
                if (e.response) {
                  return { status: e.response.status, message: e.response.data, data: null };
                }
                else {
                  return { message: e.message, data: null };
                }
              });
}

export const genericServices = {
  getEntity,
  getEntities,
  getEntitiesFromRdm,
  getModel,
  saveEntities,
  createEntities,
  deleteEntities,
  getCreationScope,
  getGenericApiEntities,
  getApiEntities,
  getApiEntityByID,
  getTimeSeries, 
  getSimpleWidgetData,
  getEntityListWithNoChildren
};