<template src="./slider-list.html"></template>

<style lang="scss">
    @import './slider-list.scss';
</style>

<script>

export default {
  name: 'slider-list',

  props:{
    elements: Array
  },

  data() {
    return {
      sliderContainer: null
    }
  },

  mounted() {
    this.initSliderSlide();
  },

  methods: {
    initSliderSlide(){
      this.sliderContainer = this.$refs["slider-container-id"];
      if (this.sliderContainer && this.sliderContainer.childNodes.length > 0) {
        this.elementWidth = this.sliderContainer.childNodes[0].offsetWidth;
      }
    },

    nextElement() {
      if (this.sliderContainer.childNodes.length > 0)
        this.sliderContainer.scrollTo(this.sliderContainer.scrollLeft + this.sliderContainer.childNodes[0].offsetWidth, 0);
    },

    previousElement() {
      if (this.sliderContainer.childNodes.length > 0)
        this.sliderContainer.scrollTo(this.sliderContainer.scrollLeft - this.sliderContainer.childNodes[0].offsetWidth, 0);
    }
  }
}
</script>
