<template src="./promotion-management.html"></template>

<style lang="scss">
    @import './promotion-management.scss';
</style>

<script>
import { ValidationObserver, ValidationProvider, localize } from "vee-validate";
import descriptionInput from '@/core/components/common/description-input/description-input.vue';
import calendar from '@/core/components/common/calendar/calendar.vue';
import multiTypeInput from '@/backoffice/modules/promotion/components/multi-type-input/multi-type-input.vue';
import { PromotionScopeCode, DiscountTypeCode, Promotion, PromotionScope, PromotionDiscount, CoveredAmount } from '@/backoffice/modules/promotion/domain/promotion.js'
import { OperationType } from '@/backoffice/domain/backoffice.const.js'
import { mapActions, mapGetters, mapMutations } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import prompts from '@/core/tools/notifications/notifications'

export default {
    name: 'promotion-management-template',
    components: {
        ValidationObserver,
        ValidationProvider,
        descriptionInput,
        calendar,
        multiTypeInput
    },
    data() {
        return {
            preventSave: false,
            promotionScopes: [],
            promotion: new Promotion(),
            promotionCopy: new Promotion(),
            componentLoaded: false
        };
    },
    computed:{
        ...mapGetters('Promotion', ['getPromotion', 'getPromotions', 'getOperationType']),
        ...mapGetters('Account', ['getCurrentTarget']),
        isEdit(){
            return this.getOperationType === OperationType.Edition
        },
        isEdited(){
            return this.isEdit && !isEqual(this.promotion, this.promotionCopy)
        },
        discount: {
            get() {
                if (this.promotionScopes.length === 0)
                    return null

                if (!this.promotion.scope?.scopeId)
                    return this.promotionScopes[0].types[0]

                const selectedScope = this.promotionScopes.find(scope => scope.id === this.promotion.scope.scopeId)

                const discount = this.promotion.discount

                const selecetedDiscountType = selectedScope.types.find(type => type.id == discount.typeId)

                let value = discount.value
                if (discount.typeCode === DiscountTypeCode.PercentDiscount)
                    value = discount.value * 100

                return { ...selecetedDiscountType, value: value }
            },
            set(multiTypeInput) {
                if (!this.promotion.scope)
                    this.promotion.scope = new PromotionScope()

                this.promotion.scope = multiTypeInput.data.scope

                const discount = this.promotion.discount || new PromotionDiscount()
                discount.typeId = multiTypeInput.data.type.typeId
                discount.typeCode = multiTypeInput.data.type.typeCode

                let value = multiTypeInput.value
                if (discount.typeCode === DiscountTypeCode.PercentDiscount)
                    value = multiTypeInput.value / 100

                if(this.promotion.scope.scopeCode === PromotionScopeCode.Item)
                    discount.coveredAmount = CoveredAmount.Subtotal

                discount.value = discount.typeCode === DiscountTypeCode.Freebie ? 0 : value

                this.promotion.discount = discount
            }
        },
        promotionCodeCollision(){
            const promo = this.getPromotions(true).find(p => p.code === this.promotion.code && p.targetID === this.promotion.targetID && p.id !== this.promotion.id)
            return promo != null && !this.isEdit
        },
        promoCodeCollisionMsg(){
            const promo = this.getPromotions(true).find(p => p.code === this.promotion.code && p.targetID === this.promotion.targetID && p.id !== this.promotion.id)
            return promo && promo.isDeleted ? this.$t('Promotion.Messages.DeletedPromotionCodeCollision') : this.$t('Promotion.Messages.ExistingPromotionCodeCollision')
        },
        disableInstantPromotion(){
            if(!this.promotion || !this.promotion.scope)
                return true

            return this.promotion.scope.scopeCode !== PromotionScopeCode.Item
        },
        showCoveredAmount(){
            return this.promotion.scope.scopeCode === PromotionScopeCode.Transaction
        },
        inputCurrencyRequired(){
            return this.promotion.discount?.typeCode !== DiscountTypeCode.Freebie
        },
        inputCurrencyDisabled(){
            return this.isEdit || this.promotion.discount?.typeCode === DiscountTypeCode.Freebie
        }
    },

    watch:{
        disableInstantPromotion(val){
            if(val)
                this.promotion.instant = false
        },
        'promotion.instant'(val){
            if(val)
                this.promotion.maxUsage  = null
        },
        isEdited(val){
            this.$sidePanel.isEdit(val)
        }
    },

    mounted(){
        localize(this.$i18n.locale)
        this.loadComponent()
    },

    methods: {
        ...mapActions('Promotion', ['getPromotionScopes', 'savePromotion']),
        ...mapMutations('Promotion', ['SET_PROMOTION']),
        async loadComponent() {
            await this.getPromotionScopesTypes()

            this.promotion = cloneDeep(this.getPromotion)
            this.promotionCopy = cloneDeep(this.promotion)

            if (this.getOperationType === OperationType.Creation){
                this.$sidePanel.disabledBackground(true)
                this.$sidePanel.disabledTabs(true)
                this.promotion.targetId = this.getCurrentTarget._TargetID
                this.promotion.targetDescription = this.getCurrentTarget.description
            }

            this.componentLoaded = true
        },

        async getPromotionScopesTypes(){
            const promotionScopesTypes = await this.getPromotionScopes()
            this.promotionScopes = promotionScopesTypes.map(scope => ({
                description: this.$tc(`Promotion.Scopes.${scope.scopeCode}`, scope.types.length),
                id: scope.scopeId,
                types: scope.types.map(type => ({
                    track: `${scope.scopeId}-${type.typeId}`,
                    id: type.typeId,
                    groupDescription: this.$tc(`Promotion.Scopes.${scope.scopeCode}`, scope.types.length),
                    description: this.$t(`Promotion.Types.${type.typeCode}`),
                    inputType: type.typeCode === DiscountTypeCode.PercentDiscount ? 'percentage' : 'dollar',
                    value: null,
                    data: {
                        scope: scope,
                        type: type
                    }
                }))
            }))
        },
        cancel(){
            this.$sidePanel.close();
        },
        next(){
            this.SET_PROMOTION(this.promotion)
            this.$sidePanel.isEdit(false)
            this.$sidePanel.disabledTabs(false)
            this.$sidePanel.nextTab();
        },
        async save(){
            this.preventSave = true;
            try {
                await this.savePromotion(this.promotion);
            }
            catch(error){
                const text = error.response.status === 400 ? 'Promotion.Messages.ValidationError' : 'Promotion.Messages.SavePromotionError'
                prompts.error({
                    text: this.$t(text)
                });
                this.preventSave = false;
                return
            }

            this.preventSave = false;

            this.$sidePanel.submit(true)
            prompts.toast({
                icon: 'success',
                title: this.$t('Promotion.Messages.SavePromotionSuccess', { promotionCode: this.getPromotion.code }),
                position: 'bottom-end'
            });
        }
    }
}
</script>