<template src="./options-tool-panel.html"></template>

<style lang="scss">
    @import "./options-tool-panel.scss";
</style>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'OptionsToolPanel',

    computed: {
        ...mapGetters('Grid', ['getUserGridState']),
        ...mapGetters('Account', ['getCurrentTargetLayerId', 'getUser']),
        displayResetButton() {
            return this.params.gridId
        },
        disabledResetButton() {
            return !this.userGridState
        },
        userGridState() {
            const userGridState = this.getUserGridState[this.getUser._UserID]
            if (!userGridState) return null
            const gridState = userGridState[this.params.gridId]
            if(!gridState) return null
            const targetLayerState = gridState[this.getCurrentTargetLayerId]
            return targetLayerState || null
        }
    },

    methods: {
        resetUserGridState() {
            this.params.api.closeToolPanel()
            this.params.resetGridState()
        }
    }
}
</script>