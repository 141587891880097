export const SearchLimitOptionEnum = {
    ALL: 'all',
    ITEM: 'item',
    SUBDEPARTMENT: 'subdepartment',
    DEPARTMENT: 'department'
}

export const SearchLimitOption = [
    { value: SearchLimitOptionEnum.ALL, text: 'Button.All' },
    { value: SearchLimitOptionEnum.ITEM, text: 'SideNav.Management.Items' },
    { value: SearchLimitOptionEnum.SUBDEPARTMENT, text: 'SideNav.Management.SubDepartments' },
    { value: SearchLimitOptionEnum.DEPARTMENT, text: 'SideNav.Management.Departments' }
]