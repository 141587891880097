<template src="./inventory-product-search.html"></template>

<script>
import { mapGetters, mapActions } from 'vuex';
import documentServices from '@/backoffice/modules/inventory/services/document.services.js'
import Swal from 'sweetalert2';
import inventoryScan from '@/backoffice/modules/inventory/components/side-panel/inventory-product-scan/inventory-product-scan.vue';
import inventoryProductCount from '@/backoffice/modules/inventory/components/side-panel/inventory-product-count/inventory-product-count.vue';
import inventoryProductAdjustment from '@/backoffice/modules/inventory/components/side-panel/inventory-product-adjustment/inventory-product-adjustment.vue';
import ProductSearch from '@/core/components/layout/side-panel/product-search/product-search.vue'

export default {
    name: 'inventory-product-search',

    props :{
        templatePayload: Object
    },

    components: {
        ProductSearch
    },

    data() {
        return {
            sidePanelComponents: {
                'InventoryProductAdjustment': [{ component: inventoryProductAdjustment }],
                'InventoryProductCount': [{ component: inventoryProductCount }]
            }
        };
    },

    computed:{
        ...mapGetters('Account', ['getCurrentTargetLayerId', 'hasPermission']),
        ...mapGetters('Inventory', ['getDocument'])
    },

    methods: {
        ...mapActions('Inventory', ['validate']),

        async select(product){
            const validationObject = {
                id:this.getDocument.id,
                targetLayerId : this.getCurrentTargetLayerId,
                upc : product.itemUPC,
                sku : product.itemSKU,
                docType:   this.getDocument.documentType,
                documentStatus: this.getDocument.documentStatus
            };

            const inProgressDocument = await this.validate(validationObject);
            if (inProgressDocument) {
                const result = await Swal.fire({    
                    heightAuto: false,
                    confirmButtonText: this.$i18n.t('Inventory.Messages.DoubleCount.ConfirmActionText'),
                    showCancelButton: true,
                    cancelButtonText: this.$i18n.t('Inventory.Messages.DoubleCount.CancelActionText'),
                    icon: 'question',
                    title: this.$i18n.t('Inventory.Messages.DoubleCount.Title'),
                    text: this.$i18n.t('Inventory.Messages.DoubleCount.Message'),
                });
                
                if (result.isConfirmed) {
                    this.$sidePanel.close();
                    this.$router.push({ 
                        name: 'document', 
                        params: { 
                            id: inProgressDocument.id 
                        },
                        query: { 
                            searchFilters: [ product.itemUPC ] 
                        }
                    });
                }
            }
            else {
                const inventoryProductTemplate = documentServices.getSidePanelTemplate(this.getDocument.documentType);
                this.$sidePanel.show(this.sidePanelComponents[inventoryProductTemplate], { itemUPC: product.itemUPC });
            }
        },

        back(){
            this.$sidePanel.show([{ component: inventoryScan }], null, { backgroundIsDisabled: true });
        }
    }
}
</script>