
import { genericServices } from '@/core/services/generic/generic.services';
import { CustomError } from '../../services/generic/types/customError';

export const EntityServices = {
  async getEntity(request){
    const response = await genericServices.getEntity(request);

    if(response.status == 200){
      return response.data;
    }
    else {
      throw new CustomError(response, 'An error occured when fetching entity');
    }
  },
  async getEntities(request){
    const response = await genericServices.getEntities(request);

    if(response.status == 200){
      return response.data;
    }
    else {
      throw new CustomError(response, 'An error occured when fetching entities');
    }
  },
  async saveEntities(request){
    const response = await genericServices.saveEntities(request);

    if(response.status == 200){
      return response.data;
    }
    else {
      throw new CustomError(response, 'An error occured when saving entities');
    }
  },
  async createEntities(request){
    const response = await genericServices.createEntities(request);

    if(response.status == 200){
      return response.data;
    }
    else {
      throw new CustomError(response, 'An error occured when creating entities');
    }
  },
  async deleteEntities(request){
    const response = await genericServices.deleteEntities(request);

    if(response.status == 200){
      return true;
    }
    else {
      throw new CustomError(response, 'An error occured when deleting entities');
    }
  }
}