<template src="./main.html"></template>

<script>
import i18n from "@/i18n"
import { mapGetters } from 'vuex';

export default {
  name: 'ReleaseNotes',
  computed: {
    ...mapGetters('ReleaseNote', ['getReleaseNotes']),
    filteredAndSortedVersions(){
      const releaseNotes = this.getReleaseNotes
        if(!releaseNotes){
            return []
        }

        const filtered = releaseNotes.filter(rn => rn.publicationdate < new Date()).sort((a, b) => b.publicationdate - a.publicationdate)

        return filtered;
    }
  },

  methods: {
    translateDescription(desc){
      return desc[i18n.locale];
    }
  }
}
</script>