import Vue from 'vue';
import { toCurrency, toAccountingCurrency, toDecimal, toPercent, toWeight, toLongDate, toDate } from '@/core/functions';

Vue.filter('toCurrency', function (value) {
    return toCurrency(value);
});

Vue.filter('toAccountingCurrency', function (value) {
    return toAccountingCurrency(value);
});

Vue.filter('toNumber', function (value) {
    return toDecimal(value);
});

Vue.filter('toPercent', function (value) {
    return toPercent(value);
});

Vue.filter('toWeight', function (value) {
    return toWeight(value);
});

Vue.filter('toLongDate', function (value) {
    return toLongDate(value);
});

Vue.filter('toDate', function (value) {
    return toDate(value);
});