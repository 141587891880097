<template src="./direct-receiving-product-scan.html"></template>

<script>
import { mapGetters, mapActions } from 'vuex';
import receivingProductSearch from '@/backoffice/modules/purchase/components/sidePanel/direct-receivings/direct-receiving-product-search/direct-receiving-product-search.vue';
import receivingProduct from '@/backoffice/modules/purchase/components/sidePanel/direct-receivings/direct-receiving-product/direct-receiving-product.vue';
import ScanProduct from '@/core/components/layout/side-panel/scan-product/scan-product.vue'
import SearchError from '@/backoffice/modules/purchase/components/sidePanel/orders/scan-search-error/error.vue'

export default {
    name: 'receiving-product-scan',

    components: {
        ScanProduct,
        SearchError
    },

    computed:{
        ...mapGetters('Receiving', ['getReceiving']),
        ...mapGetters('Product', ['getProductWithVendorCosts'])
    },

    data() {
        return {
            invalid: false
        }
    },

    methods: {
        ...mapActions('Product', ['fetchProductCostsByVendor']),

        cancel(){
            this.$sidePanel.close();
        },

        closeError() {
            this.invalid = false;
        },

        search(){
            this.$sidePanel.show([{ component: receivingProductSearch }], null, { classes: ['side-panel--sticky-footer'] });
        },

        scan() {
            this.invalid = false;
        },

        async select(product) {
            try {
                await this.fetchProductCostsByVendor({product: product, vendorCode: this.getReceiving.vendorCode});
            }
            catch (error) {
                return;
            }

            if (this.getProductWithVendorCosts && this.getProductWithVendorCosts.costs && this.getProductWithVendorCosts.costs.length > 0){
                this.$sidePanel.show([{ component: receivingProduct }]);
            }
            else {
                this.invalid = true;
            }
        }
    }
}
</script>