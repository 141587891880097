import { render, staticRenderFns } from "./product-info-header.html?vue&type=template&id=447a99ce&"
import script from "./product-info-header.vue?vue&type=script&lang=js&"
export * from "./product-info-header.vue?vue&type=script&lang=js&"
import style0 from "./product-info-header.vue?vue&type=style&index=0&id=447a99ce&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports