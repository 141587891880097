import i18n from '@/i18n';
import formatters from '@/core/components/common/grid/formatters/formatters.js';
import { languages } from '@/core/domain/language/language.const.js';

const ReportType = {
    Dashboard: 'Dashboard',
    Brand: 'Brand',
    RefundableDeposit: 'RefundableDeposit'
};

const caseInsensitiveComparator = (descA, descB) => descA.localeCompare(descB, languages.en, {sensitity: 'base'})

const getColumnDefintions = (reportType, isMobile, options) => {

    const cellClass = isMobile ? ['ag-cell-overflow', 'dropdown-cellrenderer'] : ['ag-cell-overflow']
    const cellRenderer = isMobile ? 'dropdownRenderer' : 'menuRenderer';
    const cellRendererParams = (params) => { 
        return isMobile ? { 
            openSidePanel: options.openSidePanel,
            canDelete: () => params.data.canDelete,
            onDelete: options.delete
        } : 
        {
            clickMenuLink: options.clickMenuLink,
            editEntity: options.editEntity,
            delete: options.delete
        }
    };

    if (reportType == ReportType.Dashboard) {
        return [
            { 
                hide: false,
                field: 'description',
                headerName: i18n.t('Dashboard.Columns.Description'),
                headerTooltip: i18n.t('Dashboard.Columns.Description'),
                cellRenderer: cellRenderer,
                cellRendererParams: cellRendererParams,
                cellClass: cellClass,
                autoHeight: isMobile,
                sort: 'asc'
            },
            { hide: isMobile, field: 'targetDescription', headerName: i18n.t('Dashboard.Columns.TargetDescription'), headerTooltip: i18n.t('Dashboard.Columns.TargetDescription') },
            { hide: isMobile, field: 'listRoles', headerName: i18n.t('Dashboard.Columns.ListRoles'), headerTooltip: i18n.t('Dashboard.Columns.ListRoles') }
        ];
    }
    else if (reportType == ReportType.Brand) {
        return [
            { 
                hide: false,
                field: 'description',
                headerName: i18n.t('Brand.Columns.Description'),
                headerTooltip: i18n.t('Brand.Columns.Description'),
                cellRenderer: cellRenderer,
                cellRendererParams: cellRendererParams,
                cellClass: cellClass,
                sort: 'asc',
                comparator: caseInsensitiveComparator
            },
            {
                hide: isMobile,
                field: 'targetDescription',
                headerName: i18n.t('Brand.Columns.TargetDescription'),
                headerTooltip: i18n.t('Brand.Columns.TargetDescription')
            },
        ];
    }
    else if (reportType == ReportType.RefundableDeposit) {
        return [
            { 
                hide: false,
                field: 'shortDescription',
                headerName: i18n.t('RefundableDeposit.Columns.ShortDescription'),
                headerTooltip: i18n.t('RefundableDeposit.Columns.ShortDescription'),
                cellRenderer: cellRenderer,
                cellRendererParams: cellRendererParams,
                cellClass: cellClass,
                sort: 'asc'
            },
            {
                hide: isMobile,
                field: 'targetDescription',
                headerName: i18n.t('RefundableDeposit.Columns.TargetDescription'),
                headerTooltip: i18n.t('RefundableDeposit.Columns.TargetDescription')
            },
            { 
                hide: isMobile,
                field: 'refundableDepositCode',
                headerName: i18n.t('RefundableDeposit.Columns.RefundableDepositCode'),
                headerTooltip: i18n.t('RefundableDeposit.Columns.RefundableDepositCode')
            },
            { 
                hide: isMobile,
                field: 'value',
                headerName: i18n.t('RefundableDeposit.Columns.Value'),
                headerTooltip: i18n.t('RefundableDeposit.Columns.Value'),
                filter: 'agNumberColumnFilter', 
                valueFormatter: formatters.currencyFormatter
            }
        ];
    }
};

export { getColumnDefintions };