import * as PriceLevelServices from '@/backoffice/modules/priceLevel/services/priceLevel.services'

const getDefaultState = () => {
    return {
        priceLevel: null,
        priceLevels: []
    }
}
  
export default {
    namespaced: true,
    name: 'PriceLevel',
    state: getDefaultState(),

    getters: {
        getPriceLevel: state => {
            return state.priceLevel
        },
        getPriceLevels: state => {
            return state.priceLevels
        }
    },

    mutations: {
        RESET_STORE(state) {
            Object.assign(state, getDefaultState())
        },
        SET_PRICE_LEVEL(state, priceLevel) {
            state.priceLevel = priceLevel
        },
        SET_PRICE_LEVELS(state, priceLevels) {
            state.priceLevels = priceLevels || []
        }
    },

    actions: {
        async fetchPriceLevels({ commit }) {
            const priceLevels = await PriceLevelServices.getPriceLevels()
            commit('SET_PRICE_LEVELS', priceLevels)
        }
    }
}