<template src="./stats-block.html"></template>

<style scoped lang="scss">
@import './stats-block.scss';
</style>

<script>
import moment from 'moment';
import axios from 'axios';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';

export default {
  name: "statsBlock",
  props: ['targetID', 'date'],
  data() {
    return {
      cancelToken: {},
      source: {},
      contentVisible: true,
      loading: false,
      types: ['Operator','Pos','Store'],
      activeTabIndex: 0,
      activeBlockIndex: 0,
      selectedTab: {},
      selectedBlock: {},
      statsBlock: {},
    }
  },
  computed:{
    ...mapGetters('App', ['isMobile']),    
  },
  beforeDestroy() {
    this.source.cancel('OPERATION_CANCELED');
  },
  created() {
    this.cancelToken = axios.CancelToken;
    this.source = this.cancelToken.source();
  },
  mounted() {
    this.setWidget();
    this.getData();
  },

  methods: {
    ...mapActions('Widgets', ['getWidgetGetStatsPer', 'getWidgetGetStats']),
    setWidget(){
      this.statsBlock = {
        classes: "col-xlg-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xxs-12 justify-center",
        title:"Widgets.StatisticPer",
        tabs: [
                { type:'Operator', title: 'Widgets.Operator', items:[ { items:[] } ] }, 
                { type:'Pos', title: 'Widgets.Pos', items:  [] }, 
                { type:'Store', title: 'Widgets.Store', items:  [] } 
              ]
      
      }
      this.activeTabIndex = 0,
      this.activeBlockIndex = 0,
      this.selectedTab = {},
      this.selectedBlock = {}
    },
    getData(){
      const currentDate = moment().format('YYYY-MM-DD');
      const request = {
        params: { date: this.date ? this.date : currentDate, type: '' },
        cancelToken: { cancelToken: this.source.token },
        targetIDSelected: this.targetID
      }
      for (let i = 0; i < this.types.length; i++) {
        request.params.type = this.types[i];
        this.getWidgetGetStatsPer(request)
        .then((widget) => {
          this.statsBlock.tabs[i] = widget;
          //Set the selected tab and block only if the index is 0 so we are ready to show data
          if (this.selectedTab && i == 0) {
            this.selectedTab = this.statsBlock.tabs[i];
            this.selectedBlock = this.selectedTab.items[0];
            this.getStats();
          }
        })      
        .catch(() => {
          this.setWidget();
        });
      }
    },
    async getStats() {
      if(this.selectedBlock && ((this.selectedTab.title == 'Widgets.Operator') || this.selectedBlock._TargetLayerID)) {
        this.loading = true;
        let name = null;
        let code = null;

        if(this.selectedTab.title == 'Widgets.Operator'){
          name = this.selectedBlock.label;
          code = this.selectedBlock.extra1;
        }

        const currentDate = moment().format('YYYY-MM-DD');
        const request = {
          params: { date : this.date ? this.date : currentDate,
            tl: this.selectedBlock._TargetLayerID,
            type: this.selectedBlock.widgetType,
            name: name,
            code: code
          },
          cancelToken: { cancelToken: this.source.token },
          targetIDSelected: this.targetID
        }

        let widget = null;

        try {
          widget = await this.getWidgetGetStats(request);
        } catch (error) {
          this.statsBlock.tabs[this.activeTabIndex].items[this.activeBlockIndex].items = null;
          this.selectedBlock.items = null;
          this.loading = false;
        }

        if (widget) {
          //result.data contains a SummaryBlock that contains a list of Items
          this.statsBlock.tabs[this.activeTabIndex].items[this.activeBlockIndex].items = widget;
          this.selectedBlock.items = widget;
          this.loading = false;
        }
      }
    },
    changeTab(tabIndex) {
      this.activeTabIndex = tabIndex;
      this.activeBlockIndex = 0;
      this.selectedTab = this.statsBlock.tabs[this.activeTabIndex];
      this.selectedBlock = this.selectedTab.items[this.activeBlockIndex];
      this.getStats();
    },


    changeBlock(blockIndex) {
      if(this.activeBlockIndex == blockIndex){
          this.contentVisible = !this.contentVisible;
      }
      else{
      this.contentVisible = true;
      }

      this.activeBlockIndex = blockIndex;
      this.selectedBlock = this.selectedTab.items[blockIndex];
      this.getStats();
    },

    changeBlockMobile(blockIndex) {
      const elmnt = document.getElementById('widget'+blockIndex);

      this.selectedBlock = this.selectedTab.items[this.activeBlockIndex];
      this.getStats();

      if(this.activeBlockIndex == blockIndex){
          this.contentVisible = !this.contentVisible;
          elmnt.scrollIntoView();
      }
      else{
      this.contentVisible = true;
      elmnt.scrollIntoView();
      }
        this.activeBlockIndex = blockIndex;
    },


    changeTabMobile(direction) {
      if ( direction == 'left') {
        if ( this.activeTabIndex === 0 ){
          this.activeTabIndex = this.statsBlock.tabs.length -1;
        }
        else {
          this.activeTabIndex = ((this.activeTabIndex - 1) % this.statsBlock.tabs.length);
        }
      } 
      else if ( direction == 'right' ){
        this.activeTabIndex= ((this.activeTabIndex + 1) % this.statsBlock.tabs.length);
      }

      this.selectedTab = this.statsBlock.tabs[this.activeTabIndex];
      this.selectedBlock = this.selectedTab.items[this.activeBlockIndex];
      this.getStats();
    }
  }
}
</script>
