<template inline-template v-cloak src="./filter-input.html"></template>

<style lang="scss">
  @import './filter-input.scss';
</style>

<script>
import multiselect from 'vue-multiselect';
import { CurrencyInput  } from 'vue-currency-input'
import { ValidationObserver, ValidationProvider, localize } from "vee-validate";
import { Operators, OperatorByType } from '@/core/components/common/backend-filters/operators/operators.js';
import cloneDeep from 'lodash/cloneDeep';
import { numberKeypress } from '@/core/functions';

import calendar from '@/core/components/common/calendar/calendar.vue'
import horizontalList from '@/core/components/common/horizontal-list/horizontal-list.vue'

export default {
  name: 'filterInput',
  model: {
    prop: 'filter',
  },
  props: {
    filter: Object,
  },
  components:{  
    calendar,
    horizontalList,
    multiselect,
    CurrencyInput,
    ValidationObserver,
    ValidationProvider
  },

  data() {
    return {
      localFilter: cloneDeep(this.filter),
      inputValue: null,
      disabledValidation: true,
      selectedFilterOperator: {},
      decimalConfigs: {
        locale: this.$i18n.locale,
        currency: null,
        distractionFree: false,
        precision: 2
      },
      showOperatorsDropdown: false,
      inputTimeout: null,
      booleanMultiSelectOptions: [0, 1]
    };
  },

  watch:{
    'filter.column': {
      handler(newVal, oldVal) {
        if(newVal && newVal != oldVal) {
          this.localFilter = cloneDeep(this.filter);
          this.selectedFilterOperator = Operators.equal;
          this.reset();
        }
      },
    },
    'selectedFilterOperator.value':{
      handler(newVal, oldVal){
        if(oldVal && newVal != oldVal){
          this.reset();
        }
      }
    }
  },

  computed:{
    numberKeypress: function () {
      return numberKeypress;
    },
    multiValue(){
      return ["in", "notin"].includes(this.localFilter.operator);
    },
    placeholder(){
      return this.multiValue ? this.$t("BackendFilter.Placeholder.EnterValue") : this.$t("BackendFilter.Placeholder.SetValue");
    },
    filterOperators(){
      return OperatorByType[this.filter.type] || [];
    },

    validationRules(){
      if (this.disabledValidation)
        return null;

      if (!this.multiValue) {
        if(["empty", "notempty"].includes(this.localFilter.operator)){
          return null;
        }
        if(this.localFilter.type == "integer"){
          return 'required|integer';
        }
        return 'required';
      }
      else {
        if (this.localFilter.value.length > 0)
          return null;
        else
          return 'required';
      }
    }
  },

  mounted(){
    localize(this.$i18n.locale);

    if (this.localFilter.value && !this.multiValue)
      this.inputValue = this.localFilter.value[0];

    if (this.localFilter.type && this.localFilter.operator)
      this.selectedFilterOperator = this.filterOperators.find(op => op.value == this.localFilter.operator);
  },

  methods: {
    open(){
      this.showOperatorsDropdown = !this.showOperatorsDropdown;
    },
    hide(){
      this.showOperatorsDropdown = false;
    },
    updateFilterValue(evt){
      if (evt && evt.keyCode === 13) {
        if (this.multiValue && this.inputValue) {
          this.localFilter.value.push(this.inputValue);
          this.inputValue = null;
          this.updateFilter();
        }
      }
      else {
        if (!this.multiValue) {
          if (this.inputTimeout)
            clearTimeout(this.inputTimeout);

          this.inputTimeout = setTimeout(() => {
            clearTimeout(this.inputTimeout);
            
            if (this.localFilter.value.length == 0)
              this.localFilter.value.push(this.inputValue);
            else
              this.localFilter.value[0] = this.inputValue;

            this.updateFilter();
          }, 500);
        }
      }
    },

    reset() {
      this.localFilter.operator = this.selectedFilterOperator.value;
      this.localFilter.value = [];
      this.inputValue = null;
      this.disabledValidation = false;
      this.updateFilter();
    },
    
    updateFilter() {
      this.$emit('input', this.localFilter);
    },

    deleteFilter(deletedValue) {
      const deletedValueIndex = this.localFilter.value.findIndex(value => value === deletedValue);
      this.localFilter.value.splice(deletedValueIndex, 1);
      this.updateFilter();
    }
  }
}

</script>
