<template src='./promotion-product-scan.html'></template>

<script>
import { mapGetters } from 'vuex';
import promotionProductAdd from '@/backoffice/modules/promotion/components/sidePanel/promotion-product-add/promotion-product-add.vue';
import promotionProductSearch from '@/backoffice/modules/promotion/components/sidePanel/promotion-product-search/promotion-product-search.vue';
import ScanProduct from '@/core/components/layout/side-panel/scan-product/scan-product.vue'
import { PromotionItemCategory } from '@/backoffice/modules/promotion/domain/promotion';
import i18n from "@/i18n";

export default {
  name: 'promotion-product-scan',

  props: {
    templatePayload: {
      type: Object,
      default: function(){
        return { category: PromotionItemCategory.GetY}
      }
    }
  },

  components: {
    ScanProduct
  },

  computed: {
    ...mapGetters('Promotion', ['getPromotion', 'getPromotionDescription']),
    itemCategory(){
      if(!this.templatePayload?.category){
        return ''
      }
      return i18n.t(`Promotion.ItemCategory.${this.templatePayload.category}`)
    }
  },
  methods: {
    cancel() {
      this.$sidePanel.close();
    },

    search() {
      this.$sidePanel.show([{ component: promotionProductSearch }], {category: this.templatePayload?.category}, { classes: ['side-panel--sticky-footer'] });
    },

    select(product) {
      this.$sidePanel.show([{ component: promotionProductAdd }], { productUpc: product.itemUPC, category: this.templatePayload?.category });
    }
  }
}
</script>
