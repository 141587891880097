const getDefaultState = () => {
    return {
        client: null,
        operationType: null
    }
}
  
export default {
    namespaced: true,
    name: 'ClientAccount',
    state: getDefaultState(),
    getters: {
        getClient: state => {
            return state.client
        },
        getOperationType: state => {
            return state.operationType
        }
    },
    mutations: {
        RESET_STORE(state) {
            Object.assign(state, getDefaultState())
        },
        SET_CLIENT(state, client){
            state.client = client
        },
        SET_OPERATION_TYPE(state, type){
            state.operationType = type
        }
    }
}