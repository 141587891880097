import { ReleaseNoteItem } from "@/backoffice/modules/release-note/domain";

export class ReleaseNoteVersion {
    constructor(version){
        this.version = version.version;
        this.publicationdate = new Date(version.publicationdate);
        if(isNaN(this.publicationdate.getTime())){
            throw new Error('invalid publication date')
        }
        this.description = version.description;

        this.items = version.items?.map(item => new ReleaseNoteItem(item));
    }
}