<template src="./grid.html"></template>
<style lang="scss" src="./grid.scss"></style>

<script>
import { AgGridVue } from "@ag-grid-community/vue";
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { GridChartsModule } from '@ag-grid-enterprise/charts';
import { SideBarModule } from '@ag-grid-enterprise/side-bar';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { stringFunction } from '@/core/functions';

import checkboxRenderer from  './renderers/checkbox-renderer/checkbox-renderer.vue';
import checkboxTreeRenderer from  './renderers/checkbox-renderer/checkbox-tree-renderer.vue';
import detailRenderer from  './renderers/detail-renderer/detail-renderer.vue';
import dropdownRenderer from './renderers/dropdown-renderer/dropdown-renderer.vue';
import groupRenderer from './renderers/group-renderer/group-renderer.vue';
import menuRenderer from  './renderers/menu-renderer/menu-renderer.vue';
import negativeAmountRenderer from  './renderers/negative-amount-renderer/negative-amount-renderer.vue';
import negativeValueRenderer from  './renderers/negative-value-renderer/negative-value-renderer.vue';
import optionsRenderer from  './renderers/options-renderer/options-renderer.vue';
import periodRenderer from './renderers/period-renderer/period-renderer.vue';
import panelRenderer from './renderers/panel-renderer/panel-renderer.vue';
import integerInputRenderer from  './renderers/integer-input-renderer/integer-input-renderer.vue';
import currencyInputRenderer from  './renderers/currency-input-renderer/currency-input-renderer.vue';
import toggleRenderer from  './renderers/toggle-renderer/toggle-renderer.vue';
import booleanRenderer from './renderers/boolean-renderer/boolean-renderer.vue';
import currencyRenderer from './renderers/currency-renderer/currency-renderer.vue';
import clickCallbackRenderer from './renderers/click-callback-renderer/click-callback-renderer.vue';
import linkRenderer from './renderers/link-renderer/link-renderer.vue';
import iconRenderer from './renderers/icon-renderer/icon-renderer.vue';
import availabilityMaximumRenderer from './renderers/availability-maximum-renderer/availability-maximum-renderer.vue'
import { getTranslationForRangePicker } from '@/core/functions/';
import { Datatype } from '@/core/datatype/';
import { mapGetters } from 'vuex';
import { MaxValueForSizeColumnsToFit } from '@/core/components/common/grid/grid.const'

export default {
  name: 'grid',
  model: {
    prop: 'componentGrid'
  },
  props:{
    gridOptions: Object,  
    gridTitle: String,
    filters: Array,
    rowData: Array,
    columnDefs: Array,
    overlayLoadingTemplateMessage: {
      type: String,
      default: function () {
        return this.$t('Reporting.LoadingData');
      }
    }
  },
  components: {
    AgGridVue,
    booleanRenderer,
    currencyRenderer,
    checkboxTreeRenderer,
    checkboxRenderer,
    detailRenderer,
    dropdownRenderer,
    groupRenderer,
    iconRenderer,
    menuRenderer,
    negativeAmountRenderer,
    negativeValueRenderer,
    optionsRenderer,
    periodRenderer ,
    panelRenderer,
    integerInputRenderer,
    currencyInputRenderer,
    toggleRenderer,
    clickCallbackRenderer,
    linkRenderer,
    availabilityMaximumRenderer
  },
  data(){
    return{
      modules: [ClientSideRowModelModule, CsvExportModule, ClipboardModule, ColumnsToolPanelModule, RowGroupingModule, 
                SideBarModule, ExcelExportModule, FiltersToolPanelModule, MenuModule, SetFilterModule, MultiFilterModule, RangeSelectionModule, GridChartsModule],
      overlayNoRowsTemplate: '<span>' + this.$t('General.Messages.NoData') + '</span>',
      overlayLoadingTemplate: '<span class="ag-overlay-loading-center">' + this.overlayLoadingTemplateMessage + '</span>',
      excelStyles: [{ id: `${Datatype._String}Type`, dataType: Datatype._String, }]
    }
  },

  computed:{
    ...mapGetters('App', ['isMobile', 'getResize']),
    headerHeight() { return this.gridOptions.headerHeight ?? 50; }
  },

  watch:{
    getResize(){
      this.resizeColumns();
    },
    filters() {
      this.filterData();
    }
  },
  
  methods: {
    onGridReady: function(params){
      params.api.hideOverlay();
      params.api.sizeColumnsToFit();

      this.filterData();
      if(!this.rowData || this.rowData.length == 0)
        this.gridApi?.showNoRowsOverlay()
    },
    onSelectionChanged: function(params){ 
      this.$emit('onSelectionChanged', params)
    },
    resizeColumns(){
      this.$nextTick(() => {
        const cols = this.gridOptions.columnApi.getAllDisplayedColumns();
        if(this.isMobile || cols.length < MaxValueForSizeColumnsToFit){
            this.gridOptions.api.sizeColumnsToFit();
        }
        else{
          this.gridOptions.columnApi.autoSizeAllColumns();
        }
      });
    },
    getLocaleText: function(params) {
        const gridKey = 'grid.' + params.key;
        const value = this.$t(gridKey);
        return this.compareCI(value, gridKey) ? params.defaultValue : value;
    },
    getContextMenuItems: function(){
      const result = [   
        "copy", "copyWithHeaders", "copyWithGroupHeaders", "paste", "separator",
        {
          name: this.$t("grid.export"),
          subMenu: [
            {
              name: this.$t("grid.csvExport"),
              action: this.exportAsCsv
            },
            {
              name: this.$t("grid.excelExport"),
              action: this.exportAsExcel
            }
          ]
        },
        "chartRange"
      ];

      return result;
    },
    exportAsCsv: function(){    
      this.gridOptions.api.exportDataAsCsv({
        processCellCallback: (params) => {
          const colDef = params.column.getColDef()
          // try to reuse valueFormatter from the colDef
          if (colDef.valueFormatter) {
            const valueFormatterParams = {
              ...params,
              data: params.node.data,
              node: params.node,
              colDef: params.column.getColDef()
            };
            return colDef.valueFormatter(valueFormatterParams);
          }
          return params.value;
        },
        fileName: this.gridTitle,
        sheetName: this.gridTitle
      });
    },
    exportAsExcel: function(){
      this.gridOptions.api.exportDataAsExcel({
        processCellCallback: (params) => {
          const colDef = params.column.getColDef()
          // try to reuse valueFormatter from the colDef
          if (colDef.valueFormatter) {
            const valueFormatterParams = {
              ...params,
              data: params.node.data,
              node: params.node,
              colDef: params.column.getColDef()
            };
            return colDef.valueFormatter(valueFormatterParams);
          }
          return params.value;
        },
        fileName: this.gridTitle,
        sheetName: this.gridTitle
      });
    },
    isExternalFilterPresent(){
      return !!this.filters && this.filters.length > 0;
    },
    doesExternalFilterPass(node){
      const displayedColumns = [...this.gridOptions.columnApi.getAllDisplayedColumns(), ...this.gridOptions.columnApi.getRowGroupColumns()];

      const hasMatch = displayedColumns.some(column => {
        const colField = column.colDef.field;
        let rawValue = null;

        if(node.data && colField)
          rawValue = node.data[colField];
        else if(node.key)
          rawValue = node.key
        else
          return

        const rawValueIsStringable = !!rawValue && typeof rawValue !== 'object'
        const fieldValue = rawValueIsStringable ? rawValue.toString() : undefined;

        return this.filters.some(filter => {
          if(colField == 'period') {
            let translatedRangePicker = getTranslationForRangePicker(node.data.period);
            translatedRangePicker = stringFunction.normalize(translatedRangePicker);
            const filterValue = stringFunction.normalize(filter);
            return translatedRangePicker.includes(filterValue);
          }

          let formattedValue = this.getGridFormattedValue(node, column, fieldValue);
          if (!formattedValue) {
            return false;
          }
          
          formattedValue = stringFunction.normalize(formattedValue.toString());

          const filterValue = stringFunction.normalize(filter);
          return formattedValue.includes(filterValue);
        });
      });

      return hasMatch;
    },
    getGridFormattedValue(node, column, value) {
      const nodeFilter = Object.assign({ rowPinned: node.isRowPinned() }, node);
      const filterParams = {
        value: value,
        data: nodeFilter.data,
        node: nodeFilter,
        colDef: column.colDef
      };

      if (column.colDef.valueGetter) {
        value = column.colDef.valueGetter(filterParams);

        // Reset value with the return value from valueGetterFilter because ValueFormatter is apply with the modification fromn ValueGetter.
        filterParams.value = value;
      }
      
      if (column.colDef.valueFormatter)
        value = column.colDef.valueFormatter(filterParams);

      return value;
    },
    filterData() {
      if (this.gridOptions.api) {
        this.gridOptions.api.onFilterChanged();
      }
    }
  }
}
</script>