<template src="./accountManagement.html"></template>

<style lang="scss">
    @import './accountManagement.scss';
</style>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import toggle from '@/core/components/common/toggle/toggle.vue'
import { mapGetters } from 'vuex';
import { clientAccountServices } from '@/backoffice/modules/clientAccount/services/clientAccount.services.js'
import clientAccountClient from '@/backoffice/modules/clientAccount/clients/clientAccount.client.js'
import prompts from '@/core/tools/notifications/notifications'
import { permissionsDictionary } from '@/core/security/permissions-dictionary.js';
import { OperationType } from '@/backoffice/domain/backoffice.const.js'
import cloneDeep from 'lodash/cloneDeep';
import { toCurrency } from '@/core/functions';

export default {
    name: 'AccountManagement',
    components: {
        ValidationObserver,
        ValidationProvider,
        toggle
    },
    data (){
        return {
            client: {},
            invalidForm: true,
            preventSave: false,
            showAccountStatementButton: false
        }
    },
    mounted(){
       this.client = cloneDeep(this.getClient)

       this.showAccountStatementButton = (!this.readOnly &&
                                           this.client?.targetId === this.getCurrentTargetId &&
                                           (this.client?.isChargeToAccountEnabled || this.client?.balance > 0))
    },
    computed: {
        ...mapGetters('ClientAccount', ['getClient', 'getOperationType']),
        ...mapGetters('Account', ['hasPermission', 'getCurrentTargetId']),
        readOnly(){
            if(this.getOperationType === OperationType.Creation)
                return !this.hasPermission([permissionsDictionary.CLIENT_CREATE])

            return !this.hasPermission([permissionsDictionary.CLIENT_UPDATE])
        },
        creditLimitRules(){
            return this.client?.isChargeToAccountEnabled ? 'required|min_value:1': ''
        },
        balanceAmount() {
            return this.client?.balance ? toCurrency(this.client.balance) : null
        }
    },
    watch: {
        invalidForm(){
            const disabledTab = this.invalidForm || this.getOperationType === OperationType.Creation
            this.$sidePanel.disabledTabs(disabledTab)
        },
        getOperationType(){
            const disabledTab = this.invalidForm || this.getOperationType === OperationType.Creation
            this.$sidePanel.disabledTabs(disabledTab)
        }
    },

    methods: {
        async save(){
            this.preventSave = true

            try{
                if (this.client.isChargeToAccountEnabled && this.client.balance === null)
                    this.client.balance = 0

                await clientAccountServices.createOrUpdate(this.client, this.getOperationType)
                this.$sidePanel.close();

                prompts.toast({
                    icon: 'success',
                    title: this.$t('ClientAccount.Messages.SaveClientSuccess', { email: this.client.email }),
                    position: 'bottom-end'
                });
            }
            catch(error){
                if(error.status === 409)
                    prompts.error({ text: this.$t('ClientAccount.Messages.DuplicatedClientError') });
                else
                    prompts.error({ text: this.$t('ClientAccount.Messages.SaveClientError') });
            }

            this.preventSave = false
        },

        async sendClientAccountStatement(){
            this.preventSave = true

            try{
                await clientAccountClient.sendClientAccountStatement(this.client.id)

                prompts.toast({
                    icon: 'success',
                    title: this.$t('ClientAccount.Messages.SendClientAccountStatementSuccess', { email: this.client.email }),
                    position: 'bottom-end'
                });
            }
            catch{
                prompts.error({ text: this.$t('ClientAccount.Messages.SendClientAccountStatementError', { email: this.client.email }) });
            }

            this.preventSave = false
        },
        cancel(){
            this.$sidePanel.close();
        },
        isInvalid(invalid){
            this.invalidForm = invalid;
            return invalid;
        }
    }
}
</script>