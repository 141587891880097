import AnonymousApi from '../_api.config.anonymous';
import axios from 'axios';

async function loginUser(basicLogin) {
  try {    
    const response = await AnonymousApi().post('/api/SecurityFacade/Login', basicLogin);
    return ({ status: response.status, data: response.data });
  } catch(e) {
    if(axios.isAxiosError(e)) {
      return { status: e.response?.status,  message: e.response?.data, data: null };
    }
  }
}

export const basicLoginServices = {
  loginUser
};

