import Vue from 'vue';
import { permissionsDictionary } from '@/core/security/permissions-dictionary.js';

export const hasPermissions = {
  inserted(el, binding, vnode){
    const userPermission = vnode.context.$store.getters['Account/getPermissions'] || [];

    const bindingValue = binding.value ? binding.value : [];
    const requiredPermission = Array.isArray(bindingValue) ? bindingValue : [bindingValue];
    const authorize = requiredPermission.some(requiredPermission => {
        return userPermission.includes(permissionsDictionary[requiredPermission]) || userPermission.includes(requiredPermission)
      })
          
    if(!requiredPermission.length || authorize) {
      return 
    }

    if(binding.modifiers['removeChild'] && el.parentNode){
      el.parentNode.removeChild(el)
    }
    else{
      el.style.visibility = "hidden";
      el.style.width = "0px";
      el.style.padding = "0px";
      el.style.margin = "0px";
    }
  } 
};

Vue.directive('has-permissions', hasPermissions)
  