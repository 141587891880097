<template src="./chart-block.html"></template>

<style scoped lang="scss">
@import './chart-block.scss';
</style>

<script>
import { mapGetters, mapActions } from 'vuex';
import Highcharts from 'highcharts';
import moment from 'moment';
import axios from 'axios';

export default {
  name: 'chartBlock',
  props: ['targetID', 'date'],
  data() {
    return {
      currentTargetLayer: {},
      range: null,
      open: false,
      cancelToken: {},
      source: {},
      chart: {},
      chartObj: null
    }
  },
  computed: {
    ...mapGetters('Account', ['getCurrentTargetLayer']),
    ...mapGetters('Widgets', ['getRange'])
  },
  created(){
    this.cancelToken = axios.CancelToken;
    this.source = this.cancelToken.source();
  },
  mounted(){
    this.currentTargetLayer = this.getCurrentTargetLayer;
    this.setHighcharts();
    this.setRange();
    this.setWidget();
    this.setChart();
    this.getData();
  },
  beforeDestroy() {
    this.source.cancel('OPERATION_CANCELED');
  },
  methods:{
    ...mapActions('Widgets', ['getWidgetSalesPerRange']),

    close(){
      this.open = false;
    },

    setRange(){
      this.range = this.getRange || 'Hours';
      if(this.currentTargetLayer.targetLayerType == 'ENTERPRISE' && (this.range == 'Weeks' || this.range == 'Months')){
        this.range = 'Hours';
      }
    },

    setHighcharts(){
      Highcharts.setOptions({
        lang: {
            loading: this.$t('Widgets.Loading')
        }
      });
    },
    setWidget(){      
      this.chart = {
        title: 'Widgets.SalesPerRange.' + this.range,
        type: 'line',
        xAxis: { categories: [] },
        yAxis: { title: null },
        colors: ['#005394', '#50C8E8', '#40B282'],
        series: [{
          data: [],
          detail: [],
          name: null,
          showInLegend: true,
          tooltip: null,
          translation: ''
        },
        {
          data: [],
          detail: [],
          name: null,
          showInLegend: true,
          tooltip: null,
          translation: ''
        }
        ],
        legend: {
          backgroundColor: 'rgba(255,255,255,0)',
          y: 20,
          x: 30,
          align: 'left',
          verticalAlign: 'bottom',
          itemStyle: { 'color': '#5A5A5A', 'font-size': '16px', 'font-weight': 'normal' },
          enabled: true,
          useHTML: true,
          labelFormatter: function(){
            return '<div><span>' + this.userOptions.translation + '</span></div>';
          }
        },
        tooltip:{
          crosshairs: { width: 1, color: '#005394' },
          backgroundColor: '#005394',
          style: { 'color': 'white' },
          useHTML: true
        }
      }
    },
    setFormatter(){
      const filters = this.$options.filters;
      const i18n = this.$i18n;
      return function() {
          let s = '<div style="text-align:center"><b>'+ this.x +'</b></div>';
          let chart;
          let y;
          if(this.points){
            chart = this.points[0].series.chart; //get the chart object
          }
          else{
            chart = this.point.series.chart; //get the chart object
            y = this.y;
          }
          const index = this.point.index;

          if(chart.hoverSeries.userOptions && chart.hoverSeries.userOptions.detail){
            chart.hoverSeries.userOptions.detail.forEach(function(detail)
            {
              if(detail.type == "UnderTitle" && detail.data[index]){
                s += '<br/><div style="text-align:center"><b>'+ detail.data[index] +'</b></div>';
              }
            });
          }

          s += '<br/><div>' + filters.toCurrency(y)  + '</div>';

          if(chart.hoverSeries.userOptions && chart.hoverSeries.userOptions.detail){
            chart.hoverSeries.userOptions.detail.forEach(function(detail)
            {
              if(detail.type == "Detail"){
                s += '<br/><div>' + detail.data[index] + ' ' + i18n.t('Widgets.SalesPerRange.' + detail.title) + '</div>';
              }
            });
          }
          return s;
      }
    },
    async getData(){
      this.open = false;
      this.chartObj.showLoading();

      const currentDate = moment().format('YYYY-MM-DD');
      const request = {
        params: { date: this.date ? this.date : currentDate, range: this.range },
        cancelToken: { cancelToken: this.source.token },
        targetIDSelected: this.targetID
      }

      let widget = null;

      try {
        widget = await this.getWidgetSalesPerRange(request);
      } catch (error) {
        this.setWidget();
      }
      
      if (widget) {
        for (let i = 0; i < widget.series.length; i++) {
          widget.series[i].translation = this.translation(widget.series[i].name);
          widget.series[i].showInLegend = true;
          widget.series[i].tooltip = { 'valueSuffix': '$' };
        }

        this.chart.xAxis.categories = this.xAxis(widget.xAxis);
        this.chart.title = widget.title;
        this.chart.series = widget.series;
        this.chart.tooltip.formatter = this.setFormatter();
        this.setChart();
      }
    },
    translation(name){
      let translation;
      if(this.range == 'Hours'){
        if(name == 'Total'){
          translation = this.$t('Widgets.SalesPerRange.Today');
        }
        else{
          const d = this.$t('Calendar.DayNames.' +  moment(this.date).format('dddd'));          
          translation = this.$t('Widgets.SalesPerRange.LastDay', { day: d } );
        }
      }
      else if(this.range == 'Days'){
        translation = name == 'Total' ? this.$t('Widgets.SalesPerRange.ThisWeek') : this.$t('Widgets.SalesPerRange.LastYear');
      }
      else if(this.range == 'Weeks'){
        translation = name == 'Total' ? this.$t('Widgets.SalesPerRange.ThisYear') : this.$t('Widgets.SalesPerRange.LastYear');
      }
      else if(this.range == 'Months'){
        translation = name == 'Total' ? this.$t('Widgets.SalesPerRange.ThisYear') : this.$t('Widgets.SalesPerRange.LastYear');
      }
      return translation;
    },
    xAxis(axis){
      let xAxis = [];
      if(this.range == 'Days'){
        for (let i = 0; i < axis.length; i++) {
          xAxis.push(this.$t('Calendar.DayNames.' + axis[i]));              
        }
      }
      else if(this.range == "Weeks"){
        for (let i = 0; i < axis.length; i++) {
          xAxis.push(this.$t('Calendar.Week') + ' ' + axis[i]);              
        }   
      }
      else if(this.range == 'Months'){
        for (let i = 0; i < axis.length; i++) {
          xAxis.push(this.$t('Calendar.MonthNames.' + axis[i]));              
        }      
      }
      else{
        xAxis = axis;
      }
      return xAxis;
    },
    setChart(){
      const chartContainer = this.$refs['chart-container'];
      this.chartObj = Highcharts.chart(chartContainer, {
        chart:{
          type: this.chart.type,
          marginTop: 50,
          events: this.chart.events
        },
        title: this.chart.title,
        xAxis: this.chart.xAxis,
        yAxis: this.chart.yAxis,
        colors: this.chart.colors,
        series: this.chart.series,
        legend: this.chart.legend,
        tooltip: this.chart.tooltip,
        advancedInfo: this.chart.advancedInfo,
        credits: {
          enabled: false
        }
      });
      this.chartObj.hideLoading();
    }
  }
}
</script>
