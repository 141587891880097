
import { genericServices } from '@/core/services/generic/generic.services';

const getDefaultState = () => {
  return {
    scopeList: null,
    selectedScope: null
  };
};

export const Scope = {
  namespaced: true,
  name: 'Scope',
  state: getDefaultState(),
  getters: {
    getSelectedScope: state =>{
      return state.selectedScope;
    }
  },
  mutations: {
    //Reset store
    RESET_STORE(state){
      Object.assign(state, getDefaultState());
    },
    
    SET_SCOPE_LIST(state, scopeList){
      state.scopeList = scopeList;
    },
    SET_SELECTED_SCOPE(state, scope){
      state.selectedScope = scope;
    }
  },
  actions: {    
    async getCreationScope({ commit }, request){
      
      const response = await genericServices.getCreationScope(request);

      if(response.status == 200){
        commit('SET_SCOPE_LIST', response.data);
        return response.data;
      }
      else {
        commit('SET_SCOPE_LIST', null);
        throw new Error(response);
      }
    }   
  }
}