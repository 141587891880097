<template src="./click-callback-renderer.html"></template>

<script>
export default{
  name: 'clickCallbackRenderer',
  computed: {
    isDisabled() {
      return this.params?.disable && this.params?.disable(this.params) //TODO: valider
    },
    decoratorClass() {
      const decoratorClass = this.isDisabled ? 'title' : 'link'
      return [`text-semi-bold__${decoratorClass}`]
    }
  },
  methods: {
    clickCallback() {
      const promotion = this.params.data || this.params;
      if (!this.isDisabled)
        this.params.callback(promotion);
    }
  }
};
</script>
