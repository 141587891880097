<template src="./icon-renderer.html"></template>

<style scoped lang="scss">
@import './icon-renderer.scss';
</style>

<script>
import i18n from "@/i18n";
export default{
  name: 'iconRenderer',
  computed: {
    getIcon() {
      if (!this.params.getIconValue || !this.params.data)
        return
      return [this.params.getIconValue(this.params.data)];
    }
  },
  methods: {
    getFilteredValue(value){
      return i18n.t(`Document.Columns.${value}`);
    }
  }
};
</script>
