<template src="./link-renderer.html"></template>

<style scoped lang="scss">
@import './link-renderer.scss';
</style>

<script>
export default{
  name: 'linkRenderer',
  
  computed: {
    isDisabled() {
      if (this.params?.disable === null || this.params?.disable === undefined)
        return false

      return this.params?.disable && this.params?.disable(this.params)
    },
    displayName(){
      return this.params.displayName ?? this.params.value
    }
  }
};
</script>
