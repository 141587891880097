<template src="./top.html"></template>
<style lang="scss" scoped src="./top.scss"></style>

<script>
import { ValidationProvider } from "vee-validate";
import cloneDeep from 'lodash/cloneDeep';
import { mapGetters } from 'vuex'
import checkbox from '@/core/components/common/checkbox/checkbox.vue'
import columnAggregatePicker from '@/core/components/common/column-aggregate-picker/column-aggregate-picker.vue'
import modelColumnPicker from '@/core/components/common/model-column-picker/model-column-picker.vue'

export default {
  name: "top",
  components:{
    checkbox,
    columnAggregatePicker,
    modelColumnPicker,
    ValidationProvider
  },
  model: {
    prop: 'config'
  },
  props: {
    config: Object,
    model: Object,
  },
  data(){
    return {
      options: [],
      addColumn: {},
      columnCounter: this.config && this.config.columns && this.config.columns.length || 0,
      errorMsg: this.$t('Widgets.Top.ErrorMsg')

    }
  },
  watch: {
    'model.name'(newVal, oldVal){
      if(newVal != oldVal){
        this.setConfig();
        this.setColumnsFromModels();
      }
    }
  },
  computed: {
    ...mapGetters('DataModels', ['getModelColumnPickerOptions']),

    localConfig: {
        get(){
          return this.config;
        },
        set(chg){
          this.$emit('input', Object.assign({}, this.config, chg));
        } 
    },
    validateTop: function(){
      return this.config.top && this.config.top>=0 && this.config.top<=100;
    },  
  },
  created() {
    this.setConfig();
  },
  mounted() {
    this.options = this.getModelColumnPickerOptions(this.model.name);
  },
  methods: {
    setConfig() {
      this.localConfig = {
        group: this.localConfig.group ?? null,
        columns: this.localConfig.columns ?? [],
        top: this.localConfig.top ?? 10,
        showNO: this.localConfig.showNO ?? true,
      };
    },
    columnAdd(e) {
      const columns = cloneDeep(this.config.columns);
      columns.push({id: this.columnCounter++, order: null, ...e});

      this.localConfig = {columns: columns};

      this.addColumn = {};
    },
    columnChange(index, e) {
      const columns = cloneDeep(this.config.columns);
      columns[index].column = e.column;
      columns[index].aggregate = e.aggregate;

      this.localConfig = {columns: columns};
    },
    columnDelete(index) {
      const columns = cloneDeep(this.config.columns);
      columns.splice(index, 1);

      this.localConfig = {columns: columns};
    },
    columnOrderChange(index) {
      const columns = cloneDeep(this.config.columns);
      columns[index].order = !columns[index].order ? 'ASC' : columns[index].order == 'ASC' ? 'DESC' : null;

      this.localConfig = {columns: columns};
    },
  }
}
</script>