<template src="./widget-config.html"></template>

<style lang="scss">
    @import './widget-config.scss';
</style>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import prompts from '@/core/tools/notifications/notifications';
import { ValidationObserver, ValidationProvider } from "vee-validate";
import multiselect from 'vue-multiselect';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { descriptionLanguages } from '@/core/domain/language/language.const.js';

import descriptionInput from '@/core/components/common/description-input/description-input.vue'
import periodPicker from '@/backoffice/modules/reporting/components/period-picker/period-picker.vue'
import collapse from '@/core/components/common/collapse/collapse.vue'
import badge from '@/core/components/common/badge/badge.vue'
import backendFilters from '@/core/components/common/backend-filters/backend-filters.vue'
import tile from './components/tile/tile.vue'
import top from './components/top/top.vue'
import chart from './components/chart/chart.vue'
import horizontalList from '@/core/components/common/horizontal-list/horizontal-list.vue'

const defaultWidgetConfig =
{
    id: null,
    descriptions: [{ language: descriptionLanguages.FR, shortDescription: '' }, 
                   { language: descriptionLanguages.EN, shortDescription: '' }],
    period: null,
    model: null,
    genericListFilter: [],
    widgetTypeConfig: {}
};

export default {
    name: 'WidgetConfig',
    components: {
        multiselect,
        descriptionInput,
        periodPicker,
        collapse,
        badge,
        backendFilters,
        tile,
        top,
        chart,
        horizontalList,
        ValidationObserver,
        ValidationProvider
    },
    props:{
        templatePayload: Object
    },
    data() {
        return {
            widget: null,
            config: null,
            model: null,
            multiConfigWidgetType: ['chart', 'top']
        }
    },
    watch: {
        templatePayload: {
            handler(newVal, oldVal) {
                if(!isEqual(newVal, oldVal)) {
                    this.widget = null;
                    this.selectedConfig = null;
                    this.model = null;

                    this.loadWidget();
                }
            },
            deep: true
        },
        widget: {
            handler() {
                let isEdited = false;
                
                const payloadWidgetHasConfigs = this.templatePayload.widget.configs.length > 0;

                if (payloadWidgetHasConfigs) {
                    isEdited = !isEqual(this.widget, this.templatePayload.widget);
                }
                else if (this.widget.configs.length > 0) {
                    const widgetIsEqualToDefaultValue = isEqual({ ...this.widget.configs[0], descriptions: [] }, defaultWidgetConfig);
                    const widgetDescriptionsIsEqualToDefaultValue = !this.widget.configs[0].descriptions.some(description => (!!description.shortDescription && description.shortDescription != ''));
                    isEdited = !widgetIsEqualToDefaultValue || !widgetDescriptionsIsEqualToDefaultValue;
                }

                this.$sidePanel.isEdit(isEdited);
            },
            deep: true
        }
    },
    computed:{
        ...mapGetters('Dashboard', [
            'getSelectedDashboard', 
            'getSelectedDashboardWidgets',
            'getModelTypes'
        ]),
         ...mapGetters('DataModels', ['hasMandatoryDate']),
         
        periodPickerOptions() {
            const chartOptions = [
                { name : "RangePicker.today", value : "-0d", selected : false },
                { name : "RangePicker.yesterday", value : "-1d", selected : false },
                { name : "RangePicker.same", selected : false, choices : [
                        { name : "RangePicker.sameDayLastWeek", value : "-1wd", selected : false },
                        { name : "RangePicker.sameDayLastYear", value : "-1ydr", selected : false }
                    ]
                }
                
            ];

            return this.widget && this.widget.type == 'chart' ? chartOptions : undefined;
        },
        getWidgetComponent(){     
            if(this.widget){
                return (this.widget.type == 'simple') ? 'tile' : this.widget.type;
            }
            return null;
        },
        selectedConfig: {
            get() {
                return this.config;
            },
            set(value) {
                this.model = null;
                this.config = value;
                this.updateSelectedConfig();
            }
        },
        mandatoryDateExists() {
            if(!this.model)
                return null;

            return this.hasMandatoryDate(this.model.name);
        },
        isMultiConfigType(){
            return this.widget && this.multiConfigWidgetType.indexOf(this.widget.type) != -1;
        }
    },
    mounted(){
        this.loadData();
        this.loadWidget();
    },
    methods: {
        ...mapActions('Dashboard', ['fetchModelTypes', 'saveWidget']),
        ...mapActions('Dashboard', {
            fetchModelTypes: 'fetchModelTypes',
            updateDashboard: 'updateDashboard'
        }),
        ...mapActions('DataModels', {
            fetchModel: 'getModel',
        }),
        ...mapMutations('Dashboard', {
            updateWidget: 'UPDATE_SELECTED_DASHBOARD_WIDGET',
        }),
        async loadData() {
            await this.fetchModelTypes();
        },
        loadWidget() {
            if (this.templatePayload) {
                this.model = null;

                this.widget = cloneDeep(this.templatePayload.widget);

                if (this.widget.configs.length == 0)
                    this.widget.configs.push(cloneDeep(defaultWidgetConfig));
                
                this.selectedConfig = (this.templatePayload.selectedConfig && this.widget.configs.find(config => isEqual(config, this.templatePayload.selectedConfig))) || this.widget.configs[0]; 
            }
        },
        cancel(){
            this.$sidePanel.close();
        },
        async save(){
            let lastId = (this.widget.configs.length > 0 && Math.max(...this.widget.configs.map(config => config.id))) || 0;
            this.widget.configs.forEach(config => {
                if (!config.id) {
                    config.id = ++lastId;
                }
            });

            try {
                await this.saveWidget(this.widget);
                this.$sidePanel.close();
            }
            catch(e){
                prompts.error({
                    text: this.$t('Dashboard.Messages.SaveDashboardError')
                });
            }
        },
        periodChanged(period) {
            this.selectedConfig.period = period;
        },
        onSelectModelType(option) {
            this.getModelFromName(option);
            
            this.selectedConfig.period = null;
            this.selectedConfig.genericListFilter = [];
            this.selectedConfig.widgetTypeConfig = {};
        },
        async getModelFromName(modelName) {
            if (modelName) {
                this.model = await this.fetchModel(modelName);
            }
            else {
                this.model = null;
            }
        },
        async updateSelectedConfig(){
            if (this.selectedConfig) {
                await this.getModelFromName(this.selectedConfig.model);
            }
        },
        addConfig(){
            this.widget.configs.push(cloneDeep(defaultWidgetConfig));

            this.selectedConfig = this.widget.configs[(this.widget.configs.length - 1)];
            this.$refs['widgetConfigObserver'].reset();
        },
        deleteConfig(deletedConfig){
            const deletedConfigIndex = this.widget.configs.findIndex(config => isEqual(config, deletedConfig));
            const currentConfigIndex = this.widget.configs.findIndex(config => isEqual(config, this.selectedConfig));

            let nextConfigIndex = null;
            const configsLengthAfterSplice = (this.widget.configs.length - 1);

            if (configsLengthAfterSplice > 0) {
                nextConfigIndex = (deletedConfigIndex < currentConfigIndex && currentConfigIndex > 0) || (currentConfigIndex > (configsLengthAfterSplice - 1))
                            ? (currentConfigIndex - 1)
                            : currentConfigIndex;
            }

            this.widget.configs.splice(deletedConfigIndex, 1);
            this.selectedConfig = (nextConfigIndex > -1) ? this.widget.configs[nextConfigIndex] : null;
        }
    }
}
</script>