<template src="./period-renderer.html"></template>

<script>
export default{
  name: 'periodRenderer',
  computed: {
    formattedValue: function() {
        return this.formatDate(this.params.value)
    }
  },
  methods: {
    formatDate(value){
      switch(value){
        case "-0d":
          return "RangePicker.today";
        case "-1d":
          return "RangePicker.yesterday";
        case "-0w":
          return "RangePicker.thisWeek";
        case "-0m":
          return "RangePicker.thisMonth";
        case "-0q":
          return "RangePicker.thisQuarter";
        case "-0y":
          return "RangePicker.thisYear";
        case "-1w":
          return "RangePicker.lastWeek";
        case "-1m":
          return "RangePicker.lastMonth";
        case "-1q":
          return "RangePicker.lastQuarter";
        case "-1y":
          return "RangePicker.lastYear";
        case "-1wd":
          return "RangePicker.sameDayLastWeek";
        case "-1ydr":
          return "RangePicker.sameDayLastYear";
        case "-1ywr":
          return "RangePicker.sameWeekLastYear";
        case "-1ym":
          return "RangePicker.sameMonthLastYear";
        case "-1yq":
          return "RangePicker.sameQuarterLastYear";
        default:
          return "RangePicker.custom";
      }
    }
  }
};
</script>
