<template src="./line-group-renderer.html"></template>

<style scoped lang="scss">
@import './line-group-renderer.scss';
</style>

<script>
export default{
  name: 'lineGroupRenderer',
  methods:{
    callback(){
      if(this.hasAction)
        this.params.action(this.params.value)
    }
  },
  computed:{
    hasAction(){
      return (typeof this.params['action'] == 'function')
    },
    label(){
      if(this.params.label)
        return `${this.params.label} ${this.params.value}`

      return `${this.params.value}`
    }
  }
};
</script>
