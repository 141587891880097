import Vue from 'vue';
import { stringFunction } from '../functions/';
Vue.mixin({
  methods: {
    sort(array, property, numerical, nested, translate) 
    {
      //Recursively sort nested array (must have same property name)
      if(nested){
        for (let i = 0; i < array.length; i++) {
          if(this.hasOwnPropertyCI(array[i], nested)){
            this.sort(array[i][nested], property, numerical, nested, translate);
          }          
        }
      }

      array.sort((a, b) => {
        let descA, descB;
        if(translate){
          descA = property ? this.$t(a[property]) : this.$t(a);
          descB = property ? this.$t(b[property]) : this.$t(b);
        }
        else{
          descA = property ? a[property] : a;
          descB = property ? b[property] : b;
        }
        if (numerical) {
          const ret = this.numericalSort(descA, descB);
          return ret;
        }
        else {
          descA = stringFunction.removeAccent(descA);
          descB = stringFunction.removeAccent(descB);

          if (descA < descB) { return -1; }
          if (descA > descB) { return 1; }
          return 0;
        }
      });
    },
    numericalSort(a, b) {
      const reA = /[^a-zA-Z]/g;
      const reN = /[^0-9]/g;
      
      if (typeof (a) == "number" && typeof (b) == "number") {
        return a === b ? 0 : a > b ? 1 : -1;
      }
      else {
        const aA = a.replace(reA, "");
        const bA = b.replace(reA, "");
        if (aA === bA) {
          const aN = parseInt(a.replace(reN, ""), 10);
          const bN = parseInt(b.replace(reN, ""), 10);
          return aN === bN ? 0 : aN > bN ? 1 : -1;
        } else {
          return aA < bA ? 1 : -1;
        }
      }
    }
  }
});
