import PriceLevelClient from '@/backoffice/modules/priceLevel/client/priceLevel.client'
import { PriceLevel, Description, PriceLevelDto, DescriptionDto } from '@/backoffice/modules/priceLevel/domain/priceLevel'
import { PriceLevelConflictError } from '@/backoffice/modules/priceLevel/domain/priceLevelException'

export async function getPriceLevels() {
    const response = await PriceLevelClient.getPriceLevels()
    if (response.status !== 200)
        return []

    return response.data.map(plDto => new PriceLevel(plDto.id, plDto.targetId, plDto.code, plDto.isDeleted, plDto.descriptions.map(descDto => new Description(descDto.id, descDto.priceLevelId, descDto.language, descDto.value)), plDto.targetDescription))
}

export async function savePriceLevel(priceLevel) {
    const priceLevelDto = new PriceLevelDto(priceLevel.id, priceLevel.targetId, priceLevel.code, priceLevel.isDeleted, priceLevel.descriptions.map(descDto => new DescriptionDto(descDto.id, descDto.priceLevelId, descDto.language, descDto.value), priceLevel.target))

    let response = null
    if (priceLevelDto.id) {
        response = await PriceLevelClient.updatePriceLevel(priceLevelDto)
    }
    else {
        response = await PriceLevelClient.createPriceLevel(priceLevelDto)
    }

    if (response.status === 200) {
        const plDto = response.data
        const savedPriceLevel = new PriceLevel(plDto.id, plDto.targetId, plDto.code, plDto.isDeleted, plDto.descriptions.map(descDto => new Description(descDto.id, descDto.priceLevelId, descDto.language, descDto.value)), plDto.targetDescription)
        return savedPriceLevel
    }
}

export async function deletePriceLevel(id) {
    try {
        const response = await PriceLevelClient.deletePriceLevel(id)
        return response.status === 200
    } catch (error) {
        if (error.status === 409) {
            throw new PriceLevelConflictError(error.message)
        }

        throw error
    }
}