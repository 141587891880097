<template src="./information.html"></template>

<style lang="scss">
    @import './information.scss';
</style>

<script>

export default {
  name: 'information',

  props:{
    element: Object,
    elementNumber: Number,
    icon: String,
    title: String,
    label1: String,
    label2: String,
    label3: String,
    label4: String,
    elementClicked: Object
  }
}
</script>
