import Api from '../_api.config';

const securityFacadeEndpoint = 'api/SecurityFacade'

async function saveUserAccess(userAccess) {
  const response = await Api().put(`${securityFacadeEndpoint}/UserAccess`, userAccess)
  return response.data
}

async function createUserAccess(userAccess) {
  const response = await Api().post(`${securityFacadeEndpoint}/UserAccess`, userAccess)
  return response.data
}

async function deleteUserAccess(userAccess) {
  const response = await Api().delete(`/api/SecurityFacade/UserAccess`, { data: userAccess })
  return response.data
}

async function getUserAccesses(userId){
  const response = await Api().get(`${securityFacadeEndpoint}/GetUserAccesses?userId=${userId}`)
  return response.data
}

export const userAccessServices = {
  saveUserAccess,
  createUserAccess,
  deleteUserAccess,
  getUserAccesses
};