<template src="./reporting.html"></template>

<style lang="scss">
@import './reporting.scss';
</style>

<script>
export default {
  name: 'Reporting'
}
</script>