import Api from '../_api.config';

async function saveTarget(targets){

  return await Api()
              .put('/api/Target', targets)
              .then(response => {
                return {status: response.status, data: response.data };
              })
              .catch((e) => {
                if (e.response) {
                  return { status: e.response.status, message: e.response.data, data: null };
                }
                else {
                  return { message: e.message, data: null };
                }
              });
}
 
async function getTargetbyModel(model){

  return await Api()
              .get(`/api/${model}/GetCreationScope`)
              .then(response => {
                return {status: response.status, data: response.data };
              })
              .catch((e) => {
                if (e.response) {
                  return { status: e.response.status, message: e.response.data, data: null };
                }
                else {
                  return { message: e.message, data: null };
                }
              });
}

async function getTargets(getChildren, filters ){

  return await Api()
              .get(`/api/Target?getChildren=${getChildren}&filters=${encodeURIComponent(JSON.stringify(filters))}`)
              .then(response => {
                return {status: response.status, data: response.data };
              })
              .catch((e) => {
                if (e.response) {
                  return { status: e.response.status, message: e.response.data, data: null };
                }
                else {
                  return { message: e.message, data: null };
                }
              });
}


export const targetServices = {
    saveTarget,
    getTargetbyModel,
    getTargets
};
