<template src="./scan-product.html"></template>

<style lang="scss">
    @import './scan-product.scss';
</style>

<script>
import { mapActions } from 'vuex';
import scan from '@/core/components/layout/side-panel/scan/scan.vue';
import { Operators } from '@/core/components/common/backend-filters/operators/operators.js';
import { ItemService } from '@/backoffice/modules/item/item.service.js'
import { ItemWithCostSearchCriteria } from '@/backoffice/modules/item/itemWithCostSearchCriteria'

export default {
    name: 'scan-product',

    props:{
        icon: String,
        title: String,
        mustIncludeItemCost: {
            type: Boolean,
            default: false
        },
        mustIncludeItemPrice: {
            type: Boolean,
            default: false
        }
    },

    components: {
        scan
    },

    data() {
        return {
            invalidProduct: false
        }
    },

    methods: {
        ...mapActions('Product', ['fetchProducts']),

        search() {
            this.$emit('onSearchProduct');
        },

        async searchUpc(upc){
            if (!upc) {
                return;
            }

            const paddedUpc = upc.padStart(13, '0');
            let result = []
            try{
                //TODO: This 3 seperates calls could be managed by a factory instead of if/else
                if(this.mustIncludeItemCost){
                    const itemWithCostSearchCriteria = new ItemWithCostSearchCriteria({ vendorCode: this.vendorCode, itemUpc: paddedUpc })
                    result = await ItemService.getItemsWithCosts(itemWithCostSearchCriteria);
                }
                else{
                    const params = { getChildren: false, filters: [{ "Column": "ItemUPC", "Operator": Operators.equal.value, "Value": [paddedUpc] }] };
                    if(this.mustIncludeItemPrice)
                        result = await ItemService.getItemsWithPrice(params)
                    else
                        result = await this.fetchProducts(params);
                }

                const product = result.length > 0 ? result[0] : {}
                if(!product._ItemID){
                    this.setInvalidProduct()
                    return
                }

                this.$emit('onScanProduct', product);
            }
            catch(e){
                this.setInvalidProduct()
            }
        },

        backToScan() {
            this.invalidProduct = false;
        },
        setInvalidProduct(){
            this.invalidProduct = true;
            this.$nextTick(() => 
                this.$refs.backToScan.focus()
            );
        },
        cancel() {
            this.$emit('onCancel');
        }
    }
}
</script>