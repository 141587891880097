import Vue from 'vue';

export const autoFocus = {
    inserted: function (el, binding) {
      if (binding.value === undefined || binding.value) {
        el.focus()
      }
    }
}
Vue.directive('auto-focus', autoFocus);
