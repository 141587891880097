import { genericServices } from '@/core/services/generic/generic.services';
import { hasOwnPropertyCI } from '@/core/functions';
import { stringFunction } from '@/core/functions';
import models from "@/core/models/models.json";

const mergeProperty = (prop, modelName, feModel) => {
  prop.model = modelName;

  if (hasOwnPropertyCI(prop, 'agColAtt')) {
    prop.headerName = prop.agColAtt.headerName;
  }

  if (hasOwnPropertyCI(prop, 'columnAtt')) {
    prop.dataType = prop.columnAtt.dataType;
    if (hasOwnPropertyCI(prop.columnAtt, 'typeName')) {
      if (prop.columnAtt.typeName.toLowerCase().includes("varchar")) {
        prop.type = "text";
      }
      else if (prop.columnAtt.typeName.toLowerCase() == "int") {
        prop.type = "integer";
      }
      else if (prop.columnAtt.typeName.toLowerCase() == "float" ||
        prop.columnAtt.typeName.toLowerCase().includes("decimal") ||
        prop.columnAtt.typeName.toLowerCase() == "double" ||
        prop.columnAtt.typeName.toLowerCase() == "money") {
        prop.type = "numeric";
      }
      else if (prop.columnAtt.typeName.toLowerCase() == "date") {
        prop.type = "date";
      }
      else if (prop.columnAtt.typeName.toLowerCase().includes("datetime")) {
        prop.type = "datetime";
      }
      else if (prop.columnAtt.typeName.toLowerCase() == "bit") {
        prop.type = "boolean";
      }
      else {
        prop.type = null;
      }
    }
  }

  if (feModel && feModel.properties && feModel.properties[prop.name]) {
    const properties = Object.getOwnPropertyNames(feModel.properties[prop.name]);
    properties.forEach(p => {
      prop[p] = feModel.properties[prop.name][p];
    });
  }

  return prop;
};

const mergeModel = (beModel) => {
  const feModel = models[beModel.name];

  if (beModel.sinMemberAtt) {
    beModel.permissions = {
      create: beModel.sinMemberAtt.permissionCreate,
      update: beModel.sinMemberAtt.permissionUpdate,
      delete: beModel.sinMemberAtt.permissionDelete,
      read: beModel.sinMemberAtt.permissionRead,
      report: beModel.sinMemberAtt.permissionReport
    }
  }

  beModel.properties.forEach(prop => {
    mergeProperty(prop, beModel.name, feModel);
  });

  if (beModel.annexTableModels) {
    beModel.annexTableModels.forEach(annex => {
      mergeModel(annex);
    });
  }

  if (feModel) {
    const properties = Object.getOwnPropertyNames(feModel);
    properties.forEach(prop => {
      if (prop !== "properties") {
        beModel[prop] = feModel[prop];
      }
    });
  }

  return beModel;
};

const getDefaultState = () => {
  return {
    models: {} 
  };
};
 
const defaultPropertyTypesFilter = () => {
  return ['text', 'integer', 'numeric', 'date', 'datetime', 'boolean'];
} 

export const DataModels = {
  namespaced: true,
  name: 'DataModels',
  state: getDefaultState(),
  getters: {
    models: state =>{
      return state.models;
    },
    model: (state) => (modelName) => {
      return state.models[modelName];
    },

    hasMandatoryDate: (state) => (modelName) => {
      const model = state.models[modelName];
      if (!model || !model.properties) {
          return false
      }
      return model.properties.some(p => hasOwnPropertyCI(p, 'sinIndexAtt') && p.sinIndexAtt.name == 'MandatoryDate');
    },

    hasView: (state) =>(modelName) => {
      const model = state.models[modelName];
      if(model && model.sinMemberAtt && model.sinMemberAtt.view)
        return true;
    
      return false;
    },

    //TODO: probably the property will have its own required instead of 'requiredAtt'
    isPropertyRequired: (state) =>(modelName, propertyName) => {
      const model = state.models[modelName];
      let property;
      property = model.properties.find(p => p.name.toLowerCase() == propertyName.toLowerCase());

      if(!property){
        for (let i = 0; i < model.annexTableModels.length; i++) {
          const annex = model.annexTableModels[i];
          property = annex.properties.find(p => p.name.toLowerCase() == propertyName.toLowerCase());
          if(property){
            break;
          }          
        }      
      }
      return hasOwnPropertyCI(property, 'requiredAtt');
    },

    getMandatoryDateProperty: (state) => (modelName) => {
      const model = state.models[modelName];
      if (!model || !model.properties) {
          return null;
      }
      return model.properties.find(p => hasOwnPropertyCI(p, 'sinIndexAtt') && p.sinIndexAtt.name == 'MandatoryDate');
    },

    getChildProperties: (state) => (modelName) => {
      const model = state.models[modelName];
      if (!model || !model.properties) {
        return false;
      }
      return model.properties.filter(p => hasOwnPropertyCI(p, "sinFieldAtt") && p.sinFieldAtt.isChild);
    },

    getNonChildProperties: (state) => (modelName) => {
      const model = state.models[modelName];
      if (!model || !model.properties) {
        return false;
      }
      return model.properties.filter(p => !hasOwnPropertyCI(p, "sinFieldAtt") || !p.sinFieldAtt.isChild);
    },

    getScopeProperty: (state) =>  (modelName) => {
      const model = state.models[modelName];

      if (!model || !model.properties || !model.sinMemberAtt || !model.sinMemberAtt.scopeProperty) {
        return null
      }
        
      const scopeProperty = model.sinMemberAtt.scopeProperty;  
      return model.properties.find(p => hasOwnPropertyCI(p, 'columnAtt') && p.columnAtt.name == scopeProperty);
    },
    
    getPropertiesByType : (state) => (modelName, propertyTypes = defaultPropertyTypesFilter()) => {
      const model = state.models[modelName];
      const properties = [];
      
      model.properties.forEach(prop => {
        if (hasOwnPropertyCI(prop, 'agColAtt') && propertyTypes.includes(prop.type)){
          properties.push(prop);
        }
      });
      if (model.annexTableModels) {
        model.annexTableModels.forEach(annex => {
          annex.properties.forEach(prop => {
            if (hasOwnPropertyCI(prop, 'agColAtt') && propertyTypes.includes(prop.type)){              
              properties.push(prop);
            }              
          });
        });
      }
      return properties;
    },

    getPropertyByName: (state) => (modelName, propertyName) => {
      const model = state.models[modelName];

      if(!model || !propertyName){
        return null;
      }

      let property;
      property = model.properties.find(p => p.name.toLowerCase() == propertyName.toLowerCase());

      if(property){
        return property;
      }
      model.annexTableModels.forEach(annex => {
        property = annex.properties.find(p => p.name.toLowerCase() == propertyName.toLowerCase());
        if(property){
          return property;
        }
      });

      return property;
    },
    
    getHeaderNameByColumnDefField: (state) =>  (modelName, columnDefField) => {
      const model = state.models[modelName];

      if(!model || !columnDefField){
        return null;
      }

      const columnDef = model.columnDefs.find(columnDef => columnDef.field.toLowerCase() == columnDefField.toLowerCase());

      if (!columnDef)
        return null;
    
      return columnDef.headerName;
    },

    //TODO: moved this out of the data-model store
    getModelColumnPickerOptions: (state) => (modelName, propertyTypes = defaultPropertyTypesFilter()) => {
      const model = state.models[modelName];
      const options = [];

      if(model){  
        const properties = [];
        
        model.properties.forEach(prop => {
          if (hasOwnPropertyCI(prop, 'agColAtt') && propertyTypes.includes(prop.type)){
            properties.push(prop);
          }
        });
        if (model.annexTableModels) {
          model.annexTableModels.forEach(annex => {
            annex.properties.forEach(prop => {
              if (hasOwnPropertyCI(prop, 'agColAtt') && propertyTypes.includes(prop.type)){              
                properties.push(prop);
              }              
            });
          });
        }

        const currentIndex = options.push({
          model: 'Reporting.Type.' + model.name,
          columns: []
        });
        for (let i = 0; i < properties.length; i++) {
          const property = properties[i];
          const option = {
            model: 'Reporting.Type.' + property.model,
            name: property.name,
            description: property.headerName,
            type: property.type
          };
          options[currentIndex - 1].columns.push(option);
        }
      }
      return options;
    },

    getModelObjectDefinition: (state) => (modelName) => {
      const model = state.models[modelName];

      if (!model)
        return {};

      const mainProperties = model.properties
        .filter(prop => prop.annexTables.length == 0)
        .reduce(function(obj, prop) {
          obj[stringFunction.camelize(prop.name)] = null;
          return obj;
        }, {});

      const annexTableProperties = model.annexTableModels
        .reduce(function(obj, annexModel) {
          obj[stringFunction.camelize(annexModel.name)] = annexModel.properties
            .filter(prop => prop.annexTables.length == 0)
            .reduce(function(obj, prop) {
              obj[stringFunction.camelize(prop.name)] = null;
              return obj;
            }, {});

          return obj;
        }, {});

      return {...mainProperties, ...annexTableProperties };
    }
  },

  mutations: {
    RESET_STORE(state){
      Object.assign(state, getDefaultState());
    },
    
    ADD_OR_UPDATE_MODEL(state, model){
      state.models[model.name] = model;
    }  
  },
  actions: {
    async getModel({commit, state}, modelName){
      if (!modelName)
        return null;

      if(state.models[modelName]){
        return state.models[modelName];
      }
      const response = await genericServices.getModel(modelName);
  
      if(response.status == 200){
        response.data.model.columnDefs = response.data.columnDefs;
        response.data.model = mergeModel(response.data.model);

        commit('ADD_OR_UPDATE_MODEL', response.data.model);
        return  response.data.model;
      }
      else{
        throw new Error(response);
      }
    }
  }
}