<template>
  <div class="page" id="app">

    <UpdateBlock />

    <main>
      <router-view />
    </main>
    <vue-progress-bar></vue-progress-bar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import Swal from 'sweetalert2';
import UpdateBlock from '@/core/components/layout/update-block/update-block.vue';

export default {
  name: 'app',
  data() {
    return {
      checkUserActivityInterval: null
    }
  },

  mounted() {
    this.$i18n.locale = this.getTwoLettersLanguage || this.$i18n.locale;
    this.checkUserActivityInterval = setInterval(this.checkUserActivity, process.env.VUE_APP_TOKEN_RENEWAL_INTERVAL)
  },

  components: {
    UpdateBlock
  },

  watch: {
    getTwoLettersLanguage(language){
      this.$i18n.locale = language
    }
  },

  computed: {
    ...mapGetters('App', [
      'getTwoLettersLanguage',
      'getLastUserAction'
    ]),
    ...mapGetters('Account', ['getCurrentTargetLayer', 'getToken', 'tokenIsExpired']),
    currentRouteName() {
      return this.$route.name;
    }
  },

  created() {
    window.addEventListener('resize', this.debounce);
    window.addEventListener('click', this.logUserEvent);
  },

  methods: {
    ...mapActions('App', [
      'setResize',
      'setIsMobile',
      'setLastUserAction'
    ]),
    ...mapActions('Account', ['fetchNewTargetLayerToken', 'logout']),
    
    debounce(){
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        const mobile = window.innerWidth <= 768;
        this.setResize(window.innerWidth);
        this.setIsMobile(mobile);
      }, 250);
    },

    logUserEvent(){
      this.setLastUserAction(moment())
    },

    async checkUserActivity() {
      if (!this.getCurrentTargetLayer?._TargetLayerID || !this.getToken)
        return;

      const timeInMinutesSinceLastAction = moment().diff(moment(this.getLastUserAction), 'minutes');

      if (timeInMinutesSinceLastAction < process.env.VUE_APP_SESSION_TIMEOUT && !this.tokenIsExpired)
      {
        this.fetchNewTargetLayerToken(this.getCurrentTargetLayer);
        return;
      }

      try {
        clearInterval(this.checkUserActivityInterval)
        await Swal.fire({
          heightAuto: false,
          confirmButtonText: this.$i18n.t('Session.Modal.ReturnToLogIn'),
          showCancelButton: false,
          icon: 'question',
          title: this.$i18n.t('Session.Modal.Title'),
          text: this.$i18n.t('Session.Modal.Content'),
          allowOutsideClick: false,
          allowEscapeKey: false
        });
      } finally {
        this.checkUserActivityInterval = setInterval(this.checkUserActivity, process.env.VUE_APP_TOKEN_RENEWAL_INTERVAL)
        this.logout()
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/core/assets/scss/base/bootstrap.scss';
@import '@/core/assets/scss/base/buttons.scss';
@import '@/core/assets/scss/base/form.scss';
@import '@/core/assets/scss/base/global.scss';
@import '@/core/assets/scss/base/grid-base.scss';
@import '@/core/assets/scss/base/li.scss';
@import '@/core/assets/scss/base/links.scss';
@import '@/core/assets/scss/base/reset.scss';
@import '@/core/assets/scss/base/input.scss';
@import '@/core/assets/scss/base/text.scss';
@import '@/core/assets/scss/base/svg.scss';
@import '@/core/assets/scss/base/heading.scss';
@import '@/core/assets/scss/base/multiSelect.scss';
@import '@/core/assets/scss/layouts/layout.scss';

@import "../node_modules/@ag-grid-community/core/dist/styles/ag-grid.css";
@import "../node_modules/@ag-grid-community/core/dist/styles/ag-theme-material.css";
@import "../node_modules/@ag-grid-community/core/dist/styles/ag-theme-balham.css";
@import "../node_modules/@ag-grid-community/core/dist/styles/ag-theme-alpine.css";
@import "../node_modules/spin.js/spin.css";

</style>
