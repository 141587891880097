<template src="./menu-renderer.html"></template>

<style scoped lang="scss">
@import './menu-renderer.scss';
</style>

<script>
import { mapGetters } from 'vuex';

export default{
  name: 'menuRenderer',
  data() {
    return {
      show: false,
      value: null,
      openTop: false,
      templates: null
    }
  },
  computed: {
    ...mapGetters('DataModels', ['getPropertyByName']),
    canUpdate() {
      return this.params.canUpdate ? this.params.canUpdate(this.params.node) : this.params.data?.canUpdate
    },
    canDelete() {
      return this.params.canDelete ? this.params.canDelete(this.params.node) : this.params.data?.canDelete
    },
    hasSidePanel() {
      return !!this.params.openSidePanel;
    }
  },
  mounted() {
    this.value = this.params.valueFormatted || this.params.value;
  },
  methods: {
    openPanel(){
      this.params.openSidePanel(this.params.data);
    },
    setOpenPosition(){
      const index = this.params.api.getLastDisplayedRow();
      if (this.params.node.rowIndex > 0 && this.params.node.rowIndex == index && index > 1){
        this.openTop = true;
      }
      else{
        this.openTop = false;
      }
    },
    open() {
      this.setOpenPosition();
      this.show = !this.show;
    },
    close(){
      this.show = false;
    },
    invokeParentLinkClickMethod() {
      this.params.clickMenuLink(this.params.node);
    },
    invokeParentEditMethod() {
      this.params.editEntity(this.params.node);
      this.show = false;
    },
    invokeParentDelete() {
      this.params.delete(this.params.node);
      this.show = false;
    },
  }
};
</script>
