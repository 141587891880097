<template src="./order-management.html"></template>

<style lang="scss">
    @import './order-management.scss';
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import multiselect from 'vue-multiselect';
import { mapGetters, mapActions } from 'vuex';
import prompts from '@/core/tools/notifications/notifications';
import { OrderStatus } from '@/backoffice/modules/purchase/domain/purchase.js';
import { ValidationObserver, ValidationProvider, localize } from "vee-validate";
import { stringFunction } from '@/core/functions';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import Swal from 'sweetalert2';
import orderProductScan from '@/backoffice/modules/purchase/components/sidePanel/orders/order-product-scan/order-product-scan.vue';

export default {
    name: 'order-template',
    components: {
        multiselect,
        ValidationObserver,
        ValidationProvider
    },

    data() {
        return {
            preventSave: false,
            multiOrderValidationPending: false,
            vendorSearchValue: '',
            selectedVendor: null,
            order: {},
            orderCopy: {}
        }
    },
    computed:{
        ...mapGetters('Order', ['getOrder']),
        ...mapGetters('Vendor', ['getVendors']),
        ...mapGetters('Account', ['getCurrentTargetLayerId', 'getUser']),
        ...mapGetters('App', ['isMobile']),

        filteredVendorList() {
            return this.getVendors
                    .slice()
                    .filter(v => {
                        const matchVendorName = stringFunction.normalize(v.vendorName).includes(stringFunction.normalize(this.vendorSearchValue));
                        const matchVendorCode = stringFunction.normalize(v.vendorCode).includes(stringFunction.normalize(this.vendorSearchValue));

                        return matchVendorName || matchVendorCode;
                    })
                    .sort((objA, objB) => {
                        const descA = stringFunction.removeAccent(objA.vendorName);
                        const descB = stringFunction.removeAccent(objB.vendorName);

                        if (descA < descB) { return -1; }
                        if (descA > descB) { return 1; }
                        return 0;
                    });
        },
        blockSaveAction() {
            return this.preventSave || this.multiOrderValidationPending;
        },
        edited(){
            return !isEqual(this.orderCopy, this.order);
        }
    },

    watch:{
        edited(){
            this.$sidePanel.isEdit(this.edited);
        }
    },

    async created() {
        try {
            await this.fetchVendors();
        }
        catch (error) {
            prompts.error({
                text: this.$t('Purchase.Messages.GetOrderError')
            });
        }
    },

    mounted() {
        localize(this.$i18n.locale);
        this.init();
    },

    methods: {
        ...mapActions('Order', ['saveOrder', 'fetchOrdersByVendorCode']),
        ...mapActions('Vendor', ['fetchVendors']),

        async init(){
            await this.fetchVendors();
            this.setOrder();
            this.setVendorFocus();
        },

        setOrder() {
            this.order = {
                referenceNO: null,
                comment: null,
                canUpdate: true,
                targetLayerID: this.getCurrentTargetLayerId,
                orderStatus: OrderStatus.OPEN,
                createdDate: moment().format('YYYY-MM-DD'),
                createdDatetime: moment().format('YYYY-MM-DD HH:mm:ss'),
                userEmail: this.getUser.email,
                userFullName: `${this.getUser.firstName} ${this.getUser.lastName}`,
                userID: this.getUser._UserID,
                userUsername: this.getUser.username,
                vendorID: null,
                vendorCode: null,
                vendorName: null
            };
            
            this.orderCopy = cloneDeep(this.order);
        },

        setVendorFocus() {
            this.$nextTick(() => {
                if(!this.isMobile){
                    this.$refs.vendor.$refs.search.focus();
                }
                
            });
        },

        async save() {
            this.preventSave = true;

            try {
                await this.saveOrder(this.order);
            }
            catch(e) {
                prompts.error({
                    text: this.$tc('Purchase.Messages.SaveOrderError')
                });
            }

            if (this.getOrder.id) {
                this.$sidePanel.show([{ component: orderProductScan }], null, { backgroundIsDisabled: true, isPreventAutoClose: true });
                this.$router.push({ name: 'order', params: { id: this.getOrder.id } });
            }

            this.preventSave = false;
        },

        searchVendor(value) {
            this.vendorSearchValue = value;
        },

        async selectVendor(vendor) {
            this.order.vendorID = vendor._VendorID;
            this.order.vendorCode = vendor.vendorCode;
            this.order.vendorName = vendor.vendorName;

            await this.promptMultiOrderQuestionIfNecessary(vendor);
        },

        validateVendor() {
            this.$refs.vendorValidator.validate();
        },

        cancel() {
            this.$sidePanel.close();
        },

        async promptMultiOrderQuestionIfNecessary(vendor) {
            
            this.multiOrderValidationPending = true;
            
            const orders = await this.fetchOrdersByVendorCode(vendor.vendorCode);

            if(!orders){
                return
            }
            const openOrders = orders.filter(o => o.orderStatus == OrderStatus.OPEN)

            if (openOrders?.length > 0) {
                const result = await Swal.fire({    
                    heightAuto: false,
                    confirmButtonText: this.$i18n.t('Purchase.Order.MultipleOrdersPrompt.ConfirmActionText'),
                    showCancelButton: true,
                    cancelButtonText: this.$i18n.t('Purchase.Order.MultipleOrdersPrompt.CancelActionText'),
                    icon: 'question',
                    title: this.$i18n.t('Purchase.Order.MultipleOrdersPrompt.Title'),
                    text: this.$i18n.t('Purchase.Order.MultipleOrdersPrompt.Text', { vendor: vendor.vendorName }),
                });
                
                if (result.isDismissed) {
                    this.$sidePanel.close();
                    this.$router.push({ 
                        name: 'orders', 
                        query: { 
                            searchFilters: [ vendor.vendorName ] 
                        }
                    });
                }
            }

            this.multiOrderValidationPending = false
        }
    }
}
</script>