<template src="./group-renderer.html"></template>


<style scoped lang="scss">
@import './group-renderer.scss';
</style>

<script>

export default{
  name: 'groupRenderer',
  methods: {
    invokeParentChangeLayer() {
       return this.params.changeGroup(this.params.node.data, true);
    },

  }
};
</script>
