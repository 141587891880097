import { FileServer } from "@/core/services/file-server.config";
import { ReleaseNoteVersion } from "@/backoffice/modules/release-note/domain";

async function getReleaseNoteVersions(datetime){

    let response;
    try{
        response = await FileServer()
        .get(`release-notes.json?datetime=${datetime}`)

        if(response.status === 200 && response.data?.versions){
            return response.data.versions.map(version => new ReleaseNoteVersion(version));
        }
    }
    catch(error){
        return null;
    }
}

export const releaseNoteServices = {
    getReleaseNoteVersions
}