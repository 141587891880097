<template inline-template v-cloak src="./icon-button.html"></template>

<style scoped lang="scss">
  @import "./icon-button.scss";
</style>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'icon-button',

  props: {
    icon: String,
    isDisabled: Boolean,
    isBlue: Boolean,
    id:{
      type: String,
      default: 'iconButton-id'
    }
  },

  computed: {
    ...mapGetters('App', ['isMobile'])
  }
};
</script>
