<template src="./user.html"></template>
<style lang="scss">
    @import './user.scss';
</style>

<script>
import { EntityServices } from '@/core/store/modules/entity.services.js';
import { mapGetters } from 'vuex';
import prompts from '@/core/tools/notifications/notifications';
import { ValidationObserver, ValidationProvider, localize } from "vee-validate";
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import calendar from '@/core/components/common/calendar/calendar.vue'
import languageInput from '@/core/components/common/form/language-input/language-input.vue'
import scopeDropdown from '@/core/components/common/drop-down/scope-dropdown/scope-dropdown.vue'

const MODE = {
  UPDATE: 'update',
  CREATE: 'create'
}

export default {
  name: 'user-template',
  components: {
    calendar,
    languageInput,
    scopeDropdown,
    ValidationObserver,
    ValidationProvider,
    localize
  },
  props:{
    templatePayload: Object
  },

  data() {
    return {
      user: null,
      userCopy: null,
      phoneMask: '(###) ###-#### ext:########',
      maskedPhoneRegex: /^(\d{3})?(\d{3})?(\d{4})?(\d{1,8})?.*/,
      maskedPhonePattern: '($1) $2-$3 ext:$4',
      unmaskedPhoneRegex: /[^0-9&&^.]/g,
      userExistRegex: /InvalidUsernameNotUnique|InvalidEmailNotUnique/igm,
      isSaving: false
    }
  },

  computed:{
    ...mapGetters('DataModels', ['getModelObjectDefinition']),
    ...mapGetters('Account', ['getUser', 'getCurrentTargetLayer']),
    mode(){
      return this.user && this.user._UserID > 0 ? MODE.UPDATE : MODE.CREATE;
    },
    isEdited() {
      if(!this.user || !this.userCopy)
        return false

      return !isEqual(this.user, this.userCopy);
    },
    disabled() {
      return !this.userCopy.canUpdate;
    }
  },

  watch: {
    getSidePanelChangeTrigger: {
      handler(newVal){
        if(newVal == 'changed')
          this.onSidePanelChange();
      }
    },
    isEdited(edited){
      this.$sidePanel.isEdit(edited);
    }
  },

  mounted() {
    localize(this.$i18n.locale);
    if (this.templatePayload) {
      this.fetchUser();
    }
  },

  methods: {
    onSidePanelChange() {
      this.setSidePanelChangeTrigger('');
      this.fetchUser();
    },

    async fetchUser() {
      const userDefault = this.getModelObjectDefinition('User');

      if (this.templatePayload._UserID) {
        const params = { getChildren: true, modelName: 'User' , filters: [ { Column: '_UserID', Value: [this.templatePayload._UserID] } ] } 
        const userEntity = await EntityServices.getEntity(params);

        if(userEntity){
          const userAddress = { ...userDefault.address, ...userEntity.address };
          const user = { ...userDefault, ...userEntity, ...{ address: userAddress } };
          this.setUser(user);
        }
      }
      else {
        userDefault.isActive = true;
        userDefault.canUpdate = true;
        this.$sidePanel.disabledTabs(true);

        this.setUser(userDefault);
      }
    },

    setUser(user) {
      user.phone = user.phone ? user.phone.replace(this.maskedPhoneRegex, this.maskedPhonePattern) : null
      this.user = user;
      this.userCopy = cloneDeep(this.user);
    },

    onScopeChanged(target) {
      this.userCopy._TargetID = target.id;
    },

    setUsername() {
      if (this.mode === MODE.CREATE) {
        this.$refs.Email.validate().then((result) => 
        {
          if (result.valid) {
            const index = this.userCopy.email.indexOf('@');
            const email = this.userCopy.email.substring(0, index);
            this.userCopy.username = email;
          }
        });
      }
    },

    async save(){
      const user = cloneDeep(this.userCopy);
      this.isSaving = true;
      if(user.phone)
        user.phone = user.phone.replace(this.unmaskedPhoneRegex, '')

        if(!user.isLocked && this.user.isLocked){
          user.passwordTry = 0;
        }

      let userEntity = null;
      try{
        if(this.mode === MODE.UPDATE){
          userEntity = await EntityServices.saveEntities({ targetModel: 'User', entities: [user] });
        }
        else{
          user._TargetLayerID = this.getCurrentTargetLayer._TargetLayerID;
          user._UserLinkID = this.getUser._UserID;

          userEntity = await EntityServices.createEntities({ targetModel: 'User', entities: [user] });
        }
      }
      catch(e){
        if(e.metaData){
          const strMetaData = JSON.stringify(e.metaData)
          if(strMetaData.match(this.userExistRegex)){
            await prompts.error({html: this.$t('General.Messages.UserExists')})
          }
        }
      }

      if (Array.isArray(userEntity) && userEntity.length > 0) {
        const data = this.mode === MODE.UPDATE ? { 'save': true, 'row': userEntity[0] } : { 'create': true, 'row': userEntity[0] }

        this.setUser(userEntity[0]);
        this.$emit('templatePayload-updated', userEntity[0]);
        this.$sidePanel.update(data);
        this.$sidePanel.disabledTabs(false);
      }
      this.isSaving = false;
    },

    async cancel(){
      const result = await prompts.warning({ html: this.$t('General.Messages.Revert') });
      
      if(result.isConfirmed){
        if (this.user.id) {
          this.userCopy = Object.assign({}, this.user);
        }
        else {
          this.$sidePanel.close();
        }
      }
    }
  }
}
</script>




