<template src="./negative-value-renderer.html"></template>

<style scoped lang="scss">
@import './negative-value-renderer.scss';
</style>

<script>
export default {
  name: "negativeValueRenderer"
};
</script>

