import Api from '@/core/services/_api.config';

const securityFacadeEndpoint = 'api/SecurityFacade'
const roleEndpoint = 'api/Role'

async function getReadRoles(getChildren, filters, _ReportDataModelID, _TargetLayerID) {
  const reportDataModelId = _ReportDataModelID || ''
  const encodedFilters = encodeURIComponent(JSON.stringify(filters || []))
  const targetLayerId = _TargetLayerID || ''
  const request = `${roleEndpoint}?reportDataModelID=${reportDataModelId}&getChildren=${getChildren}&filters=${encodedFilters}&targetLayerID=${targetLayerId}`

  const response = await Api().get(request)
  return { status: response.status, data: response.data }
}

async function getRoles() {
  const response = await Api().get(`${securityFacadeEndpoint}/GetRoles`);
  return { status: response.status, data: response.data };
}

async function getRolePermissions(roleId, cancelToken) {
  let response = {}

  if(roleId > 0)
    response = await Api().get(`${securityFacadeEndpoint}/GetRolePermissions?roleId=${roleId}`, cancelToken);
  else
    response = await Api().get(`${securityFacadeEndpoint}/GetPermissions`, cancelToken);

  return { status: response.status, data: response.data };
}


async function createRole(role) {
  const request = `${securityFacadeEndpoint}/createrole`
  const response = await Api().post(`${request}`, role)
  return { status: response.status, data: response.data };
}

async function updateRole(role) {
  const request = `${securityFacadeEndpoint}/updaterole`
  const response = await Api().put(`${request}`, role)
  return { status: response.status, data: response.data };
}

async function deleteRole(role) {
  const response = await Api().delete(`${securityFacadeEndpoint}`, { data: role })
  return { status: response.status, data: response.data };
}

export const roleServices = {
  getReadRoles,
  getRoles,
  getRolePermissions,
  createRole,
  updateRole,
  deleteRole
};