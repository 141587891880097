<template src="./negative-amount-renderer.html"></template>

<style scoped lang="scss">
@import './negative-amount-renderer.scss';
</style>

<script>
export default {
  name: "negativeAmountRenderer"
};
</script>

