<template src="./simple-widget.html"></template>

<style scoped lang="scss">
@import '@/backoffice/modules/dashboard/components/dynamic-dashboard/widgets/widget.scss';
</style>
<style scoped lang="scss">
@import './simple-widget.scss';
</style>

<script>
import { mapGetters, mapActions } from 'vuex';
import widgetConfig from '@/backoffice/modules/dashboard/components/dynamic-dashboard/widgets/widget-config/widget-config.vue';
import genericWidget from '@/backoffice/modules/dashboard/components/dynamic-dashboard/widgets/generic-widget';
import extractTime from '@/backoffice/modules/reporting/mixins/extract-time.js';
import axios from 'axios';
import errors from '@/core/tools/errors/errors';
import { genericServices } from '@/core/services/generic/generic.services';
import isEqual from 'lodash/isEqual';
import { stringFunction } from '@/core/functions';
import spinLoader from '@/core/components/common/spin-loader/spin-loader.vue';

const initialComponentState = () => ({
  config: null,
  primaryValue: "",
  secondaryValue: "",
  periodProperty: null,
  primaryColumnProperty: null,
  secondaryColumnProperty: null,
  loading: false,
  errorResponse: null,
  modelMetadata: null,
  cancelToken: null
})

export default {
  mixins: [extractTime],
  name: 'simple-widget',
  components:{
    spinLoader
  },
  props:{
    widget: Object,
    editable: Boolean
  },
  data() {
    return initialComponentState()
  },
  mounted(){
    this.initializeConfig();
  },
  computed: {
    ...mapGetters('Dashboard', ['getDate', 'getTargetID']),
    ...mapGetters('DataModels', ['getScopeProperty', 'getPropertyByName', 'getMandatoryDateProperty']),
    title() {
      return genericWidget.getTitle(this.config, this.$i18n.locale);
    }
  },
  watch: {
    getDate: {
      handler(newVal, oldVal){
        if (newVal && newVal != oldVal) {
          this.initializeConfig();
        }
      }
    },
    getTargetID: {
      handler(newVal, oldVal){
        if (newVal && newVal != oldVal) {
          this.initializeConfig();
        }
      }
    },
    'widget.configs': {
      handler(newVal, oldVal){
        const hasChange = !isEqual(newVal, oldVal);
        if (hasChange) {
          this.initializeConfig();
        }
      },
      deep: true
    }
  },
  beforeDestroy() {
    if (this.cancelToken)
      this.cancelToken.cancel('OPERATION_CANCELED');
  },
  methods:{
    ...mapActions('DataModels', ['getModel']),
    ...mapActions('Reporting', {
      fetchReportDataFromRdm: 'getReportDataFromRdm',
    }),
    async initializeConfig() {
      if (this.cancelToken)
        this.cancelToken.cancel('OPERATION_CANCELED');

      this.config = (this.widget && this.widget.configs && this.widget.configs[0]) || null;
      if (!this.config || !this.config.model) {
        
        Object.assign(this.$data, initialComponentState());
        return;
      }
        
      let model;
      try {
        model = await this.getModel(this.config.model);
      }
      catch(response) {
        this.errorResponse = response;
      }
                  
      if (model){
        this.modelMetadata = model;
        
        this.periodProperty = this.getMandatoryDateProperty(this.modelMetadata.name);
        this.primaryColumnProperty = this.getPropertyByName(this.modelMetadata.name, this.config.widgetTypeConfig.primaryConfig.column);
        this.secondaryColumnProperty = this.getPropertyByName(this.modelMetadata.name, this.config.widgetTypeConfig.secondaryConfig.column);

        this.getData();
      }
    },
    async getData() {

      const getConfigFromColumn = (config) => {
        const isValid = config && config.column && config.aggregate;
        if (!isValid) {
          return null;
        }

        return {
          name: stringFunction.camelize(config.column), 
          aggFunc: config.aggregate
        }
      }

      const initialReportDataModel = genericWidget.createReportDataModel(this.config);
      const reportDataModel = {
        ...initialReportDataModel,
        genericListFilter: [
          ...initialReportDataModel.genericListFilter,
          ...this.getPeriodFilters(this.config.period),
          ...this.getTargetFilters(this.modelMetadata)
        ],
        genericListColumn: [
          getConfigFromColumn(this.config.widgetTypeConfig.primaryConfig), 
          getConfigFromColumn(this.config.widgetTypeConfig.secondaryConfig)
        ].filter(c => !!c)
      }

      this.loading = true;

      let response;
      try {
        this.cancelToken = axios.CancelToken.source();
        response = await genericServices.getSimpleWidgetData(
          this.config.model, 
          {
            reportDataModel, 
            options: {
              groupBy: this.config.widgetTypeConfig.groupBy
            }
          },
          { 
            cancelToken: this.cancelToken.token 
          });
      }
      catch(response) {
        this.errorResponse = response;
      }

      const getValueFromResponse = (values, config, columnMetadata) => {

        if (!values || !config.column) {
          return undefined
        }

        let value = values[stringFunction.camelize(config.column)];
        if (value != null && value != undefined && config.aggregate !== 'count') {
          value = genericWidget.formatValue(columnMetadata, value);
        }

        return value;
      }

      if (response.status == 200) {
        this.cancelToken = null;
        this.primaryValue = getValueFromResponse(response.data.data, this.config.widgetTypeConfig.primaryConfig, this.primaryColumnProperty);
        this.secondaryValue = getValueFromResponse(response.data.data, this.config.widgetTypeConfig.secondaryConfig, this.secondaryColumnProperty);
      }

      this.loading = false;
    },
    getPeriodFilters(period) {
      if (!period || !this.periodProperty) {
        return []
      }

      const dates = this.convert(period, this.getDate);
      return [
        { column: this.periodProperty.name, operator: '>=', value: [dates.start] },
        { column: this.periodProperty.name, operator: '<=', value: [dates.end] }
      ]
    },
    getTargetFilters(modelMetadata) {
      if (!modelMetadata) {
        return []
      }

      const scopeProperty = this.getScopeProperty(modelMetadata.name);
      if(scopeProperty && scopeProperty.name){
        return [
          { Model: 'TargetLink', MainColumn: scopeProperty.name, JoinedColumn: '_TargetLayerID', Column: '_TargetID', Value: [this.getTargetID] }
        ];      
      }
      
      return [];
    },
    displayApiError() {
      errors.displayApiError(this.errorResponse);
    },
    openSidePanel() {
      this.$sidePanel.show([{ component: widgetConfig }], { widget: this.widget });
    }
  }
}
</script>
