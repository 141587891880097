import moment from 'moment';
import i18n from "@/i18n";
import { getTranslationForRangePicker } from '@/core/functions/';

const agGridGetters = {
  valueTranslateGetter(params, translateKey) {
    if (params.data && params.node && !params.node.rowPinned) {
      const colkey = params.colDef.colKey ?? params.colDef.field;
      const value = params.data[colkey];
      return value ? i18n.t(`${translateKey}.${value}`) : '';
    }
  },
  dateGetter(params) {
    if (params.data && params.node && !params.node.rowPinned) {
      const colkey = params.colDef.colKey ?? params.colDef.field;
      const value = params.data[colkey];
      return value ? moment(value).format("YYYY-MM-DD") : '';
    }
  },
  dateWithMonthNamed(params) {
    if (params.data && params.node && !params.node.rowPinned) {
      const colkey = params.colDef.colKey ?? params.colDef.field;
      const value = params.data[colkey];
      return value ? moment(value).locale(i18n.locale).format("LL") : i18n.t('Reporting.DataDef.NotAvailable');
    }
  },
  calendarMonthGetter(params) {
    if (params.data && params.node && !params.node.rowPinned) {

      const colkey = params.colDef.colKey;
      let m;
      switch(params.data[colkey]){
        case 1:
          m = 12
          break;
        default:
          m = params.data[colkey] - 1;
      }
      const month = moment().month(m).locale(i18n.locale).format("MMMM")
      return month.charAt(0).toUpperCase() + month.slice(1);
    }
  },
  dayOfWeekGetter(params) {
    if (params.data && params.node && !params.node.rowPinned) {
      const colkey = params.colDef.colKey;
      if(params.data[colkey]){          
        let day;
        switch(params.data[colkey]){
          case 1:
            day = 7
            break;
          default:
            day = params.data[colkey] - 1;
        }
        const weekDay = moment().isoWeekday(day).locale(i18n.locale).format("dddd");
        return weekDay.charAt(0).toUpperCase() + weekDay.slice(1);
      }
    }
  },
  dayOfYearGetter(params){
    if (params.data && params.node && !params.node.rowPinned) {
      return i18n.t('Reporting.Columns.Day') + ' ' + params.data[params.colDef.colKey];
    }
  },
  weekOfYearGetter(params) {
    if (params.data && params.node && !params.node.rowPinned) {
      return i18n.t('Reporting.Columns.Week') + ' ' + params.data[params.colDef.colKey];
    }
  },
  calendarDayGetter(params){
    if (params.data && params.node && !params.node.rowPinned) {
      return i18n.t('Reporting.Columns.Day') + ' ' + params.data[params.colDef.colKey];
    }
  },
  weekOfMonthGetter(params){
    if (params.data && params.node && !params.node.rowPinned) {
      return i18n.t('Reporting.Columns.Week') + ' ' + params.data[params.colDef.colKey];
    }
  },
  hourGetter(params) {
    if (params.data && params.node && !params.node.rowPinned) {
      const colkey = params.colDef.colKey;
      const m = moment(params.data[colkey]).startOf('hour');
      return m.format("HH:mm");
    }
  },
  booleanGetter(params){
    if (params.data && params.node && !params.node.rowPinned) {
      const key = params.colDef.colKey;
      const value = params.data[key];
      if(value){
        return i18n.t('Reporting.DataDef.true');
      }
      else{
        return i18n.t('Reporting.DataDef.false');
      }
    }
  },
  avgMarginGetter(params) {
    if (params.node && params.node.group && !params.node.rowPinned) {
      if (params.node.aggData) {
        const total = params.node.aggData.price;          
        const avgCost = params.node.aggData.averageUnitCost * params.node.aggData.qty;
        if (!!total && !!avgCost) {
          return ((total - avgCost) / total) * 100;
        }
      }
    }
    else{
      const total = params.node.data.price;         
      const avgCost = params.node.data.averageUnitCost * params.node.data.qty;
      if (!!total && !!avgCost) {
        return ((total - avgCost) / total) * 100;
      }

      const value = params.data[params.colDef.field];
      return value || i18n.t('Reporting.DataDef.NotAvailable')
    }
  },
  rangePickerGetter(params) {
    if (params.data && params.node && !params.node.rowPinned) {
      return getTranslationForRangePicker(params.node.data.period);
    }
  },
  integrityGetter(params){
    let integrity = 0;
    if (params && params.node && !params.node.rowPinned && !params.node.group) {
        if (params.data[params.colDef.field]) {
            integrity = params.data[params.colDef.field];
        }
        return integrity >= 99.99 && integrity < 100 ? 99.99 : integrity;
    }
    else {
      if (params.node && params.node.group && !params.node.rowPinned) {
        let diff;  
        if (params.node.aggData) {
          let total;
          switch(params.colDef.field){
            case "salesIntegrity":                  
              total = params.node.aggData['finSalesTotal'];
              diff = params.node.aggData['salesDiff'];
            break;
            case "receivingIntegrity":                  
              total = params.node.aggData['finReceivingTotal'];
              diff = params.node.aggData['receivingDiff'];
            break;
            case "inventoryIntegrity":                  
              total = params.node.aggData['finInventoryTotal'];
              diff = params.node.aggData['inventoryDiff'];
            break;
            default:
              total = 0;
              diff = 0;
            break;
          }
          if(diff == 0){
            integrity = 100;
          }
          else if (total && diff != undefined) {
              integrity = (1 - (diff / total)) * 100;
          }
        }
        const final = Math.round(integrity * 100) / 100;
        if(final == 100 && diff > 0){
          return 99.99;
        }
        else if(final < 0){
          return 0.00;
        }
        else{
          return final;
        }
      }
    }
  },
  accessTarget(params){
    if (params.data && params.node && !params.node.rowPinned && params.data.accessTarget) {
      let accessTarget;
      try{
        accessTarget = JSON.parse(params.data.accessTarget);
      }
      catch(e){
        return null;
      }
      if(accessTarget.targetCount > 1){
        return i18n.t('UserAccessCount.AccessTargetCount', { targetCount: accessTarget.targetCount } );
      }
      else{
        return accessTarget.targetDescription
      }
    }
  },
  accessRole(params){
    if (params.data && params.node && !params.node.rowPinned && params.data.accessRole) {
      let accessRole;
      try{
        accessRole = JSON.parse(params.data.accessRole);
      }
      catch(e){ 
        return null; 
      }

      if(accessRole.roleCount > 1){
        return i18n.t('UserAccessCount.AccessRoleCount', { roleCount: accessRole.roleCount } );
      }
      else{
        return accessRole.roleDescription
      }
    }
  },
  description(params){
    if (params?.data && params?.node) {
      const desc = params.data?.descriptions.find(desc => desc?.language == i18n.locale.toUpperCase() && desc?.value)
      const fallBackdesc = params.data?.descriptions.find(desc => desc?.value)
      if (desc)
        return desc.value
        
      else if(fallBackdesc)
        return fallBackdesc.value

      return ''
    }
  },
  nonAvailableGetter(params){
      if (params.data && params.node && !params.node.rowPinned) {
          const colkey = params.colDef.colKey ?? params.colDef.field;
          const value = params.data[colkey];
          return value ? value : i18n.t('Reporting.NA');
      }
  },
  negativeValue(params){
    if (params.data && params.node && !params.node.rowPinned) {
      const colkey = params.colDef.colKey ?? params.colDef.field
      const value = params.data[colkey]
      return (Math.abs(value) * -1)
    }
  },
  multiplePromoGetter(params) {
    if (params.data && params.node && !params.node.rowPinned) {
      const colkey = params.colDef.colKey ?? params.colDef.field
      const value = params.data[colkey]

      if (params.data.hasMultiplePromotion) {
        return i18n.t('LabelBatch.Multiples');
      }
      else if (value) {
        return value;
      }
      else {
        return '';
      }
    }
  },
  labelTypeGetter(params) {
    const colkey = params.colDef.colKey ?? params.colDef.field
    
    if (params?.data && colkey) {
      const value = params.data[colkey]
      if (value === true)
        return i18n.t("LabelBatch.LabelType.Promo");
    }
    
    return i18n.t("LabelBatch.LabelType.Regular");
  }
}

export default agGridGetters;