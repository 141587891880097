<template src="./nested-draggable.html"></template>

<style lang="scss">
@import './nested-draggable.scss';
</style>

<script>
import draggable from "vuedraggable";
export default {
  model:{
    prop: 'columns'
  },
  props: {
    columns:{
      type: Array,
      default: function () {
        return []
      }
    },
    isColumnGroupableStart: Function,
    isGroupableEnd: Function,
    setColumn: Function,
    fct: Function,
    onAdd: Function,
    checkGroup: Function,
    columnIndex: String,
    id: String
  },
  components: {
    draggable
  },
  name: "nested-draggable"
};
</script>