<template src="./dropdown-renderer.html"></template>


<style scoped lang="scss">
@import './dropdown-renderer.scss';
</style>

<script>
import defaultHeaderRenderer from './default-header-renderer/default-header-renderer.vue';

const baseRowHeight = 48;
const deleteButton = 40;
const detailRowHeight = 40;

export default{
  name: 'dropdownRenderer',
  data: function () {
    return {
      open: false,
      data: {},
      headerRenderer: null
    }
  },

  computed:{
    canDelete() {
      return this.params.onDelete && this.params.canDelete();
    },
    isDisabled() {
      return this.params.disable && this.params.disable(this.params);
    }
  },

  created() {
    this.headerRenderer = this.params.headerRenderer || defaultHeaderRenderer;
  },

  mounted(){
    //If the parent gave column parameters, use them. Else, use the grid column def
    this.params.columnDefs ? this.setDataWithParamsColumnDefs() : this.setDataWithGridColumnDefs();
  },
  beforeDestroy(){
    this.params.node.setRowHeight(48);
  },
  methods: {
    setDataWithParamsColumnDefs(){
      for(const key in this.params.data){
        const columnDef = this.params.columnDefs.find(col => this.compareCI(col.field, key) && !this.compareCI(col.field, this.params.colDef.field));
        if(columnDef){
          this.setData(columnDef, this.params.data[key], key);
        }
      }
    },
    setDataWithGridColumnDefs(){
      const columnDefs = this.params.api.getColumnDefs();
      const filteredColumnDefs = columnDefs.filter(col => !col.cellRenderer);
      filteredColumnDefs.forEach(columnDef => {
        const key = columnDef.field ?? columnDef.colId;
        const value = this.params.api.getValue(key, this.params.node);  
        this.setData(columnDef, value, key);
      });
    },
    setData(columnDef, value, key){
      const colParams = {
        ...this.params,
        value: value,
        colDef: {
          ...columnDef,
          colKey: key
        }
      };

      colParams.value = (columnDef.valueGetter && columnDef.valueGetter(colParams)) || colParams.value;
      colParams.value = (columnDef.valueFormatter && columnDef.valueFormatter(colParams)) || colParams.value;

      this.data[key] = {
        value: colParams.value,
        description: columnDef.headerName
      };
    },
    openPanel(){
      if (!this.isDisabled)
        this.params.openSidePanel(this.params.data);
    },
    drop(){
      if(this.open){
          this.params.node.setRowHeight(48);
          this.params.api.onRowHeightChanged();
      }
      else{
        const height = baseRowHeight + (this.params.onDelete ? deleteButton : 0) + (Object.keys(this.data).length * (detailRowHeight));

        this.params.node.setRowHeight(height);
        this.params.api.onRowHeightChanged();
      }
      this.open = !this.open;
    },
    invokeParentDelete() {
      this.params.onDelete(this.params.node);
    },
  }
};
</script>
