<template src='./generateBatch.html'></template>

<style lang='scss'>
@import './generateBatch.scss';
</style>

<script>
import { mapGetters, mapActions } from 'vuex'
import prompts from '@/core/tools/notifications/notifications'
import formatters from '@/core/components/common/grid/formatters/formatters.js'
import grid from '@/core/components/common/grid/persisted-grid.vue'
import searchBar from '@/core/components/common/search-bar/search-bar.vue'
import iconButton from '@/core/components/common/icon-button/icon-button.vue'
import { GridId, AgFilter, AgFilterType, AgAllowedAggFuncs, MultiFilterParams, FilterParams } from '@/core/components/common/grid/grid.const.js'
import labelBatchClient from '@/backoffice/modules/labels/clients/labelBatch.client.js'
import { permissionsDictionary } from '@/core/security/permissions-dictionary.js'
import Swal from 'sweetalert2'

export default {
  name: 'LabelBatch',
  components: {
    grid,
    searchBar,
    iconButton
  },

  data() {
    return {
      batchSize: 0,
      products: [],
      gridOptions: {
        rowSelection: 'multiple',
        enableRangeSelection: false,
        suppressRowClickSelection: true,
        getRowId: (params) => params.data?._ItemID
      },
      gridApi: null,
      filters: [],
      defaultFilters: {
        statusCode: {
          filterType: AgFilterType.Set,
          values: ['true']
        }
      },
      selectedRowQty : 0
    }
  },

  computed: {
    ...mapGetters('Account', ['hasPermission', 'getCurrentTargetLayerId']),
    getGridId() {
      return GridId.LabelBatch
    },
    columnDefs() {
      return [
        { field: 'itemUPC', headerName: this.$t('LabelBatch.Columns.ItemUPC'), headerTooltip: this.$t('LabelBatch.Columns.ItemUPC'), allowedAggFuncs: AgAllowedAggFuncs.Text, filter: AgFilter.TextColumnFilter, headerCheckboxSelection: true, checkboxSelection: true, headerCheckboxSelectionFilteredOnly: true },
        { field: 'description', headerName: this.$t('LabelBatch.Columns.Description'), minWidth: 150, headerTooltip: this.$t('LabelBatch.Columns.Description'), allowedAggFuncs: AgAllowedAggFuncs.Text, filter: AgFilter.TextColumnFilter },
        { field: 'brandDescription', headerName: this.$t('LabelBatch.Columns.Brand'), headerTooltip: this.$t('LabelBatch.Columns.Brand'), allowedAggFuncs: AgAllowedAggFuncs.Text, filterParams: MultiFilterParams.NormalizeTextFilters },
        { field: 'formatDescription', headerName: this.$t('LabelBatch.Columns.Format'), headerTooltip: this.$t('LabelBatch.Columns.Format'), allowedAggFuncs: AgAllowedAggFuncs.Text, filter: AgFilter.TextColumnFilter },
        { field: 'itemPrice', headerName: this.$t('LabelBatch.Columns.ItemPrice'), headerTooltip: this.$t('LabelBatch.Columns.ItemPrice'),  valueFormatter: formatters.currencyFormatter, filterParams: FilterParams.NumberFilter },
        { field: 'subDepartmentDescription', headerName: this.$t('LabelBatch.Columns.SubDepartmentDescription'), headerTooltip: this.$t('LabelBatch.Columns.SubDepartmentDescription'), allowedAggFuncs: AgAllowedAggFuncs.Text, filter: AgFilter.TextColumnFilter },
        { field: 'statusCode', headerName: this.$t('LabelBatch.Columns.Status'), headerTooltip: this.$t('LabelBatch.Columns.Status'), cellRenderer: 'booleanRenderer', filter: AgFilter.SetColumnFilter, filterParams: { valueFormatter: (params) => params.value === 'true' ? this.$t('Button.Yes') : this.$t('Button.No') }, valueGetter: (params) => params?.data?.statusCode ? params.data.statusCode.toString() : 'false' } //TODO: Simplify this line after ag-grid update (version 29+) 
      ]
    },
    rowData() {
      return this.products
    },
    canUpdate() {
      return this.hasPermission([permissionsDictionary.LABEL_UPDATE])
    },
  },

  watch: {
    getCurrentTargetLayerId() {
      this.loadProducts()
      this.getBatchSize()
    }
  },

  mounted() {
    this.loadProducts()
    this.getBatchSize()
  },

  methods: {
    ...mapActions('Product', ['fetchProducts']),

    onGridReady(params) {
      this.gridApi = params.api
    },

    async loadProducts() {
      try {
        const params = { getChildren: false, _TargetLayerID: this.getCurrentTargetLayerId }
        this.products = (await this.fetchProducts(params)).filter(p => p.itemPrice !== undefined)
      } 
      catch (error) {
        prompts.error({
          text: this.$t('LabelBatch.Messages.Error')
        })
      }
    },

    async getBatchSize() {
      try {
        this.batchSize = await labelBatchClient.getBatchSize()
      } catch (error) {
        prompts.error({
          text: this.$t('LabelBatch.Messages.Error')
        })
      }
    },

    filtersChanged(filters) {
      this.filters = filters
    },

    async addToBatch() {
      const selectedRows = this.gridApi.getSelectedRows()
      const itemUpcs = selectedRows.map(x => x.itemUPC)

      try {
        this.batchSize = await labelBatchClient.addToBatch(itemUpcs)
        this.gridApi.deselectAll()
      } catch (error) {
        prompts.error({
          text: this.$t('LabelBatch.Messages.Error')
        })
      }
    },

    async emptyBatch() {
      const answer = await Swal.fire({
        heightAuto: false,
        confirmButtonText: this.$i18n.t('Button.YES'),
        showCancelButton: true,
        cancelButtonText: this.$i18n.t('Button.NO'),
        icon: 'warning',
        title: this.$i18n.t('LabelBatch.EmptyBatch'),
        text: this.$i18n.t('LabelBatch.Messages.EmptyBatchConfirm', {quantity: this.batchSize}),
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      if (!answer.isConfirmed) {
        return
      }

      try {
        await labelBatchClient.emptyBatch()
        this.batchSize = 0
      } catch (error) {
        prompts.error({
          text: this.$t('LabelBatch.Messages.Error')
        })
      }
    },

    onSelectionChanged(event) {
      this.selectedRowQty = event.api.getSelectedNodes().length
    }
  }
}
</script>