import { render, staticRenderFns } from "./model-column-picker.html?vue&type=template&id=04bf95c8&scoped=true&"
import script from "./model-column-picker.vue?vue&type=script&lang=js&"
export * from "./model-column-picker.vue?vue&type=script&lang=js&"
import style0 from "./model-column-picker.scss?vue&type=style&index=0&id=04bf95c8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04bf95c8",
  null
  
)

export default component.exports