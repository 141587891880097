<template inline-template v-cloak src="./forgot-password.html"></template>

<style scoped lang="scss">
@import '@/core/pages/account/account.scss';
</style>

<script>
import { mapActions } from 'vuex';
import Errors from "@/core/tools/errors/errors";
import prompts from '@/core/tools/notifications/notifications';

export default {
    name: 'forgot-password',
    data() {
        return {
            email: '',
            disabled: false
        }
    },

    methods: {
        ...mapActions('Account', ['forgotPassword']),
        async checkForm(e){
            e.preventDefault();
            this.disabled = true;

            try {
                await this.forgotPassword({ email: this.email } );
            } catch (error) {
                if (error.message == 'LDAP') {

                    prompts.error({
                        title: this.$t('Error.Plugins.BasicLogin.ForgotPasswordLDAP'),
                        text: this.$t('Error.Plugins.BasicLogin.ForgotPasswordLDAPMsg')
                    });
                }
                else if (error.message == 'LOCKED') {

                    prompts.error({
                        title: this.$t('Error.Plugins.BasicLogin.Login'),
                        text: this.$t('Error.Plugins.BasicLogin.LockedMsg')
                    });
                }
                else {
                    this.disabled = false;
                    Errors.displayApiError({status: error.message});
                }
            }
        }
    }
}
</script>