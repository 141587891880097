<template src="./order.html"></template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import prompts from '@/core/tools/notifications/notifications';
import { OrderStatus, CanSendStatuses } from '@/backoffice/modules/purchase/domain/purchase.js';
import formatters from '@/core/components/common/grid/formatters/formatters.js';
import orderProductRenderer from '@/backoffice/modules/purchase/components/order-product-renderer/order-product-renderer.vue';
import cloneDeep from 'lodash/cloneDeep';
import searchBar from '@/core/components/common/search-bar/search-bar.vue';
import orderInfo from '@/backoffice/modules/purchase/components/order-info/order-info.vue';
import grid from '@/core/components/common/grid/persisted-grid.vue';
import collapse from '@/core/components/common/collapse/collapse.vue';
import orderProductScan from '@/backoffice/modules/purchase/components/sidePanel/orders/order-product-scan/order-product-scan.vue';
import oderProduct from '@/backoffice/modules/purchase/components/sidePanel/orders/order-product/order-product.vue';
import sendProduct from '@/backoffice/modules/purchase/components/sidePanel/orders/send-order/send-order.vue';
import tabs from '@/core/components/common/tabs/tabs.vue';
import purchaseVendorItems from '@/backoffice/modules/purchase/components/purchase-vendor-items/purchase-vendor-items.vue';
import orderRelatedReceivings from './order-related-receivings/order-related-receivings.vue'
import { OrderGridTabs } from '@/backoffice/modules/purchase/pages/orders/order/orderGridTabs.js';
import { GridId, AgAllowedAggFuncs, FilterParams } from '@/core/components/common/grid/grid.const.js'
import { permissionsDictionary } from '@/core/security/permissions-dictionary.js'

export default {
  name: 'Order',
  components: {
    searchBar,
    orderInfo,
    grid,
    collapse,
    tabs,
    purchaseVendorItems,
    orderRelatedReceivings
  },

  props: {
    id: [Number, String]
  },

  data(){
    return {
      gridOptions: {
        getRowId: (params) => params?.data?.id
      },
      filters: [],
      sendPermission: permissionsDictionary.ORDER_SEND,
      dataLoaded: false
    }
  },

  computed: {
    ...mapGetters('App', ['isMobile']),
    ...mapGetters('Order', ['getOrder', 'getOrderGridTabIndex', 'getOrderReceivedInfos']),
    ...mapGetters('Account', ['getCurrentTargetLayerId']),
    agTheme() {
      return this.isMobile ? 'ag-theme-material' : 'ag-theme-alpine'
    },
    getGridId() {
      return this.showReceivedInfo ? GridId.OrderReceiving : GridId.Order
    },
    orderId() {
      return Number.parseInt(this.id);
    },
    orderNO() {
      return this.getOrder?.orderNO || '';
    },
    canSend() {
      return this.getOrder?.orderBodies?.length > 0 && CanSendStatuses.includes(this.getOrder.orderStatus);
    },
    addItemIsDisabled() {
      return !this.getOrder?.canUpdate || this.getOrder.orderStatus != OrderStatus.OPEN;
    },
    voidOrderIsDisabled() {
      return !this.getOrder?.canUpdate || (this.getOrder.orderStatus != OrderStatus.OPEN && this.getOrder.orderStatus != OrderStatus.PENDING);
    },
    showActions(){
      if (!this.getOrder)
        return false;

      return this.getOrder.orderStatus !== OrderStatus.VOID;
    },
    showReceivedInfo(){
      if (!this.getOrder)
          return false;
          
      return this.getOrder.orderStatus == OrderStatus.CLOSED || 
              this.getOrder.orderStatus == OrderStatus.PENDING || 
              this.getOrder.orderStatus == OrderStatus.PARTIALLYRECEIVED
    },
    showPurchaseVendorProducts(){
      if (!this.getOrder)
        return false;

      return !this.showReceivedInfo
    },
    purchaseVendorProps(){
      return {
        itemUpcs: this.getOrder?.orderBodies?.map(ob => ob.itemUPC),
        vendorCode: this.getOrder?.vendorCode
      } 
    },
    orderTabs(){
      return [this.$t('Purchase.Order.OrderDetails'), this.showReceivedInfo ? this.$t('Purchase.Order.RelatedReceivings') : this.$t('Purchase.VendorProducts')]
    },
    rowData(){
      if(!this.dataLoaded)
        return null

      if (!this.getOrder?.orderBodies)
        return []

      return this.getOrder.orderBodies.map(p => {
        const receiveInfo = this.getOrderReceivedInfos[p.id]
        return {
          ...p,
          itemDescription: p.itemDescription || '',
          receivedQty: receiveInfo?.qty || 0,
          receivedUnitCost: receiveInfo?.qty && Number(( receiveInfo.cost / receiveInfo.qty).toFixed(2)) || 0,
          costReceived: (receiveInfo?.qty && receiveInfo.cost) || 0
        }
      });
    },
    columnDefs(){
      const cellRenderer = this.isMobile ? 'dropdownRenderer' : 'clickCallbackRenderer';
      const cellRendererParams = this.isMobile ? {
        openSidePanel: (row) => this.openProductSP(row?.itemUPC),
        headerRenderer: orderProductRenderer,   
        canDelete: () => this.getOrder.canDelete,
        onDelete: this.deleteBody
      } : { callback: (row) => this.openProductSP(row?.itemUPC) }
      
      const columnDefs = [
        { 
          field: 'itemDescription',
          headerName: this.$t('Purchase.Order.Columns.ItemDescription'), 
          headerTooltip: this.$t('Purchase.Order.Columns.ItemDescription'),
          cellRenderer: cellRenderer,
          cellRendererParams: cellRendererParams,
          cellClass: this.isMobile ? "dropdown-cellrenderer" : "",
          valueGetter: params => params?.data?.itemDescription,
          allowedAggFuncs: AgAllowedAggFuncs.Text
        },
        { hide: this.isMobile, field: 'itemUPC', colId: 'itemUPC', sort: '', headerName: this.$t('Purchase.Order.Columns.ItemUPC'), headerTooltip: this.$t('Purchase.Order.Columns.ItemUPC'), valueGetter: params => params?.data?.itemUPC, allowedAggFuncs: AgAllowedAggFuncs.Text },
        { hide: this.isMobile, field: 'unitQty', sort: '', headerName: this.$t('Purchase.Order.Columns.UnitQty'), headerTooltip: this.$t('Purchase.Order.Columns.UnitQty'), valueGetter: params => params?.data?.unitQty, filterParams: FilterParams.NumberFilter },
        { hide: this.isMobile, field: 'uomDescription', sort: '', headerName: this.$t('Purchase.Order.Columns.UomDescription'), headerTooltip: this.$t('Purchase.Order.Columns.UomDescription'), valueGetter: params => params?.data?.uomDescription, allowedAggFuncs: AgAllowedAggFuncs.Text },
        { hide: this.isMobile, field: 'qty', sort: '', headerName: this.$t('Purchase.Order.Columns.OrderedQty'), headerTooltip: this.$t('Purchase.Order.Columns.OrderedQty'), valueGetter: params => params.data?.qty, filterParams: FilterParams.NumberFilter },
        { hide: this.isMobile, field: 'unitCost', sort: '', headerName: this.$t('Purchase.Order.Columns.UnitCost'), headerTooltip: this.$t('Purchase.Order.Columns.UnitCost'), filterParams: FilterParams.NumberFilter, valueGetter: params => params?.data?.unitCost, valueFormatter: formatters.currencyFormatter },
        { hide: this.isMobile, field: 'cost', sort: '', headerName: this.$t('Purchase.Order.Columns.Cost'), headerTooltip: this.$t('Purchase.Order.Columns.Cost'), filterParams: FilterParams.NumberFilter, valueGetter: params => params?.data?.cost, valueFormatter: formatters.currencyFormatter },
        { 
          field: 'itemUPC',
          colId: 'menu',
          headerName: '', 
          hide: this.isMobile,
          width: 100,
          resizable: false,
          sortable: false,
          enableRowGroup: false,
          enableValue: false,
          enablePivot: false,
          floatingFilter: false,
          suppressColumnsToolPanel: true,
          filter: null,
          suppressMovable: true,
          cellClass:'ag-cell-overflow',
          cellRenderer: 'menuRenderer',
          cellRendererParams: {
            alwaysVisible: true,
            openLeft: true,
            hideValue: true,
            canUpdate: () => this.getOrder.canUpdate,
            canDelete: () => this.getOrder.canDelete && ([OrderStatus.CLOSED, OrderStatus.VOID, OrderStatus.PENDING, OrderStatus.PARTIALLYRECEIVED].indexOf(this.getOrder.orderStatus) === -1),
            editEntity: (params) => this.openProductSP(params.data.itemUPC),
            delete: this.deleteBody
          },
          pinned: 'right'
        }
      ];

      const receivingColumnDefs = !this.showReceivedInfo ? [] : [
        { 
          hide: this.isMobile, 
          field: 'receivedQty', 
          sort: '', 
          headerName: this.$t('Purchase.Order.ReceivedQty'),
          headerTooltip: this.$t('Purchase.Order.ReceivedQty'), 
          cellClass: params => {
            return params.value != params?.data?.qty ? ['purchase-cell purchase-cell--indeterminate'] : 'purchase-cell';
          },
          valueGetter: params => params?.data?.receivedQty,
          filterParams: FilterParams.NumberFilter,
        },
        { 
          hide: this.isMobile, 
          field: 'receivedUnitCost', 
          sort: '',
          headerName: this.$t('Purchase.ReceivedCost'),
          headerTooltip: this.$t('Purchase.ReceivedCost'),           
          cellClass: params => {
            return params.value != params?.data?.unitCost ? ['purchase-cell purchase-cell--indeterminate'] : 'purchase-cell';
          },
          valueGetter: params => params?.data?.receivedUnitCost,
          filterParams: FilterParams.NumberFilter,
          valueFormatter: formatters.currencyFormatter
        },
        { 
          hide: this.isMobile, 
          field: 'costReceived', 
          sort: '',
          headerName: this.$t('Purchase.Order.Columns.CostReceived'),
          headerTooltip: this.$t('Purchase.Order.Columns.CostReceived'),
          cellClass: params => {
            return params.value != params?.data?.cost ? ['purchase-cell purchase-cell--indeterminate'] : 'purchase-cell'
          },
          valueGetter: params => params?.data?.costReceived,
          filterParams: FilterParams.NumberFilter,
          valueFormatter: formatters.currencyFormatter
        }
      ]

      return [...columnDefs, ...receivingColumnDefs]
    },
    
    selectedTab: {
      get() {
        return this.getOrderGridTabIndex
      },
      set(newValue){
        this.setOrderGridTabIndex(newValue)
      }
    }
  },

  watch: {
    getCurrentTargetLayerId() {
      this.$router.push({ name: 'orders' });
    }
  },

  beforeDestroy() {
    this.setOrder(null);
  },

  methods: {
    ...mapActions('Order', ['fetchOrder', 'fetchOrderRelatedReceivingBodies', 'saveOrder']),
    ...mapActions('Product', ['fetchProduct', 'fetchProductCostsByVendor']),
    ...mapMutations('Order', {
      setOrder: 'SET_ORDER',
      setOrderGridTabIndex: 'SET_ORDER_GRID_TAB_INDEX'
    }),
    ...mapMutations('Breadcrumb', {
      setBreadcrumbContext: 'SET_CONTEXT'
    }),

    async onGridReady(params) {
      await this.loadData()
      this.gridOptions.api = params.api
      
      if (this.showReceivedInfo)
        this.gridOptions.api.refreshCells({
          force: true,
          suppressFlash: true,
          columns: ['receivedQty', 'receivedCost'],
        });
    },

    async loadData() {
      this.dataLoaded = false

      await this.getOrderData();
      
      if (this.showReceivedInfo) {
        await this.getReceivedInfo()
      }

      this.dataLoaded = true
    },

    async getOrderData() {
      try {
        await this.fetchOrder(this.orderId);
      } 
      catch (error) {
        prompts.error({
          text: this.$t('Purchase.Messages.GetOrdersError')
        });
      }

      if (this.getOrder.id) {
        this.setBreadcrumbContext({ order: this.getOrder })
      }
    },

    async getReceivedInfo(){
      try {
        await this.fetchOrderRelatedReceivingBodies(this.orderId);
      } 
      catch (error) {
        prompts.error({
          text: this.$t('Purchase.Messages.GetOrderReceivedInfoError')
        });
      }
    },

    async deleteBody(node) {
      const result = await prompts.warning({html: this.$t('Purchase.Messages.DeleteOrderBody')});
      if (result.isConfirmed) {    
        const currentBody = this.getOrder.orderBodies.find(b => b.id == node.data.id);
        currentBody.remove = true;
        
        try{
          await this.saveOrder(this.getOrder);
        }
        catch(error){
          prompts.error({
            text: this.$t('Purchase.Messages.DeleteBodyError')
          });
        }
      }
    },

    async openProductSP(itemUpc) {
      const product = await this.fetchProduct(itemUpc);
      await this.fetchProductCostsByVendor({product: product, vendorCode: this.getOrder.vendorCode});
      this.$sidePanel.show([{ component: oderProduct }]);
    },
  
    redirectToReceiving(receiving){
      this.$router.push({ name: 'receiving', params: { id: receiving.id } });
    },

    addItem() {
      this.setOrderGridTabIndex(OrderGridTabs.OrderBodiesTabIndex)
      this.$sidePanel.show([{ component: orderProductScan }], null, { backgroundIsDisabled: true });
    },
    
    async sendOrder() {
      this.$sidePanel.show([{ component: sendProduct }]);
    },
    async voidOrder(){
      const result = await prompts.warning({html: this.$t('Purchase.Messages.VoidOrder')});
      if (!result.isConfirmed)
        return;

      const order = cloneDeep(this.getOrder);
      order.orderStatus = OrderStatus.VOID;

      try{
        await this.saveOrder(order);
      }
      catch(error){
        prompts.error({
          text: this.$t('Purchase.Messages.VoidOrderError')
        });
      }
    },

    filtersChanged(filters){
      this.filters = filters;
    }
  }
}
</script>