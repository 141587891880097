
import { hasOwnPropertyCI } from '@/core/functions';
import Vue from 'vue';

Vue.mixin({
  methods: {
    deconstruct(obj, data) {
      for (const prop in data) {
        if (hasOwnPropertyCI(data, prop)) {
          if (typeof data[prop] === "object" && data[prop] !== null) {
            this.deconstruct(obj, data[prop]);
            delete obj[prop];
          } 
          else {
            obj[prop] = data[prop];
          }
        }
      }
      return obj;
    },
    hasOwnPropertyCI: hasOwnPropertyCI
  }
});
