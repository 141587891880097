<template src="./main.html"></template>

<style lang="scss">
    @import './main.scss';
</style>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import creationButton from '@/core/components/common/creation-button/creation-button.vue';
import searchBar from '@/core/components/common/search-bar/search-bar.vue';
import grid from '@/core/components/common/grid/persisted-grid.vue';
import promotionManagement from '@/backoffice/modules/promotion/components/sidePanel/promotion-management/promotion-management.vue';
import promotionConditions from '@/backoffice/modules/promotion/components/sidePanel/promotion-conditions/promotion-conditions.vue';
import prompts from '@/core/tools/notifications/notifications';
import { PromotionValueFormatter, PromotionStatusGetter, PromotionTypeGetter, PromotionFilterValue } from '@/backoffice/modules/promotion/promotion-getters/promotion-getters.js'
import formatters from '@/core/components/common/grid/formatters/formatters.js';
import getters from '@/core/components/common/grid/getters/getters.js';
import { PromotionScopeCode, Promotion, PromotionSearchByOption, PromotionSearchByOptionEnum } from '@/backoffice/modules/promotion/domain/promotion.js'
import { OperationType } from '@/backoffice/domain/backoffice.const.js'
import { GridId, AgFilter, AgFilterType, MultiFilterParams, FilterParams, AgAllowedAggFuncs } from '@/core/components/common/grid/grid.const.js'
import discountTypeCellRenderer from '@/backoffice/modules/promotion/promotion-cell-renderers/discount-type-cell-renderer/discount-type-cell-renderer.vue'

export default {
  name: 'Promotions',
  components: {
    creationButton,
    searchBar,
    grid,
    promotionManagement,
    discountTypeCellRenderer
  },
  data() {
    return {
      gridOptions: {
        getRowId: (params) => params?.data?.id
      },
      defaultFilters: {
        active: {
          filterType: AgFilterType.Set,
          values: [ PromotionStatusGetter(true) ]
        }
      },
      filters: [],
      limitOptions: PromotionSearchByOption
    }
  },

  watch: {
    getCurrentTargetLayerId() {
      this.init();
    },
    rowData:{
      handler(){
        this.gridOptions.api?.refreshCells();
      },
      deep: true
    }
  },

  computed: {
    ...mapGetters('App', ['isMobile']),
    ...mapGetters('Account', ['getCurrentTargetLayerId', 'getCurrentTargetId', 'hasPermission']),
    ...mapGetters('Promotion', ['getPromotions']),

    agTheme() {
      return this.isMobile ? 'ag-theme-material' : 'ag-theme-alpine'
    },
    getGridId() {
      return GridId.Promotions
    },
    canCreatePromotion() {
      return this.hasPermission(['PROMOTION_CUD'])
    },
    columnDefs(){
      const descriptionCellRenderer = this.isMobile ? 'dropdownRenderer' : 'linkRenderer';
      const descriptionCellRendererParams = this.isMobile ? (params) => {
          return{
            openSidePanel: this.managePromotion, 
            canDelete: () => params?.data?.canDelete,
            onDelete: this.deletePromotionPrompt,
            disable: (params) => params?.data?.scope?.scopeCode === PromotionScopeCode.Transaction
          }
      } : (params) => ({
        route: { name: 'promotion', params: { id: params?.data?.id } },
        disable: (params) => params?.data?.scope?.scopeCode === PromotionScopeCode.Transaction
      })
    
      return [
        { hide: this.isMobile, field: 'targetDescription', minWidth: 150, headerName: this.$t('Promotion.Columns.TargetDescription'), headerTooltip: this.$t('Promotion.Columns.TargetDescription'),
          cellClass:'ag-cell-overflow',
          cellRenderer: 'menuRenderer',
          cellRendererParams: {
            canUpdate : params => this.canUpdateOrDelete(params?.data),
            canDelete : params => this.canUpdateOrDelete(params?.data),
            editEntity: params => this.editPromotion(params?.data),
            delete: params => this.deletePromotionPrompt(params?.data)
          },
          filterParams: MultiFilterParams.NormalizeTextFilters,
          allowedAggFuncs: AgAllowedAggFuncs.Text
        },
        { field: 'description', headerName: this.$t('Promotion.Columns.Description'), headerTooltip: this.$t('Promotion.Columns.Description'), 
          cellRenderer: descriptionCellRenderer,
          cellRendererParams: descriptionCellRendererParams,
          enableRowGroup: false,
          cellClass: this.isMobile ? "dropdown-cellrenderer" : "",
          filterParams: MultiFilterParams.NormalizeTextFilters,
          allowedAggFuncs: AgAllowedAggFuncs.Text,
          valueGetter: getters.description,
        },
        { hide: this.isMobile, field: 'code', filterParams: MultiFilterParams.NormalizeTextFilters, headerName: this.$t('Promotion.Columns.Code'), headerTooltip: this.$t('Promotion.Columns.Code') },
        { hide: this.isMobile, field: 'instant', filter: AgFilter.SetColumnFilter, headerName: this.$t('Promotion.Columns.IsInstant'), headerTooltip: this.$t('Promotion.Columns.IsInstant'), cellRenderer: 'booleanRenderer', filterParams: { valueFormatter: (params) => this.$t(`Reporting.DataDef.${params.value.toString()}`) }, valueGetter: (params) => params?.data?.instant ? params.data.instant.toString() : 'false' },
        { hide: this.isMobile, colId: 'type', filterParams: MultiFilterParams.NormalizeTextFilters, minWidth: 200, headerName: this.$t('Promotion.Columns.Type'), headerTooltip: this.$t('Promotion.Columns.Type'), valueGetter: (params) => PromotionTypeGetter(params?.data), cellRenderer: 'discountTypeCellRenderer', allowedAggFuncs: AgAllowedAggFuncs.Text },
        { hide: this.isMobile, field: 'value', filterParams: FilterParams.NumberFilter, headerName: this.$t('Promotion.Columns.Value'), headerTooltip: this.$t('Promotion.Columns.Value'), valueFormatter: (params) => PromotionValueFormatter(params?.data), filterValueGetter: (params) => PromotionFilterValue(params?.data) },
        { hide: this.isMobile, colId: 'min', filterParams: FilterParams.NumberFilter, maxWidth: 150, headerName: this.$t('Promotion.Columns.Min'), headerTooltip: this.$t('Promotion.Columns.Min'), valueGetter: (params) => params?.data?.discount?.discountMinItems },
        { hide: this.isMobile, colId: 'max', filterParams: FilterParams.NumberFilter, maxWidth: 150, headerName: this.$t('Promotion.Columns.Max'), headerTooltip: this.$t('Promotion.Columns.Max'), valueGetter: (params) => params?.data?.discount?.discountMaxItems },
        { hide: this.isMobile, field: 'maxUsage', filterParams: FilterParams.NumberFilter, headerName: this.$t('Promotion.Columns.AvailabilityLimit'), cellRenderer: 'availabilityMaximumRenderer', headerTooltip: this.$t('Promotion.Columns.AvailabilityLimit') },
        { hide: this.isMobile, field: 'startDate', filter: AgFilter.DateColumnFilter, headerName: this.$t('Promotion.Columns.StartDate'), headerTooltip: this.$t('Promotion.Columns.StartDate'), valueFormatter: formatters.dateFormatter, filterValueGetter: (params) => params?.data?.startDate && new Date(params.data.startDate) },      
        { hide: this.isMobile, field: 'endDate',  filter: AgFilter.DateColumnFilter, headerName: this.$t('Promotion.Columns.EndDate'), headerTooltip: this.$t('Promotion.Columns.EndDate'), valueFormatter: formatters.dateFormatter, filterValueGetter: (params) => params?.data?.endDate && new Date(params.data.endDate) },
        { hide: this.isMobile, field: 'active', filter: AgFilter.SetColumnFilter, headerName: this.$t('Promotion.Columns.Status'), headerTooltip: this.$t('Promotion.Columns.Status'), valueGetter: (params) => PromotionStatusGetter(params?.data?.active) },
      ];
    },
    rowData(){
      const promotions = this.getPromotions(false)
      if(promotions?.length === 0)
        return []
      
      return promotions
    }
  },

  beforeDestroy(){
    this.SET_PROMOTIONS([])
  },

  methods: {
    ...mapActions('Promotion', ['fetchPromotions', 'deletePromotion', 'searchPromotionsByProduct']),
    ...mapMutations('Promotion', ['SET_PROMOTION', 'SET_PROMOTIONS', 'SET_OPERATION_TYPE']),
    onGridReady(params) {
      this.gridOptions.api = params.api
      this.init()
    },
    async init(){

      try{
        await this.fetchPromotions()
      }
      catch(e){
        prompts.error({
          text: this.$t('Promotion.Messages.FetchPromotionsError')
        });
      }
      
    },
    managePromotion(promotion){
      this.$router.push({ name: 'promotion', params: { id: promotion.id } });
    },
    async createPromotion(){
      await this.openPromotionSP(new Promotion(), OperationType.Creation)
    },
    async editPromotion(promotion){
      await this.openPromotionSP(promotion, OperationType.Edition)
    },
    async openPromotionSP(promotion, operationType){
      this.SET_PROMOTION(promotion)
      this.SET_OPERATION_TYPE(operationType)
      await this.$sidePanel.show([{ component: promotionManagement, tab: 'Promotion.Tabs.General' }, 
                                  { component: promotionConditions, tab: 'Promotion.Tabs.Conditions' }]);
      this.SET_PROMOTION(null)
      this.SET_OPERATION_TYPE(null)
      this.gridOptions.api?.refreshCells();
    },
    async deletePromotionPrompt(promotion){
      const result = await prompts.warning({html: this.$t('Promotion.Messages.DeletePromotionPrompt')});
      if (result.isConfirmed) {
        try{
          await this.deletePromotion(promotion);
        }
        catch(error){
          prompts.error({
            text: this.$t('Promotion.Messages.DeletePromotionError')
          });
        }
      }
    },
    filtersChanged(filters, scope){      

      if(scope === PromotionSearchByOptionEnum.PROMOTION){       
        this.filters = filters;
        this.fetchPromotions()
      }
      
      if(scope === PromotionSearchByOptionEnum.ITEM){
        this.filters = []
        this.searchPromotionsByProduct(filters)
      }
      
    },
    canUpdateOrDelete(promotion){
      return promotion?.targetId  === this.getCurrentTargetId
    }
  }
}
</script>