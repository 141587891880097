<template src="./role-dropdown.html"></template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped lang="scss" src="./role-dropdown.scss"></style>

<script>
import multiselect from 'vue-multiselect'
import { roleServices } from "@/backoffice/modules/role/services/role.services";
import { mapActions } from 'vuex';

export default {
  name: 'roleDropdown',
  props:{
    classes: [String, Object],
    roles: {
      default: () => [],
      type: Array
    },
    _TargetLayerID: Number,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components:{
    multiselect
  },
  data() {
    return {
      list: null,
      multiSelect:{
        value: null,
        options: null,
        trackBy: 'iD',
        label: 'description',
        multiple: true,
        showLabels: false,
        placeholder: this.$t('Role.RoleDescriptionPh'),
        allowEmpty: false
      }
    };
  },
  watch:{
    _TargetLayerID: {
      handler(newVal, oldVal) {
        if (newVal != oldVal)
          this.getList();
      },
    }
  },
  mounted() {
    if (this._TargetLayerID)
      this.getList();
  },
  methods: {
    async getList() {
      const response = await roleServices.getReadRoles(false, [], null, this._TargetLayerID);

      if (response.status == 200)
        this.setList(response.data);
    },
    setList(data){
      const selectedValue = [];
      this.roles.forEach(role => {
        const roleFound = data.find(r => r.iD == role.roleID);

        if (roleFound)
          selectedValue.push(Object.assign({}, role, roleFound));
      });
      
      this.multiSelect.value = selectedValue;
      this.multiSelect.options = data;

      if (!this.disabled)
        this.onInput();
    },
    onSelect(){
      this.sort(this.multiSelect.options, 'description', false, false);
    },
    onRemove(){
      this.sort(this.multiSelect.options, 'description', false, false, false);
    },
    onInput(){
      // Roles selected
      let roles = this.multiSelect.value.map(role => ({iD: role.iD, targetID: role.targetID, description: role.description, targetDescription: role.targetDescription}));

      // Add deleted roles
      const deletedRole = this.roles.filter(role => this.multiSelect.value.find(r => r.iD == role.roleID) == null)
                                    .map(role => ({iD: role.roleID, targetID: role.targetID, description: role.description, targetDescription: role.targetDescription, remove: true}));

      roles = roles.concat(deletedRole);

      this.$emit("roles-changed", roles)
    }
  },
}
</script>
