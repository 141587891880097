<template src="./discount-type-cell-renderer.html"></template>

<style lang="scss">
    @import './discount-type-cell-renderer.scss';
</style>

<script>
export default{
  name: 'discountTypeCellRenderer',
  computed: {
    promotionScopeCode(){
        return this.params?.data?.scope?.scopeCode
    },
    promotionValue(){
        return this.params?.value
    },
    showRenderer() {
      return this.promotionScopeCode && this.promotionValue
    }
  }
};
</script>
