import { itemClient } from "@/backoffice/modules/item/item.client.js"
import { convertApiItemToItem, hasPrice, isForSale } from '@/backoffice/modules/item/domain/item.js'
import { ItemWithCost } from '@/backoffice/modules/item/itemWithCost'

async function getItemsWithPrice(params) {
    const response = await itemClient.getProducts(params);

    if(response.status === 200) {
      const products = response.data.map(d => convertApiItemToItem(d));
      return products.filter(hasPrice).filter(isForSale)
    }
    else {
      throw response;
    }
}

async function getItemsWithCosts(itemWithCostSearchCriteria) {
  const response = await itemClient.getItemsWithCosts(itemWithCostSearchCriteria);
  return response.status === 200 ? response.data.map(d => new ItemWithCost(d)) : []
}

export const ItemService = {
    getItemsWithPrice,
    getItemsWithCosts
}