<template src="./clientAccountManagement.html"></template>

<style lang="scss">
    @import './clientAccountManagement.scss';
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import { Languages, Countries } from '@/backoffice/modules/clientAccount/domain/client.js'
import toggle from '@/core/components/common/toggle/toggle.vue'
import { ValidationObserver, ValidationProvider, localize } from "vee-validate";
import { mapGetters, mapMutations } from 'vuex';
import { clientAccountServices } from '@/backoffice/modules/clientAccount/services/clientAccount.services.js'
import { OperationType } from '@/backoffice/domain/backoffice.const.js'
import prompts from '@/core/tools/notifications/notifications'
import multiselect from "vue-multiselect"
import languageInput from '@/core/components/common/form/language-input/language-input.vue'
import { permissionsDictionary } from '@/core/security/permissions-dictionary.js';
import cloneDeep from 'lodash/cloneDeep';
import spinLoader from '@/core/components/common/spin-loader/spin-loader.vue';

export default {
    name: 'ClientAccountManagement',
    components: {
        toggle,
        ValidationObserver,
        ValidationProvider,
        multiselect,
        languageInput,
        spinLoader
    },
    data(){
        return {
            client: {},
            invalidForm: true,
            preventSave: false,
            loading: false
        }
    },
    mounted(){
        localize(this.$i18n.locale)
        this.init()
    },

    computed: {
        ...mapGetters('ClientAccount', ['getClient', 'getOperationType']),
        ...mapGetters('Account', ['getUser', 'getCurrentTargetLayerId', 'hasPermission']),
        readOnly(){
            if(this.getOperationType === OperationType.Creation)
                return !this.hasPermission([permissionsDictionary.CLIENT_CREATE])

            return !this.hasPermission([permissionsDictionary.CLIENT_UPDATE])
        },
        postalCodeMask(){
            return clientAccountServices.getZipCodeMask(this.client.country)
        },
        states(){
            return clientAccountServices.getStates(this.client.country)
        },
        countries(){
            return Object.values(Countries)
        },
        languages(){
            return Object.values(Languages)
        },
        postalCodeValidationRule(){
            const rule = this.client?.country === Countries.USA ? 'PostalCodeUS' : 'PostalCodeCan'
            return `${rule}|max:20`
        }
    },

    watch: {
        invalidForm(){
            const disabledTab = this.invalidForm || this.getOperationType === OperationType.Creation
            this.$sidePanel.disabledTabs(disabledTab)
        },
        getOperationType(){
            const disabledTab = this.invalidForm || this.getOperationType === OperationType.Creation
            this.$sidePanel.disabledTabs(disabledTab)
        }
    },

    methods: {
        ...mapMutations('ClientAccount', { setOperationType: 'SET_OPERATION_TYPE', setClient: 'SET_CLIENT' }),
        async init(){
            try {
                this.loading = true
                this.client = await clientAccountServices.buildClient(this.getOperationType, this.getClient, this.getCurrentTargetLayerId, this.getUser._UserID)
            } catch (error) {
                prompts.error({
                    text: this.$t('ClientAccount.Messages.FetchClientConfigError')
                });
            }
            finally{
                this.loading = false
            }
        },

        async save(){
            this.preventSave = true;

            try{
                const savedClient = await clientAccountServices.createOrUpdate(this.client, this.getOperationType)
                this.setOperationType(OperationType.Edition)
                this.setClient(savedClient)
				this.client = cloneDeep(savedClient)

                prompts.toast({
                    icon: 'success',
                    title: this.$t('ClientAccount.Messages.SaveClientSuccess', { email: this.client.email }),
                    position: 'bottom-end'
                });
            }
            catch(error){
                if(error.status === 409)
                    prompts.error({ text: this.$t('ClientAccount.Messages.DuplicatedClientError') });
                else
                    prompts.error({ text: this.$t('ClientAccount.Messages.SaveClientError') });
            }

            this.preventSave = false;
        },
        cancel(){
            this.$sidePanel.close();
        },
        countryChanged(){
            this.client.zipCode = null;
            this.client.state = null;
        },
        isInvalid(invalid){
            this.invalidForm = invalid;
            return invalid;
        },
        validateLanguage() {
            this.$refs.languageValidator?.validate();
        }
    },
}
</script>