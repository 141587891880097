<template src="./breadcrumb.html"></template>

<style scoped lang="scss">
@import "./breadcrumb.scss";
</style>

<script>
import { languageMapping } from '@/core/domain/language/language.const.js';
import { mapGetters } from 'vuex';
export default {
  name: 'breadcrumb',
  props:{
    show: Boolean
  },
  
  data() {
    return {
      breadcrumbsConfig: null
    }
  },

  computed: {
    ...mapGetters('Breadcrumb', ['getContext']),
    ...mapGetters('App', ['getLanguage']),
    activeBreadcrumb() {
      const parts = this.breadcrumbsConfig && this.breadcrumbsConfig[this.$route.name] || [];
      return parts.map(part => ({
          route: part.routeNameTo && {
            name: part.routeNameTo,
            params: this.$route.params,
            query: part.routeQueryString
          },
          description: part.explicitTailFunc && part.explicitTailFunc(this.getContext) || this.$t(part.linkDescription)
        })
      )
    },
    breadcrumbClasses(){
      return this.getLanguage === languageMapping.en ? 'capitalized' : ''
    }
  },

  mounted() {
    this.buildBreadcrumbsConfig();
  },
  
  methods: {
    buildBreadcrumbsConfig() {
      const breadcrumbs = {};

      const parse = (arr, parentBread = []) => {
        arr.forEach(route => {
          const meta = route.meta || {};
          if (meta.breadcrumb) {
            const newBreadcrumbs = [...parentBread, {
                linkDescription: meta.breadcrumb.linkDescription,
                routeNameTo: meta.breadcrumb.routeNameTo,
                routeQueryString: meta.breadcrumb.routeQueryString,
                explicitTailFunc: null
            }];
            breadcrumbs[route.name] = newBreadcrumbs;

            const hasExplicitTail = meta.breadcrumb.explicitTail && (meta.breadcrumb.explicitTail.func || meta.breadcrumb.explicitTail.fromParam);
            if (hasExplicitTail) {

              breadcrumbs[route.name].push({
                linkDescription: null,
                routeNameTo: null,
                explicitTailFunc: meta.breadcrumb.explicitTail.func || (ctx => this.$route.params[meta.breadcrumb.explicitTail.fromParam]).bind(this)
              });
            }

            if (route.children) {
              parse(route.children, newBreadcrumbs);
            }
          }
          else if (route.children) {
            parse(route.children, parentBread);
          }
        });
      };

      parse(this.$router.options.routes);
      this.breadcrumbsConfig = breadcrumbs;
    }
  }
}
</script>


