import i18n from "@/i18n";
import moment from 'moment';

const getLocale = () => `${i18n.locale}-CA`;
const systemCurrency = 'CAD';

const currencyFormatter = (regional, currencySign, displayDigit) => new Intl.NumberFormat(regional, {
    style: 'currency',
    currency: systemCurrency,
    currencyDisplay: 'narrowSymbol',
    currencySign: currencySign,
    minimumFractionDigits: displayDigit ? 2 : 0,
    maximumFractionDigits: displayDigit ? 2 : 0
});

const decimalFormatter = (regional) => new Intl.NumberFormat(regional, {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});

const kilogramFormatter = (regional) => new Intl.NumberFormat(regional, {
    style: 'unit',
    unit: 'kilogram',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});

const toCurrency = (value, currencySign = 'standard', displayDigit = true) => {
    if (isNaN(value)) {
        return value;
    }
 
    return currencyFormatter(getLocale(), currencySign, displayDigit).format(value)
};

const toAccountingCurrency = (value, displayDigit = true) => {
    return toCurrency(value, 'accounting', displayDigit);
};

const toShortCurrency = (value, currencySign = 'standard') => {
    if (isNaN(value)) {
        return value;
    }

    let unit = '';

    if (value >= 1000000) {
        unit = 'M';
        value = value / 1000000;
    }
    else if (value >= 1000) {
        unit = 'K';
        value = value / 1000;
    }

    const displayDigit = !(value%1 == 0);

    const formattedValue = currencyFormatter(getLocale(), currencySign, displayDigit).formatToParts(value).map(({type, value}) => {
        switch (type) {
          case 'literal': return `${unit}${value}`;
          default : return value;
        }
      }).reduce((string, part) => string + part);

    return formattedValue.indexOf(unit) > -1 ? formattedValue : `${formattedValue}${unit}`;
};

const toNumber = (value) => {
    if(isNaN(value))
        return value

    return parseInt(value)
}

const toDecimal = (value) => {
    if (isNaN(value)) {
        return value;
    }
    else if(value % 1 == 0) {
        return parseInt(value, 10);
    }
    else {
        return decimalFormatter(getLocale()).format(value);
    }
};

const toPercent = (value) => {
    if (isNaN(value)) {
        return value;
    }
    else if (value % 1 == 0) {
        return `${parseInt(value, 10)} %`;
    }
    else {
        return `${decimalFormatter(getLocale()).format(value)} %`;
    }
};

const toWeight = (value) => {
    if (isNaN(value)) {
        return value;
    }

    return `${kilogramFormatter(getLocale()).format(value)}`;
};

const toDate = (value) => {
    if(!value)
        return ''

    const m = moment(value);
    return m.format("YYYY-MM-DD");
};

const toDateTime = (value) => {
    const m = moment(value);
    return m.format("YYYY-MM-DD HH:mm:ss");
}

const getFormatValueFromDatatype = (datatype, value, currencySign = 'standard', displayDigit = true) => {
    if (datatype == 'Currency') {
        return toCurrency(value, currencySign, displayDigit);
    }
    else if (datatype == 'Percentage') {
        return toPercent(value);
    }
    else if (datatype == 'Decimal') {
        return toDecimal(value);
    }
    else if (datatype == 'Weight') {
        return toWeight(value);
    }
    else if (datatype == 'Integer' && value % 1 != 0) {
        return toDecimal(value);
    }
    else {
        return value;
    }
};

const toLongDate = (date) => {
    return moment(date).locale(i18n.locale).format('LL');
};

export { systemCurrency, toDateTime, toLongDate, toDate, toCurrency, toAccountingCurrency, toShortCurrency, toNumber, toDecimal, toPercent, toWeight, getFormatValueFromDatatype };