<template src="./error.html"></template>

<style lang="scss">
    @import '@/core/components/layout/side-panel/scan-product/scan-product.scss';
</style>

<script>

export default {
    name: 'error',

    props:{
        icon: String,
        title: String,
        returnButtonLabel: String
    },

    mounted() {
        this.$refs.backToScan.focus();
    },

    methods: {
        backToScan() {
            this.$emit('onClose');
        },

        cancel() {
            this.$emit('onCancel');
        }
    }
}
</script>