import { EntityServices } from '@/core/store/modules/entity.services.js';

export const RefundableDeposit = {
  namespaced: true,
  name: 'RefundableDeposit',
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async saveRefundableDeposit({ commit }, refundabledeposit) {
      let result = null;
      const data = { targetModel: 'RefundableDeposit', entities: [refundabledeposit] };
      if(refundabledeposit?.id){
          result = await EntityServices.saveEntities(data);
      }
      else{
          result = await EntityServices.createEntities(data);
      }
      
      return result[0];
    }
  }
}