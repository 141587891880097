<template src="./availability-maximum-renderer.html"></template>

<style scoped lang="scss">
@import './availability-maximum-renderer.scss';
</style>

<script>

export default{
  name: 'availabilityMaximumRenderer',
  computed:{
    totalUsage(){
      return this.params?.data?.totalUsage || 0
    },
    maxUsage(){
      return this.params?.data?.maxUsage
    },
    isInfinite(){
      return this.maxUsage === undefined || this.maxUsage === null
    }
  }
};
</script>