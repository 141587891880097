<template src="./default-template.html"></template>

<script>
export default{
  name: 'default-template',

  props: {
    params: Object
  }
};
</script>
