<template inline-template v-cloak src="./notification-banner.html"></template>

<style scoped lang="scss">
@import "./notification-banner.scss";
</style>

<script>

export default {
    name: 'NotificationBanner',
    props: {
        message: String,
        action: String
    },
    methods: {
        doAction(){
            this.$emit('on-action')
        }
    },
    computed: {
        hasAction(){
            return Boolean(this.action);
        }
    }
}

</script>