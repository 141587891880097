import dashboard from '@/backoffice/modules/dashboard/dashboard.vue'
import main from '@/backoffice/modules/dashboard/pages/main/main.vue'

const dashboardRouter = {  
  path: '/bi',
  component: dashboard,
  meta: {
    title: 'Dashboard'
  },
  children: [
    {
      path: '',
      name: 'dashboard',
      component: main,
      meta: {
        requiresAuth: true,
        sideNav: {
          image: 'fal fa-home',
          label: 'SideNav.Dashboard',
          orderIndex: 0
        }
      }
    }
  ]
}

export default dashboardRouter;