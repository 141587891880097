const aggregateTypes = {
    avg: {code: "avg", desc: "Reporting.Aggregation.avg"},
    count: {code: "count", desc: "Reporting.Aggregation.count"},
    max: {code: "max", desc: "Reporting.Aggregation.max"},
    min: {code: "min", desc: "Reporting.Aggregation.min"},
    sum: {code: "sum", desc: "Reporting.Aggregation.sum"}
};
  
const aggregates = {
    text: [aggregateTypes.max, aggregateTypes.min, aggregateTypes.count],
    numeric: [aggregateTypes.sum, aggregateTypes.avg, aggregateTypes.count, aggregateTypes.max, aggregateTypes.min],
    date: [aggregateTypes.max, aggregateTypes.min, aggregateTypes.count],
    datetime: [aggregateTypes.max, aggregateTypes.min, aggregateTypes.count],
    boolean: [aggregateTypes.max, aggregateTypes.min, aggregateTypes.count],
    integer: [aggregateTypes.sum, aggregateTypes.avg, aggregateTypes.count, aggregateTypes.max, aggregateTypes.min]
};

export { aggregates }