<template src="./inventory-count.html"></template>

<style lang="scss">
    @import './inventory-count.scss';
</style>

<script>
import { mapGetters, mapActions } from 'vuex';
import prompts from '@/core/tools/notifications/notifications';
import { DocumentStatus, DocumentType } from '@/backoffice/modules/inventory/domain/document';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import inventoryScan from '@/backoffice/modules/inventory/components/side-panel/inventory-product-scan/inventory-product-scan.vue';
import { ValidationObserver, ValidationProvider, localize } from "vee-validate";

export default {
    name: 'inventory-count-template',
    components: {
        ValidationObserver,
        ValidationProvider
    },
    props:{
        template: undefined,
        templatePayload: undefined
    },

    data() {
        return {
            preventSave: false,
            document: {
                iD: null, 
                targetLayerID: null, 
                documentNO: null,
                documentType: null, 
                documentStatus: null, 
                documentDate: null, 
                documentDatetime: null, 
                processDate: null, 
                userID: null, 
                userUsername: null, 
                userEmail: null, 
                userName: null, 
                terminalID: null, 
                comment: "", 
                description: "",
                documentBodies: []
            },
            documentCopy: null
        }
    },
    computed:{
        ...mapGetters('Inventory', ['getDocument']),
        ...mapGetters('Account', ['getUser', 'getCurrentTargetLayerId']),
        edited(){
            return this.documentCopy && !isEqual(this.documentCopy, this.document);
        }
    },

    watch:{
        edited(){
            this.$sidePanel.isEdit(this.edited);
        }
    },

    mounted(){
        localize(this.$i18n.locale);

        this.document.documentType = DocumentType.Count;
        this.document.documentStatus = DocumentStatus.Open;
        this.document.userID = this.getUser._UserID;
        this.document.targetLayerID = this.getCurrentTargetLayerId;
        this.document.userUsername = this.getUser.username;
        this.document.username = `${this.getUser.firstName} ${this.getUser.lastName}`;
        this.document.userEmail = this.getUser.email;

        this.documentCopy = cloneDeep(this.document);
    },

    methods: {
        ...mapActions('Inventory', ['addDocument']),
        async saveDocument(){
            try{
                this.preventSave = true;
                await this.addDocument(this.document);
                
                this.$sidePanel.show([{ component: inventoryScan }], null, { backgroundIsDisabled: true, isPreventAutoClose: true });
                this.$router.push({ name: 'document', params: { id: this.getDocument.id } });
            }
            catch(e){
                prompts.error({
                    text: this.$t('Inventory.Messages.SaveDocumentError')
                });
            }

            this.preventSave = false;
        },
        cancel(){
            this.$sidePanel.close();
        }
    }
}
</script>