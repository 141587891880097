<template inline-template v-cloak src="./column-aggregate-picker.html"></template>
<style lang="scss" src="./column-aggregate-picker.scss"></style>

<script>
import { aggregates } from '@/backoffice/domain/aggregate-definitions.js';
import multiselect from 'vue-multiselect';
import { mapGetters } from 'vuex';
import modelColumnPicker from '@/core/components/common/model-column-picker/model-column-picker.vue'

export default {
  name: 'columnAggregatePicker',
  model: {
    prop: 'columnAggregate'
  },
  components:{
    modelColumnPicker,
    multiselect
  },
  props: {
    columnAggregate: Object,
    model: Object,
    required: Boolean,
    propertyTypesFilter: Array,
    aggregateFilter: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      modelColumnPickerOptions: [],
      modelProperties: [],
      propertyAggregates: []
    }
  },
  computed: {
    ...mapGetters('DataModels', ['getPropertiesByType', 'getModelColumnPickerOptions']),
    aggregate: {
      get() {
        return this.columnAggregate && this.propertyAggregates.find(ag => ag.code == this.columnAggregate.aggregate) || null;
      },
      set(value) {
        this.$emit("input", {...this.columnAggregate, aggregate: value.code});
      }
    }
  },
  watch: {
    'model.name': {
      handler(newVal, oldVal) {
        if(newVal && newVal != oldVal) {
          this.initialize();
        }
      }
    }
  },
  mounted() {
    this.initialize();
  },
  methods:{
    initialize(){
      this.modelColumnPickerOptions = this.getModelColumnPickerOptions(this.model.name, this.propertyTypesFilter);

      this.modelProperties = this.getPropertiesByType(this.model.name, this.propertyTypesFilter);

      const column = this.columnAggregate ? this.columnAggregate.column : null;
      this.setPropertyAggregates(column);
    },
    setPropertyAggregates(column){
      const property = this.modelProperties.find(prop => prop.name == column);

      if (property && property.type && aggregates[property.type]) {
        if (this.aggregateFilter.length == 0) {
          this.propertyAggregates = aggregates[property.type];
        }
        else {
          this.propertyAggregates = aggregates[property.type].filter(agg => this.aggregateFilter.includes(agg.code));
        }
      }
      else {
        this.propertyAggregates = [];
      }
    },
    columnChange(column){
      this.setPropertyAggregates(column.name);
      const aggregate = column.name && this.propertyAggregates.length && this.propertyAggregates[0].code;
      this.$emit("input", {...this.columnAggregate, column: column.name, aggregate: aggregate});
    }
  }
}
</script>
