import Vue from 'vue';

export const numbersOnly = {
  onKeypress: function (evt) {
      if (evt.charCode ==13 || evt.charCode >= 48 && evt.charCode <= 57) {
        return;
      }

      evt.preventDefault();
      evt.stopPropagation();
  },
  bind: function (el) {
    el.addEventListener('keypress', numbersOnly.onKeypress);
  },
  unbind: function (el) {
    el.removeEventListener('keypress', numbersOnly.onKeypress);
  }
}
Vue.directive('numbers-only', numbersOnly)
