<template inline-template v-cloak src="./create-update.html"></template>
<style lang="scss" src="./create-update.scss"></style>

<script>
import manageColumns from '@/backoffice/modules/reporting/mixins/manage-columns.js';
import extractTime from '@/backoffice/modules/reporting/mixins/extract-time.js';
import Errors from "@/core/tools/errors/errors";
import prompts from "@/core/tools/notifications/notifications";
import { mapGetters, mapMutations, mapActions } from 'vuex';
import axios from 'axios';
import { ValidationObserver } from "vee-validate";
import { getCurrentLanguageDescription } from '@/core/functions';
import { RouteType } from '@/core/router/route-type.js'
import { Action } from '@/backoffice/modules/reporting/router/action.js'
import { aggregates } from '@/backoffice/domain/aggregate-definitions.js';

import grid from '@/core/components/common/grid/grid.vue'
import step from '@/core/components/common/step/step.vue'
import periodPicker from '@/backoffice/modules/reporting/components/period-picker/period-picker.vue'
import descriptionInput from '@/core/components/common/description-input/description-input.vue'
import backendFilters from '@/core/components/common/backend-filters/backend-filters.vue'
import scopeDropdown from '@/core/components/common/drop-down/scope-dropdown/scope-dropdown.vue'
import roleDropdown from '@/core/components/common/drop-down/role-dropdown/role-dropdown.vue'
import columnPicker from '@/backoffice/modules/reporting/components/column-picker/column-picker.vue'

export default {
  mixins: [manageColumns, extractTime],
  name: 'CreateUpdate',
  
  props: {
    reportType: String,
    action: String,
    labels: Object
  },
  components: {
    grid,
    step,
    periodPicker,
    descriptionInput,
    backendFilters,
    scopeDropdown,
    roleDropdown,
    columnPicker,
    ValidationObserver
  },
  data() {
    return {      
      cancelToken: null,
      source: null,
      model: null,
      columns: [],
      gridOptions: {
        sideBar: true,
        overlayNoRowsTemplate: '<span class="ag-overlay-loading-center">' + this.$t('Reporting.VisualizeNoData') + '</span>',
        defaultColDef: {
          sortable: true,
          resizable: true
        },
        onGridReady: (params) => {
          params.api.showNoRowsOverlay();
      
          if(this.reportUserCustomization._ReportUserCustomizationID > 0){
            params.columnApi.applyColumnState({ state: JSON.parse(this.reportUserCustomization.customization) });
            params.columnApi.setPivotMode(this.reportUserCustomization.pivot);
            params.api.setFilterModel(JSON.parse(this.reportUserCustomization.listFilter));
          }
        }
      },
      saving: false,
      dataLoaded: false,
      currentStepIndex: 0,

      steps: []
      
    }
  },
  computed: {
    ...mapGetters('App', ['isMobile']),    
    ...mapGetters('Account', ['getUser', 'getCurrentTargetLayerId', 'getReportFavorite']),
    ...mapGetters('Reporting', ['reportDataModel', 'reportUserCustomization', 'scope']),
    ...mapGetters('DataModels', ['hasView', 'getMandatoryDateProperty', 'getPropertyByName']),
    management: function(){
      return ['Target', 'User', 'Product', 'Role'].includes(this.reportType);
    },
    viewExists(){
      return this.hasView(this.model.name);
    },
    compLabels(){
      const internalLabels={
        title:'Reporting.Title',
        descriptionLabel: 'Reporting.ReportName',
        stepsFirstLabel: this.action == Action.Update ? 'Reporting.Update' : 'Reporting.Create'
      }
      return {...internalLabels,...(this.labels||{})};
    }
  },

  watch:{
    getCurrentTargetLayerId(){
      this.backToMainReport();
    },
    'reportDataModel.descriptions' () {
      
      const currentLanguageDescription = getCurrentLanguageDescription(this.reportDataModel && this.reportDataModel.descriptions)
      if (currentLanguageDescription) {
        this.setBreadcrumbContext({ rdmDescription: currentLanguageDescription.value })
      }
    }
  },
  beforeDestroy() {
    if(this.source){
      this.source.cancel('OPERATION_CANCELED');
    }
    if(this.gridOptions.api){
      this.gridOptions.api.setRowData([]);
      this.gridOptions.api.destroy();
      this.gridOptions = null;
    }         
  },

  created(){
    this.resetStore();
  },

  mounted() {
    
    this.steps = [{label: this.compLabels.stepsFirstLabel}, {label: 'Reporting.ManageColumn'}]; 

    if(!this.management){
      this.steps.push({label: 'Reporting.Custom'});
    } 
        
    this.load();
  },
  methods: {
    ...mapActions('Account', ['fetchReportFavorite']),
    ...mapActions('Reporting', [
      'fetchReportDataModel',
      'fetchReportUserCustomization',
      'getReportDataFromRdm',
      'updateReport',
      'createReport',
      'updateReportUserCustomization',
      'createReportUserCustomization'
    ]),
    ...mapActions('DataModels', [
      'getModel'
    ]),
    ...mapMutations('Reporting', {
      resetStore: 'RESET_STORE',
      setPeriod: 'SET_PERIOD',
      setScope: 'SET_SCOPE',
      setReportDataModelRoles: 'SET_REPORT_DATA_MODEL_ROLES',
      setGenericListFilter: 'SET_GENERIC_LIST_FILTER',
      setGenericListColumn: 'SET_GENERIC_LIST_COLUMN',
      setGenericListGroup: 'SET_GENERIC_LIST_GROUP'
    }),
    ...mapMutations('Breadcrumb', {
      setBreadcrumbContext: 'SET_CONTEXT'
    }),
    async load(){
      try{
        if (this.action == Action.Update){
          await this.getReportDataModel();
          await this.getReportUserCustomization();
        }
        await this.getReportTypeModel();
        
        this.dataLoaded = true;
      }
      catch(response){
        Errors.displayApiError(response);
      }
    },

    async getReportDataModel() {
      const request = {
        _ReportDataModelID: Number.parseInt(this.$route.params._ReportDataModelID),
        getChildren: true
      };
      
      await this.fetchReportDataModel(request);
    },

    async getReportUserCustomization() {
      const request = {
        _ReportDataModelID: this.reportDataModel._ReportDataModelID,
        getChildren: true
      };

      await this.fetchReportUserCustomization(request);
    },

    async getReportTypeModel(){
      let model;
      try{
        model = await this.getModel(this.reportType);
      }
      catch(e){
        Errors.displayApiError(e);
      }
      if(model){
        this.model = model;
        this.columns = this.model.columnDefs;
      }
    },

    async getReportData() {
      //Cancel current request if there is one and create new cancel token
      if(this.source){ this.source.cancel('OPERATION_CANCELED'); }
      this.cancelToken = axios.CancelToken;
      this.source = this.cancelToken.source();

      this.gridOptions.api.showLoadingOverlay();
      const dataFilters = this.setDataFilters();

      let reportData;
      const reportDataModel = this.setReportDataModel();
      
      const request = {
        params: { modelName: this.reportType, filters: dataFilters, reportDataModel: reportDataModel}, 
        cancelToken: { cancelToken: this.source.token } 
      };
      try{
        reportData = await this.getReportDataFromRdm(request);
      }
      catch(e){
        Errors.displayApiError(e);
      }

      let data = [];
      if(reportData)
        data = reportData.map(item => this.deconstruct(item, item));

      this.gridOptions.api.setRowData(data);
      this.gridOptions.api.hideOverlay();
    },

    async createUpdateReport() {
      if (this.validateReport() && await this.validateFilters() && this.validateColumns()) {
        this.saving = true;
        const reportDataModel = this.setReportDataModel();
        try{
          //Report Data Model
          if(this.reportDataModel._ReportDataModelID > 0){
            await this.updateReport([reportDataModel]);
          }
          else{
            const newReport = await this.createReport([reportDataModel]);

            const favorite = this.getReportFavorite[this.reportType]
            if (favorite) {
              await this.fetchReportFavorite({
                ...favorite,
                reportList: [
                  ...favorite.reportList,
                  newReport
                ]
              })
            }
          }
          
          const reportUserCustomization = this.setReportUserCustomization();

          //Report User customization
          if(this.reportUserCustomization._ReportUserCustomizationID > 0){
            await this.updateReportUserCustomization([reportUserCustomization]);
          }
          else{
            await this.createReportUserCustomization([reportUserCustomization]);
          }

          //Go back to report list
          this.backToMainReport();
        }
        catch(response){
          Errors.displayApiError(response);
        }

        this.saving = false;
      }
    },

    //Component Callbacks / onReady
    async onStepChanged(index) {
      if (this.action == Action.Update || (this.validateReport() && await this.validateFilters())) {
        this.currentStepIndex = index;
      }
    },
    periodChanged(period){
      this.setPeriod(period);
    },
    onScopeChanged(target){
      this.setScope(target);
    },
    onRolesChanged(roles){
      this.setReportDataModelRoles(
        roles.map(r => ({
          _ReportDataModelID: this.reportDataModel._ReportDataModelID,
          roleID: r.iD,
          remove: r.remove
        }))
      );
    },

    onColumnsPicked(columns) {
      const hasGroupColumn = this.reportDataModel.genericListGroup.length > 0;
      this.setGenericListColumn(
        columns.map(c => {
          if (hasGroupColumn && c.aggFunc === null) {
            const cProperty = this.getPropertyByName(this.model.name, c.field);
            let aggFunc = '';

            if (cProperty && aggregates[cProperty.type] && aggregates[cProperty.type].length > 0)
              aggFunc = aggregates[cProperty.type][0].code;

            c.aggFunc = aggFunc;
          }

          return { 
            name: c.field, 
            aggFunc: c.aggFunc
          }
        })
      );

      this.gridOptions.api.setColumnDefs(this.setAgGridColumns(this.columns, this.reportDataModel.genericListColumn, this.reportDataModel.genericListGroup, null, true, false));
    },
    onColumnsGrouped(columns){
      this.setGenericListGroup(columns.map(c => c.field));
    },

    //Component Callbacks / onReady

    cancelReport() {
      if (this.action == Action.Create) {
        this.$router.push({ name: `${this.$route.meta.associatedParentRouteName}` });
      }
      else {
        this.backToMainReport();
      }
    },

    setReportDataModel() {
      return {
        DataSource: this.reportDataModel.dataSource || this.reportType,
        Descriptions: this.reportDataModel.descriptions,
        IsDeleted: this.reportDataModel.isDeleted,
        Period: this.reportDataModel.period,
        genericListColumn: this.reportDataModel.genericListColumn,
        genericListFilter: this.reportDataModel.genericListFilter,
        genericListGroup: this.reportDataModel.genericListGroup,
        _ReportDataModelID: this.reportDataModel._ReportDataModelID,
        _TargetID: this.reportDataModel._TargetID,
        _UserID: this.reportDataModel._UserID || this.getUser._UserID,
        IsList: this.management,
        reportDataModelRoles: this.reportDataModel.reportDataModelRoles
      };
    },

    //Snap ag-grid state
    setReportUserCustomization(){
      return {
        customization: JSON.stringify(this.gridOptions.columnApi.getColumnState()),
        listFilter: JSON.stringify(this.gridOptions.api.getFilterModel()),
        period: this.reportDataModel.period,
        pivot: this.gridOptions.columnApi.isPivotMode(),
        _ReportDataModelID: this.reportDataModel._ReportDataModelID,
        _ReportUserCustomizationID: this.reportUserCustomization._ReportUserCustomizationID,
        _UserID: null,
        _WidgetUserCustomizationID: null
      };
    },

    validateReport() {
      let pass = false;
      if(this.management){
        this.reportDataModel.period = "-0d";
      }
      for (let i = 0; i < this.reportDataModel.descriptions.length; i++) {
        if (!this.isEmptyOrWhiteSpace(this.reportDataModel.descriptions[i].value)) {
          pass = true;
        }
      }
      if (!this.reportDataModel.period) {
        pass = false;
      }

      if(!pass){
        prompts.warning({
          html: this.$t('Reporting.ReportIncomplete'),
          showCancelButton: false
        });
      }

      return pass;
    },

    async validateFilters(){
      const result = await this.$refs['filterValidationRef'].validate();
      if(!result){
        prompts.warning({
          html: this.$t('Filters.FiltersIncomplete'),
          showCancelButton: false
        });
      }
      return result;
    },

    validateColumns() {
      if(this.reportDataModel.genericListColumn.length > 0){
        return true;
      }
      else{
        prompts.warning({
          html: this.$t('Reporting.ColumnsIncomplete'),
          showCancelButton: false
        });
        return false;
      }
    },

    nextStepMobile() {
      if (this.currentStepIndex < (this.steps.length - 1) && this.isMobile) {
        this.currentStepIndex++;
      }
    },
    previousStepMobile() {
      if (this.currentStepIndex > 0 && this.isMobile) {
        this.currentStepIndex--;
      }
    },

    setDataFilters(){
      const dataFilters = this.reportDataModel.genericListFilter.slice();
      const dates = this.convert(this.reportDataModel.period);     

      const mandatoryDateProperty = this.getMandatoryDateProperty(this.model.name);
      
      //All reporting models should have a mandatoryDate property
      if(mandatoryDateProperty){     
        //If model has a view, we get the mandatoryDate property and build filter using it. 
        if(this.hasView(this.model.name)){
          dataFilters.push({ column: mandatoryDateProperty.name, operator: '>=', value: [dates.start] });
          dataFilters.push({ column: mandatoryDateProperty.name, operator: '<=', value: [dates.end] });
        }
        //Else, it uses a stored procedure and need the static filter StartDate, EndDate
        else{
          dataFilters.push({ column: 'StartDate', value: [dates.start] });
          dataFilters.push({ column: 'EndDate', value: [dates.end] });
        }
      }
      return encodeURIComponent(JSON.stringify(dataFilters));
    },
    backToMainReport() {
      this.$router.push({ name: `${this.$route.meta.associatedParentRouteName}${RouteType.Read}`, params: { _ReportDataModelID: this.reportDataModel._ReportDataModelID } });
    }
  }
}
</script>
