import { dashboardServices } from '@/backoffice/modules/dashboard/services';
import i18n from "@/i18n";
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';

const systemDashboardId = 0;
const getSystemDashboard = () => ({
    id: systemDashboardId,
    description: i18n.t('SideNav.Dashboard'),
    targetID: 1,
    targetDescription: 'SIR',
    canUpdate: false,
    canDelete: false
})
const getDefaultState = () => {
    return {
        dashboards: [],
        favoriteDashboard: null,
        selectedDashboard: null,
        dashboardUserCustomization: null,
        targetID: null,
        date: moment().format('YYYY-MM-DD'),
        modelTypes: []
    };
};

export const Dashboard = {
    namespaced: true,
    name: 'Dashboard',
    state: getDefaultState(),
    getters: {
        getTargetID: (state) =>{
            return state.targetID;
        },
        getDate: (state) =>{
            return state.date;
        },
        getDashboards: (state) =>{
            return state.dashboards;
        },
        getSelectedDashboard: (state) =>{
            return state.selectedDashboard;
        },
        getDashboardUserCustomization: (state) =>{
            return state.dashboardUserCustomization;
        },
        getSelectedDashboardWidgets: (state) =>{
            return state.selectedDashboard.widgets;
        },
        getFavoriteDashboard: (state) =>{
            return state.dashboardUserCustomization ? state.dashboards.find(dashboard => dashboard.id == state.dashboardUserCustomization.dashboardID) : null;
        },
        getDashboardComponentName: (state) =>{
            if (!state.selectedDashboard)
                return null;

            const isSystemDashboard = state.selectedDashboard.id == systemDashboardId;
            return isSystemDashboard ? 'systemDashboard' : 'dynamicDashboard';
        },
        getModelTypes: (state) => {
            return state.modelTypes;
        },
        systemDashboard: () =>{
            return getSystemDashboard();
        },
    },
    mutations: {
        //Reset store
        RESET_STORE(state){
            Object.assign(state, getDefaultState());
        },

        SET_TARGETID(state, targetID){
            state.targetID = targetID;
        },

        SET_DATE(state, date){
            state.date = date;
        },

        SET_DASHBOARDS(state, dashboards){
            state.dashboards = dashboards;
        },

        SET_SELECTED_DASHBOARD(state, dashboard){
            state.selectedDashboard = dashboard;
        },

        SET_SELECTED_DASHBOARD_WIDGETS(state, widgets){
            state.selectedDashboard.widgets = widgets;
        },

        SET_WIDGET(state, { dashboardIndex, widget }){
            Object.assign(state.dashboards[dashboardIndex].widgets[widget.i], widget);
        },

        SET_DASHBOARD_USER_CUSTOMIZATION(state, dashboardUserCustomization){
            state.dashboardUserCustomization = dashboardUserCustomization;
        },

        SET_MODEL_TYPES(state, types) {
            state.modelTypes = types;
        },

        UPDATE_DASHBOARD(state, { dashboard, index }){
            Object.assign(state.dashboards[index], dashboard);
        }
    },
    actions: {
        async fetchDashboards({ commit }){
            const getChildren = true;
            const filters = [];
            const response = await dashboardServices.fetchDashboards(getChildren, filters);
        
            if(response.status == 200) {
                const dashboards =  response.data.map(dashboard => {
                    return { ...dashboard, ...{ widgets: JSON.parse(dashboard.widgets)} };
                });

                commit('SET_DASHBOARDS', [getSystemDashboard(), ...dashboards]);
            }
            else {
                commit('SET_DASHBOARDS', [getSystemDashboard()]);
                throw response;
            }
        },

        async fetchModelTypes({ commit }) {
            const response = await dashboardServices.fetchModelTypes();

            if (response.status == 200) {
                commit('SET_MODEL_TYPES', response.data);
                return response.data;
            }
            else {
                commit('SET_MODEL_TYPES', []);
                throw response;
            }
        },

        async saveDashboard({ commit, state }, dashboard) {
            let dash = cloneDeep(dashboard);
            if (typeof dash.widgets != 'string') {
                dash.widgets = JSON.stringify(dash.widgets);
            }

            let response = null;
            if (dashboard.id == null) {
                response = await dashboardServices.addDashboard(dash);
            }
            else {
                response = await dashboardServices.updateDashboard(dash);
            }

            if (response.status == 200) {
                dash =  response.data[0];
                dash.widgets = JSON.parse(dash.widgets);

                if (dashboard.id == null) {
                    commit('SET_DASHBOARDS', state.dashboards.concat(response.data));
                }
                else {
                    const dashboardIndex = state.dashboards.findIndex(d => d.id == dash.id);
                    commit('UPDATE_DASHBOARD', { dashboard: dash, index: dashboardIndex });
                }

                return dash;
            }
            else {
                throw response;
            }
        },

        async saveWidget({ commit, state }, widget) {
            const dashboard = cloneDeep(state.selectedDashboard);
            dashboard.widgets[widget.i] = widget;
            dashboard.widgets = JSON.stringify(dashboard.widgets);

            const response = await dashboardServices.updateDashboard(dashboard);

            if (response.status == 200) {
                const dashboardIndex = state.dashboards.findIndex(d => d.id == dashboard.id);
                commit('SET_WIDGET', { dashboardIndex: dashboardIndex, widget: widget });

                return response.data[0];
            }
            else {
                throw response;
            }
        },

        async deleteDashboard({ commit }, dashboard) {
            const response = await dashboardServices.deleteDashboard(dashboard.id);
            if (response.status == 200) {
                commit('SET_SELECTED_DASHBOARD', {});
            }
            else {
                throw response;
            }
        },


        async fetchDashboardUserCustomization({ commit, rootGetters }){
            const targetLayer = rootGetters['Account/getCurrentTargetLayer'];
            const user = rootGetters['Account/getUser'];
            const filters = [
                { Column: 'TargetLayerID', Value: [targetLayer._TargetLayerID] },
                { Column: 'UserID', Value: [user._UserID] },
            ]
            const response = await dashboardServices.fetchDashboardUserCustomization(false, filters);

            if (response.status >= 200 && response.status <= 299) {
                commit('SET_DASHBOARD_USER_CUSTOMIZATION', response.data);
            }
            else {
                throw response;
            }
        },

        async saveDashboardUserCustomization({commit}, dashboardUserCustomization){
            let response;

            if (dashboardUserCustomization.id == null) {
                response = await dashboardServices.addDashboardUserCustomization(dashboardUserCustomization);
            }
            else {
                response = await dashboardServices.updateDashboardUserCustomization(dashboardUserCustomization);
            }

            if (response.status == 200) {
                commit('SET_DASHBOARD_USER_CUSTOMIZATION', response.data[0]);
            }
            else {
                throw response;
            }
        }
    }
}