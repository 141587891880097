<template src="./panel-renderer.html"></template>
<style scoped lang="scss">
@import './panel-renderer.scss';
</style>

<script>
export default{
  name: 'panel-renderer',
  data() {
    return {
      value: null
    }
  },
  mounted() {
    this.value = this.params.valueFormatted || this.params.value;
  },
  methods: {
    openPanel(){
      this.params.openSidePanel(this.params.data);
    }
  }
};
</script>
