import { render, staticRenderFns } from "./language-input.html?vue&type=template&id=6fe203a3&scoped=true&"
import script from "./language-input.vue?vue&type=script&lang=js&"
export * from "./language-input.vue?vue&type=script&lang=js&"
import style0 from "./language-input.scss?vue&type=style&index=0&id=6fe203a3&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fe203a3",
  null
  
)

export default component.exports