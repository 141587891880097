import i18n from '@/i18n';

const rdmCreateUpdateBreadcrumbTailFormatter = (ctx, action) => {

  if (ctx.rdmDescription) {
    return `${ctx.rdmDescription} (${i18n.t(action)})`;
  }
  return i18n.t(action);
};

export const rdmBreadcrumbTailFormatter = ctx => ctx.reportDataModel && ctx.reportDataModel.description;
export const rdmCreateBreadcrumbTailFormatter = (ctx) => rdmCreateUpdateBreadcrumbTailFormatter(ctx, 'Action.Create')
export const rdmUpdateBreadcrumbTailFormatter = (ctx) => rdmCreateUpdateBreadcrumbTailFormatter(ctx, 'Action.Update')