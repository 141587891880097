import axios from 'axios'

const FileServer = () => {
  return axios.create({
    baseURL: "/",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
    }
  })
}

export { FileServer }