<template src="./user-access-list.html"></template>

<style lang="scss">
@import './user-access-list.scss';
</style>

<script>
import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { EntityServices } from '@/core/store/modules/entity.services.js';
import { userAccessServices } from '@/core/services/userAccess/userAccess.services';
import { mapActions, mapGetters } from 'vuex';
import prompts from '@/core/tools/notifications/notifications';
import {pagePrinter, getQrCodeHtmlTemplate} from '@/core/functions/utils.js'
import grid from '@/core/components/common/grid/grid.vue'
import scopeDropdown from '@/core/components/common/drop-down/scope-dropdown/scope-dropdown.vue'

export default {
    name: 'user-access-list',
    components: {
        grid,
        scopeDropdown
    },

    props: {
        templatePayload: Object
    },

    data() {
        return {
            accessGridOptions: {
                rowSelection: "single",
                columnDefs: [
                    { cellRenderer: this.accessCellRenderer, autoHeight: true, cellStyle: { 'padding': '5px 10px', 'white-space': 'normal', 'line-height': 'normal' } },
                    {
                        cellRenderer: 'optionsRenderer',
                        cellRendererParams: {
                            toggle: this.toggle,
                            editEntity: this.accessSelectionChanged,
                            delete: this.delete
                        },
                        cellClass: 'ag-cell-overflow',
                        width: 30
                    }
                ],
                suppressCellFocus: true,
                onGridReady: () => {
                    this.getAccess();
                },
                onGridSizeChanged: (params) => {
                    params.api.sizeColumnsToFit();
                }
            },
            targetLayerGridOptions: {
                rowHeight: 60,
                rowSelection: "single",
                columnDefs: [
                    { field: 'list', cellRenderer: 'detailRenderer', width: 420, getQuickFilterText: this.quickFilterText }
                ],
                suppressCellFocus: true,
                onGridReady: () => {
                    this.targetLayerGridPromiseResolver();
                },
                onSelectionChanged: this.targetLayerSelectionChanged
            },
            roleGridOptions: {
                rowHeight: 60,
                rowSelection: "single",
                columnDefs: [
                    {
                        field: 'description',
                        flex: 1,
                        cellStyle: { 'display': 'flex', 'align-items': 'center', 'padding-left': '20px' }
                    },
                    {
                        field: 'toggled',
                        cellRenderer: 'toggleRenderer',
                        width: 60,
                        valueSetter: params => {
                            this.roleGridCellValueChanged(params.data)
                            return true;
                        }
                    }
                ],
                suppressCellFocus: true,
                suppressRowClickSelection: true,
                onGridReady: () => {
                    this.loadRoles();
                },
                onGridSizeChanged: (params) => {
                    params.api.sizeColumnsToFit();
                }
            },

            targetLayerGridPromiseResolver: null,
            targetChangedEventPromiseResolver: null,
            componentInitialized: null,

            accessList: [],
            formattedAccessList: [],

            selectedAccess: null,
            selectedScopeTargetID: null,
            selectedScopeTargetLayerID: null,
            selectedTargetID: null,
            selectedTargetLayerID: null,
            selectedRoles: {},
            roles: {},
            rolesCopy:[],
            filter: '',
            cancelToken: {},
            source: {},
            mode: null,
            saving: false,
            currentStepIndex: 0,
            permissions: ["USER_ACCESS_CUD"],
            componentKey: 0
        }
    },

    computed: {
        ...mapGetters('App', ['isMobile']),
        ...mapGetters('Role', ['getRoles']),
        ...mapGetters('Account', ['getCurrentTargetLayerId']),

        rolesData() {
            return Object.values(this.roles)
        },
        hasNoRoles() {
            return !this.rolesData.some(role => role.toggled);
        },
        saveEnabled() {
            return this.mode === 'create' ? true : this.rolesData.some(r => (r.toggled && this.rolesCopy.find(b => b.roleId == r.roleId && b.toggled)===undefined));
        },
        posCode(){
            return this.selectedAccess?.posCode ? this.selectedAccess.posCode : this.$t('User.Access.Codena')
        },
        hasPosCode(){
            return this.selectedAccess?.posCode?true:false
        },
        qrCode(){
            return this.selectedAccess?.qrCode? 'data:image/bmp;base64,'+this.selectedAccess.qrCode:''
        },
        hasPassword(){
            return this.selectedAccess? this.selectedAccess.qrCode && this.selectedAccess.posCode:false
        }
    },

    watch: {
        templatePayload: {
            handler(newVal, oldVal) {
                if (!isEqual(newVal, oldVal)) {
                    this.getAccess();
                }
            }
        },
        currentStepIndex(){
            this.componentKey++
        }
    },

    created() {
        this.cancelToken = axios.CancelToken;
        this.source = this.cancelToken.source();

        Promise.all([
                new Promise(resolve => {
                    this.targetLayerGridPromiseResolver = resolve;
                }),
                new Promise(resolve => {
                    this.targetChangedEventPromiseResolver = resolve;
                })
            ]).then(async () => {
                await this.getTargetLayer();
                this.componentInitialized = true;
            });
    },

    beforeDestroy() {
        this.source.cancel('Operation canceled by the user.');
    },

    methods: {
        ...mapActions('Role', ['fetchRoles']),
        setCurrentStepIndex(index){
            this.currentStepIndex = index;
        },
        addAccess() {
            this.$sidePanel.isEdit(true);
            this.mode = 'create';
            this.setCurrentStepIndex(1);
        },
        async cancel() {
            if (this.mode) {

                const promptResult = await prompts.warning({
                    html: this.$t('General.Messages.Revert')
                });

                if (promptResult.isConfirmed) {
                    this.revert();
                    return;
                }
            }
            else {
                this.revert();
            }
        },
        revert() {
            this.$sidePanel.isEdit(false);
            this.setCurrentStepIndex(0);
            this.mode = null;
            this.selectedAccess = null
            this.selectedScopeTargetID = null;
            this.selectedScopeTargetLayerID = null;
            this.selectedTargetID = null;
            this.selectedTargetLayerID = null;
            this.selectedRoles = {};
            this.roles = {};
        },

        async saveAccess() {
            const userAccessID = this.selectedAccess ? this.selectedAccess.userAccessID : null;
            const roles = this.rolesData.filter(r => 
                r.toggled && this.rolesCopy.find(b => b.roleId == r.roleId && b.toggled) === undefined
            )
            const userAccess = {
                id: userAccessID,
                TargetID: this.selectedTargetID,
                ScopeTargetID: this.selectedScopeTargetID,
                UserID: this.templatePayload._UserID,
                UserAccessRoles: roles.map(r => {
                    return {
                        UserAccessID: userAccessID,
                        RoleID: r.roleId,
                        Remove: r.remove === true
                    }
                 })
            }
            let result = null;
            this.saving = true;
            try {
                if (userAccess.id) {
                    result = await userAccessServices.saveUserAccess(userAccess);
                }
                else {
                    result = await userAccessServices.createUserAccess(userAccess);
                }
            }
            catch (error) {
                if(error?.response?.data?.error ==='RoleV1Missing'){
                    prompts.error({ html: this.$t('User.Access.AddUserAccessError')});
                }else{
                    prompts.error({
                        text: this.$t('User.Access.SaveUserAccessError')
                    });
                }
            }

            this.saving = false;

            if (result) {
                this.$sidePanel.update({ 'save': true, 'row': result[0] })
                this.revert();
            }
        },

        async getAccess() {
            this.accessGridOptions.api.showLoadingOverlay();
            let userAccesses;
            try {
                userAccesses = await userAccessServices.getUserAccesses(this.templatePayload._UserID);
            }
            catch (error) {
                prompts.error({
                    text: this.$t('User.Access.GetUserAccessesError')
                });
            }

            if (userAccesses) {
                this.accessList = cloneDeep(userAccesses);
                this.formattedAccessList = this.setAccessList(userAccesses);
                this.accessGridOptions.api.setRowData(this.formattedAccessList);
                this.accessGridOptions.api.sizeColumnsToFit();
            }
            this.accessGridOptions.api.hideOverlay();
        },

        setAccessList(rows) {
            const accesses = [];

            const uniqueUserAccessIDs = [...new Set(rows.map(row => row.userAccessID))];
            for (let i = 0; i < uniqueUserAccessIDs.length; i++) {
                const access = rows.find(row => row.userAccessID == uniqueUserAccessIDs[i]);
                access.roleDescription = [...new Set(rows.filter(row => row.userAccessID == uniqueUserAccessIDs[i]).map(row => row.roleDescription))].join(', ');
                accesses.push(access);
            }

            accesses.sort(this.compare);

            return accesses;
        },

        accessCellRenderer(params) {
            let html = '';
            html += '<div style="font-weight: bold; font-size: 16px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">' + params.data.targetLayerDescription + '</div>';
            html += '<div style="font-weight: bold; font-size: 13px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">' + params.data.targetDescription + '</div>';
            html += '<div style="display:flex; width:100%; justify-content: space-between; margin-top: 5px;">' 
                    + `<span>${params.data.roleDescription}</span><span> ${(params.data.posCode?this.$t('User.Access.Code')+' : ':'')}${(params.data.posCode?params.data.posCode:'')}</span>` + '</div>';
            return html;
        },

        compare(accessA, accessB) {
            if (accessA.targetLayerDescription.toLowerCase() == accessB.targetLayerDescription.toLowerCase())
                return (accessA.targetDescription.toLowerCase() < accessB.targetDescription.toLowerCase()) ? -1 : (accessA.targetDescription.toLowerCase() > accessB.targetDescription.toLowerCase()) ? 1 : 0;

            return (accessA.targetLayerDescription.toLowerCase() < accessB.targetLayerDescription.toLowerCase()) ? -1 : (accessA.targetLayerDescription.toLowerCase() > accessB.targetLayerDescription.toLowerCase()) ? 1 : 0;
        },
        
        onScopeChanged(target) {
            this.selectedScopeTargetID = target.id;
            this.selectedScopeTargetLayerID = target.tlid;
            if (!this.componentInitialized) {
                this.targetChangedEventPromiseResolver()
            }
            else {
                this.getTargetLayer();
            }
        },

        async getTargetLayer() {
            let result;
            try {
                result = await EntityServices.getEntities({ getChildren: false, modelName: 'Target', filters: [], params: 'targetLayerID=' + this.selectedScopeTargetLayerID });
            }
            catch (error) {
                prompts.error({
                    text: this.$t('User.Access.GetTargetsError')
                });
            }

            if (result) {
                const targetlayerList = this.setTargetLayerList(result);
                this.targetLayerGridOptions.api.setRowData(targetlayerList);
                this.targetLayerGridOptions.api.sizeColumnsToFit();
            }
        },

        setTargetLayerList(scopes) {
            const targetlayers = [];
            let found = false;
            for (let i = 0; i < scopes.length; i++) {
                found = false;

                for (let j = 0; j < this.accessList.length; j++) {
                    if (scopes[i]._TargetID == this.accessList[j]._TargetID) {
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    scopes[i].mainData = scopes[i].targetLayerDescription;
                    scopes[i].detailData = scopes[i].description;
                    targetlayers.push(scopes[i])
                }
            }
            return targetlayers;
        },
        
        targetLayerSelectionChanged() {
            const rows = this.targetLayerGridOptions.api.getSelectedRows();
            this.selectedTargetID = rows[0]._TargetID;
            this.selectedTargetLayerID = rows[0]._TargetLayerID;
        },

        async loadRoles() {
            try {
                await this.fetchRoles()
            }
            catch (e) {
                prompts.error({
                    text: this.$t('Role.Messages.FetchRolesError')
                });
            }

            if (this.getRoles?.length > 0) {
                this.roles = this.getRoles.reduce((roles, role) => {
                    roles[role.roleId] = {
                        ...role,
                        toggled: false,
                        ...(this.selectedRoles[role.roleId] || {})
                    };

                    return roles;
                }, {});
                this.roleGridOptions.api.sizeColumnsToFit();
            }
            this.rolesCopy = cloneDeep(this.rolesData)
        },
        async accessSelectionChanged() {
            const rows = this.accessGridOptions.api.getSelectedRows();
            this.selectedAccess = rows[0];
            this.selectedTargetID = this.selectedAccess._TargetID;
            this.selectedScopeTargetID = this.selectedAccess.scopeTargetID;
            this.selectedTargetLayerID = this.selectedAccess._TargetLayerID;

            this.selectedRoles = this.accessList.reduce((roles, access) => {
                if (access.userAccessID == this.selectedAccess.userAccessID) {
                    roles[access.roleID] = {
                        roleId: access.roleID,
                        targetID: access._TargetID,
                        description: access.roleDescription,
                        toggled: true,
                        remove: false
                    };
                }

                return roles;
            }, {});

            this.setCurrentStepIndex(2);
        },
        roleGridCellValueChanged(role) {
            const revertToggle = !this.roles[role.roleId].toggled;
            for(const roleId in this.roles){
                if(roleId != role.roleId){
                    if(this.roles[roleId].toggled){
                        this.roles[roleId].remove = true
                    }
                    this.roles[roleId].toggled = false
                }else{
                    this.roles[roleId].remove = !revertToggle
                    this.roles[roleId].toggled = revertToggle
                }
            }
        },
        quickFilterText(params) {
            return params.data.mainData + ' ' + params.data.detailData;
        },
        addFilter() {
            clearTimeout(this.filterTimer);
            this.filterTimer = setTimeout(() => {
                this.targetLayerGridOptions.api.setQuickFilter(this.filter);
            }, 500);
        },
        async delete() {
            const promptResult = await prompts.warning({
                html: this.$t('User.Access.DeleteMsg')
            });

            if (!promptResult.isConfirmed) {
                return;
            }
            const accessToDelete = [];
            const rows = this.accessGridOptions.api.getSelectedRows();

            for (let i = 0; i < rows.length; i++) {
                accessToDelete.push(rows[i].userAccessID);
            }            

            try {
                await userAccessServices.deleteUserAccess(accessToDelete);
            }
            catch {
                prompts.error({
                    text: this.$t('User.Access.DeleteUserAccessError')
                });

                return;
            }

            this.getAccess();
        },
        gotoProfile(){
            this.$router.push({ name: 'Profil' })
        },
        printQrCode(){
            const htmlContent = getQrCodeHtmlTemplate(this.$t('User.Access.QrCodePageTitle'), this.$t('User.Access.QrCodePrintButton'), this.qrCode)
            pagePrinter(htmlContent)
        }
    }
}
</script>