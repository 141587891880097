const transmissionTypeEmail = { 
    type: 'email', 
    description: 'Purchase.Order.SendOrderTemplate.TransmissionTypes.Email'
}
const transmissionTypes = [
    transmissionTypeEmail
]

export {
    transmissionTypeEmail,
    transmissionTypes
}