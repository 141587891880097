import { render, staticRenderFns } from "./horizontal-list.html?vue&type=template&id=9a1b534e&scoped=true&"
import script from "./horizontal-list.vue?vue&type=script&lang=js&"
export * from "./horizontal-list.vue?vue&type=script&lang=js&"
import style0 from "./horizontal-list.scss?vue&type=style&index=0&id=9a1b534e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9a1b534e",
  null
  
)

export default component.exports