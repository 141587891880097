<template src="./listing-block.html"></template>

<style scoped lang="scss">
@import './listing-block.scss';
</style>

<script>
import { mapActions } from 'vuex';
import axios from 'axios';
import { mapGetters } from 'vuex';

import moment from 'moment';
export default{
  name: "listing-block",
  props: ['targetID', 'date'],
  data(){
    return{
      cancelToken: {},
      source: {},
      listingBlock: {},
      activeBlockIndex: 0,
      selectedBlock: {
        title: 'Widgets.Department',
        items:[{},{},{},{},{},{},{},{},{},{}]
      }
    }
  },
  computed:{
    ...mapGetters('App', ['isMobile']),    
  },
  beforeDestroy() {
    this.source.cancel('OPERATION_CANCELED');
  },
  created(){
    this.cancelToken = axios.CancelToken;
    this.source = this.cancelToken.source();
  },
  mounted(){
    this.setWidget();
    this.getData();
  },
  methods: {
    ...mapActions('Widgets', ['getWidgetTopSales']),
    setWidget(){
      this.listingBlock = {
        classes: 'col-xlg-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-xxs-12',
        blocks: [{
          title: 'Widgets.Department',
          items:[]
        },
        {
          title: 'Widgets.SubDepartment',
          items:[]
        },
        {
          title: 'Widgets.Product',
          items:[]
        }]
      }
      this.selectedBlock = this.listingBlock.blocks[0];
    },
    async getData(){
      const currentDate = moment().format('YYYY-MM-DD');
      const request = {
        params: { date: this.date ? this.date : currentDate },
        cancelToken: { cancelToken: this.source.token },
        targetIDSelected: this.targetID
      }

      let widget = null;

      try {
        widget = await this.getWidgetTopSales(request);
      } catch (error) {
        this.setWidget();
      }

      if (widget) {
        this.listingBlock = widget;
        this.selectedBlock = this.listingBlock.blocks[0];
      }
    },
    changeTab(blockIndex) {
      this.activeBlockIndex = blockIndex;
      this.selectedBlock = this.listingBlock.blocks[this.activeBlockIndex];
    },
    changeTabMobile(direction){
      if(direction == 'left' && this.activeBlockIndex == 0){
        this.activeBlockIndex = this.listingBlock.blocks.length - 1;
        this.selectedBlock = this.listingBlock.blocks[this.activeBlockIndex];
      }
      else if(direction == 'left'){
        this.activeBlockIndex = this.activeBlockIndex - 1;
        this.selectedBlock = this.listingBlock.blocks[this.activeBlockIndex];
      }
      else if(direction == 'right' && this.activeBlockIndex == this.listingBlock.blocks.length - 1){
        this.activeBlockIndex = 0;
        this.selectedBlock = this.listingBlock.blocks[this.activeBlockIndex];
      }
      else{
        this.activeBlockIndex = this.activeBlockIndex + 1;
        this.selectedBlock = this.listingBlock.blocks[this.activeBlockIndex];
      }
    }
  }
}

</script>
