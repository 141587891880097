<template src="./chart-widget.html"></template>

<style scoped lang="scss">
@import '@/backoffice/modules/dashboard/components/dynamic-dashboard/widgets/widget.scss';
</style>
<style lang="scss">
@import './chart-widget.scss';
</style>

<script>
import { mapGetters, mapActions } from 'vuex';
import widgetConfig from '@/backoffice/modules/dashboard/components/dynamic-dashboard/widgets/widget-config/widget-config.vue';
import genericWidget from '@/backoffice/modules/dashboard/components/dynamic-dashboard/widgets/generic-widget.js';
import { stringFunction, toCurrency, toShortCurrency } from '@/core/functions';
import { genericServices } from '@/core/services/generic/generic.services';
import isEqual from 'lodash/isEqual';
import { AgChartsVue } from 'ag-charts-vue';
import * as agCharts from 'ag-charts-community';
import moment from 'moment';
import axios from 'axios';
import errors from '@/core/tools/errors/errors';
import extractTime from '@/backoffice/modules/reporting/mixins/extract-time.js';
import horizontalList from '@/core/components/common/horizontal-list/horizontal-list.vue'
import spinLoader from '@/core/components/common/spin-loader/spin-loader.vue'

const ColorChart = {
  Blue: {
    Base: '#50c8e8',
    BaseLittleDark: '#53c9e8',
    LessDark: '#598eb8',
    Dark: '#005394'
  }
};

export default {
  name: 'chart-widget',
  components:{
    horizontalList,
    spinLoader,
    AgChartsVue
  },
  props:{
    widget: Object,
    editable: Boolean
  },
  computed:{
    ...mapGetters('App', ['isMobile']),    
    ...mapGetters('Dashboard', ['getDate', 'getTargetID']),
    ...mapGetters('DataModels', ['getScopeProperty', 'getMandatoryDateProperty', 'getHeaderNameByColumnDefField', 'getPropertyByName']),
    chartConfig() {
      return this.config && this.config.widgetTypeConfig;
    },
    title() {
      return genericWidget.getTitle(this.config, this.$i18n.locale);
    }
  },
  watch:{
    getDate: {
      handler(newVal, oldVal){
        if (oldVal != newVal) {
          this.initializeData();
        }
      }
    },
    getTargetID: {
      handler(newVal, oldVal){
        if (oldVal != newVal) {
          this.initializeData();
        }
      }
    },
    'widget.configs': {
      handler(newVal, oldVal){
        const hasChange = !isEqual(newVal, oldVal);
        if (hasChange) {
          const config = (this.config && this.widget.configs.find(c => c.id == this.config.id)) || (this.widget.configs && this.widget.configs[0]);

          this.updateSelectedConfig(config);
        }
      },
      deep: true
    }
  },
  data() {
    return {
      config: null,
      dataSet: null,
      loading: false,
      periodDates: null,
      periodProperty: null,
      reportDataModel: null,
      errorResponse: null,
      model: null,
      axisDatatype: null,
      chartOptions: null,
      series: null,
      axes: null,
      interval: null,
      cancelToken: null
    }
  },
  mounted() {
    this.config = this.widget && this.widget.configs && this.widget.configs[0];
    this.initializeData();
  },
  beforeDestroy() {
    if (this.cancelToken)
      this.cancelToken.cancel('OPERATION_CANCELED');
  },
  methods: {
    ...mapActions('DataModels', ['getModel']),
    ...mapActions('Reporting', {
      fetchReportDataFromRdm: 'getReportDataFromRdm',
    }),

     initializeData() {
        if (this.cancelToken)
            this.cancelToken.cancel('OPERATION_CANCELED');
        
        this.dataSet = null;
        this.periodProperty = null;
        this.reportDataModel = null;
        this.errorResponse = null;

        if (this.config)
            this.createDynamicReportDataModel();
    },
    async getDataModel() {
        this.model = null;

        if (!this.reportDataModel.dataSource)
            return;
        
        let model;
        try {
            model = await this.getModel(this.reportDataModel.dataSource);
        }
        catch(response) {
          this.errorResponse = response;
        }
                    
        if (model){
          this.model = model;
        }
    },
    setPeriodProperty() {
      this.periodProperty = this.getMandatoryDateProperty(this.model.name);
      this.periodDates = this.periodProperty && this.reportDataModel.period && extractTime.methods.convert(this.reportDataModel.period, this.getDate);
    },
    getFilters() {
        const filters = [];

        if (this.periodProperty && this.reportDataModel.period && this.periodDates) {
            filters.push({ column: this.periodProperty.name, operator: '>=', value: [this.periodDates.start] });
            filters.push({ column: this.periodProperty.name, operator: '<=', value: [this.periodDates.end] });
        }

        const scopeProperty = this.getScopeProperty(this.model.name);
        if(scopeProperty && scopeProperty.name){
            filters.push({ Model: 'TargetLink', MainColumn: scopeProperty.name, JoinedColumn: '_TargetLayerID', Column: '_TargetID', Value: [this.getTargetID] });
        }  
        return filters;
    },
    displayApiError() {
      errors.displayApiError(this.errorResponse);
    },
    setChartOptions() {
      if (!this.chartConfig)
        return null;

      this.setIntervalBins();
      this.setSeries();
      this.setAxes();

      this.chartOptions = {
        padding: {
          top: this.isMobile ? 15 : 20,
          right: this.isMobile ? 12 : 20,
          bottom: this.isMobile ? 15 : 20,
          left: this.isMobile ? 12 : 20
        },
        legend: { enabled: false },
        data: this.dataSet,
        series: this.series,
        axes: this.axes
      };
    },
    setIntervalBins() {
      const interval = [];

      const time = moment(`${this.periodDates.start} ${this.chartConfig.xAxis.min}`);
      const endTime = moment(`${this.periodDates.end} ${this.chartConfig.xAxis.max}`);

      if (endTime.hour() == 0)
        endTime.add(24, 'h').add(-1, 's');

      while(endTime.diff(time, 'minutes') > 0) {
        interval.push([time.toDate(), time.add(this.chartConfig.xAxis.interval, 'm').add(-1, 's').toDate()]);
        time.add(1, 's');
      }

      this.interval = interval;
    },
    setSeries() {
      const series = [];
      
      const options = {
          xKey: 'x',
          xName: this.$t(this.getHeaderNameByColumnDefField(this.model.name, this.chartConfig.xAxis.column)),
          yKey: 'y',
          yName: this.$t(this.getHeaderNameByColumnDefField(this.model.name, this.chartConfig.yAxis.column)),
          tooltip: {
            renderer: this.tooltipRenderer
          }
        };

      series.push({ type: 'histogram', bins: this.interval, fill: ColorChart.Blue.Dark, stroke: ColorChart.Blue.LessDark, highlightStyle: { fill: ColorChart.Blue.Base, stroke: ColorChart.Blue.LessDark }, ...options});

      if (this.chartConfig.displayCurve)
        series.push({ type: 'line', stroke: ColorChart.Blue.BaseLittleDark, strokeWidth: 3, tick: { count: this.dataSet.length }, marker: { size: 5, fill: ColorChart.Blue.BaseLittleDark, strokeWidth: 0 }, highlightStyle: { fill: undefined, stroke: undefined }, ...options});

      this.series = series;
    },
    setAxes() {
      const yAxis = {
        type: 'number',
        position: 'left',
        label: {
          fontFamily: 'sans-serif',
          formatter: this.labelYFormatter
        }
      };
      const xAxis = {
        type: 'time',
        position: 'bottom',
        tick: {
          count: agCharts.time.hour.every((this.chartConfig.xAxis.interval / 60))
        },
        label: {
          fontSize: this.isMobile ? 10 : 12,
          fontFamily: 'sans-serif',
          rotation: this.isMobile ? -50 : 0,
          formatter: this.labelXFormatter
        }
      };
            
      this.axes = [xAxis, yAxis];
    },
    async createDynamicReportDataModel() {
      this.reportDataModel = genericWidget.createReportDataModel(this.config);

      this.loading = true;

      this.axisDatatype = null;
      this.chartOptions = null;

      await this.getDataModel();
      
      this.reportDataModel.genericListGroup.push(stringFunction.camelize(this.chartConfig.xAxis.column));

      this.reportDataModel.genericListColumn.push({ name: stringFunction.camelize(this.chartConfig.xAxis.column) });
      this.reportDataModel.genericListColumn.push({ name: stringFunction.camelize(this.chartConfig.yAxis.column), aggFunc: this.chartConfig.yAxis.aggregate });

      await this.getData();

      if (this.dataSet && this.dataSet.length > 0) {
        const xAxisProperty = this.getPropertyByName(this.model.name, this.chartConfig.xAxis.column);
        const yAxisProperty = this.getPropertyByName(this.model.name, this.chartConfig.yAxis.column);

        this.axisDatatype = {
          x: xAxisProperty ? xAxisProperty.dataType : null,
          y: yAxisProperty ? yAxisProperty.dataType : null
        };
        
        this.setChartOptions();
      }

      this.loading = false;
    },
    async getData() {
      if (!this.model)
        return;

      this.setPeriodProperty();

      this.reportDataModel.genericListFilter = this.reportDataModel.genericListFilter.concat(this.getFilters());

      const serieOptions = {
        interval: this.chartConfig.xAxis.interval,
        min: this.chartConfig.xAxis.min,
        max: moment.duration(this.chartConfig.xAxis.max).hours() == 0 ? '23:59:59' : this.chartConfig.xAxis.max,
        xAxis: this.chartConfig.xAxis.column,
        yAxis: this.chartConfig.yAxis.column,
        transformation: stringFunction.pascalize(this.chartConfig.yAxis.aggregate)
      };

      this.cancelToken = axios.CancelToken.source();

      let responseData = null;

      try {
        responseData = await genericServices.getTimeSeries(this.config.model, { reportDataModel: this.reportDataModel , serieOptions: serieOptions }, { cancelToken: this.cancelToken.token });
      }
      catch(response) {
        this.errorResponse = response;
      }
              
      if (responseData.status == 200) {
        this.cancelToken = null;
        this.dataSet = responseData.data.data;
      }
    },
    updateSelectedConfig(config) {
      this.config = config;

      this.initializeData();
    },
    openSidePanel() {
      const data = { widget: this.widget, selectedConfig: this.config };
      this.$sidePanel.show([{ component: widgetConfig }], data);
    },
    getFormattedValue(value) {
      if (this.axisDatatype.y == 'Currency')
        return toCurrency(value, 'accounting', false);

      return value;
    },
    tooltipRenderer(params) {
      const date = Array.isArray(params.xValue) ? `${moment(params.xValue[0]).hour()}H00 - ${moment(params.xValue[1]).add(1, 's').hour()}H00` : `${moment(params.xValue).hour()}H00`;
        return {
          title: date,
          content: this.getFormattedValue(params.yValue)
        };
    },
    labelYFormatter(params) {
      if (this.axisDatatype.y == 'Currency')
        return toShortCurrency(params.value, 'accounting');

      return params.value;
    },
    labelXFormatter(params) {
      return `${params.value.getHours()}h${String(params.value.getMinutes()).padStart(2, '0')}`;
    }
  }
}
</script>
