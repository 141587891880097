<template src="./receiving-product.html"></template>

<style lang="scss">
    @import "./receiving-product.scss";
</style>

<script>
import { mapGetters, mapActions } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import prompts from '@/core/tools/notifications/notifications';
import toggle from '@/core/components/common/toggle/toggle.vue';
import { CurrencyInput  } from 'vue-currency-input';
import ProductCostsBody from '@/core/components/layout/side-panel/product-costs-body/product-costs-body.vue';
import { ReceivingStatus } from '@/backoffice/modules/purchase/domain/purchase.js';
import receivingProductScan from '@/backoffice/modules/purchase/components/sidePanel/receivings/receiving-product-scan/receiving-product-scan.vue';
import { TOGGLE_STATE } from '@/core/components/common/toggle/toggle.const.js'

export default {
    name: 'receiving-product',

    props: {
        templatePayload: Object
    },

    components: {
        CurrencyInput,
        toggle,
        ProductCostsBody
    },

    data() {
        return {
            bodyInfo: {},
            editedField: null,
            field: {
                qty: 'qty',
                unitCost: 'unitCost'
            },
            saveInProgress: false,
            currencyInputConfigs: {
                currency: null,
                locale: this.$i18n.locale,
                precision: 2,
                distractionFree: false
            }
        };
    },

    computed: {
        ...mapGetters('Receiving', ['getReceiving', 'getRelatedReceivings', 'getRelatedOrders']),

        receivingNO() {
            return this.getReceiving.receivingNO;
        },

        readOnly() {
            return !this.getReceiving.canUpdate || this.getReceiving.receivingStatus != ReceivingStatus.OPEN;
        },

        ordersNO() {
            return new Set(this.consolidatedReceivingBodiesByUOM.flatMap(body => body.receivingBodies).map(rrb => rrb.order.orderNO));
        },

        relatedOrdersInfo() {
            return this.getRelatedOrders.reduce((ordersObj, o) => {
                ordersObj[o.id] = {
                    id: o.id,
                    orderNO: o.orderNO,
                    createdDatetime: o.createdDatetime,
                    orderBodies: o.orderBodies.reduce((bodiesObj, b) => {
                        bodiesObj[b.id] = {
                            id: b.id,
                            qty: b.qty,
                            unitCost: b.unitCost
                        };

                        return bodiesObj;
                    }, {})
                };

                return ordersObj;
            }, {});
        },

        relatedReceivingsInfo() {
            return this.getRelatedReceivings.reduce((receivingsObj, r) => {
                if (!receivingsObj[r.orderBodyID]) {
                    receivingsObj[r.orderBodyID] = {
                        orderBodyID: r.orderBodyID,
                        qty: r.qty
                    };
                }
                else {
                    receivingsObj[r.orderBodyID].qty += r.qty;
                }

                return receivingsObj;
            }, {});
        },

        consolidatedReceivingBodiesByUOM() {
            if (!this.templatePayload.itemUPC || !this.getReceiving || !this.getReceiving.receivingBodies) {
                return [];
            }

            return this.getReceiving.receivingBodies
                .filter(body => body.purchaseBodyInfo.itemUPC == this.templatePayload.itemUPC)
                .reduce((consolidatedBodiesByUOM, refBody) => {
                    const existBody = consolidatedBodiesByUOM.find(p => p.purchaseBodyInfo.uomID == refBody.purchaseBodyInfo.uomID);
                    const relatedOrder = this.relatedOrdersInfo[refBody.orderID];
                    const relatedOrderBody = relatedOrder.orderBodies[refBody.orderBodyID];
                    const totalReceived = (this.relatedReceivingsInfo[refBody.orderBodyID] && this.relatedReceivingsInfo[refBody.orderBodyID].qty) || 0;
                    const qtyToReceive = relatedOrderBody.qty - totalReceived;

                    if (!existBody) {
                        consolidatedBodiesByUOM.push({
                            qty: refBody.qty,
                            cost: refBody.cost,
                            unitCost: refBody.unitCost,
                            qtyReceived: (qtyToReceive - refBody.qty),
                            totalQtyReceive: qtyToReceive,
                            unitQtyInfo: refBody.unitQty,
                            unitCostInfo: relatedOrderBody.unitCost,
                            costInfo: qtyToReceive * relatedOrderBody.unitCost,
                            purchaseBodyInfo: refBody.purchaseBodyInfo,
                            isNotReceivingAllQty: this.hasPartiallyReceived(refBody.qty, qtyToReceive),
                            isUnitCostAsChanged: this.hasCostChanged(refBody.unitCost, relatedOrderBody.unitCost, refBody.qty),
                            receiveState: this.getReceiveState(refBody.qty, qtyToReceive, refBody.unitCost, relatedOrderBody.unitCost),
                            receivingBodies: [{
                                receivingBodyID: refBody.id,
                                qtyReceive: qtyToReceive,
                                order: {
                                    orderNO: relatedOrder.orderNO,
                                    createdDatetime: relatedOrder.createdDatetime
                                }
                            }]
                        });

                        this.bodyInfo = refBody.purchaseBodyInfo;
                    }
                    else {
                        existBody.qtyReceived += (qtyToReceive - refBody.qty);
                        existBody.totalQtyReceive += qtyToReceive;
                        existBody.qty += refBody.qty;
                        existBody.costInfo += (qtyToReceive * relatedOrderBody.unitCost);
                        existBody.isNotReceivingAllQty = this.hasPartiallyReceived(existBody.qty, existBody.totalQtyReceive);
                        existBody.receiveState = this.getReceiveState(existBody.qty, existBody.totalQtyReceive, existBody.unitCost, relatedOrderBody.unitCost);

                        if (relatedOrderBody.unitCost > existBody.unitCostInfo) {
                            existBody.unitCostInfo = relatedOrderBody.unitCost;
                            existBody.isUnitCostAsChanged = this.hasCostChanged(existBody.unitCost, relatedOrderBody.unitCost, existBody.qty);
                        }

                        existBody.receivingBodies.push({
                            receivingBodyID: refBody.id,
                            qtyReceive: qtyToReceive,
                            order: {
                                orderNO: relatedOrder.orderNO,
                                createdDatetime: relatedOrder.createdDatetime
                            }
                        });

                        existBody.receivingBodies.sort((left, right) => new Date(left.order.createdDatetime) - new Date(right.order.createdDatetime));
                    }

                    return consolidatedBodiesByUOM;
                }, []);
        }
    },

    methods: {
        ...mapActions('Receiving', ['saveReceiving']),

        isAllReceived(qtyReceived, qtyToReceive) { return qtyReceived != 0 && (qtyToReceive - qtyReceived) == 0 },
        hasPartiallyReceived(qtyReceived, qtyToReceive) { return qtyReceived != qtyToReceive && qtyReceived != 0 },
        hasCostChanged(costReceived, orderCost, qtyReceived) { return (orderCost != costReceived && costReceived != 0) || (qtyReceived != 0 && costReceived == 0) },
        getReceiveState(qtyReceived, qtyToReceive, costReceived, orderCost) {
            const isAllReceived = this.isAllReceived(qtyReceived, qtyToReceive);
            const hasCostChanged = this.hasCostChanged(costReceived, orderCost, qtyReceived);
            const hasPartiallyReceived = this.hasPartiallyReceived(qtyReceived, qtyToReceive);

            if (isAllReceived && !hasCostChanged)
                return TOGGLE_STATE.ON;

            if (hasPartiallyReceived || hasCostChanged)
                return TOGGLE_STATE.INDETERMINATE;

            return TOGGLE_STATE.OFF;
        },

        async acceptAsIs(body, state) {
            this.editedField = null;

            if (state || body.hasAnyHanyDiff) {
                body.unitCost = body.unitCostInfo;
                body.qty = body.totalQtyReceive;
            }
            else {
                body.unitCost = 0.00;
                body.qty = 0;
            }

            await this.updateReceivingBodies(body);
        },

        async updateReceivingBodies(body) {
            if (this.saveInProgress) {
                return;
            }

            this.saveInProgress = true;

            body.qty = body.qty == '' ? 0 : body.qty;
            body.unitCost = body.unitCost ? body.unitCost : 0.00;

            let qtyToDispatch = body.qty;

            const receiving = cloneDeep(this.getReceiving);

            body.receivingBodies.forEach(relatedReceivingBody => {
                const dispatchValue = qtyToDispatch > relatedReceivingBody.qtyReceive ? (qtyToDispatch - (qtyToDispatch - relatedReceivingBody.qtyReceive)) : qtyToDispatch;
                const refBody = receiving.receivingBodies.find(b => b.id == relatedReceivingBody.receivingBodyID);
                refBody.qty = dispatchValue;
                refBody.unitCost = body.unitCost;
                refBody.cost = (qtyToDispatch * body.unitCost);
                refBody.ignore = false;
                qtyToDispatch -= dispatchValue;
            });

            await this.saveReceivingModification(receiving);
        },

        async saveReceivingModification(receiving) {
            try {
                await this.saveReceiving(receiving);
            } catch (error) {
                const codeTranslate = error?.status===403?'Purchase.Messages.ForbiddenAction':'Purchase.Messages.SaveReceivingError'
                prompts.error({
                    text: this.$t(codeTranslate)
                });
            }

            this.saveInProgress = false;

            if (this.editedField) {
                this.$nextTick(() => {
                    this.editedField.focus();
                    this.editedField = null;
                });
            }
        },

        nextField(field, index) {
            if (field === this.field.qty) {
                this.editedField = this.$refs[`uom${index}-unitCost`].$el;
                this.editedField.focus();
            }
            else if (field === this.field.unitCost && this.$refs[`uom${index+1}-qty`]) {
                this.editedField = this.$refs[`uom${index+1}-qty`];
                this.editedField.focus();
            }
            else {
                this.$refs.scan.focus();
                this.editedField = null;
            }
        },

        onFocus(inputField) {
            this.editedField = inputField;
            this.editedField.select();
        },

        onCancel() {
            this.$sidePanel.close();
        },

        onScan() {
            this.$sidePanel.show([{ component: receivingProductScan }]);
        }
    }
}
</script>