<template src="./time-picker.html"></template>

<style lang="scss">
    @import './time-picker.scss';
</style>

<script>
import moment from 'moment';

export default {
    name: 'time-picker',
    model: {
        prop: 'time'
    },
    props: {
        time: String,
        disabled: Boolean
    },
    computed: {
        hour: {
            get() {
                const hour = this.time && this.time.split(':')[0] || '';
                return Number.isInteger(Number.parseInt(hour)) ? Number.parseInt(hour) : '';
            },
            set(value) {
                const hour = value > 23 ? 23 : value;

                this.$emit('set-time', `${hour}:${this.timeFormatted.minute}:00`);
            }
        },
        minute: {
            get() {
                const minute = this.time && this.time.split(':')[1] || '';
                return Number.isInteger(Number.parseInt(minute)) ? Number.parseInt(minute) : '';
            },
            set(value) {
                const minute = value > 59 ? 59 : value;

                this.$emit('set-time', `${this.timeFormatted.hour}:${minute}:00`);
            }
        },
        timeFormatted() {
            if (!this.time)
                return {};

            const parts = this.time.split(':');
            return { hour: parts[0], minute: parts[1] };
        }
    },
    created() {
        this.setDefaultValue();
    },
    updated() {
        this.setDefaultValue();
    },
    data() {
        return {
            minuteTimeout: null,
            hourInEditMode: false,
            minuteInEditMode: false
        }
    },
    methods: {
        setDefaultValue() {
            if (!this.time)
                this.$emit('set-time', moment().startOf('day').format("HH:mm:ss"));
        },
        setEditHour(isEdit) {
            this.hourInEditMode = isEdit;

            if (isEdit && Number.parseInt(this.hour) == 0) {
                this.hour = '';
            }
            else if (!isEdit && !Number.isInteger(Number.parseInt(this.hour))) {
                this.hour = '00';
            }

            if (isEdit) {
                this.$nextTick(() => {
                    this.$refs['hour-input'].focus();
                });
            }
            else {
                this.autoFormat();
            }
        },
        setEditMinute(isEdit) {
            this.minuteInEditMode = isEdit;

            if (isEdit && Number.parseInt(this.minute) == 0) {
                this.minute = '';
            }
            else if (!isEdit && !Number.isInteger(Number.parseInt(this.minute))) {
                this.minute = '00';
            }

            if (isEdit) {
                this.$nextTick(() => {
                    this.$refs['minute-input'].focus();
                });
            }
            else {
                this.autoFormat();
            }
        },
        keyUpEvent() {
            if (this.minuteTimeout)
                clearTimeout(this.minuteTimeout);

            this.minuteTimeout = setTimeout(() => {
                this.autoFormat();
            }, 500);
        },
        autoFormat() {
            if (this.minuteTimeout)
                clearTimeout(this.minuteTimeout);

            if (Number.isInteger(this.hour) && Number.isInteger(this.minute)) {
                const hour = this.hour < 10 ? `0${this.hour}` : this.hour;
                const minute = this.minute < 10 ? `0${this.minute}` : this.minute;

                this.$emit('set-time', `${hour}:${minute}:00`);
            }
        },
        increment(inc) {
            const hour = Number.isInteger(this.hour) ? this.hour : 0;
            const minute = Number.isInteger(this.minute) ? this.minute : 0;

            if (((hour > 0 || minute > 0) && inc < 0) || ((hour < 23 || minute < 59) && inc > 0 )) {
                this.$emit('set-time', (moment().startOf('day').hour(hour).minute(minute).second(0)).add(inc, 'm').format('HH:mm:ss'));
            }
        }
    }
}
</script>
