<template src='./print.html'></template>

<style lang='scss'>
@import './print.scss';
</style>

<script>
import { mapGetters } from 'vuex'
import prompts from '@/core/tools/notifications/notifications'
import grid from '@/core/components/common/grid/persisted-grid.vue'
import searchBar from '@/core/components/common/search-bar/search-bar.vue'
import iconButton from '@/core/components/common/icon-button/icon-button.vue'
import inputButton from '@/core/components/common/input-button/input-button.vue'
import formatters from '@/core/components/common/grid/formatters/formatters.js'
import getters from '@/core/components/common/grid/getters/getters.js'
import { GridId, AgFilter, AgAllowedAggFuncs, MultiFilterParams, FilterParams } from '@/core/components/common/grid/grid.const.js'
import { permissionsDictionary } from '@/core/security/permissions-dictionary.js'
import labelBatchClient from '@/backoffice/modules/labels/clients/labelBatch.client.js'
import Swal from 'sweetalert2'
import cloneDeep from 'lodash/cloneDeep'
import { renderSelectHtml, toBooleanValue } from '@/backoffice/modules/labels/services/labelBatch.services.js'
import moment from 'moment';


export default {
  name: 'PrintBatch',
  components: {
    grid,
    searchBar,
    iconButton,
    inputButton
  },

  data() {
    return {
      labelBatch: null,
      gridOptions: {
        rowSelection: 'multiple',
        enableRangeSelection: false,
        suppressRowClickSelection: true,
        getRowId: (params) => params.data?.itemUpc
      },
      gridApi: null,
      filters: [],
      selectedRows: [],
      isPrinting: false,
      sourceData: [],
      lastPrintedBy: '',
      startPosition: 1,
      showTooltip: false,
      printDate: undefined,
      printedBy: undefined
    }
  },
  computed: {
    ...mapGetters('Account', ['hasPermission', 'getCurrentTargetLayerId', 'getFirstname', 'getLastname']),
    getGridId() {
      return GridId.PrintBatch
    },
    selectItems() {
      return [{ text: this.$t('LabelBatch.LabelType.Promo'), value: true }, { text:this.$t('LabelBatch.LabelType.Regular'), value: false }]
    },
    printPositions(){
      return Array.from({length: 24}, (_, i) => i + 1)
    },
    columnDefs() {
      return [
        {
          field: 'itemUpc',
          headerName: this.$t('LabelBatch.Columns.ItemUPC'),
          headerTooltip: this.$t('LabelBatch.Columns.ItemUPC'),
          headerCheckboxSelection: true,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          minWidth: 200,
          allowedAggFuncs: AgAllowedAggFuncs.Text,
          filter: AgFilter.TextColumnFilter
        },
        {
          field: 'description',
          headerName: this.$t('LabelBatch.Columns.Description'),
          headerTooltip: this.$t('LabelBatch.Columns.Description'),
          minWidth: 150,
          allowedAggFuncs: AgAllowedAggFuncs.Text,
          filter: AgFilter.TextColumnFilter
        },
        {
          field: 'brand',
          headerName: this.$t('LabelBatch.Columns.Brand'),
          headerTooltip: this.$t('LabelBatch.Columns.Brand'),
          allowedAggFuncs: AgAllowedAggFuncs.Text,
          filterParams: MultiFilterParams.NormalizeTextFilters
        },
        {
          field: 'format',
          headerName: this.$t('LabelBatch.Columns.Format'),
          headerTooltip: this.$t('LabelBatch.Columns.Format'),
          allowedAggFuncs: AgAllowedAggFuncs.Text,
          filter: AgFilter.TextColumnFilter
        },
        {
          field: 'regularPrice',
          headerName: this.$t('LabelBatch.Columns.ItemPrice'),
          headerTooltip: this.$t('LabelBatch.Columns.ItemPrice'),
          valueFormatter: formatters.currencyFormatter,
          filterParams: FilterParams.NumberFilter
        },
        {
          field: 'discountedPrice',
          headerName: this.$t('LabelBatch.Columns.DiscountedPrice'),
          headerTooltip: this.$t('LabelBatch.Columns.DiscountedPrice'),
          cellStyle: { color: 'red' },
          valueFormatter: formatters.promoCurrencyFormatter,
          filterParams: FilterParams.NumberFilter
        },
        {
          field: 'promotionCode',
          headerName: this.$t('LabelBatch.Columns.PromotionCode'),
          headerTooltip: this.$t('LabelBatch.Columns.PromotionCode'),
          valueGetter: getters.multiplePromoGetter,
          allowedAggFuncs: AgAllowedAggFuncs.Text,
          filter: AgFilter.TextColumnFilter,
        },
        {
          field: 'promotionDescription',
          headerName: this.$t('LabelBatch.Columns.PromotionDescription'),
          headerTooltip: this.$t('LabelBatch.Columns.PromotionDescription'),
          minWidth: 150,
          valueGetter: getters.multiplePromoGetter,
          allowedAggFuncs: AgAllowedAggFuncs.Text,
          filter: AgFilter.TextColumnFilter,
        },
        {
          field: 'promotionEndDate',
          headerName: this.$t('LabelBatch.Columns.PromotionEndDate'),
          headerTooltip: this.$t('LabelBatch.Columns.PromotionEndDate'),
          valueFormatter: formatters.multiplePromoDateFormatter,
          filter: AgFilter.DateColumnFilter,
          filterValueGetter: (params) => params?.data?.promotionEndDate && new Date(params.data.promotionEndDate)
        },
        {
          field: 'sdpDescription',
          headerName: this.$t('LabelBatch.Columns.SubDepartmentDescription'),
          headerTooltip: this.$t('LabelBatch.Columns.SubDepartmentDescription'),
          minWidth: 150,
          allowedAggFuncs: AgAllowedAggFuncs.Text,
          filter: AgFilter.TextColumnFilter
        },
        {
          field: 'isToSale',
          headerName: this.$t('LabelBatch.Columns.Status'),
          headerTooltip: this.$t('LabelBatch.Columns.Status'),
          cellRenderer: 'booleanRenderer',
          valueGetter: (params) => params?.data?.isToSale ? params.data.isToSale.toString() : 'false',
          filter: AgFilter.SetColumnFilter,
          filterParams: { valueFormatter: (params) => params.value === 'true' ? this.$t('Button.Yes') : this.$t('Button.No') }
        },
        {
          field: 'hasPromotion',
          headerName: this.$t('LabelBatch.Columns.LabelType'),
          headerTooltip: this.$t('LabelBatch.Columns.LabelType'),
          cellRenderer: 'selectInputRenderer',
          cellRendererParams: {
            canUserInteractWithUI: () => this.canUpdate,
            selectItems: this.selectItems,
            sourceData: this.sourceData,
            renderSelectHtml: renderSelectHtml,
            valueConverter: toBooleanValue,
            disabledValue: this.$t('LabelBatch.LabelType.Regular')
          },
          cellStyle: { 'display': 'flex', 'align-items': 'center' },
          filter: AgFilter.SetColumnFilter,
          filterValueGetter: (params) => getters.labelTypeGetter(params)
        },
        {
          field: 'quantity',
          cellRenderer: 'integerInputRenderer',
          cellRendererParams: {
            canUserInteractWithUI: () => this.canUpdate,
            placeholder: 'LabelBatch.Columns.Quantity',
            customStyle: "width:50px",
            maxValue: 999,
            maxLength: 3
          },
          onCellValueChanged: e => this.changeRowQuantity(e),
          headerName: this.$t('LabelBatch.Columns.Quantity'), 
          headerTooltip: this.$t('LabelBatch.Columns.Quantity'),
          cellClass: !this.isMobile ? 'purchase-cell' : undefined,
          width: this.isMobile ? 90 : undefined,
          suppressMenu: this.isMobile,
          resizable: this.isMobile,
          filter: AgFilter.SetColumnFilter,
          enableRowGroup: false
        },
        {
          field: 'user',
          headerName: this.$t('LabelBatch.Columns.User'),
          headerTooltip: this.$t('LabelBatch.Columns.User'),
          filter: AgFilter.SetColumnFilter
        },
        {
          field: 'modifiedOn',
          headerName: this.$t('LabelBatch.Columns.ModifiedOn'),
          headerTooltip: this.$t('LabelBatch.Columns.ModifiedOn'),
          valueFormatter: formatters.datetimeFormatter,
          filter: AgFilter.DateColumnFilter,
          filterValueGetter: (params) => params?.data?.modifiedOn && new Date(params.data.modifiedOn)
        },
      ]
    },
    rowData() {
      return this.labelBatch
    },
    canUpdate() {
      return this.hasPermission([permissionsDictionary.LABEL_UPDATE])
    },
    lastPrintUser() {
      if(!this.rowData){
        return this.$t('LabelBatch.NA')
      }
      const printedLabels = this.rowData.filter(d=> d.printedBy != undefined)
      const printedBy = printedLabels[0]?.printedBy
      return printedBy ?? this.printedBy
    },
    lastPrintDate() {
      let result = this.$t('LabelBatch.NA')
      if(!this.rowData){
        return result
      }
      
      const printedLabels = this.rowData?.filter(d=> d.lastPrintOn != undefined)
      const printDate = printedLabels.length > 0 ? printedLabels[0].lastPrintOn : (this.printDate ? this.printDate : undefined)
      if(printDate){
        const printDateObj = moment(printDate)
        const month = printDateObj.locale(this.$i18n.locale).format('MMMM')
        const day = printDateObj.locale(this.$i18n.locale).format('Do')
        const year = printDateObj.format('YYYY')
        result = this.$i18n.locale == 'fr' ? `${day} ${month} ${year}` : `${month} ${day} ${year}`
      }
      return result
    },
    inputButtonPlaceholder() {
      return this.$t('Placeholders.Quantity')
    }
  },

  watch: {
    getCurrentTargetLayerId() {
      this.loadLabelBatch()
    }
  },

  methods: {
    async onGridReady(params) {
      await this.loadLabelBatch()

      this.gridApi = params.api
      this.gridApi.selectAll()
    },

    async loadLabelBatch() {
      try {
        this.labelBatch = await labelBatchClient.getBatch()
        this.sourceData = cloneDeep(this.rowData)
        this.printDate = undefined
        this.printedBy = undefined
      } catch (error) {
        this.labelBatch = []
        prompts.error({
          text: this.$t('LabelBatch.Messages.Error')
        })
      }
    },

    async removeBatchItems() {
      const answer = await Swal.fire({
        heightAuto: false,
        confirmButtonText: this.$i18n.t('Button.YES'),
        showCancelButton: true,
        cancelButtonText: this.$i18n.t('Button.NO'),
        icon: 'warning',
        title: this.$i18n.t('LabelBatch.RemoveBatchItems', {
          quantity: this.selectedRows.length
        }),
        text: this.$i18n.t('LabelBatch.Messages.EmptyBatchConfirm', {
          quantity: this.selectedRows.length
        }),
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      if (!answer.isConfirmed) {
        return
      }

      try {
        const items = this.selectedRows.map((x) => x.data.itemUpc)
        await labelBatchClient.removeFromBatch(items)
        await this.loadLabelBatch()
      } catch (error) {
        prompts.error({
          text: this.$t('LabelBatch.Messages.Error')
        })
      }
    },

    filtersChanged(filters) {
      this.filters = filters
    },

    onSelectionChanged(event) {
      this.selectedRows = event.api.getSelectedNodes()
    },

    async printLabels() {
      const currentTlId = this.getCurrentTargetLayerId
      const payload = {
        startPosition: this.startPosition,
        labelItems: this.selectedRows.map(x => {
          return {
            itemUpc: x.data.itemUpc,
            quantity: x.data.quantity,
            targetLayerId: currentTlId,
            hasPromotion: x.data.hasPromotion
          }
        })
      }

      this.isPrinting = true

      try {
        const data = await labelBatchClient.getLabelsPdf(currentTlId, payload)
        const byteString = atob(data)
        const buffer = new ArrayBuffer(byteString.length)
        const uint8Buffer = new Uint8Array(buffer)
        for (let i = 0; i < byteString.length; i++) {
          uint8Buffer[i] = byteString.charCodeAt(i)
        }

        const filePdf = new Blob([uint8Buffer], { type: "application/pdf" })
        const downloadUrl = URL.createObjectURL(filePdf);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = `ItemLabels${moment().format('YYYYMMDD')}.pdf`;
        link.click();
        URL.revokeObjectURL(downloadUrl);
      } 
      catch (error) {
        prompts.error({
          text: this.$t('LabelBatch.Messages.Error')
        })
      }
      finally {
        this.isPrinting = false
        this.printDate = moment().format()
        this.printedBy = `${this.getFirstname} ${this.getLastname}`.trim()
      }
    },

    async applyQuantity(quantity){
      this.gridApi.showLoadingOverlay()
      if(this.selectedRows.length === 0)
        return
      const labelItems =  this.selectedRows.map(r => {
        return { itemUpc: r.data.itemUpc, quantity, targetLayerId: this.getCurrentTargetLayerId}
      })
      try {
        await labelBatchClient.setLabelsQuantities(this.getCurrentTargetLayerId, labelItems)
        await this.loadLabelBatch()
      } catch (error) {
        prompts.error({
          text: this.$t('LabelBatch.Messages.Error')
        })
      }
      finally {
        this.gridApi.hideOverlay()
      }
    },

    async changeRowQuantity(event){
      try {
        this.gridApi.showLoadingOverlay()
        await labelBatchClient.setLabelsQuantities(this.getCurrentTargetLayerId, [{ itemUpc: event.data.itemUpc, quantity:event.newValue, targetLayerId: this.getCurrentTargetLayerId}])
      } catch (error) {
        prompts.error({
          text: this.$t('LabelBatch.Messages.Error')
        })
      }
      finally {
        this.gridApi.hideOverlay()
      }
    },

    toggleTooltip(){
      this.showTooltip = !this.showTooltip
    }
  }
}
</script>