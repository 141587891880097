import Api from '@/core/services/_api.config';

async function getReportsCustomization(_ReportDataModelID, _UserID, getChildren) {

  return await Api()
              .get(`/api/ReportUserCustomization/${_ReportDataModelID}/User/${_UserID}?getChildren=${getChildren}`)
              .then(response => {
                return ({ status: response.status, data: response.data });
              })
              .catch((e) => {
                if (e.response) {
                  return { status: e.response.status, message: e.response.data, data: null };
                }
                else {
                  return { message: e.message, data: null };
                }
              });
}

async function getReportCustomization(_ReportDataModelID, getChildren) {

  return await Api()
              .get(`/api/ReportUserCustomization/${_ReportDataModelID}?getChildren=${getChildren}`)
              .then(response => {
                return ({ status: response.status, data: response.data });
              })
              .catch((e) => {
                if (e.response) {
                  return { status: e.response.status, message: e.response.data, data: null };
                }
                else {
                  return { message: e.message, data: null };
                }
              });
}

async function updateReportCustomization(ruc) {

  return await Api()
              .put('/api/ReportUserCustomization', ruc)
              .then(response => {
                return ({ status: response.status, data: response.data });
              })
              .catch((e) => {
                if (e.response) {
                  return { status: e.response.status, message: e.response.data, data: null };
                }
                else {
                  return { message: e.message, data: null };
                }
              });
}

async function createReportCustomization(ruc) {

  return await Api()
              .post('/api/ReportUserCustomization', ruc)
              .then(response => {
                return ({ status: response.status, data: response.data });
              })
              .catch((e) => {
                if (e.response) {
                  return { status: e.response.status, message: e.response.data, data: null };
                }
                else {
                  return { message: e.message, data: null };
                }
              });
}

async function deleteReportCustomization(ids) {

  return await Api()
              .delete('/api/ReportUserCustomization', {data: ids})
              .then(response => {
                return ({ status: response.status, data: response.data });
              })
              .catch((e) => {
                if (e.response) {
                  return { status: e.response.status, message: e.response.data, data: null };
                }
                else {
                  return { message: e.message, data: null };
                }
              });
}

export const reportUserCustomizationServices = {
  getReportsCustomization,
  getReportCustomization,
  updateReportCustomization,
  createReportCustomization,
  deleteReportCustomization
};