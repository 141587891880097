import releaseNotes from '@/backoffice/modules/release-note/release-note.vue'
import main from '@/backoffice/modules/release-note/pages/main.vue'

export const releaseNoteRouter = {
  path: '/release-notes',
  component: releaseNotes,
  children:[
    {
      path: '',
      name: 'releaseNotes',
      component: main,
      meta: {
        requiresAuth: true,
        associatedParentRouteName: null,
        permissions: [],
      }
    }  
  ]
 };