<template src="./tabs.html"></template>

<style lang="scss">
    @import './tabs.scss';
</style>

<script>

export default {
    name: 'tabs',
    props: {
        tabs: {
            type: Array,
            required: true,
        },
        value: {
            type: Number,
            default: 0
        }
    },
    data(){
        return{
            selectedTab: 0
        }
    },
    watch:{
        value(val){
            this.selectedTab = val
        }
    },
    methods:{
        selectTab(i){
            this.selectedTab = i
            this.$emit('input', i)
        }
    }
}
</script>
