import { rdmBreadcrumbTailFormatter, rdmCreateBreadcrumbTailFormatter, rdmUpdateBreadcrumbTailFormatter } from './breadcrumbTailFormatter.js';
import user from '@/backoffice/modules/reporting/components/side-panel/user/user.vue';
import userAccessList from  '@/backoffice/modules/reporting/components/side-panel/user-access-list/user-access-list.vue';
import address from  '@/backoffice/modules/reporting/components/side-panel/address/address.vue';
import hierarchy from  '@/backoffice/modules/reporting/components/side-panel/hierarchy/hierarchy.vue';
import dashboardManagement from  '@/backoffice/modules/dashboard/pages/main/dashboard-management/dashboard-management.vue';
import refundableDepositManagement from  '@/backoffice/modules/reporting/components/side-panel/refundabledeposit-management/refundabledeposit-management.vue';
import { RouteType } from '@/core/router/route-type.js'
import { Action } from './action.js'
import { BrandRouter } from '@/backoffice/modules/brand/router'
import ClientAccountRouter from '@/backoffice/modules/clientAccount/router'
import LabelsRouter from '@/backoffice/modules/labels/router'
import PriceLevelRouter from '@/backoffice/modules/priceLevel/router'

import reporting from '@/backoffice/modules/reporting/reporting.vue'
import iframeWs from '@/backoffice/modules/ws-management/components/iframe-ws/iframe-ws.vue'
import mainReport from '@/backoffice/modules/reporting/pages/main-report/main-report.vue'
import readReportList from '@/backoffice/modules/reporting/pages/read-report-list/read-report-list.vue'
import createUpdate from '@/backoffice/modules/reporting/pages/create-update/create-update.vue'
import simpleList from '@/backoffice/modules/reporting/pages/simple-list/simple-list.vue'
import backOffice from '@/backoffice/modules/role/main.vue'

const managementRouter = {
    path: '/management',
    name: 'management',
    component: reporting,
    meta: {
      title: 'management',
      sideNav: {
        image: 'fal fa-display',
        label: 'SideNav.Management.Management',
        orderIndex: 2
      },
      permissions: ['P1', 'P2', 'P3', 'RL2', 'RL3', 'RL4', 'T1', 'T2', 'T5', 'U2', 'U3', 'U8', 'MaintenanceHierarchyAsRead', 'MaintenanceDpAsRead', 'MaintenanceSdpAsRead', 'MaintenanceItemAsRead', 'MaintenanceSyncAsRead', 'MaintenanceVendorAsRead', 'ManageUserRolesAsRead', 'ManageUserUserAsRead', 'MaintenancePaymentTypeAsRead', 'MaintenanceTaxAsRead'],
      breadcrumb: {
        linkDescription: 'Breadcrumbs.Management.Management',
        routeNameTo: null,
        explicitTail: null
      }
    },
    children:[
      {
        name: 'Hierarchy',
        path: 'Hierarchy',
        component: iframeWs,
        props: { routeName: 'Dashboard.Hierarchy' },
        meta: {
          sideNav: {
            image: null,
            label: 'SideNav.Management.Hierarchy'
          },
          permissions: ['MaintenanceHierarchyAsRead']
        }
      },
      {
        path: 'target',
        name: 'managementTarget',
        component: mainReport,
        props: route => ({ reportType: route.meta.modelName, labels:{firstColumnLabel:'Reporting.CustomListColumnTitle'} }),
        meta: {
          requiresAuth: true,
          modelName: 'Target',
          associatedParentRouteName: null,
          sideNav: {
            image: null,
            label: 'SideNav.Management.Targets'
          },
          permissions: ['T1', 'T2', 'T5'],
          breadcrumb: {
            linkDescription: 'Breadcrumbs.Management.Targets',
            routeNameTo: null,
            explicitTail: null
          }
        }
      },
      {
        path: 'target/read/:_ReportDataModelID',
        name: `managementTarget${RouteType.Read}`,
        component: readReportList,
        props: route => ({ reportType: route.meta.modelName,  _ReportDataModelID: route.params._ReportDataModelID }),
        meta: {
          requiresAuth: true,
          modelName: 'Target',
          associatedParentRouteName: 'managementTarget',
          sidePanelComponents: [{ component: hierarchy }],
          breadcrumb: {
            linkDescription: 'Breadcrumbs.Management.Targets',
            routeNameTo: 'managementTarget',
            routeQueryString: {
              bypassFavoriteOrDefaultRedirect: true
            },
            explicitTail: {
              func: rdmBreadcrumbTailFormatter
            }
          }
        }
      },
      {
        path: 'target/create',
        name: `managementTarget${RouteType.Create}`,
        component: createUpdate,
        props: route => ({ reportType: route.meta.modelName,  _ReportDataModelID: 0, action: Action.Create,
                            labels: {
                              title:'Reporting.CustomListTitleCreate', 
                              descriptionLabel:'Reporting.CustomListName',
                              stepsFirstLabel: 'Reporting.CustomListFirstStepLabelCreate'
                            } }),
        meta: {
          requiresAuth: true,
          modelName: 'Target',
          associatedParentRouteName: 'managementTarget',
          breadcrumb: {
            linkDescription: 'Breadcrumbs.Management.Targets',
            routeNameTo: 'managementTarget',
            explicitTail: {
              func: rdmCreateBreadcrumbTailFormatter
            }
          }
        }
      },
      {
        path: 'target/update/:_ReportDataModelID',
        name: `managementTarget${RouteType.Update}`,
        component: createUpdate,
        props: route => ({ reportType: route.meta.modelName,  _ReportDataModelID: route.params._ReportDataModelID, action: Action.Update,
                          labels: {
                            title:'Reporting.CustomListTitleUpdate', 
                            descriptionLabel:'Reporting.CustomListName',
                            stepsFirstLabel: 'Reporting.CustomListFirstStepLabelUpdate'
                          } }),
        meta: {
          requiresAuth: true,
          modelName: 'Target',
          associatedParentRouteName: 'managementTarget',
          breadcrumb: {
            linkDescription: 'Breadcrumbs.Management.Targets',
            routeNameTo: 'managementTarget',
            explicitTail: {
              func: rdmUpdateBreadcrumbTailFormatter 
            }
          }
        }
      },
      {
        name: 'MaintenanceDp',
        path: 'MaintenanceDp',
        component: iframeWs,
        props: { routeName: 'Dashboard.MaintenanceDp' },
        meta: {
          sideNav: {
            image: null,
            label: 'SideNav.Management.Departments'
          },
          permissions: ['MaintenanceDpAsRead']
        }
      },
      {
        name: 'MaintenanceSdp',
        path: 'MaintenanceSdp',
        component: iframeWs,
        props: { routeName: 'Dashboard.MaintenanceSdp' },
        meta: {
          sideNav: {
            image: null,
            label: 'SideNav.Management.SubDepartments'
          },
          permissions: ['MaintenanceSdpAsRead']
        }
      },
      {
        name: 'MaintenanceItem',
        path: 'MaintenanceItem',
        component: iframeWs,
        props: { routeName: 'Dashboard.MaintenanceItem' },
        meta: {
          sideNav: {
            image: null,
            label: 'SideNav.Management.Items'
          },
          permissions: ['MaintenanceItemAsRead']
        }
      },
      LabelsRouter,
      BrandRouter,
      {
        path: 'refundableDeposits',
        name: `refundableDeposit${RouteType.List}`,
        component: simpleList,
        props: route => ({ reportType: route.meta.modelName }),
        meta: {
          requiresAuth: true,
          modelName: 'RefundableDeposit',
          sidePanelComponents: [{ component: refundableDepositManagement }],
          sideNav: {
            image: null,
            label: 'SideNav.Management.RefundableDeposits'
          },
          permissions: ['PRD1', 'PRD2'],
          breadcrumb: {
            linkDescription: 'Breadcrumbs.Management.RefundableDeposits',
            routeNameTo: null,
            explicitTail: null
          }
        }
      },
      PriceLevelRouter,
      ClientAccountRouter,
      {
        name: 'MaintenanceSync',
        path: 'MaintenanceSync',
        component: iframeWs,
        props: { routeName: 'Dashboard.MaintenanceSync' },
        meta: {
          sideNav: {
            image: null,
            label: 'SideNav.Management.Synchronizations'
          },
          permissions: ['MaintenanceSyncAsRead']
        }
      },
      {
        name: 'MaintenanceVendor',
        path: 'MaintenanceVendor',
        component: iframeWs,
        props: { routeName: 'Dashboard.MaintenanceVendor' },
        meta: {
          sideNav: {
            image: null,
            label: 'SideNav.Management.Vendors'
          },
          permissions: ['MaintenanceVendorAsRead']
        }
      },
      {
        path: 'role',
        name: 'managementRole',
        component: backOffice,
        meta: {
          requiresAuth: true,
          associatedParentRouteName: null,
          sideNav: {
            image: null,
            label: 'SideNav.Management.Roles'
          },
          permissions: ['RL2', 'RL3', 'RL4'],
          breadcrumb: {
            linkDescription: 'Breadcrumbs.Management.Roles',
            routeNameTo: null,
            explicitTail: null
          }
        }
      },
      {
        path: 'user',
        name: 'managementUser',
        component: mainReport,
        props: route => ({ reportType: route.meta.modelName, labels:{firstColumnLabel:'Reporting.CustomListColumnTitle'} }),
        meta: {
          requiresAuth: true,
          modelName: 'User',
          associatedParentRouteName: null,
          sideNav: {
            image: null,
            label: 'SideNav.Management.Users'
          },
          permissions: ['U2', 'U3', 'U8'],
          breadcrumb: {
            linkDescription: 'Breadcrumbs.Management.Users',
            routeNameTo: null,
            explicitTail: null
          }
        }
      },
      {
        path: 'user/read/:_ReportDataModelID',
        name: `managementUser${RouteType.Read}`,
        component: readReportList,
        props: route => ({ reportType: route.meta.modelName,  _ReportDataModelID: route.params._ReportDataModelID }),
        meta: {
          requiresAuth: true,
          modelName: 'User',
          associatedParentRouteName: 'managementUser',
          sidePanelComponents: [{ component: user, tab: 'User.User' }, { component: address, tab: 'ModelType.Address' }, { component: userAccessList, tab: 'ModelType.UserAccess' }],
          breadcrumb: {
            linkDescription: 'Breadcrumbs.Management.Users',
            routeNameTo: 'managementUser',
            routeQueryString: {
              bypassFavoriteOrDefaultRedirect: true
            },
            explicitTail: {
              func: rdmBreadcrumbTailFormatter
            }
          }
        }
      },
      {
        path: 'user/create',
        name: `managementUser${RouteType.Create}`,
        component: createUpdate,
        props: route => ({ reportType: route.meta.modelName,  _ReportDataModelID: 0, action: Action.Create,
                          labels: {
                            title:'Reporting.CustomListTitleCreate', 
                            descriptionLabel:'Reporting.CustomListName',
                            stepsFirstLabel: 'Reporting.CustomListFirstStepLabelCreate'
                          } }),
        meta: {
          requiresAuth: true,
          modelName: 'User',
          associatedParentRouteName: 'managementUser',
          breadcrumb: {
            linkDescription: 'Breadcrumbs.Management.Users',
            routeNameTo: 'managementUser',
            explicitTail: {
              func: rdmCreateBreadcrumbTailFormatter
            }
          }
        }
      },
      {
        path: 'user/update/:_ReportDataModelID',
        name: `managementUser${RouteType.Update}`,
        component: createUpdate,
        props: route => ({ reportType: route.meta.modelName,  _ReportDataModelID: route.params._ReportDataModelID, action: Action.Update,
                            labels: {
                              title:'Reporting.CustomListTitleUpdate', 
                              descriptionLabel:'Reporting.CustomListName',
                              stepsFirstLabel: 'Reporting.CustomListFirstStepLabelUpdate'
                            } }),
        meta: {
          requiresAuth: true,
          modelName: 'User',
          associatedParentRouteName: 'managementUser',
          breadcrumb: {
            linkDescription: 'Breadcrumbs.Management.Users',
            routeNameTo: 'managementUser',
            explicitTail: {
              func: rdmUpdateBreadcrumbTailFormatter 
            }
          }
        }
      },
      {
        name: 'Profil',
        path: 'Profil',
        component: iframeWs,
        props: { routeName: 'Dashboard.Profil' }
      },
      {
        name: 'MaintenancePaymentType',
        path: 'MaintenancePaymentType',
        component: iframeWs,
        props: { routeName: 'Dashboard.MaintenancePaymentType' },
        meta: {
          sideNav: {
            image: null,
            label: 'SideNav.Management.PaymentTypes'
          },
          permissions: ['MaintenancePaymentTypeAsRead']
        }
      },
      {
        name: 'TaxManagement',
        path: 'TaxManagement',
        component: iframeWs,
        props: { routeName: 'Dashboard.TaxManagement' },
        meta: {
          sideNav: {
            image: null,
            label: 'SideNav.Management.Taxes'
          },
          permissions: ['MaintenanceTaxAsRead']
        }
      },
      {
          path: 'dashboards',
          name: `dashboard${RouteType.List}`,
          component: simpleList,
          props: route => ({ reportType: route.meta.modelName }),
          meta: {
            requiresAuth: true,
            modelName: 'Dashboard',
            sidePanelComponents: [{ component: dashboardManagement }]
          }
      }
    ]
  };

  export { managementRouter }