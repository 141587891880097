import { getFormatValueFromDatatype, getLanguageDescription } from '@/core/functions';

export default {
    getTitle: function (config, localLang) {
        if (!config || !config.descriptions)
            return "";

        const description = getLanguageDescription(config.descriptions, localLang);
        if (description.shortDescription) {
            return description.shortDescription;
        }

        const fallbackDescription = config.descriptions.find(description => description.shortDescription);
        if (fallbackDescription) {
            return fallbackDescription.shortDescription;
        }

        return "";
    },
    formatValue: function (property, value) {
        return getFormatValueFromDatatype(property.dataType, value, 'accounting', false);
    },
    createReportDataModel: function (config) {
        return {
            genericListColumn: [],
            genericListFilter: config.genericListFilter ?? [],
            genericListGroup: [],
            genericListOrder: [],
            period: config.period ?? '-0d',
            dataSource: config.model,
            topLines: null,
        };
    }
}