import i18n from "@/i18n";

const agGridComparators = {  
  calendarYearComparator(a, b){
    if(a && b){     
      return parseInt(a) - parseInt(b);
    }
  },
  calendarMonthComparator(a, b) {
    if(a && b){      
      const requiredOrder = 
      [
        i18n.t('Calendar.MonthNames.January'),
        i18n.t('Calendar.MonthNames.February'),
        i18n.t('Calendar.MonthNames.March'),
        i18n.t('Calendar.MonthNames.April'),
        i18n.t('Calendar.MonthNames.May'),
        i18n.t('Calendar.MonthNames.June'),
        i18n.t('Calendar.MonthNames.July'),
        i18n.t('Calendar.MonthNames.August'),
        i18n.t('Calendar.MonthNames.September'),
        i18n.t('Calendar.MonthNames.October'),
        i18n.t('Calendar.MonthNames.November'),
        i18n.t('Calendar.MonthNames.December')
      ];
      return requiredOrder.indexOf(a) - requiredOrder.indexOf(b);
    }
  },
  dayOfYearComparator(a, b){
    if(a && b){      
      const aNew = a.replace( /^\D+/g, ''); // replace all leading non-digits with nothing
      const bNew = b.replace( /^\D+/g, ''); // replace all leading non-digits with nothing
      if(isNaN(aNew) || isNaN(bNew)){
        return;
      }
      else{
        return parseInt(aNew) - parseInt(bNew);
      }
    }
  },
  calendarDayComparator(a, b){
    if(a && b){      
      const aNew = a.replace( /^\D+/g, ''); // replace all leading non-digits with nothing
      const bNew = b.replace( /^\D+/g, ''); // replace all leading non-digits with nothing
      if(isNaN(aNew) || isNaN(bNew)){
        return;
      }
      else{
        return parseInt(aNew) - parseInt(bNew);
      }
    }
  },

  weekOfYearComparator(a, b){
    if(a && b){      
      const aNew = a.replace( /^\D+/g, ''); // replace all leading non-digits with nothing
      const bNew = b.replace( /^\D+/g, ''); // replace all leading non-digits with nothing
      if(isNaN(aNew) || isNaN(bNew)){
        return;
      }
      else{
        return parseInt(aNew) - parseInt(bNew);
      }
    }
  },

  weekOfMonthComparator(a, b){
    if(a && b){      
      const aNew = a.replace( /^\D+/g, ''); // replace all leading non-digits with nothing
      const bNew = b.replace( /^\D+/g, ''); // replace all leading non-digits with nothing
      if(isNaN(aNew) || isNaN(bNew)){
        return;
      }
      else{
        return parseInt(aNew) - parseInt(bNew);
      }
    }
  },
  
  dayOfWeekComparator(a, b) {
    if(a && b){      
      const requiredOrder = 
      [ 
        i18n.t('Calendar.DayNames.Sunday'),
        i18n.t('Calendar.DayNames.Monday'),
        i18n.t('Calendar.DayNames.Tuesday'),
        i18n.t('Calendar.DayNames.Wednesday'),
        i18n.t('Calendar.DayNames.Thursday'),
        i18n.t('Calendar.DayNames.Friday'),
        i18n.t('Calendar.DayNames.Saturday')
      ];
      return requiredOrder.indexOf(a) - requiredOrder.indexOf(b);
    }
  }
}
export default agGridComparators;