const Operators = {
    like: { value: 'like', description: 'Filters.Include' },
    notlike: { value: 'notlike', description: 'Filters.NotIncluded' },
    startwith: { value: 'startwith', description: 'Filters.Start' },
    endwith: { value: 'endwith', description: 'Filters.End' },
    equal: { value: '=', description: 'Filters.Equal', icon: 'fas fa-equals' },
    notequal: { value: '!=', description: 'Filters.NotEqual', icon: 'fas fa-not-equal' },
    empty: { value: 'empty', description: 'Filters.Empty' },
    notempty: { value: 'notempty', description: 'Filters.NotEmpty'},
    in: { value: 'in', description: 'Filters.In' },
    notin: { value: 'notin', description: 'Filters.NotIn'},
    lesser: { value: '<', description: 'Filters.Lesser', icon: 'fas fa-less-than' },
    greater: { value: '>', description: 'Filters.Greater', icon: 'fas fa-greater-than' },
    lesserEqual: { value: '<=', description: 'Filters.LesserEqual', icon: 'fas fa-less-than-equal' },
    greaterEqual: { value: '>=', description: 'Filters.GreaterEqual', icon: 'fas fa-greater-than-equal' },
  };
  
  const OperatorByType = {
    text: [Operators.like, Operators.notlike, Operators.startwith, Operators.endwith, Operators.equal, Operators.notequal, Operators.empty, Operators.notempty, Operators.in, Operators.notin],
    integer: [Operators.equal, Operators.notequal, Operators.lesser, Operators.greater, Operators.lesserEqual, Operators.greaterEqual, Operators.empty, Operators.notempty, Operators.in, Operators.notin],
    numeric: [Operators.equal, Operators.notequal, Operators.lesser, Operators.greater, Operators.lesserEqual, Operators.greaterEqual, Operators.empty, Operators.notempty, Operators.in, Operators.notin],
    date: [Operators.equal, Operators.notequal, Operators.lesser, Operators.greater, Operators.lesserEqual, Operators.greaterEqual, Operators.empty, Operators.notempty],
    datetime: [Operators.equal, Operators.notequal, Operators.lesser, Operators.greater, Operators.lesserEqual, Operators.greaterEqual, Operators.empty, Operators.notempty],
    boolean: [Operators.equal, Operators.notequal, Operators.empty, Operators.notempty]
  };

  export {
	Operators,
	OperatorByType
}