<template inline-template v-cloak src="./simple-list.html"></template>

<script>
import manageColumns from '@/backoffice/modules/reporting/mixins/manage-columns.js';
import { mapGetters, mapActions } from 'vuex';
import prompts from '@/core/tools/notifications/notifications';
import { getColumnDefintions } from '@/backoffice/domain/column-definitions.js';
import { genericServices } from '@/core/services/generic/generic.services';
import { EntityServices } from '@/core/store/modules/entity.services.js';
import errors from '@/core/tools/errors/errors';

import creationButton from '@/core/components/common/creation-button/creation-button.vue'
import searchBar from '@/core/components/common/search-bar/search-bar.vue'
import grid from  '@/core/components/common/grid/grid.vue'
import descriptionTitle from '@/core/components/common/description-title/description-title.vue'

export default {
  mixins: [manageColumns],
  name: 'simpleList',
  components: {
    creationButton,
    searchBar,
    grid,
    descriptionTitle
  },
  props: {
    reportType: String
  },
  data() {
    return {
      title: "",
      model: {},
      sidePanelTemplate: null,
      rowData: null,
      gridOptions: {
        headerHeight: 50,
        groupHeaderHeight: 50,
        defaultColDef: {
          resizable: true,
          headerCheckboxSelectionFilteredOnly: false,
        },
        suppressDragLeaveHidesColumns: true,
        rowSelection: "single",
        onGridReady: this.getDataModel
      },
      filters: [],
      currentNode: null
    };
  },
  watch: {
    reportType() {
      this.gridOptions.api.setRowData([]);
      this.getDataModel();
    },
    getCurrentTargetLayerId() {
      this.$router.go();
    }
  },
  computed:{
    ...mapGetters('App', ['isMobile']),
    ...mapGetters('Account', ['getCurrentTargetLayerId', 'hasPermission']),
    canCreate() {
      return this.model.permissions && this.hasPermission([this.model.permissions.create]);
    },
    columnDefs(){
      const options = {
        clickMenuLink: this.editEntity,
        editEntity: this.editEntity,
        delete: this.delete,
        openSidePanel: this.openSidePanel
      };
      return getColumnDefintions(this.reportType, this.isMobile, options);
    }
  },
  beforeDestroy() {
    if(this.gridOptions.api){
      this.gridOptions.api.setRowData([]);
      this.gridOptions.api.destroy();
      this.grid = null;
    }
  },
  methods: {
    ...mapActions('DataModels', ['getModel']),
    //#region Grid Events
    editEntity(node){
      this.currentNode = node;
      this.openSidePanel(this.currentNode.data);
    },
    createModel() {
      this.gridOptions.api.deselectAll()
      this.currentNode = null;
      this.openSidePanel();
    },
    async delete(node){
      this.currentNode = node;

      const promptResult = await prompts.warning({
          html: this.$t(`${this.reportType}.Messages.DeleteMsg`)
      });

      if (!promptResult.isConfirmed) {
        return
      }

      const key = this.getPrimaryKey(this.currentNode.data);

      let result;
      const request = { targetModel: this.reportType, ids: [this.currentNode.data[key]] };
      try{
        result = await EntityServices.deleteEntities(request);
      }
      catch(error){
        prompts.error({
          text: this.$t('Error.CannotDeleteEntity')
        });
      }

      if(result){
        this.gridOptions.api.applyTransaction({remove: [this.currentNode.data] });
      }
    },
    //#endregion

    //#region Side Panel functions
    onSaveComplete(data){
      if (!data) {
        return;
      }

      let row = null;
      if (this.currentNode) {
        Object.assign(this.currentNode.data, data.row);
        this.gridOptions.api.applyTransaction({ update: [this.currentNode.data] });
      }
      else {
        row = this.gridOptions.api.applyTransaction({ add: [data.row] });
        if (row && row.add && row.add.length > 0)
          row.add[0].setSelected(true);
      }
    },
    openSidePanel(data){
      this.$sidePanel.show(this.$route.meta.sidePanelComponents, data).then(this.onSaveComplete);
    },
    //#endregion

    //#region API call functions
    async getDataModel(){
      const model = await this.getModel(this.reportType);

      if(model){
        this.model = model;
        this.title = this.$t('Reporting.Type.' + this.model.name);

        const sidePanelTemplateDefinition = [this.reportType];
        if (sidePanelTemplateDefinition) {
          this.sidePanelTemplate = sidePanelTemplateDefinition;
        }

        this.getData();
      }

    },
    async getData() {
      const apiResponse = await genericServices.getApiEntities(this.model.name, true, null, []);

      if (apiResponse.status == 200) {
        this.rowData = apiResponse.entities;
      }
      else {
        errors.displayApiError(apiResponse)
      }

      this.gridOptions.api.sizeColumnsToFit();
    },
    //#endregion

    getPrimaryKey(row){
      let key;

      for (let i = 0; i < this.model.properties.length; i++) {
        if(this.hasOwnPropertyCI(this.model.properties[i], 'keyAtt')){
          key = this.model.properties[i].name;
          break;
        }
      }

      for (const nodedatakey in row) {
        if (key.toLowerCase() === nodedatakey.toLowerCase()) {
          key = nodedatakey;
          break;
        }
      }

      return key;
    },
    filtersChanged(filters){
      this.filters = filters;
    }
  }
};
</script>
