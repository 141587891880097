import { roleServices } from "@/backoffice/modules/role/services/role.services";
import { convertApiRoleToRole, convertRoleToRoleDDto, convertApiRolePermissionsToRolePermissions } from '@/backoffice/modules/role/domain/role.js'

const getDefaultState = () => {
  return {
    roles: [],
    role: {},
    rolePermissions: [],
    posPermissions: [],
    permissions: []
  };
};

export default {
  namespaced: true,
  name: "Role",
  state: getDefaultState(),
  getters: {
    getRole: (state) => state.role,
    getRoles: (state) => state.roles,
    getRolePermissions: (state) => state.rolePermissions,
    getPosPermissions: (state) => state.posPermissions
  },

  mutations: {
    SET_ROLE(state, role){
      state.role = role
    },
    SET_ROLES(state, roles){
      state.roles = roles
    },
    SET_ROLE_PERMISSIONS(state, rolePermissions) {
      state.rolePermissions = rolePermissions
    },
    SET_POS_PERMISSIONS(state, posPermissions){
      state.posPermissions = posPermissions
    },
    DELETE_ROLE(state, role){
      const index = state.roles.findIndex(r => r.roleId == role.roleId )
      if (index > -1) {
        state.roles.splice(index, 1);
      }
    }
  },

  actions: {
    async fetchRoles({ commit }) {
      const response = await roleServices.getRoles();
      if (response.status == 200) {
        const roles = response.data.map(d => convertApiRoleToRole(d))
        commit('SET_ROLES', roles)
      }
      else {
        commit('SET_ROLES', [])
      }
    },

    async fetchRolePermissions({ commit }, { roleId: roleId, cancelToken: cancelToken }){
      const response = await roleServices.getRolePermissions(roleId, cancelToken);
      if (response.status == 200) {
        const permissions = response.data.map(d => convertApiRolePermissionsToRolePermissions(d)).filter(p => p != null)
        commit('SET_ROLE_PERMISSIONS', permissions.filter(p => !p.isPos))
        commit('SET_POS_PERMISSIONS', permissions.filter(p => p.isPos))
      }
      else {
        commit('SET_ROLE_PERMISSIONS', [])
        commit('SET_POS_PERMISSIONS', [])
      }
    },
    async saveRole({ commit }, role) {
      let response = null
      if(role.roleId > 0)
        response = await roleServices.updateRole(role);
      
      else
        response = await roleServices.createRole(role);
      
      if(response.status == 200)
        return convertApiRoleToRole(response.data)

    },
    async deleteRole({ commit }, role){
      const response = await roleServices.deleteRole(convertRoleToRoleDDto(role));

      if (response.status == 200) 
        commit('DELETE_ROLE', role)      
    }
  }
};
