<template src="./refundabledeposit-management.html"></template>

<style lang="scss">
    @import './refundabledeposit-management.scss';
</style>

<script>
import { mapActions } from 'vuex';
import { ValidationObserver, ValidationProvider, localize } from "vee-validate";
import prompts from '@/core/tools/notifications/notifications';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import { CurrencyInput  } from 'vue-currency-input';
import descriptionInput from '@/core/components/common/description-input/description-input.vue'
import scopeDropdown from '@/core/components/common/drop-down/scope-dropdown/scope-dropdown.vue'

export default {
    name: "refundabledeposit-template",

    components: {
        descriptionInput,
        scopeDropdown,
        ValidationObserver,
        ValidationProvider,
        localize,
        CurrencyInput
    },

    props:{
        templatePayload: Object
    },

    data() {
        return {
            currencyInputConfigs: {
                currency: null,
                locale: this.$i18n.locale,
                precision: 2,
                distractionFree: false
            },
            refundabledeposit: {
                id: null,
                targetId: null,
                description: null,
                descriptions: [],
                TargetDescription: null,
                canUpdate: true
            },
            refundabledepositCopy: null,
            preventSave: false
        }
    },

    computed: {
        readOnly() {
            return this.refundabledeposit && !this.refundabledeposit.canUpdate;
        },
        onEdit() {
            return this.refundabledeposit.id > 0;
        }
    },

    watch: {
        templatePayload: {
            handler(newVal, oldVal) {
                if(newVal && newVal != oldVal) {
                    this.setRefundableDeposit();
                }
            }
        },
        refundabledeposit: {
            handler(newentity){
                this.$sidePanel.isEdit(!isEqual(newentity, this.refundabledepositCopy));
            },
            deep: true
        }
    },

    mounted() {
        localize(this.$i18n.locale);
        this.setRefundableDeposit();
    },

    methods: {
        ...mapActions('RefundableDeposit', ['saveRefundableDeposit']),

        setRefundableDeposit() {
            if (this.templatePayload.id) {
                this.refundabledeposit = cloneDeep(this.templatePayload);
            }
            
            this.refundabledepositCopy = cloneDeep(this.refundabledeposit);
        },
        
        onCancel() {
            this.$sidePanel.close();
        },

        async onSaveRefundableDeposit() {
            let savedRefundableDeposit = null;
            this.preventSave = true;

            try {
                savedRefundableDeposit = await this.saveRefundableDeposit(this.refundabledeposit);
            } 
            catch (error) {
                prompts.error({
                    text: this.$i18n.t('RefundableDeposit.Messages.SaveRefundableDepositError')
                });
            }

            this.preventSave = false;

            if (savedRefundableDeposit) {
                this.$sidePanel.submit({ 'save': true, 'row': savedRefundableDeposit });
            }
        },
        
        onScopeChanged(target) {
            this.refundabledeposit.targetID = target ? target.id : 0;
        },
    }
}
</script>