<template src="./main-report.html"></template>

<script>
import { mapGetters, mapActions } from 'vuex';
import agGridGetters from '@/core/components/common/grid/getters/getters.js';
import prompts from '@/core/tools/notifications/notifications';
import creationButton from '@/core/components/common/creation-button/creation-button.vue'
import searchBar  from '@/core/components/common/search-bar/search-bar.vue'
import grid from  '@/core/components/common/grid/grid.vue'

export default {
  name: 'MainReport',
  components:{
    creationButton,
    searchBar,
    grid
  },
  
  data() {
    return {
      model: {},
      favorite: {},
      title: null,
      gridOptions: {
        headerHeight: 50,
        rowHeight: 50,
        groupHeaderHeight: 50,
        rowMultiSelectWithClick: false,
        rowSelection: 'single',
        suppressDragLeaveHidesColumns: true,
        defaultColDef: {
          resizable:true,
          headerCheckboxSelectionFilteredOnly: false,
        },
        rowData: [],
        onGridReady: this.init,
        onSelectionChanged: this.selectionChanged,
        onRowDoubleClicked: this.rowDoubleClicked
      },
      filters: [],
      selectedNodes: [],
      reports: [],
      incTest: 0,
    }
  },
  props: {
    reportType: String,
    labels: Object
  },
  computed:{
    ...mapGetters('Account', ['getCurrentTargetLayerId', 'hasPermission', 'getReportFavorite']),
    ...mapGetters('App', ['isMobile']),
    ...mapGetters('Reporting', ['reportList']),
    canCreate() {
      return this.model && this.model.permissions && this.hasPermission([this.model.permissions.report]);
    },
    compLabels(){
      const internalLabels={
        firstColumnLabel:'Reporting.Title'
      }
      return {...internalLabels,...(this.labels||{})};
    },
    bypassFavoriteOrDefaultRedirect(){
      return this.$route.query && this.$route.query.bypassFavoriteOrDefaultRedirect
    }
  },
  watch: {
    $route(){
      this.init();
    },
    getCurrentTargetLayerId(newVal, oldVal){
      if(newVal && newVal != oldVal){
        this.init();
      }
    },
    isMobile(newVal, oldVal){
      if(newVal != oldVal){
        this.setGridColumns();  
      }
    }
  },

  beforeDestroy() {
    if(this.gridOptions.api){
      this.gridOptions.api.setRowData([]);
      this.gridOptions.api.destroy();
      this.gridOptions = null;
    }
  },

  methods:{
    ...mapActions('Account', ['fetchReportFavorite','deleteReportFavorite']),
    ...mapActions('DataModels', ['getModel']),
    ...mapActions('Reporting', ['deleteReport', 'getReportList']),

    async init(){
      await this.getDataModel();
      await this.getReport();
    },

    async getDataModel(){
      this.model = await this.getModel(this.reportType);
    },

    async getReport(){
      await this.getReportList(this.reportType);
      await this.fetchReportFavorite(this.reportType);

      if(this.reportList){
        let redirectID = null;
        if (this.reportList.length > 0){
          if (this.getReportFavorite && this.getReportFavorite.reportDataModelID){
            redirectID = this.getReportFavorite.reportDataModelID;
          }
          else if (this.reportList.length == 1){
            redirectID = this.reportList[0]._ReportDataModelID;
          }
        }
        
        if (redirectID > 0 && !this.bypassFavoriteOrDefaultRedirect){
          this.$router.push({ name: `${this.$route.name}Read`, params: { _ReportDataModelID: redirectID } });
        }
          
        else{
          this.setGridColumns();
          this.gridOptions.api.setRowData(this.reportList);
          this.gridOptions.api.sizeColumnsToFit();
          this.setSelectedRow();
        }
      }
    },

    isManagement(){
      return ['Target', 'User', 'Product', 'Role'].includes(this.reportType);
    },

    setSelectedRow(){
      if(this.selectedNodes.length > 0){      
        this.gridOptions.api.forEachNode((node, idx) => {
          let i = 0;
          let found = false;
          while(i < this.selectedNodes.length && !found){
            if(idx == this.selectedNodes[i].id){
              node.setSelected(true);
              found = true;
            }
            i++;
          }
        });
      }
    },
    setGridColumns(){
      if(this.isMobile){
        this.gridOptions.columnDefs = [{ 
          field: 'description',
          sort: 'asc',
          headerValueGetter: this.headerValueTranslate,
          cellRenderer: "dropdownRenderer",
          cellRendererParams: (params) => {
            return {
              columnDefs: [{ headerName: this.$t('Reporting.TargetDescription'), field:'targetDescription' }],
              openSidePanel: this.executeReport,
              canDelete: () => params.data.canDelete,
              onDelete: this.delete
            }
          },
          cellClass: this.isMobile ? "dropdown-cellrenderer" : "",
          autoHeight: true
        }];
        this.gridOptions.api.setColumnDefs(this.gridOptions.columnDefs);
      }
      else{
        this.gridOptions.columnDefs = [];
        this.gridOptions.columnDefs.push({ 
          headerValueGetter: this.headerValueTranslate, 
          field:'description', 
          valueGetter: this.toUpperCase, 
          cellRenderer: 'menuRenderer',
          cellRendererParams:{
            editEntity: this.editEntity,
            delete: this.delete
          },
          cellClass: 'ag-cell-overflow', 
          sort: 'asc' 
        });
        /* NOTE: enhance Model in DataModels Store */
        if(!this.isManagement()) { 
          this.gridOptions.columnDefs.push({ 
            headerValueGetter: this.headerValueTranslate, 
            field:'period', 
            valueGetter: agGridGetters.rangePickerGetter 
          });
        }
        this.gridOptions.columnDefs.push({ headerValueGetter: this.headerValueTranslate, field:'targetDescription' });
        this.gridOptions.api.setColumnDefs(this.gridOptions.columnDefs);
      } 

      setTimeout(() => {
        this.gridOptions.api.sizeColumnsToFit();
      }, 350);
    },

    selectionChanged(){
      this.selectedNodes = this.gridOptions.api.getSelectedNodes();
    },
    rowDoubleClicked(params){
      this.runReport(params.node);
    },
    createReport(){
      this.$router.push({ name: `${this.$route.name}Create`});
    },
    editEntity(row){
      this.$router.push({ name: `${this.$route.name}Update`, params: { _ReportDataModelID: row.data._ReportDataModelID }});
    },
    readEntity(row){
      this.runReport(row);
    },
    async delete(){

      const result = await prompts.warning({
        html: this.$t('Reporting.DeleteMsg')
      });

      if (!result.isConfirmed) {
        return
      }

      const rows = this.gridOptions.api.getSelectedRows();
      if(rows.length > 0){
        const ids = rows.map(row => row._ReportDataModelID);

        const isDeleted = await this.deleteReport(ids);

        if (isDeleted) {
          this.gridOptions.api.applyTransaction({ remove: rows });

          if (this.getReportFavorite && ids.includes(this.getReportFavorite.reportDataModelID)){
            this.deleteReportFavorite(this.getReportFavorite);
          }
        }
      }
    },
    runReport(row){
      this.executeReport(row.data);
    },
    executeReport(rowData){
      this.$router.push({ name: `${this.$route.name}Read`, params: { _ReportDataModelID: rowData._ReportDataModelID} });
    },
    headerValueTranslate(params){
      switch(params.colDef.field){
        case 'description':
          return this.$t(this.compLabels.firstColumnLabel);
        case 'creator':
          return this.$t('Reporting.Owner');
        case 'period':
          return this.$t('Reporting.Period');
        case 'targetDescription':
            return this.$t('Reporting.TargetDescription');
        default:
          return params.colDef.field;
      }
    },
    formatCreator(row){
      if(row.data && row.data.user){
        return row.data.user.firstName + ' ' + row.data.user.lastName;
      }
    },
    toUpperCase(row){
      if(row.data){
        return row.data[row.colDef.field].toUpperCase();
      }
    },
    filtersChanged(filters){
      this.filters = filters;
    }
  }
}
</script>


