<template src="./order-info.html"></template>

<style lang="scss">
    @import './order-info.scss';
</style>

<script>
import { mapGetters, mapActions } from 'vuex';
import { OrderStatus } from '@/backoffice/modules/purchase/domain/purchase.js';
import prompts from '@/core/tools/notifications/notifications';

export default {
    name: 'Order-Info',

    computed: {
        ...mapGetters('Order', ['getOrder', 'getOrderReceivedInfos']),

        total() {
            if (!this.order.orderBodies)
                return 0;

            const total = this.order.orderBodies.reduce((total, b) => total + b.cost, 0);
            return total;
        },
        qty() {
            if (!this.order.orderBodies)
                return 0;

            const qty = this.order.orderBodies.reduce((total, b) => total + b.qty, 0);
            return qty;
        },
        receivedQty(){
            if(!this.getOrderReceivedInfos)
                return 0

            return Object.values(this.getOrderReceivedInfos).reduce((tq, { qty }) => tq + qty, 0)
        },
        receivedTotal(){
            if(!this.getOrderReceivedInfos)
                return 0
            
            return Object.values(this.getOrderReceivedInfos).reduce((tq, { cost }) => tq + cost, 0)
        },
        status(){
            if(!this.order.orderStatus)
                return null

            return this.$t(`Purchase.Order.Status.${this.order.orderStatus}`)
        },
        showReceivedInfo(){
            if (!this.order)
                return false;
                
            return this.order.orderStatus == OrderStatus.CLOSED || 
                   this.order.orderStatus == OrderStatus.PENDING || 
                   this.order.orderStatus == OrderStatus.PARTIALLYRECEIVED
        },
        isReadOnly() {
            if (!this.order)
                return true;

            return !this.order.canUpdate || this.order.orderStatus != OrderStatus.OPEN;
        },
        order: {
            get() {
                if (this.getOrder && this.getOrder.id != this.orderData.id) {
                    return { ...this.getOrder }
                }
                
                return { ...this.getOrder, ...this.orderData };
            },
            set(obj) {
                this.orderData = { ...this.getOrder, ...this.orderData, ...obj };
            }
        }
    },

    data() {
        return {
            orderData: {}
        }
    },

    methods: {
        ...mapActions('Order', ['saveOrder']),

        async onRefNumberKeypress(e) {
            if (e.keyCode == 13) {
                e.preventDefault();
                
                this.order = { referenceNO: e.target.value };
                
                await this.updateOrder('referenceNO', e.target.value, true);

                this.$refs.comment.focus();
            }
        },

        async onCommentKeyPress(e) {
            if (e.keyCode == 13 && !e.shiftKey) {
                e.preventDefault();

                this.order = { comment: e.target.value };

                await this.updateOrder('comment', e.target.value, true);
            }
        },

        async updateOrder(prop, value) {
            if (!value || this.getOrder[prop] == value) {
                return;
            }

            try {
                await this.saveOrder(this.order);
            } catch (error) {
                prompts.error({
                    text: this.$tc('Purchase.Messages.SaveOrderError')
                })
            }
        }
    }
}
</script>