import Api from '@/core/services/_api.config';

async function getWidgetClient(params, cancelToken, targetIDSelected){

  return await Api()
              .get('/api/Widget/data/Client?date=' + params.date + '&targetId=' + targetIDSelected, cancelToken)
              .then(response => {
                return {status: response.status, data: response.data };
              })
              .catch((e) => {
                if (e.response) {
                  return { status: e.response.status, message: e.response.data, data: null };
                }
                else {
                  return { message: e.message, data: null };
                }
              });
}

async function getWidgetGetStats(params, cancelToken, targetIDSelected){

  return await Api()
              .get('/api/Widget/data/GetStats?date=' + params.date + '&tl=' + params.tl + '&type=' + params.type + '&name=' + params.name + '&code=' + params.code + '&targetId=' + targetIDSelected, cancelToken )
              .then(response => {
                return {status: response.status, data: response.data };
              })
              .catch((e) => {
                if (e.response) {
                  return { status: e.response.status, message: e.response.data, data: null };
                }
                else {
                  return { message: e.message, data: null };
                }
              });

}

async function getWidgetGetStatsPer(params, cancelToken, targetIDSelected){

  return await Api()
              .get('/api/Widget/data/GetStatsPer?date='+ params.date +'&type=' + params.type + '&targetId=' + targetIDSelected, cancelToken)
              .then(response => {
                return {status: response.status, data: response.data };
              })
              .catch((e) => {
                if (e.response) {
                  return { status: e.response.status, message: e.response.data, data: null };
                }
                else {
                  return { message: e.message, data: null };
                }
              });

}

async function getWidgetSalesPerRange(params, cancelToken, targetIDSelected){

  return await Api()
              .get('/api/Widget/data/SalesPerRange?date=' + params.date + '&range=' + params.range + '&targetId=' + targetIDSelected, cancelToken)
              .then(response => {
                return {status: response.status, data: response.data };
              })
              .catch((e) => {
                if (e.response) {
                  return { status: e.response.status, message: e.response.data, data: null };
                }
                else {
                  return { message: e.message, data: null };
                }
              });

}

async function getWidgetSimple(params, cancelToken, targetIDSelected){

  return await Api()
              .get('/api/Widget/data/Simple?date=' + params.date + '&targetId=' + targetIDSelected, cancelToken)
              .then(response => {
                return {status: response.status, data: response.data };
              })
              .catch((e) => {
                if (e.response) {
                  return { status: e.response.status, message: e.response.data, data: null };
                }
                else {
                  return { message: e.message, data: null };
                }
              });
}

async function getWidgetTopSales(params, cancelToken, targetIDSelected){

  return await Api()
              .get('/api/Widget/data/TopSales?date=' + params.date + '&targetId=' + targetIDSelected, cancelToken)
              .then(response => {
                return {status: response.status, data: response.data };
              })
              .catch((e) => {
                if (e.response) {
                  return { status: e.response.status, message: e.response.data, data: null };
                }
                else {
                  return { message: e.message, data: null };
                }
              });

}

export const widgetsServices = {
  getWidgetClient,
  getWidgetGetStats,
  getWidgetGetStatsPer,
  getWidgetSalesPerRange,
  getWidgetSimple,
  getWidgetTopSales
};