const arrayFunctions = {
    chunks: (items, chunkSize) => {
        if(!items || !chunkSize)
            return

        const chunkedItems = []

        for (let i = 0; i < items.length; i += chunkSize) {
            chunkedItems.push(items.slice(i, i + chunkSize));
        }

        return chunkedItems
    }
}

const pagePrinter = (htmlContent)=>{
    const width =500
    const height = 500
    const left = screen.width/2-width/2
    const top = screen.height/2-height/2
    const printWindow = window.open('','_blank',`width=${width},height=${height},top=${top},left=${left},toolbars=no,scrollbars=no,status=no,resizable=no`)
    printWindow.document.write(htmlContent)
    printWindow.document.close()
    printWindow.addEventListener('blur',(event)=>{event.target.close()})
    printWindow.focus()
}

/**
 * Build and return the Html template of QR code to be printed.
 * @param {string} title Page title
 * @param {string} btnText Text to display on the button
 * @param {string} qrCode A Base64 image or url to the qrCode to display
 * @returns Retrun Html template of a QR Code to print.
 */
const getQrCodeHtmlTemplate = (title, btnText, qrCode)=>{
    return `<!DOCTYPE html>
            <html lang="en">
            <head>
            <meta charset="UTF-8">
            <meta http-equiv="X-UA-Compatible" content="IE=edge">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>${title}</title>
            <style>
                *{ margin: 0; padding: 0; }
                @media print {
                .print-button {
                    display: none !important;
                } 
                @page{
                    margin-top: 0; 
                    margin-bottom: 0;
                }
                }
                .qrcode { width: 100vw; height: 100vh; display: flex; flex-grow: 1; flex-flow: column; justify-content: center; align-items: center; }
                .qrcode img{width: auto;height: 250px;}
                .print-button{
                width: 150px; padding: 15px;
                margin-bottom: 10px; font-size: 16px; border: initial; cursor: pointer;
                outline: none; align-items: center; justify-content: center; display: flex;
                flex-flow: column; color: white; letter-spacing: 0.05em; background-color: #005394;
                border-radius: 7px; border: 0; -webkit-box-shadow: 3px 3px 3px #ccc; box-shadow: 0px 2px 5px #ccc;
                }
                .print-button:hover{background-color: #287dbf;}
            </style>
            </head>
            <body>
            <div class="qrcode">
                <button class="print-button" onclick="window.print(); window.close()">${btnText}</button>
                <img src="${qrCode}" alt="QR Code">
            </div>
            </body>
            </html>`
}

export { arrayFunctions, pagePrinter, getQrCodeHtmlTemplate };