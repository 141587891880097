<template src="./receiving-product-scan.html"></template>

<style lang="scss">
    @import './receiving-product-scan.scss';
</style>

<script>
import { mapGetters } from 'vuex';
import scan from '@/core/components/layout/side-panel/scan/scan.vue';
import receivingProduct from '@/backoffice/modules/purchase/components/sidePanel/receivings/receiving-product/receiving-product.vue';

export default {
    name: 'receiving-product-scan',

    components: {
        scan
    },

    data() {
        return {
            invalidProduct: false
        }
    },

    computed: {
        ...mapGetters('Receiving', ['getReceiving']),

        receivingNO() {
            return this.getReceiving.receivingNO;
        }
    },

    methods: {
        searchSku(sku) {
            const paddedSku = sku.padStart(13,'0')
            if (this.getReceiving.receivingBodies.some(body => body.purchaseBodyInfo.itemUPC == paddedSku)) {
                this.$sidePanel.show([{ component: receivingProduct }], { itemUPC: paddedSku });
            }
            else {
                this.invalidProduct = true;

                this.$nextTick(() => {
                    this.$refs.backToScan.focus();
                });
            }
        },

        backToScan() {
            this.invalidProduct = false;
        },
        cancel(){
            this.$sidePanel.close();
        }
    }
}
</script>