import Api, { ApiBlob } from '@/core/services/_api.config'

const labelBatchUrl = '/api/LabelBatch'

export default {
    getBatch: async () => {
        const response = await Api().get(labelBatchUrl)
        return response.data
    },
    getBatchSize: async () => {
        const response = await Api().get(labelBatchUrl + '/size')
        return response.data
    },
    addToBatch: async (itemUpcs) => {
        const response = await Api().post(labelBatchUrl, itemUpcs)
        return response.data
    },
    removeFromBatch: async (itemUpcs) => {
        const response = await Api().post(labelBatchUrl + '/remove', itemUpcs)
        return response.data
    },
    emptyBatch: async () => {
        const response = await Api().delete(labelBatchUrl)
        return response.status === 200
    },
    getLabelsPdf: async (targetLayerId, itemUpcs) => {
        const response = await ApiBlob().post(`${labelBatchUrl}/${targetLayerId}/labelsPdf`, itemUpcs)
        return response.data
    },
    setLabelsQuantities: async (targetLayerId, labelItems) => {
        const response = await Api().post(`${labelBatchUrl}/${targetLayerId}/setlabelquantity`, labelItems)
        return response.status === 200
    }
}