import Api from '@/core/services/_api.config';

//TODO: Stop returning in catches. Throw instead or dont use try catch

async function getAggregatedDocuments() {
    let response;
    try{
        response = await Api().get(`/api/Inventory/Document`)
    }
    catch(e) {
        return { status: e?.response?.status, message: e?.response?.data, data: null };
    }

    return { status: response.status, data: response.data }
}

async function getDocument(id) {
    let response;
    try{
        response = await Api().get(`/api/Inventory/Document/${id}`)
    }
    catch(e) {
        return { status: e?.response?.status, message: e?.response?.data, data: null };
    }
    
    return { status: response.status, data: response.data }
}

async function addDocument(document){
    let response;
    try{
        response = await Api().post('/api/Inventory/Document/', document)
    }
    catch(e) {
        return { status: e?.response?.status, message: e?.response?.data, data: null };
    }
    
    return { status: response.status, data: response.data }
}

async function updateDocument(document){
    let response;
    try{
        response = await Api().put('/api/Inventory/Document/', document)
    }
    catch(e) {
        return { status: e?.response?.status, message: e?.response?.data, data: null };
    }
    
    return { status: response.status, data: response.data }
}

async function forceOpenUpdateDocument(document) {
    let response;
    try{
        response = await Api().put('/api/Inventory/Document/ForceOpen/', document)
    }
    catch(e) {
        return { status: e?.response?.status, message: e?.response?.data, data: null };
    }
    
    return { status: response.status, data: response.data }
}

async function deleteDocument(document){
    let response;
    try{
        response = await Api().delete('/api/Inventory/Document/', { data: document })
    }
    catch(e) {
        return { status: e?.response?.status, message: e?.response?.data, data: null };
    }
    
    return { status: response.status, data: response.data }
}

async function getInventory(upc){
    let response;
    try{
        response = await Api().get(`/api/Inventory/${upc}`)
    }
    catch(e) {
        return { status: e?.response?.status, message: e?.response?.data, data: null };
    }
    
    return { status: response.status, data: response.data }
}

async function getInventories(upcs){
    let response;
    try{
        response = await Api().post(`/api/Inventory/`, upcs)    
    }
    catch(e) {
        return { status: e?.response?.status, message: e?.response?.data, data: null };
    }
    
    return { status: response.status, data: response.data }
}

async function getAdjustmentReason() {
    return new Promise(resolve => {
        const reason = [
            { code: 'Adjustment+', value: 'AdjustPlusReason1', description: '', fr: 'Gratuité', en: 'Free'}, 
            {code: 'Adjustment+', value: 'AdjustPlusReason2', description: '', fr: 'Retrouvé', en: 'Found'}, 
            {code: 'Adjustment-', value: 'AdjustMinusReason1', description: '', fr: 'Pertes', en: 'Lost'}, 
            {code: 'Adjustment-', value: 'AdjustMinusReason2', description: '', fr: 'Endommagé', en: 'Damaged'}, 
            {code: 'Adjustment-', value: 'AdjustMinusReason3', description: '', fr: 'Périmé', en: 'Expired'},
            {code: 'Adjustment-', value: 'AdjustMinusReason4', description: '', fr: 'Vol', en: 'Theft'},
            {code: 'Adjustment-', value: 'AdjustMinusReason5', description: '', fr: 'Transféré', en: 'Transferred'}
        ];
        resolve({status: 200, data: reason})
    })
}

async function validate(id, targetLayerId, upc, sku, docType, documentStatus){
    let response;
    try{
        response = await Api().get(`/api/Inventory/Document/Validate?id=${id}&targetLayerID=${targetLayerId}&sku=${sku}&upc=${upc}&docType=${docType}&documentStatus=${documentStatus}`)
    }
    catch(e) {
        return { status: e?.response?.status, message: e?.response?.data, data: null };
    }
    
    return { status: response.status, data: response.data }
}

export const inventoryServices = {
    getAggregatedDocuments,
    getDocument,
    addDocument,
    updateDocument,
    forceOpenUpdateDocument,
    deleteDocument,
    getInventory,
    getInventories,
    getAdjustmentReason,
    validate
};
