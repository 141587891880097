import Api from '@/core/services/_api.config';
import { descriptionLanguages } from '@/core/domain/language/language.const.js';

const receivingApiRoute = '/api/Purchase/Receiving';

async function getAggregateReceivings() {
  try {
    const response = await Api().get(`${receivingApiRoute}/aggregates`);
    return {status: response.status, data:response.data}
  } catch (e) {
    return {messge: e?.message,status: e?.response?.status, data: e?.response?.data}
  }
}

async function getDirectReceivings() {
  try {
    const response = await Api().get(`${receivingApiRoute}/directReceivings`);
    return {status: response.status, data:response.data}
  } catch (e) {
    return {messge: e?.message,status: e?.response?.status, data: e?.response?.data}
  }
}

async function getReceiving(id){
    try
    {
        const response = await Api().get(`${receivingApiRoute}/${id}`);
        return { status: response.status, data: response.data }
    }
    catch(e) {
      return {messge: e?.message,status: e?.response?.status, data: e?.response?.data}
    }
}

async function getReceivings(params) {
  try
  {
    const searchCriteria = encodeURIComponent(JSON.stringify(params))
    const response = await Api().get(`${receivingApiRoute}/?receivingSearchCriteria=${searchCriteria}`);
    return { status: response.status, data: response.data };
  }
  catch(e) {
    return {messge: e?.message,status: e?.response?.status, data: e?.response?.data}
  }
}

async function addReceiving(receiving) {
    try
    {
      const response = await Api().post(receivingApiRoute, receiving);
      return { status: response.status, data: response.data };
    }
    catch(e) {
      return {messge: e?.message,status: e?.response?.status, data: e?.response?.data}
    }
}

async function updateReceiving(receiving) {
    try
    {
      const response = await Api().put(receivingApiRoute, receiving);
      return { status: response.status, data: response.data };
    }
    catch(e) {
      return {messge: e?.message,status: e?.response?.status, data: e?.response?.data}
    }
}

async function getReceivingVarianceReasons( ) {
  return new Promise(resolve => {
      const reason = [
          { code: 'ReceivingVariance', value: 'ShippingCancelled', description: '', 
            descriptions: [ 
              { _DescriptionID: 0, id: 0, language: descriptionLanguages.FR, value: 'Livraison annulée' }, 
              { _DescriptionID: 0, id: 0, language: descriptionLanguages.EN, value: 'Shipping cancelled' } 
            ]
          }, 
          { code: 'ReceivingVariance', value: 'BackOrder', description: '', 
            descriptions: [ 
              { _DescriptionID: 0, id: 0, language: descriptionLanguages.FR, value: 'Rupture de stock' }, 
              { _DescriptionID: 0, id: 0, language: descriptionLanguages.EN, value: 'Back order' } 
            ]
          }, 
          { code: 'ReceivingVariance', value: 'DifferentQuantity', description: '', 
            descriptions: [ 
              { _DescriptionID: 0, id: 0, language: descriptionLanguages.FR, value: 'Quantité différente' }, 
              { _DescriptionID: 0, id: 0, language: descriptionLanguages.EN, value: 'Different quantity' } 
            ]
          }, 
          { code: 'ReceivingVariance', value: 'Damaged', description: '', 
            descriptions: [ 
              { _DescriptionID: 0, id: 0, language: descriptionLanguages.FR, value: 'Endommagé' }, 
              { _DescriptionID: 0, id: 0, language: descriptionLanguages.EN, value: 'Damaged' } 
            ]
          }, 
          { code: 'ReceivingVariance', value: 'PromotionalMaterial', description: '', 
            descriptions: [ 
              { _DescriptionID: 0, id: 0, language: descriptionLanguages.FR, value: 'Matériel promotionnel' }, 
              { _DescriptionID: 0, id: 0, language: descriptionLanguages.EN, value: 'Promotional material' } 
            ]
          }, 
          { code: 'ReceivingVariance', value: 'Other', description: '', 
            descriptions: [ 
              { _DescriptionID: 0, id: 0, language: descriptionLanguages.FR, value: 'Autre' }, 
              { _DescriptionID: 0, id: 0, language: descriptionLanguages.EN, value: 'Other' } 
            ]
          }
      ];
      resolve({status: 200, data: reason});
  });
}

async function closeReceiving(params) {
  try
  {
    const response = await Api().put(`${receivingApiRoute}/${params.receivingId}/close`, params.receivingVariances);
    return { status: response.status, data: response.data };
  }
  catch(e) {
    return {messge: e?.message,status: e?.response?.status, data: e?.response?.data}
  }
}

async function closeDirectReceiving(receivingId) {
  try
  {
    const response = await Api().put(`${receivingApiRoute}/${receivingId}/closeDirectReceiving`);
    return { status: response.status, data: response.data };
  }
  catch(e) {
    return {messge: e?.message,status: e?.response?.status, data: e?.response?.data}
  }
}

export const receivingServices = {
    getAggregateReceivings,
    getReceiving,
    getReceivings,
    addReceiving,
    updateReceiving,
    getReceivingVarianceReasons,
    closeReceiving,
    closeDirectReceiving,
    getDirectReceivings
};
