<template src="./checkbox.html"></template>

<style scoped lang="scss">
@import './checkbox.scss';
</style>

<script>
export default{
    name: 'checkbox',
    props: {
        value: Boolean,
        label: String,
        disabled: Boolean,
        smallCheckbox: Boolean
    },
    computed: {
        check: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    }
};
</script>