<template src="./exception-report.html"></template>
<style lang="scss" src="./exception-report.scss">

</style>

<script>
import { mapActions, mapGetters } from 'vuex';
import prompts from '@/core/tools/notifications/notifications';
import searchBar from '@/core/components/common/search-bar/search-bar.vue';
import targetDropdown from '@/core/components/common/drop-down/target-dropdown/target-dropdown.vue';
import periodPicker from '@/backoffice/modules/reporting/components/period-picker/period-picker.vue';
import grid from '@/core/components/common/grid/grid.vue';
import extractTime from '@/backoffice/modules/reporting/mixins/extract-time.js';
import formatters from '@/core/components/common/grid/formatters/formatters.js';

export default {
    name: 'Exception-Report',
    mixins: [extractTime],
    components: {
        searchBar,
        targetDropdown,
        periodPicker,
        grid,
    },

    data() {
        return {
            gridReadyPromiseResolver: null,
            targetChangedEventPromiseResolver: null,
            componentInitialized: false,
            filters: null,
            period: '-0w',
            startDate: null,
            endDate: null,
            targetLayerId: null,
            targetDropdownKey: 0,
            exceptions: [],
            gridOptions: {
                sideBar: true,
                rowGroupPanelShow: 'always',
                groupIncludeTotalFooter: true,
                defaultColDef: {
                    sortable: true,
                    resizable: true,
                    filter: 'agSetColumnFilter'
                },
                columnDefs: [
                    {
                        headerName: this.$t('Exception.Report.Group'), headerTooltip: this.$t('Exception.Report.Group'),
                        children: [
                            { field: 'enterprise', hide: true, headerName: this.$t('Exception.Report.Enterprise'), headerTooltip: this.$t('Exception.Report.Enterprise'), enableRowGroup: true, rowGroupIndex: 0 },
                            { field: 'store', hide: true, headerName: this.$t('Exception.Report.Store'), headerTooltip: this.$t('Exception.Report.Store'), enableRowGroup: true, rowGroupIndex: 1 },
                        ]
                    },
                    {
                        headerName: this.$t('Exception.Report.ExceptionType'), headerTooltip: this.$t('Exception.Report.ExceptionType'),
                        children: [
                            { field: 'exceptionDescription', headerName: this.$t('Exception.Report.ExceptionType'), headerTooltip: this.$t('Exception.Report.Group'), enableValue: true, aggFunc: 'sum'},
                        ]
                    },
                    {
                        headerName: this.$t('Exception.Report.Amount'), headerTooltip: this.$t('Exception.Report.Amount'),
                        children: [
                            { field: 'amount', headerName: this.$t('Exception.Report.Amount'), headerTooltip: this.$t('Exception.Report.Group'), enableValue: true, aggFunc: 'sum', valueFormatter: formatters.currencyFormatter},
                        ]
                    },
                    {
                        headerName: this.$t('Exception.Report.NbOfLines'), headerTooltip: this.$t('Exception.Report.NbOfLines'),
                        children: [
                            { field: 'lineCount', headerName: this.$t('Exception.Report.NbOfLines'), headerTooltip: this.$t('Exception.Report.NbOfLines'), enableValue: true, aggFunc: 'sum'}
                        ]
                    }
                ],
                onGridReady: () => {
                    this.gridReadyPromiseResolver();
                }
            },
            periodPickerOptions : [
                { name : "RangePicker.today", value : "-0d", selected : false },
                { name : "RangePicker.yesterday", value : "-1d", selected : false },
                { name : "RangePicker.this", selected : false, choices : [
                    { name : "RangePicker.thisWeek", value : "-0w", selected : false },
                    { name : "RangePicker.thisMonth", value : "-0m", selected : false },
                    { name : "RangePicker.thisQuarter", value : "-0q", selected : false },
                    { name : "RangePicker.thisYear", value : "-0y", selected : false }
                    ]
                },
                { name : "RangePicker.last", selected : false, choices : [
                    { name : "RangePicker.lastWeek", value : "-1w", selected : false },
                    { name : "RangePicker.lastMonth", value : "-1m", selected : false },
                    { name : "RangePicker.lastQuarter", value : "-1q", selected : false },
                    { name : "RangePicker.lastYear", value : "-1y", selected : false }
                    ]
                },
                { name : "RangePicker.custom", dates: "", selected : false, isDateRangePicker : true, value: "" }
            ]
        }
    },

    computed: {
        ...mapGetters('Account', ['getCurrentTargetLayerId']),
        rowData(){
            return this.exceptions
        }
    },

    watch: {
        getCurrentTargetLayerId() {
            this.targetDropdownKey++;
        }
    },

    created() {
        Promise.all([
            new Promise(resolve => {
                this.gridReadyPromiseResolver = resolve;
            }),
            new Promise(resolve => {
                this.targetChangedEventPromiseResolver = resolve;
            })
        ]).then(async () => {
            await this.fetchExceptions();
            this.componentInitialized = true;
        });
    },

    methods: {
        ...mapActions('Reporting', ['getExceptionsReport']),
        async fetchExceptions() {
            const dates = this.convert(this.period)
            this.startDate = dates.start
            this.endDate = dates.end

            const request = {
                startDate: this.startDate,
                endDate: this.endDate,
                targetLayerId: this.targetLayerId
            }

            this.gridOptions.api.showLoadingOverlay();

            try {
                this.exceptions = await this.getExceptionsReport(request)
            }
            catch (e) {
              prompts.error({
                  text: this.$t('Exception.Message.GetExceptionReportError')
              });
            }

            this.gridOptions.api.hideOverlay();
        },
        periodChanged(period) {
            this.period = period;
            this.fetchExceptions();
        },
        onTargetChanged(target) {
            this.targetLayerId = target.targetLayerId;
            if (!this.componentInitialized) {
                this.targetChangedEventPromiseResolver()
            }
            else {
                this.fetchExceptions();
            }
        },
        filtersChanged(filters) {
            this.filters = filters;
        }
    }
}
</script>