<template src="./role.html"></template>

<style lang="scss">
@import './role.scss';
</style>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { ValidationObserver, localize } from "vee-validate";
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import prompts from '@/core/tools/notifications/notifications';
import { Role } from '@/backoffice/modules/role/domain/role.js';
import { getCurrentLanguageDescription } from '@/core/functions/descriptions-helper.js';

import grid from  '@/core/components/common/grid/grid.vue'
import descriptionInput from '@/core/components/common/description-input/description-input.vue'
import scopeDropdown from '@/core/components/common/drop-down/scope-dropdown/scope-dropdown.vue'

export default {
    name: 'role-tab',
    components: {
        grid,
        descriptionInput,
        scopeDropdown,
        ValidationObserver
    },

    data() {
        return {
            preventSave: false,
            role: new Role(),
            roleCopy: new Role(),
            descriptionKey: 0,
            targetLayerId: 0
        }
    },

    watch: {
        isEdited(val) {
            this.$sidePanel.isEdit(val)
        }
    },
    computed: {
        ...mapGetters('Role', ['getRolePermissions', 'getRole', 'getPosPermissions']),
        ...mapGetters('Account', ['hasPermission']),

        isCreate() {
            return this.getRole?.roleId == -1
        },
        isEdit() {
            return this.getRole?.roleId > 0
        },
        isEdited() {
            return this.isEdit && !isEqual(this.role, this.roleCopy)
        },
        readOnly() {
            return this.isEdit && !this.getRole?.canUpdate
        }
    },
    mounted() {
        localize(this.$i18n.locale)
        this.loadComponent()
    },
    methods: {
        ...mapActions('Role', ['saveRole']),
        ...mapMutations('Role', ['SET_ROLE']),
        loadComponent() {
            if(!this.isEdit && !this.isCreate){
                this.$nextTick(() => {
                    this.$sidePanel.disabledBackground(true)
                    this.$sidePanel.disabledTabs(true)
                })
            }
            else{
                this.role = cloneDeep(this.getRole)
                this.roleCopy = cloneDeep(this.getRole)
                this.descriptionKey++
            }
        },
        async save() {
            const wasEdit = this.isEdit
            this.preventSave = true
            let savedRole = null

            const permissions = this.getRolePermissions.concat(this.getPosPermissions)
            const role = { ...this.role, targetLayerId: this.targetLayerId, permissions: permissions }
            try {
                savedRole = await this.saveRole(role)
            }
            catch (error) {
                prompts.error({
                    text: this.$t('Role.Messages.SaveRolePermissionsError')
                })
            }

            this.preventSave = false

            if (savedRole?.roleId > 0) {
                this.$sidePanel.submit(true)

                prompts.toast({
                    icon: 'success',
                    title: this.$t(`Role.Messages.${wasEdit ? 'UpdateRoleSuccessfully' : 'CreateRoleSuccessfully'}`),
                    position: 'bottom-end'
                })
            }
        },

        cancel() {
            this.$sidePanel.close()
        },
        onTargetChanged(target) {
            this.role.targetId = target.id
            this.targetLayerId = target.tlid
        },
        next() {
            const description = this.role.descriptions[0]
            this.SET_ROLE({ ...this.role, targetLayerId: this.targetLayerId, description: description.longDescription, roleId: -1 })
            this.$sidePanel.disabledTabs(false)
            this.$sidePanel.nextTab()
        }
    }
}
</script>