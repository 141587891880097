<template src="./model-column-picker.html"></template>
<style lang="scss" scoped src="./model-column-picker.scss"></style>

<script>
import multiselect from 'vue-multiselect';
export default {
  name: 'modelColumnPicker',
  model: {
    prop: "column"
  },
  props:{
    options: {
      type: Array,
      required: true,
      default: function () {
        return [];
      }
    },
    column: String
  },
  components:{
    multiselect
  },
  data() {
    return {
      multiSelect:{
        value: null,
        trackBy: 'name',
        allowEmpty: false,
        label: 'description',
        groupLabel: 'model',
        groupValues: 'columns',
        showLabels: false,
        placeholder: this.$t('Filters.Placeholder')
      },
    }
  },
  computed:{
    selectedColumn:{
      get() {
        if (this.options && this.column) {
          const model = this.options.find(op => op.columns.find(col => col.name == this.column));
          if (model)
            return model.columns.find(col => col.name == this.column);
        }
        return null;
      },
      set(value){
        this.$emit('input', value);
      }
    }
  },
  methods: {
    translateLabel({ description }) {
      return this.$t(description);
    }
  }
}
</script>