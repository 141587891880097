import Api from '../_api.config';
import AnonymousApi from '../_api.config.anonymous';

async function changePassword(newPassword){

  return await Api(newPassword.token)
              .post('/api/ForgotPassword/chg', newPassword)
              .then(response => {
                return ({status: response.status, data: response.data});
              })
              .catch((e) => {
                if (e.response) {
                  return { status: e.response.status, message: e.response.data, data: null };
                }
                else {
                  return { message: e.message, data: null };
                }
              });
}

async function forgotPassword(email){

  return await AnonymousApi()
              .post('/api/ForgotPassword', email)
              .then(response => {
                return ({status: response.status, data: response.data});
              })
              .catch((e) => {
                if (e.response) {
                  return { status: e.response.status, message: e.response.data, data: null };
                }
                else {
                  return { message: e.message, data: null };
                }
              });
}

export const forgotPasswordServices = {
    changePassword,
    forgotPassword
};

