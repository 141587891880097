import { render, staticRenderFns } from "./click-callback-renderer.html?vue&type=template&id=5901b5f7&"
import script from "./click-callback-renderer.vue?vue&type=script&lang=js&"
export * from "./click-callback-renderer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports