<template src="./priceLevel.html"></template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import Grid from '@/core/components/common/grid/grid.vue'
import CreationButton from '@/core/components/common/creation-button/creation-button.vue'
import SearchBar from '@/core/components/common/search-bar/search-bar.vue'
import PriceLevelManagement from '@/backoffice/modules/priceLevel/components/priceLevel-management/priceLevel-management.vue'
import getters from '@/core/components/common/grid/getters/getters.js'
import { permissionsDictionary } from '@/core/security/permissions-dictionary.js'
import { GridId, AgFilter, MultiFilterParams } from '@/core/components/common/grid/grid.const.js'
import * as PriceLevelServices from '@/backoffice/modules/priceLevel/services/priceLevel.services'
import { PriceLevelConflictError } from '@/backoffice/modules/priceLevel/domain/priceLevelException'
import prompts from '@/core/tools/notifications/notifications'

export default {
    name: 'PriceLevel',

    components: {
        Grid,
        SearchBar,
        CreationButton,
        PriceLevelManagement
    },
    
    data() {
        return {
            gridOptions: {
                headerHeight: 50,
                rowHeight: 40,
                defaultColDef: {
                    resizable: true,
                    sortable: true,
                    enableRowGroup: true,
                    floatingFilter: true,
                    filter: 'agMultiColumnFilter'
                },
                sideBar: {
                    toolPanels: [
                        'columns',
                        'filters'
                    ],
                    hiddenByDefault: true,
                    defaultToolPanel: 'columns'
                },
                rowSelection: 'single',
                rowGroupPanelShow: 'onlyWhenGrouping',
                suppressDragLeaveHidesColumns: true,
                suppressMakeColumnVisibleAfterUnGroup: true,
                overlayNoRowsTemplate: '<span>' + this.$t('PriceLevel.Messages.NoPriceLevelFound') + '</span>',
                onGridReady: () => {
                    this.gridOptions.api.setSideBarVisible(true)
                    this.gridOptions.api.sizeColumnsToFit()
                },
                getRowId: (params) => params?.data?.id
            },
            priceLevels: null,
            filters: []
        }
    },

    computed: {
        ...mapGetters('Account', ['hasPermission', 'getCurrentTarget', 'getCurrentTargetLayerId']),

        columnDefs() {
            return [
                {
                    field: 'description',
                    headerName: this.$t('PriceLevel.Description'),
                    minWidth: 100,
                    headerTooltip: this.$t('PriceLevel.Description'),
                    cellClass:'ag-cell-overflow',
                    filter: 'agTextColumnFilter',
                    cellRenderer: 'menuRenderer',
                    valueGetter: getters.description,
                    cellRendererParams: {
                        canUpdate: row => row.data.targetId === this.getCurrentTarget._TargetID && this.hasPermission([permissionsDictionary.ITEM_PRICE_UPDATE]),
                        canDelete: row => row.data.targetId === this.getCurrentTarget._TargetID && this.hasPermission([permissionsDictionary.ITEM_PRICE_DELETE]),
                        clickMenuLink: row => this.editPriceLevel(row.data),
                        editEntity: row => this.editPriceLevel(row.data),
                        delete: row => this.deletePriceLevel(row.data)
                    },
                    enableRowGroup: false,
                    filterParams: MultiFilterParams.NormalizeTextFilters
                },
                { field: 'target', headerName: this.$t('PriceLevel.Target'), headerTooltip: this.$t('PriceLevel.Target'), cellClass: 'stringType', filterParams: MultiFilterParams.NormalizeTextFilters },
                { field: 'code', headerName: this.$t('PriceLevel.Code'), headerTooltip: this.$t('PriceLevel.Code'), filter: AgFilter.NumberColumnFilter }
            ]
        },

        getGridId() {
            return GridId.PriceLevels
        },

        canCreate() {
            return this.hasPermission([permissionsDictionary.ITEM_PRICE_CREATE])
        },

        rowData() {
            return this.priceLevels
        }
    },
    
    watch:{
        getCurrentTargetLayerId() {
            this.loadPriceLevels()
        },
        rowData:{
            handler(){
                this.gridOptions.api?.refreshCells()
            },
            deep: true
        }
    },

    mounted() {
        this.loadPriceLevels()
    },

    methods: {
        ...mapMutations('PriceLevel', { setPriceLevel: 'SET_PRICE_LEVEL' }),

        filtersChanged(filters) {
            this.filters = filters
        },

        async loadPriceLevels() {
            this.priceLevels = null
            try {
                this.priceLevels = await PriceLevelServices.getPriceLevels()
            } catch (error) {
                this.priceLevels = []
                prompts.error({
                    text: this.$t('PriceLevel.Messages.GetPriceLevelsError')
                })
            }

            if (!this.priceLevels?.length)
                this.gridOptions.api.showNoRowsOverlay()
        },

        async editPriceLevel(priceLevel) {
            this.setPriceLevel(priceLevel)
            const updatedPriceLevel = await this.$sidePanel.show([{ component: PriceLevelManagement }], null, { backgroundIsDisabled: true })
            if (!updatedPriceLevel)
                return
            
            let priceLevelRow = this.priceLevels.find(pl => pl.id === updatedPriceLevel.id)
            if (priceLevelRow)
                priceLevelRow = Object.assign(priceLevelRow, updatedPriceLevel)
        },

        async createPriceLevel() {
            this.setPriceLevel(null)
            const newPriceLevel = await this.$sidePanel.show([{ component: PriceLevelManagement }], null, { backgroundIsDisabled: true })
            if (newPriceLevel)
                this.priceLevels.push(newPriceLevel)
        },

        
        async deletePriceLevel(priceLevel) {
            const result = await prompts.warning({html: this.$t('PriceLevel.Messages.DeletePriceLevel')});
            if (result.isConfirmed) {
                try {
                    await PriceLevelServices.deletePriceLevel(priceLevel.id)
                    this.priceLevels = this.priceLevels.filter(pl => pl.id !== priceLevel.id)
                    prompts.toast({
                        icon: 'success',
                        title: this.$t('PriceLevel.Messages.DeletePriceLevelSuccess'),
                        position: 'bottom-end'
                    })
                }
                catch(error) {
                    const errorMessage = error instanceof PriceLevelConflictError ? 'PriceLevel.Messages.DeletePriceLevelConflictError' : 'PriceLevel.Messages.DeletePriceLevelError'
                    prompts.error({
                        text: this.$t(errorMessage)
                    })
                }
            }
        }
    }
}
</script>