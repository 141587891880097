import Labels from '@/backoffice/modules/labels/labels.vue'
import GenerateBatch from '@/backoffice/modules/labels/components/generateBatch/generateBatch.vue'
import PrintBatch from '@/backoffice/modules/labels/components/print/print.vue'
import { permissionsDictionary } from '@/core/security/permissions-dictionary.js'

export default {
  path: 'labels',
  redirect: 'generateBatch',
  component: Labels,
  meta: {
    breadcrumb: {
      linkDescription: 'Breadcrumbs.Management.Labels',
      routeNameTo: null,
      explicitTail: null
    }
  },
  children:[
    {
      path: 'generateBatch',
      name: 'labels',
      component: GenerateBatch,
      associatedParentRouteName: null,
      meta: {
        requiresAuth: true,
        associatedParentRouteName: null,
        sideNav: {
          image: null,
          label: 'SideNav.Management.Labels'
        },
        permissions: [permissionsDictionary.LABEL_READ, permissionsDictionary.LABEL_UPDATE],
        breadcrumb: {
          linkDescription: 'Breadcrumbs.Management.GenerateBatch',
          routeNameTo: null,
          explicitTail: null
        }
      }
    },
    {
      path: 'print',
      name: 'printBatch',
      component: PrintBatch,
      associatedParentRouteName: null,
      meta: {
        requiresAuth: true,
        associatedParentRouteName: null,
        permissions: [permissionsDictionary.LABEL_READ, permissionsDictionary.LABEL_UPDATE],
        breadcrumb: {
          linkDescription: 'Breadcrumbs.Management.Printing',
          routeNameTo: null,
          explicitTail: null
        }
      }
    },
  ]
}