<template src="./currency-renderer.html"></template>

<script>
import { toCurrency } from "@/core/functions/format-value.js"

export default{
  name: 'currencyRenderer',

  computed: {
    value() {
      if (!isNaN(this.params?.value)) {
        return toCurrency(this.params.value);
      }
      return null 
    }
  }
};
</script>
