<template src="./dashboard.html"></template>

<style lang="scss">
    @import './dashboard.scss';
</style>

<script>
export default {
  name: 'Dashboard'
}
</script>