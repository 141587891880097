export class PriceLevel {
    constructor(id, targetId, code, isDeleted, descriptions, target) {
        this.id = id
        this.targetId = targetId
        this.code = code
        this.descriptions = descriptions
        this.target = target
        this.isDeleted = isDeleted
    }
}

export class Description {
    constructor(id, priceLevelId, language, value) {
        this.id = id
        this.priceLevelId = priceLevelId
        this.language = language
        this.value = value
    }
}

export class PriceLevelDto {
    constructor(id, targetId, code, isDeleted, descriptions, targetDescription) {
        this.id = id
        this.targetId = targetId
        this.targetDescription = targetDescription
        this.code = code
        this.descriptions = descriptions
        this.isDeleted = isDeleted
    }
}

export class DescriptionDto {
    constructor(id, priceLevelId, language, value) {
        this.id = id
        this.priceLevelId = priceLevelId
        this.language = language
        this.value = value
    }
}