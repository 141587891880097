import { itemClient } from '@/backoffice/modules/item/item.client.js';
import { arrayFunctions } from '@/core/functions/utils.js';
import { Operators } from '@/core/components/common/backend-filters/operators/operators.js';
import { UnitUOM } from '@/backoffice/modules/inventory/domain/document.js';
import { convertApiItemToItem } from '@/backoffice/modules/item/domain/item.js'
import moment from 'moment';

const getDefaultState = () => {
  return {
    productWithVendorCosts: null,
    enhancedProduct: {
      productCost: {},
      uom: UnitUOM
    }
  }
}

export const Product = {
  namespaced: true,
  name: 'Product',
  state: getDefaultState(),
  getters: {
    getProductWithVendorCosts: state => {
      return state.productWithVendorCosts;
    },
    getEnhancedProduct: state =>{
      return state.enhancedProduct;
    }
  },

  mutations: {
    RESET_STORE(state){
      Object.assign(state, getDefaultState());
    },
    SET_PRODUCT_WITH_COSTS(state, productWithVendorCosts) {
      state.productWithVendorCosts = productWithVendorCosts;
    },
    SET_ENHANCED_PRODUCT(state, enhancedProduct){
      state.enhancedProduct = enhancedProduct;
    }
  },

  actions: {
    // TODO : directly use the client instead if not planning to store the data after fetch
    // TODO remove references before safely delete this
    async fetchProduct({ commit }, upc){
      const response = await itemClient.getProduct(upc, false);  
      return convertApiItemToItem(response.data)      
    },

    // TODO : move to item service when touching it
    async fetchProducts({ commit }, params){
      const response = await itemClient.getProducts(params);
  
      if(response.status == 200) {
        return response.data.map(d => convertApiItemToItem(d));
      }
      else {
        throw response;
      }
    },

    // TODO : move to item service when touching it
    async fetchCosts({ commit }, params) {
      const response = await itemClient.getCosts(params);

      if (response.status == 200) {
        return response.data.data;
      }
      else {
        throw response;
      }
    },

    // TODO : move to item service when touching it
    async fetchProductsAverageLastWeeks({ commit }, { itemUpcs, nbOfWeeks }){
      const chunkSize = 500
      const chunks = arrayFunctions.chunks(itemUpcs, chunkSize)

      const dateNow = moment();
      const week = dateNow.week();
      const year =  dateNow.year();
      const filters = [];
      
      filters.push({"Column":"DateWeek","Operator":"<","Value":[week]});
      filters.push({"Column":"DateWeek","Operator":">=","Value":[week-nbOfWeeks]});
      filters.push({"Column":"DateYear","Operator":"=","Value":[year]});

      let productsAverageLastWeeks = {}

      for(const itemUpcs of chunks){

        const response = await itemClient.getProductSales(itemUpcs, filters);
        if(response.status >= 300 || response.status === 204)
          continue
        
        const reducedProductsAverageLastWeeks = response.data.reduce(function(res, product) {
          if (!res[product.itemUPC]) {
            res[product.itemUPC] = { itemUPC: product.itemUPC, quantity: 0, amount: 0 };
          }
          res[product.itemUPC].quantity += (product.quantity / nbOfWeeks);
          res[product.itemUPC].amount += (product.amount / nbOfWeeks);
          return res;
        }, {});

        productsAverageLastWeeks = { ...productsAverageLastWeeks, ...reducedProductsAverageLastWeeks }
      }

      return productsAverageLastWeeks
    },

    // TODO : move to item service when touching it
    async updateItemsForSale({ commit }, itemUpcs) {
      const response = await itemClient.updateItemsForSale(itemUpcs)
      if(response.status >= 200 && response.data?.success) {
        return true;
      }

      throw response;
    },
    
    // TODO : move to item service when touching it
    async fetchProductCostsByVendor({ commit }, params) {
      const productVendorWithCosts = {
        ...params.product,
        costs: []
      }

      const costsResponse = await itemClient.getCosts({
        getChildren: true,
        _ReportDataModelID: null,
        filters: [{ "Column": "ItemSKU", "Operator": Operators.equal.value, "Value": [productVendorWithCosts.itemUPC] }, 
                  { "Column": "VendorCode", "Operator": Operators.equal.value, "Value": [params.vendorCode] }],
        targetLayerID: null
      });

      if (costsResponse.status == 200) {
        productVendorWithCosts.costs = costsResponse.data.data;
      }
      else {
        commit('SET_PRODUCT_WITH_COSTS', null);
        throw costsResponse;
      }

      commit('SET_PRODUCT_WITH_COSTS', productVendorWithCosts);
    },

    // TODO : move to item service when touching it
    async fetchEnhancedProduct({ commit }, upc) {

      const enhancedProduct = {
        productCost: {},
        uom: UnitUOM
      };

      const productResponse = await itemClient.getProduct(upc, false);
      if (productResponse.status != 200) {
        const hasNoProductApi = productResponse.status == 404
        if (hasNoProductApi) {
          commit('SET_ENHANCED_PRODUCT', 
          {
            product: {
              _ItemID: 0,
              itemUPC: upc,
              itemSKU: upc,
            },
            ...enhancedProduct
          });
          return;
        }

        throw productResponse;
      }

      const costResponse = await itemClient.getSingleCostByCode([{ "Column": "ItemSKU", "Operator": "=", "Value": [upc] }]);

      commit('SET_ENHANCED_PRODUCT', 
      {
        ...productResponse.data,
        productCost: costResponse.data || {},
        uom: UnitUOM // Count & Adjustment will be always with EA/Each UOM even if the cost is CS/Case.
      });
    },
  }
}
