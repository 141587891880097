import axios from 'axios';
import vuex from '@/core/store';
export default () => {
  return axios.create({
    baseURL: process.env.VUE_APP_API_ENDPOINT || '',
    withCredentials: false,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Accept-Language': vuex.getters['App/getLanguage'] || navigator.languages
    }
  });
};