<template src="./promotion-info.html"></template>

<style lang="scss">
    @import './promotion-info.scss';
</style>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment'
import { PromotionValueFormatter, PromotionStatusGetter } from '@/backoffice/modules/promotion/promotion-getters/promotion-getters.js'
import { DiscountTypeCode } from '@/backoffice/modules/promotion/domain/promotion.js'

export default {
    name: 'Promotion-Info',
    computed: {
        ...mapGetters('Promotion', ['getPromotion', 'getPromotionDescription']),
        promotion(){
            return this.getPromotion
        },
        promotionDescription() {
            return this.getPromotionDescription
        },
        promotionStatus(){
            return PromotionStatusGetter(this.promotion)
        },
        promotionType(){
            if(this.promotion.discount){
                if(this.promotion.discount.typeCode == DiscountTypeCode.FixedPrice)
                    return this.$t(`Promotion.Types.FixedPriceOf`)

                return this.$t(`Promotion.Types.DiscountOf`)
            }
            return ''
        },
        promotionValue(){
            switch (this.promotion?.discount?.typeCode) {
                case DiscountTypeCode.Freebie:
                    return this.$t('Promotion.Types.Freebie')
                case DiscountTypeCode.BundleOffer:
                    return `${this.$t('Promotion.Types.BundleOffer')} ${PromotionValueFormatter(this.promotion)}`
                default:
                    return `${this.promotionType} ${PromotionValueFormatter(this.promotion)}`
            }
        },
        promotionDiscountMinItems(){
            return this.promotion?.discount?.discountMinItems
        },
        promotionDiscountMaxItems(){
            return this.promotion?.discount?.discountMaxItems
        },
        availableQtyLimit(){
            return this.promotion.maxUsage ? this.promotion.maxUsage - this.promotion.totalUsage : null
        },
        currentDate(){
            return moment(new Date()).format("YYYY-MM-DD hh:mm:ss")
        }
    }
}
</script>