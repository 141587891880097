<template src="./chart.html"></template>
<style lang="scss" src="./chart.scss"></style>

<script>
import multiselect from 'vue-multiselect';
import isEqual from 'lodash/isEqual';
import { mapGetters } from 'vuex'
import { ValidationProvider } from "vee-validate";
import checkbox from '@/core/components/common/checkbox/checkbox.vue'
import timePicker from '@/core/components/common/time-picker/time-picker.vue'
import columnAggregatePicker from '@/core/components/common/column-aggregate-picker/column-aggregate-picker.vue'
import modelColumnPicker from '@/core/components/common/model-column-picker/model-column-picker.vue'

export default {
  name: "chart",
  components:{
    checkbox,
    timePicker,
    columnAggregatePicker,
    modelColumnPicker,
    multiselect,
    ValidationProvider
  },
  model: {
    prop: 'chartConfig'
  },
  props: {
    chartConfig: Object,
    model: Object,
    disabled: Boolean
  },
  data() {
    return {
      modelColumnPickerPropertyTypesFilter: ['datetime'],
      chartType: ['Histogram'],
      interval: [60, 120, 240, 360],
      modelColumnPickerOptions: [],
      errorMsg: this.$t('Widgets.Chart.ErrorMsg')

    }
  },
  computed: {
    ...mapGetters('DataModels', ['getModelColumnPickerOptions']),

    config: {
      get() {
        return this.chartConfig;
      },
      set(config) {
        this.$emit('input', config);
      }
    },
    configLoaded() {
      return !isEqual(this.config, {});
    },
    validateConfigRequirements: function() {
      
     return this.config && this.config.charType && this.config.xAxis && this.config.xAxis.column &&  this.config.xAxis.interval && this.config.xAxis.max && this.config.xAxis.min && this.config.yAxis && this.config.yAxis.column && this.config.yAxis.aggregate ;
    }
  },
  watch:{
    'model.name': {
      handler(newVal, oldVal) {
        if(newVal != oldVal) {
          this.initialize();
        }
      }
    }
  },
  mounted() {
    this.initialize();
  },
  updated() {
    this.setDefaultValue();
  },
  methods: {
    initialize(){
      this.modelColumnPickerOptions = this.getModelColumnPickerOptions(this.model.name, this.modelColumnPickerPropertyTypesFilter);
      this.setDefaultValue();
    },
    setProperty(prop) {
        this.config = { ...this.chartConfig, ...prop };
    },
    setXAxisProperty(prop) {
        this.config = { ...this.chartConfig, xAxis: { ...this.chartConfig.xAxis, ...prop } };
    },
    setDefaultValue() {
      if (!this.configLoaded)
        this.config = { 
          ...this.chartConfig,
          ...{
            charType: 'Histogram',
            displayCurve: false,
            xAxis: {
              column: null,
              interval: 60,
              min: '00:00:00',
              max: '00:00:00'
            },
            yAxis: {
              column: null,
              aggregate: null
            }
          }
        };       
    }
  }
}
</script>