<template src="./current-inventory-report.html"></template>
<style lang="scss" src="./current-inventory-report.scss">

</style>

<script>
import { mapActions, mapGetters } from 'vuex';
import prompts from '@/core/tools/notifications/notifications';
import searchBar from '@/core/components/common/search-bar/search-bar.vue';
import targetDropdown from '@/core/components/common/drop-down/target-dropdown/target-dropdown.vue';
import grid from '@/core/components/common/grid/grid.vue';
import formatters from '@/core/components/common/grid/formatters/formatters.js';
import getters from '@/core/components/common/grid/getters/getters.js';

export default {
    name: 'Current-Inventory-Report',
    components: {
        searchBar,
        targetDropdown,
        grid
    },

    data() {
        return {
            gridReadyPromiseResolver: null,
            targetChangedEventPromiseResolver: null,
            componentInitialized: false,
            filters: null,
            targetLayerId: null,
            targetDropdownKey: 0,
            currentInventories: [],
            gridOptions: {
                sideBar: true,
                rowGroupPanelShow: 'always',
                groupIncludeTotalFooter: true,
                defaultColDef: {
                    sortable: true,
                    resizable: true,
                    filter: 'agSetColumnFilter'
                },

                columnDefs: [
                    {
                        headerName: this.$t('CurrentInventoryReport.TargetLayerType'), headerTooltip: this.$t('CurrentInventoryReport.TargetLayerType'),
                        children: [
                            { field: 'enterprise', hide: true, headerName: this.$t('CurrentInventoryReport.Enterprise'), headerTooltip: this.$t('CurrentInventoryReport.Enterprise'), enableRowGroup: true, rowGroupIndex: 0 },
                            { field: 'store', hide: true, headerName: this.$t('CurrentInventoryReport.Store'), headerTooltip: this.$t('CurrentInventoryReport.Store'), enableRowGroup: true, rowGroupIndex: 1 },
                        ]
                    },
                    {
                        headerName: this.$t('CurrentInventoryReport.Product'), headerTooltip: this.$t('CurrentInventoryReport.Product'),
                        children: [
                            { field: 'productUpc', hide: true, headerName: this.$t('CurrentInventoryReport.ProductUpc'), headerTooltip: this.$t('CurrentInventoryReport.ProductUpc'), enableRowGroup: true },
                            { field: 'productDescription', headerName: this.$t('CurrentInventoryReport.ProductDescription'), headerTooltip: this.$t('CurrentInventoryReport.ProductDescription'), enableRowGroup: true },
                        ],
                    },
                    {
                        headerName: this.$t('CurrentInventoryReport.SubDepartment'), headerTooltip: this.$t('CurrentInventoryReport.SubDepartment'),
                        children: [
                            { field: 'subDepartmentCode', hide: true, headerName: this.$t('CurrentInventoryReport.SubDepartmentCode'), headerTooltip: this.$t('CurrentInventoryReport.SubDepartmentCode'), enableRowGroup: true, },
                            { field: 'subDepartmentDescription', hide: true, headerName: this.$t('CurrentInventoryReport.SubDepartment'), headerTooltip: this.$t('CurrentInventoryReport.SubDepartment'), enableRowGroup: true, rowGroupIndex: 3 },
                        ]
                    },
                    {
                        headerName: this.$t('CurrentInventoryReport.Department'), headerTooltip: this.$t('CurrentInventoryReport.Department'),
                        children: [
                            { field: 'departmentCode', hide: true, headerName: this.$t('CurrentInventoryReport.DepartmentCode'), headerTooltip: this.$t('CurrentInventoryReport.DepartmentCode'), enableRowGroup: true },
                            { field: 'departmentDescription', hide: true, headerName: this.$t('CurrentInventoryReport.Department'), headerTooltip: this.$t('CurrentInventoryReport.Department'), enableRowGroup: true, rowGroupIndex: 2 },
                        ]
                    },
                    {
                        headerName: this.$t('CurrentInventoryReport.Metrics'), headerTooltip: this.$t('CurrentInventoryReport.Metrics'),
                        children: [
                            { field: 'inventory', headerName: this.$t('CurrentInventoryReport.CurrentInventory'), headerTooltip: this.$t('CurrentInventoryReport.CurrentInventory'), valueGetter: this.nonAvailableGetter, enableValue: true, filter: 'agNumberColumnFilter', aggFunc: 'sum' },
                            { field: 'averageUnitCost', headerName: this.$t('CurrentInventoryReport.AverageUnitCost'), headerTooltip: this.$t('CurrentInventoryReport.AverageUnitCost'), valueGetter: this.nonAvailableGetter, enableValue: true, filter: 'agNumberColumnFilter', valueFormatter: formatters.currencyFormatter },
                            { field: 'inventoryValue', headerName: this.$t('CurrentInventoryReport.InventoryValue'), headerTooltip: this.$t('CurrentInventoryReport.InventoryValue'), valueGetter: getters.nonAvailableGetter, valueFormatter: formatters.currencyFormatter, enableValue: true, filter: 'agNumberColumnFilter', aggFunc: 'sum' },
                        ]
                    }
                ],
                onGridReady: () => {
                    this.gridReadyPromiseResolver();
                }
            },
        }
    },

    computed: {
        ...mapGetters('Account', ['getCurrentTargetLayerId']),
        rowData(){
            return this.currentInventories
        }
    },

    watch:{
        getCurrentTargetLayerId(){
            this.targetDropdownKey++;
        }
    },

    created() {
        Promise.all([
            new Promise(resolve => {
                this.gridReadyPromiseResolver = resolve;
            }),
            new Promise(resolve => {
                this.targetChangedEventPromiseResolver = resolve;
            })
        ]).then(async () => {
            await this.fetchCurrentInventories();
            this.componentInitialized = true;
        });
    },

    methods: {
        ...mapActions('Reporting', ['getCurrentInventories']),
        async fetchCurrentInventories() {
            this.gridOptions.api.showLoadingOverlay();

            try {
                this.currentInventories = await this.getCurrentInventories(this.targetLayerId)
            }
            catch (e) {
                prompts.error({
                    text: this.$t('Reporting.Messages.GetCurrentInventoriesError')
                });
            }

            this.gridOptions.api.hideOverlay();
        },
        onTargetChanged(target) {
            this.targetLayerId = target.targetLayerId;
            if (!this.componentInitialized) {
                this.targetChangedEventPromiseResolver()
            }
            else {
                this.fetchCurrentInventories();
            }
        },
        filtersChanged(filters) {
            this.filters = filters;
        }
    }
}
</script>


