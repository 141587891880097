<template inline-template v-cloak>
    <span>{{current.value}}</span>
</template>

<script>
import { getCurrentLanguageDescription } from "@/core/functions";

export default {
  name: 'description-title',
  props:{
    descriptions: Array
  },
  data() {
    return {
      current: {}
    };
  },
  mounted() {  
    this.changeLanguage();
  },
  methods: {
    changeLanguage(){
      const currentDescription = getCurrentLanguageDescription(this.descriptions)
      if (currentDescription) {
        this.current = currentDescription;
      }
    },
  }
}

</script>
