<template src="./main-block.html"></template>

<style scoped lang="scss">
@import './main-block.scss';
</style>

<script>
import moment from 'moment';
import axios from 'axios';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';

export default {
  name: 'mainBlock',
  props: ['targetID', 'date'],
  data() {
    return {
      cancelToken: {},
      source: {},
      mainBlock: {}
    };
  },
  computed:{
    ...mapGetters('App', ['isMobile']),    
  },
  beforeDestroy() {
    this.source.cancel('OPERATION_CANCELED');
  },
  created() {
    this.cancelToken = axios.CancelToken;
    this.source = this.cancelToken.source();
  },
  mounted() {
    this.setWidget();
    this.getData();
  },
  methods: {
    ...mapActions('Widgets', ['getWidgetSimple']),
    setWidget(){
      const d = this.$t('Calendar.DayNames.' +  moment(this.date).format('dddd')); 
      this.mainBlock = {
        classes: 'col-xlg-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xxs-12',
        color: '#005394',
        title: 'Widgets.DailySales.Title',
        items: [
          {
            color: '#50c8e8',
            info: this.$t('Widgets.DailySales.LastDay', { day: d } ),
            label: '0:00 AM - 23:59 PM',
            svgIcon: '<svg viewBox="0 0 60.63 53.25"><path class="cls-1" d="M59.63,7.52H48.33V1a1,1,0,0,0-2,0V7.52H15.25V1a1,1,0,0,0-2,0V7.52H1a1,1,0,0,0-1,1V52.25a1,1,0,0,0,1,1H59.63a1,1,0,0,0,1-1V8.52A1,1,0,0,0,59.63,7.52Zm-1,2v9.61H2V9.52ZM2,51.25V21.13H58.63V51.25Z" transform="translate(0 0)"/></svg>',
            underValue: 0,
            value: 0
          },
          {
            color: '#50c8e8',
            info: '',
            label: '',
            svgIcon: '<svg viewbox="0 0 36 36"><path class="summary-block__circle" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"/><path d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" fill="none" stroke-width="3"/></svg>',
            underValue: 0,
            value: 0,
            percent: 0
          }
        ]
      }
    },
    async getData(){
      const currentDate = moment().format('YYYY-MM-DD');
      const request = {
        params: { date: this.date ? this.date : currentDate },
        cancelToken: { cancelToken: this.source.token },
        targetIDSelected: this.targetID
      }

      let widget = null;
      
      try {
        widget = await this.getWidgetSimple(request);
      } catch (error) {
        this.setWidget();
      }

      if (widget && widget.items) {
        this.mainBlock.items[0].value = widget.items[0].value;
        this.mainBlock.items[0].underValue = widget.items[0].underValue;
        this.mainBlock.items[0].label = widget.items[0].label;

        this.mainBlock.items[1].underValue = widget.items[0].value - widget.items[0].underValue;
        this.mainBlock.items[1].percent = Math.abs(widget.items[0].underValue) > 0 ? (widget.items[0].value / Math.abs(widget.items[0].underValue)) * 100 : 0;
        this.mainBlock.items[1].label = widget.items[0].label;

        if (this.mainBlock.items[1].underValue >= 0) {
          this.mainBlock.items[1].info = this.$t('Widgets.DailySales.OverGoal');
          this.mainBlock.items[1].underColor = 'green';
        } 
        else {
          this.mainBlock.items[1].info = this.$t('Widgets.DailySales.UntilGoal');
          this.mainBlock.items[1].underColor = 'red';
        }
      }
    }
  }
};
</script>
