<template v-cloak src="./period-picker.html"></template>

<style scoped lang="scss">
@import './period-picker.scss';
</style>

<script>
import moment from 'moment';
import extractTime from '@/backoffice/modules/reporting/mixins/extract-time.js';
import calendar from '@/core/components/common/calendar/calendar.vue'

const defaultOptions = [
  { name : "RangePicker.today", value : "-0d", selected : false },
  { name : "RangePicker.yesterday", value : "-1d", selected : false },
  { name : "RangePicker.this", selected : false, choices : [
      { name : "RangePicker.thisWeek", value : "-0w", selected : false },
      { name : "RangePicker.thisMonth", value : "-0m", selected : false },
      { name : "RangePicker.thisQuarter", value : "-0q", selected : false },
      { name : "RangePicker.thisYear", value : "-0y", selected : false }
    ]
  }, 
  { name : "RangePicker.last", selected : false, choices : [
      { name : "RangePicker.lastWeek", value : "-1w", selected : false },
      { name : "RangePicker.lastMonth", value : "-1m", selected : false },
      { name : "RangePicker.lastQuarter", value : "-1q", selected : false },
      { name : "RangePicker.lastYear", value : "-1y", selected : false }
    ]
  },
  { name : "RangePicker.same", selected : false, choices : [
      { name : "RangePicker.sameDayLastWeek", value : "-1wd", selected : false },
      { name : "RangePicker.sameDayLastYear", value : "-1ydr", selected : false },
      { name : "RangePicker.sameWeekLastYear", value : "-1ywr", selected : false },
      { name : "RangePicker.sameMonthLastYear", value : "-1ym", selected : false },
      { name : "RangePicker.sameQuarterLastYear", value : "-1yq", selected : false }
    ]
  }, 
  { name : "RangePicker.custom", dates: "", selected : false, isDateRangePicker : true, value: "" }
];

export default {
  mixins: [extractTime],
  name: 'periodPicker',
  props: {
    period: String,
    placeholder: String,
    format: String,
    relativeOptionsOnly: Boolean,
    options: {
      type: Array,
      default: () => {
        return defaultOptions;
      }
    }
  },
  watch:{
    //Listen for change made in the parent component so we can trigger the setIndex
    period:{
      handler(val, oldVal){
        if(val != oldVal && this.syncPeriod != val){
          this.syncPeriod = val;
          this.setIndex();
        }
      }
    }
  },
  components : {
    calendar
  },
  computed: {
    periodOptions() {
      return this.options.filter(option => !this.relativeOptionsOnly || (this.relativeOptionsOnly && !option.isDateRangePicker));
    }
  },
  data() {
    return {
      subMenu : { parentIndex: null, childs:[] },
      menuActif : false,
      subMenuActif : false,
      showCalendar : false,
      selectedValue : "",
      configs: [],
      pDate: null,
      locale: this.$i18n.locale,
      syncPeriod: null
    }
  },
  mounted(){
    this.syncPeriod = this.period;
    this.setIndex(false);
  },

  methods: {
    setIndex(emitEvent = true){

      if (!this.syncPeriod) {
        this.syncPeriod = '-0d'
      }

      for (let i = 0; i < this.options.length; i++) {
        if(this.options[i].choices){
          for (let j = 0; j < this.options[i].choices.length; j++) {
            this.options[i].choices[j].selected = false;
          }
        }
        this.options[i].selected = false;
      }

      let i = 0;
      let j = 0;
      let found = false;
      while(i < this.options.length && !found){
        if(this.options[i].choices){
          j = 0;
          while(j < this.options[i].choices.length && !found){
            if(this.options[i].choices[j].value == this.syncPeriod){
              this.options[i].selected = true;
              this.options[i].choices[j].selected = true;
              this.selectedValue = this.options[i].choices[j];
              this.setDates();
              found = true;
            }
            j++;
          }
        }
        else if(this.options[i].value == this.syncPeriod && !this.options[i].isDateRangePicker){
          this.options[i].selected = true;
          this.selectedValue = this.options[i];
          this.setDates();
          found = true;
        }
        else if(this.options[i].isDateRangePicker){
          this.options[i].selected = true;
          this.selectedValue = this.options[i];
          this.setDates();
          found = true;
        }
        i++;
      }

      if (emitEvent) {
        this.$emit('period-changed', this.syncPeriod);
      }
    },

    cb: function(element, index){
       //if the value is defined, we use it.
       if(element.value !== undefined && !element.isDateRangePicker){
         this.subMenuActif = false;
         this.syncPeriod = element.value;
         this.setIndex();
       }
       else if(element.choices !== undefined){
         this.showCalendar = false;
         this.subMenuActif = false;
         this.subMenu.parentIndex = index;
         this.subMenu.childs = element.choices;
         this.subMenuActif = true;
       }
       else if(element.isDateRangePicker){
         this.subMenuActif = false;
         this.showCalendar = element.isDateRangePicker;
       }
    },
    open: function(){
      this.menuActif = !this.menuActif;
      this.subMenuActif = false;
      this.showCalendar = false;
    },
    hide: function(){
      if(!this.subMenuActif && !this.showCalendar){
        this.menuActif = false;
        this.subMenuActif = false;
        this.showCalendar = false;
        this.subMenu = [];
      }
    },

    setDates(){
      this.pDate = this.convert(this.syncPeriod);

      if(this.pDate.start && this.pDate.end && !this.relativeOptionsOnly) {
        this.selectedValue.dates = ': ' + moment(this.pDate.start).locale(this.locale).format(this.format)
                                 + ' - ' + moment(this.pDate.end).locale(this.locale).format(this.format);
      }      
    },

    startStopUpdate(){
      const now = moment();
      const start = moment(this.pDate.start);
      const stop = moment(this.pDate.end);
      const interStart = -1 * now.diff(start, 'days');
      const interStop = -1 * now.diff(stop, 'days');
      this.syncPeriod = interStart + 'd to ' + interStop + 'd';
      this.showCalendar = false;
      this.hide();
      
      this.setIndex();
    }
  }
}
</script>
