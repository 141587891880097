import {releaseNoteServices} from "@/backoffice/modules/release-note/services/release-note.services";
import moment from "moment"

const getDefaultState = () => {
    return {
      upcomingRelease: null,
      acknowledged: false,
      releaseNotes: []
    };
  };
  
  export default {  
    namespaced: true,
    name: "ReleaseNote",
    state: getDefaultState(),
    getters: {
      getPublicationDate: (state) => {
        return state.upcomingRelease?.publicationdate
      },
      showInProgressRelease: (state) => {
        if(!state.upcomingRelease?.publicationdate)
          return false
        const now = moment() 
        const publicationDate = moment(state.upcomingRelease?.publicationdate)
        return now.isAfter(publicationDate) && now.isBefore(publicationDate.clone().add(2, 'h'))
      },
      showUpcomingRelease: (state) => {
        if(!state.upcomingRelease?.publicationdate)
          return false
        const now = moment()
        const publicationDate = moment(state.upcomingRelease?.publicationdate)
        return now.isAfter(publicationDate.clone().subtract(1, 'd')) && now.isBefore(publicationDate) && !state.acknowledged
      },
      getReleaseNotes: (state) => {
        return state.releaseNotes
      }
    },
  
    mutations: {
      RESET_STORE(state) {
        Object.assign(state, getDefaultState());
      },
      SET_RELEASE_NOTES(state, releaseNotes) {
        state.releaseNotes = releaseNotes
      },
      CLOSE_UPCOMING_RELEASE_NOTIFICATION(state){
        state.acknowledged = true 
      },
      SET_UPCOMING_RELEASE(state, newestRelease){
        state.upcomingRelease = newestRelease
        state.acknowledged = false
      }
    },
  
    actions: {
      async fetchReleaseNotes({ commit, state }) {

        const versions = await releaseNoteServices.getReleaseNoteVersions(new Date().toISOString());

        if(!versions || versions.length === 0)
          return

        commit('SET_RELEASE_NOTES', versions)

        const newestRelease = versions.reduce((newest, release) => moment(release.publicationdate).isAfter(moment(newest.publicationdate)) ? release : newest, {publicationdate: moment('0000-01-01')})

        if(!newestRelease)
          return

        if(!moment(state.upcomingRelease?.publicationdate).isSame(moment(newestRelease.publicationdate)) || state.upcomingRelease?.version !== newestRelease.version){
          commit("SET_UPCOMING_RELEASE", newestRelease)
        }
      }
    }
  };