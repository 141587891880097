<template src="./receiving-product-variance.html"></template>

<style lang="scss">
    @import "./receiving-product-variance.scss";
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import { mapGetters, mapActions } from 'vuex';
import multiselect from 'vue-multiselect';
import searchList from '@/core/components/common/search-list/search-list.vue';
import checkbox from '@/core/components/common/checkbox/checkbox.vue';
import { toDate } from "@/core/functions/format-value.js";
import prompts from '@/core/tools/notifications/notifications';
import cloneDeep from 'lodash/cloneDeep';
import { OrderStatus } from '@/backoffice/modules/purchase/domain/purchase.js';
import Swal from 'sweetalert2';

export default {
    name: 'receiving-product-variance',

    components: {
        multiselect,
        searchList,
        checkbox
    },

    data() {
        return {
            preventSave: false,
            varianceProducts: [],
            varianceProductsCopy: [],
            selectedVarianceProducts: [],
            receivingVariances: [],
            varianceReason: null,
            closeOrder: false, // TODO: Update Translate for order(s)
            closedOrderIds: []
        };
    },

    computed: {
        ...mapGetters('Receiving', ['getReceiving', 'getReceivingRelatedInfos', 'getReceivingVarianceReasons']),
        receiving(){
            return this.getReceiving ? this.getReceiving : {};
        },
        receivingRelatedInfos(){
            return this.getReceivingRelatedInfos ? this.getReceivingRelatedInfos : {};
        },
        receivingVarianceReasons(){
            return this.getReceivingVarianceReasons || [];
        },
        formattedDate(){
            return toDate(this.receiving.createdDate);
        },
        hasSelectedVarianceProducts(){
            return this.selectedVarianceProducts.length > 0;
        },
        canApplyVarianceReason(){
            return this.hasSelectedVarianceProducts && !this.preventSave && this.selectedReason;
        },
        isEdited(){
            return this.hasSelectedVarianceProducts || this.selectedReason;
        },
        hasVariances(){
            return this.varianceProductsCopy.length > 0;
        },
        affectedOrdersCountByVarianceProduct() {
            return this.varianceProducts.reduce((orderIds, product) => [ ...new Set([ ...orderIds, ...product.orderIds]) ] , []).length;
        },
        disableCloseOrder() {
            return this.selectedReason?.value !== 'ShippingCancelled';
        },
        closedOrderIdsCount() {
            return this.closedOrderIds.length
        },
        selectedReason: {
            get() {
                return this.varianceReason;
            },
            set(value) {
                this.varianceReason = value;

                if (this.disableCloseOrder) {
                    this.closeOrder = false;
                }
            }
        }
    },

    watch:{
        isEdited(){
            this.$sidePanel.isEdit(this.isEdited);
        }
    },

    mounted(){
        const receivingBodies = this.receiving.receivingBodies || [];
        const calculatedBodies = this.calculateReceivingBodyVariance(receivingBodies, this.receivingRelatedInfos);
        this.varianceProducts = this.groupReceivingBodies(calculatedBodies);
        this.varianceProductsCopy = cloneDeep(this.varianceProducts);
        if (this.varianceProducts.length > 0)
            this.getVarianceReasons();
        else
            this.$refs.saveReceiving.focus()
    },

    methods: {
        ...mapActions('Receiving', ['fetchReceiving', 'fetchReceivingVarianceReasons', 'closeReceiving']),
        ...mapActions('Order', ['fetchOrdersByIds', 'updateOrders']),

        async getVarianceReasons(){
            try {
                await this.fetchReceivingVarianceReasons();
            }
            catch (error) {
                prompts.error({
                    text: this.$t('Purchase.Messages.GetReceivingVarianceReasonsError')
                });
            }
        },

        async closeReceivingVariances(){
            const confirmCloseOrder = await this.confirmCloseOrder()
            if (!confirmCloseOrder)
                return

            let success = true;
            this.preventSave = true;
            let autoCloseSidePanel = false

            try {
                await this.closeReceiving({receivingId: this.receiving.id, receivingVariances: this.receivingVariances});

                if (this.closedOrderIdsCount > 0)
                    await this.closeOrders()
            }
            catch (error) {
                success = false;
                let codeTranslate = 'Purchase.Messages.CloseReceivingError'
                if(error?.data?.message === 'CLOSE_VOIDED_RECEPTION'){
                    autoCloseSidePanel = true
                    codeTranslate = 'Purchase.Messages.CloseVoidedReceiving';
                }else if(error?.status === 403){
                    autoCloseSidePanel =true
                    codeTranslate = 'Purchase.Messages.ForbiddenAction'
                }

                prompts.error({
                    text: this.$t(codeTranslate)
                });
            }

            this.preventSave = false;

            if(success){
                prompts.toast({
                    icon: 'success',
                    title: this.$t('Purchase.Messages.CloseReceivingSuccess', { receivingNO: this.receiving.receivingNO }),
                    position: 'bottom-end'
                });

                this.$sidePanel.close();
                this.$router.push({ name: 'receivings' });
            }
            if(autoCloseSidePanel){
                this.fetchReceiving(this.receiving.id)
                this.$sidePanel.close()
            }
        },

        async confirmCloseOrder() {
            if (this.closedOrderIdsCount === 0)
                return true

            const result = await Swal.fire({
                heightAuto: false,
                confirmButtonText: this.$i18n.tc('Purchase.Receiving.ConfirmCloseAssociatedOrder.ConfirmActionText', this.closedOrderIdsCount),
                showCancelButton: true,
                cancelButtonText: this.$i18n.tc('Purchase.Receiving.ConfirmCloseAssociatedOrder.CancelActionText', this.closedOrderIdsCount),
                icon: 'question',
                title: this.$i18n.t('Purchase.Receiving.ConfirmCloseAssociatedOrder.Title'),
                text: this.$i18n.tc('Purchase.Receiving.ConfirmCloseAssociatedOrder.Text', this.closedOrderIdsCount, { count: this.closedOrderIdsCount }),
            });

            return result.isConfirmed;
        },

        async closeOrders() {
            let orders = [];

            try {
                orders = await this.fetchOrdersByIds(this.closedOrderIds);

                await this.updateOrders(orders.map(order => { return {
                    ...order,
                    orderStatus: OrderStatus.CLOSED,
                    orderBodies: order.orderBodies.map(item => {
                        return {
                            ...item,
                            ignore: true
                        }
                    })
                }}));
            }
            catch(error) {
                prompts.error({
                    text: this.$tc('Purchase.Messages.SaveOrderError', this.closedOrderIdsCount)
                });
            }
        },

        calculateReceivingBodyVariance(receivingBodies, receivingRelatedInfos){
            const calculatedBodies = receivingBodies.map(o => {

                const orderInfos = receivingRelatedInfos[o.orderBodyID] || {};
                const qtyOrdered = orderInfos.qtyOrdered || 0; 
                const qtyReceivedInAllReceiving = (orderInfos.qtyReceivedInOtherReceiving || 0) + o.qty;
                const qtyToReceive = qtyOrdered - qtyReceivedInAllReceiving;

                return {
                    ...o,
                    qtyToReceive: qtyToReceive > 0 ? qtyToReceive : 0, 
                    qtyReceived: o.qty
                }
            });

            return calculatedBodies;
        },

        groupReceivingBodies(calculatedBodies){
            const groupedProducts = [...calculatedBodies.reduce((products, item) => {
                const key = item.purchaseBodyInfo.itemUPC + '-vendorCode:' + item.vendorCode + '-uomID:' + item.purchaseBodyInfo.uomID + 'unitQty:' + item.unitQty;

                const product = products.get(key) || Object.assign({}, {
                    vendorID: item.vendorID,
                    vendorCode: item.vendorCode,
                    vendorName: item.vendorName,
                    itemUPC: item.purchaseBodyInfo.itemUPC,
                    itemDescription: item.purchaseBodyInfo.itemDescription,
                    uomID: item.purchaseBodyInfo.uomID,
                    uomDescription: item.purchaseBodyInfo.uomDescription,
                    unitQty: item.unitQty,
                    summedQtyToReceive: 0,
                    summedQtyReceived: 0,
                    ids: [],
                    orderIds: []
                });

                product.summedQtyToReceive += item.qtyToReceive;
                product.summedQtyReceived += item.qtyReceived;
                product.ids.push(item.id);

                if (item.qtyToReceive > 0)
                    product.orderIds.push(item.orderID);

                return products.set(key, product);
            }, new Map).values()];

            return groupedProducts
                    .filter(product => product.summedQtyToReceive != 0)
                    .map(product => ({ ...product, totalQtyToReceive: product.summedQtyToReceive + product.summedQtyReceived }))
                    .sort((left, right) => left.itemUPC - right.itemUPC);
        },

        applyVarianceReason(){
            this.selectedVarianceProducts.forEach(selectedVarianceProduct => {
                if (this.closeOrder) {
                    this.closedOrderIds = [ ...new Set([ ...this.closedOrderIds, ...selectedVarianceProduct.orderIds ]) ];
                }

                selectedVarianceProduct.ids.forEach(id => {
                    this.receivingVariances.push({
                        receivingBodyID: id,
                        reasonCode: this.selectedReason.value,
                        reason: this.selectedReason.description
                    });
                });

                for (let i = this.varianceProducts.length - 1; i >= 0; i--) {
                    if (this.varianceProducts[i].itemUPC === selectedVarianceProduct.itemUPC &&
                        this.varianceProducts[i].vendorCode === selectedVarianceProduct.vendorCode &&
                        this.varianceProducts[i].uomID === selectedVarianceProduct.uomID &&
                        this.varianceProducts[i].unitQty === selectedVarianceProduct.unitQty) { 
                        this.varianceProducts.splice(i, 1);
                    }
                } 
            });

            this.selectedVarianceProducts = [];
            this.closeOrder = false;
        },

        selectAllVarianceProducts(){
            this.selectedVarianceProducts = [...this.varianceProducts];
        },
        reloadVarianceProduct(){
            this.varianceProducts = cloneDeep(this.varianceProductsCopy);
            this.selectedVarianceProducts = [];
            this.closeOrder = false;
            this.closedOrderIds = [];
        },
        onCancel(){
            this.$sidePanel.close();
        },
        selectionChanged(selectedVarianceProducts) {
            this.selectedVarianceProducts = selectedVarianceProducts;
        }
    }
}
</script>