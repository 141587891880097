import BrandServices from '@/backoffice/modules/brand/services/brand.services.js'

export const Brand = {
  namespaced: true,
  name: 'Brand',
  actions: {
    async getBrandById(_, id) {
      return await BrandServices.getBrandById(id)
    },
    async getBrands() {
      return await BrandServices.getBrands()
    },
    async getBrandCreationScope() {
      return await BrandServices.getCreationScope()
    },
    async saveBrand(_, brand) {
      let result = null
      if (brand.id > 0) {
        result = await BrandServices.updateBrand(brand)
      }
      else{
        result = await BrandServices.createBrand(brand)
      }
      
      return result;
    },
    async deleteBrand(_, id) {
      return await BrandServices.deleteBrand(id)
    }
  }
}