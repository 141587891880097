import { DocumentStatus } from '@/backoffice/modules/inventory/domain/document.js';
import { inventoryServices } from '@/backoffice/modules/inventory/services/inventory.services';
import { convertApiInventoryToInventory } from '@/backoffice/modules/inventory/domain/inventory.js'

const getDefaultState = () => {
  return {
    document:{},
    documents: [],
    adjustmentReason: [],
    inventory: null,
    inventories: []
  }
}

export const Inventory = {
  namespaced: true,
  name: 'Inventory',
  state: getDefaultState(),
  getters: {
    getOpenDocuments: state =>{
      return state.documents.filter(d => d.documentStatus === DocumentStatus.Open || d.documentStatus === DocumentStatus.Suspended);
    },
    getClosedDocuments: state =>{
      return state.documents.filter(d => d.documentStatus === DocumentStatus.Closed);
    },
    getDocuments: state =>{
      return state.documents;
    },
    getDocument: state =>{
      return state.document;
    },
    isDocumentOpen: state =>{
      return state.document && state.document.documentStatus == DocumentStatus.Open;
    },
    getAdjustmentReason: state =>{
      return state.adjustmentReason;
    }
  },

  mutations: {
    RESET_STORE(state){
      Object.assign(state, getDefaultState());
    },
    SET_DOCUMENTS(state, documents){
      state.documents = documents;
    },
    SET_DOCUMENT(state, document){
      state.document = document;
    },
    SET_ADJUSTMENT_REASON(state, adjustmentReason){
      state.adjustmentReason = adjustmentReason;
    },
    RESET_DOCUMENTS(state){
      state.documents = []
    },
    RESET_DOCUMENT(state){
      Object.assign(state.document, {})
    }
  },

  actions: {
    //Document actions
    async fetchAggregatedDocuments({ commit }) {
      const response = await inventoryServices.getAggregatedDocuments();
  
      if(response.status == 200) {
        commit('SET_DOCUMENTS', response.data);
      }
      else {
        commit('SET_DOCUMENTS', []);
        throw response;
      }
    },

    async fetchDocument({ commit }, id) {
      const response = await inventoryServices.getDocument(id);

      if (response.status == 200) { //TODO: Stop validating response status in store. Validation should be done in client (service)
        commit('SET_DOCUMENT', response.data);
        return response.data;
      }
      else {
        commit('SET_DOCUMENT', {});
        throw response;
      }
    },

    async addDocument({ commit }, document){
      const response = await inventoryServices.addDocument(document);

      if(response.status == 200) { //TODO: Stop validating response status in store. Validation should be done in client (service)
        commit('SET_DOCUMENT', response.data);
      }
      else {
        commit('SET_DOCUMENT', {});
        throw response;
      }
    },

    async updateDocument({ commit, dispatch }, document) {
      const response = await inventoryServices.updateDocument(document);

      if (response.status == 200) { //TODO: Stop validating response status in store. Validation should be done in client (service)
        commit('SET_DOCUMENT', response.data);
      }
      else if (response.status == 403) { //TODO: Stop validating response status in store. Validation should be done in client (service)
        const updatedDocument = await dispatch("fetchDocument", document.id);
        if (document.userID !== updatedDocument.userID)
          throw new Error("ForceOpen");
        else
          throw response;
      }
      else {
        throw response;
      }
    },

    async forceOpenUpdateDocument({ commit }, document) {
      const response = await inventoryServices.forceOpenUpdateDocument(document);

      if (response.status == 200) { //TODO: Stop validating response status in store. Validation should be done in client (service)
        commit('SET_DOCUMENT', response.data);
      }
      else {
        throw response;
      }
    },

    async deleteDocument({ commit, dispatch }, document) {
      const response = await inventoryServices.deleteDocument(document);
      if (response.status == 200) { //TODO: Stop validating response status in store. Validation should be done in client (service)
        commit('SET_DOCUMENT', {});
      }
      else if (response.status == 403) { //TODO: Stop validating response status in store. Validation should be done in client (service)
        const updatedDocument = await dispatch("getDocument", document.id);
        if (document.userID !== updatedDocument.userID)
          throw new Error("ForceOpen");
        else
          throw response;
      }
      else {
        throw response;
      }
    },
    
    async fetchAdjustmentReason({ commit }){
      const filters = [{"Column":"Code","Operator":"=","Value":["Adjustment+","Adjustment-"]}, {"Column":"Parameter","Operator":"=","Value":["Reason"]}];
      const params = { getChildren: false, filters: filters };

      const response = await inventoryServices.getAdjustmentReason(params);
  
      if(response.status == 200) { //TODO: Stop validating response status in store. Validation should be done in client (service)
        commit('SET_ADJUSTMENT_REASON', response.data);
      }
      else {
        commit('SET_ADJUSTMENT_REASON', []);
        throw response;
      }
    },

    async fetchProductInventory({ commit }, upc){
      const response = await inventoryServices.getInventory(upc);
      //TODO: Stop validating response status in store. Validation should be done in client (service)
      return response.status == 200 ? convertApiInventoryToInventory(response.data) : convertApiInventoryToInventory(null)
    },

    async fetchProductsInventories({ commit }, upcs){
      const response = await inventoryServices.getInventories(upcs);
      //TODO: Stop validating response status in store. Validation should be done in client (service)
      return response.status == 200 ? response.data.map(d => convertApiInventoryToInventory(d)) : []
    },
  
    async validate({ commit }, validationObject){

      const {id, targetLayerId, upc, sku, docType, documentStatus} = validationObject
      const response = await inventoryServices.validate(id, targetLayerId, upc, sku, docType, documentStatus);
  
      if(response.status >= 300) { //TODO: Stop validating response status in store. Validation should be done in client (service)
        throw response;
      }
      if(response.data=="")
        return null;
      return response.data;
    }
  }
}
