<template src="./favorite-title.html"></template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped src="./favorite-title.scss"></style>

<script>
import multiselect from 'vue-multiselect';

export default {
  name: 'favorite-title',
  props: {
    selectOptions: Object,
    currentElement: Object,
    currentFavoriteID: Number
  },

  data() {
    return {
      value: null,
      favoriteID: null
    }
  },

  watch:{
    currentElement(newVal, oldVal){
      if (newVal != oldVal)
        this.value = newVal;
    },
    currentFavoriteID(newVal, oldVal){
      if (newVal != oldVal)
        this.favoriteID = newVal;
    }
  },

  components:{
    multiselect
  },

  mounted() {
    this.value = this.currentElement;
    this.favoriteID = this.currentFavoriteID;
  },

  methods: {
    selectionChanged(params) {
      this.$emit('selection-changed', params);
    },
    selectFavorite(event, favoriteID) {
      event.stopPropagation();

      if (favoriteID !== this.favoriteID) {
        this.$emit('favorite-changed', favoriteID);
      }
      else {
        this.$emit('favorite-deleted');
      }
    }
  }
}

</script>