
export const DescriptionProperty = {
    Value: 'value',
    ShortDescription: 'shortDescription',
    LongDescription: 'longDescription',
    TargetDescription1: 'targetDescription1'
}

export class Description {
    constructor(descriptionId, id, language, value) {
        this.descriptionId = descriptionId
        this.id = id
        this.language = language
        this.value = value
    }
}