import prompts from '@/core/tools/notifications/notifications';
import i18n from "@/i18n";


function displayApiStatus(response){
  switch(response.status){
    case 400:
    case 401:
    case 403:
    case 404:
    case 406:
    case 500:
    case 502:
    case 503:
      prompts.error({
        title: i18n.t(`Error.Code.${response.status}`),
        text: i18n.t(`Error.Message.${response.status}`)
      });
      break;
    default:
      prompts.error({
        title: i18n.t('Error.Unknow'),
        text: i18n.t(response.message)
      });
  }
}

function displayApiError(response) {
  //Message(s) is/are set. Prioritise this
  if(response.message){
    //If multiple message, append them
    if(Array.isArray(response.message)){
      let html = "<div>";
      for (let i = 0; i < response.message.length; i++) {
        const msg = response.message[i];
        
        for (let j = 0; j < msg.messages.length; j++) {
          const element = msg.messages[j];
          html += `<span>${i18n.t('Error.' + element.code)}</span></br>`;
        }
      }
      html += "</div>"

      prompts.error({
        title: i18n.t(`Error.Code.${response.status}`),
        html
      });
    }
    else{
      switch(response.message){
        case "Network Error":
          
          prompts.error({
            title: i18n.t('Error.NetworkError'),
            text: i18n.t('Error.NetworkErrorMsg')
          });

          break;
        case "OPERATION_CANCELED":          
          break;
        default:
          displayApiStatus(response);
          break;
      }
    }
  }
  else if(response.status){
    displayApiStatus(response);
  }
}

export default {
  displayApiError
};