import i18n from "@/i18n";
const getTranslationForRangePicker = (value) => {
    switch(value){
        case "-0d":
        return i18n.t("RangePicker.today");
        case "-1d":
        return i18n.t("RangePicker.yesterday");
        case "-0w":
        return i18n.t("RangePicker.thisWeek");
        case "-0m":
        return i18n.t("RangePicker.thisMonth");
        case "-0q":
        return i18n.t("RangePicker.thisQuarter");
        case "-0y":
        return i18n.t("RangePicker.thisYear");
        case "-1w":
        return i18n.t("RangePicker.lastWeek");
        case "-1m":
        return i18n.t("RangePicker.lastMonth");
        case "-1q":
        return i18n.t("RangePicker.lastQuarter");
        case "-1y":
        return i18n.t("RangePicker.lastYear");
        default:
        return i18n.t("RangePicker.custom");
    }
};

export { getTranslationForRangePicker }