<template src="./product-result-footer.html"></template>

<style lang="scss">
    @import './product-result-footer.scss';
</style>

<script>
export default {
    name: 'product-result-footer'
}
</script>