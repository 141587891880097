import { genericServices } from '@/core/services/generic/generic.services';

const getDefaultState = () => {
  return {
    vendors:[],
  }
}

export const Vendor = {
  namespaced: true,
  name: 'Vendor',
  state: getDefaultState(),
  getters: {
    getVendors: state => {
      return state.vendors;
    }
  },

  mutations: {
    RESET_STORE(state){
      Object.assign(state, getDefaultState());
    },
    SET_VENDORS(state, vendors) {
      state.vendors = vendors;
    },
    ADD_UPDATE_VENDOR(state, vendor) {
      const index = state.vendors.findIndex(v => v._VendorCode == vendor.vendorCode)
      if (index > -1) {
        state.vendors[index] = vendor
      }
      else {
        state.vendors.push(vendor)
      }
    }
  },

  actions: {
    async fetchVendors({ commit }) {
      const params = {
          getChildren: false,
          modelName: 'Vendor',
          filters: []
      };

      const response = await genericServices.getEntities(params);
      if (response.status == 200) {
        commit('SET_VENDORS', response.data);
      }
      else {
        commit('SET_VENDORS', []);
        throw response;
      }
    },
    async fetchVendor({ commit }, vendorCode) {

      const params = {
        getChildren: false,
        modelName: 'Vendor',
        filters: [
          { 
            "Column": "VendorCode", 
            "Operator": "=", 
            "Value": [vendorCode] 
          }
        ],
      };

      const response = await genericServices.getEntity(params);
      if (response.status == 200) {
        commit('ADD_UPDATE_VENDOR', response.data);
        return response.data;
      }
      else {
        throw response;
      }
    }
  }
}
