<template src="./summary-block.html"></template>

<style lang="scss">
@import './summary-block.scss';
</style>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
import axios from 'axios';

export default{
  name: "summary-block",
  props: ['targetID', 'date'],
  data(){
    return{
      cancelToken: {},
      source: {},
      summaryBlock: {}
    }
  },
  created(){
    this.cancelToken = axios.CancelToken;
    this.source = this.cancelToken.source();
  },
  beforeDestroy() {
    this.source.cancel('OPERATION_CANCELED');
  },
  mounted(){
    this.setWidget();
    this.getData();
  },
  methods: {
    ...mapActions('Widgets', ['getWidgetClient']),
    setWidget(){
      this.summaryBlock = 
      {
        classes: 'col-xlg-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-xxs-12',
        title: 'Widgets.ClientStatistics.Title',
        items: 
        [ 
          { 
            color: '#155393', 
            label: 'Widgets.ClientStatistics.TotalClient',
            svgIcon: "<svg viewBox='0 0 44.3 44.3' xml:space='preserve'><path d='M22.1,0C9.9,0,0,9.9,0,22.1s9.9,22.1,22.1,22.1s22.1-9.9,22.1-22.1S34.3,0,22.1,0z M10.9,38.8c0-0.1,0-0.1,0-0.2c0.1-6.1,5.1-11.1,11.2-11c6,0.1,11,5,11,11c0,0.1,0,0.2,0.1,0.3c-3.2,2.1-7,3.4-11.1,3.4C18,42.3,14.1,41,10.9,38.8z M22.1,24.8c-3.6,0-6.6-3-6.6-6.6s3-6.6,6.6-6.6c3.6,0,6.6,3,6.6,6.6S25.8,24.8,22.1,24.8z M35.1,37.4c-0.5-5.4-4.4-9.9-9.6-11.4c3-1.3,5.2-4.4,5.2-7.9c0-4.7-3.9-8.6-8.6-8.6s-8.6,3.9-8.6,8.6c0,3.5,2.1,6.5,5.1,7.9c-5.1,1.4-9.1,5.9-9.6,11.3c-4.3-3.7-7-9.2-7-15.2C2,11,11,2,22.1,2s20.1,9,20.1,20.1C42.3,28.3,39.5,33.8,35.1,37.4z'/></svg>",
            value: 0,
            valueLabel: ''
          },
          { 
            color: '#155393', 
            label: 'Widgets.ClientStatistics.TransactionVoid',
            svgIcon: '<svg viewBox="0 0 44.3 44.3" xml:space="preserve"><path d="M22.1,0C9.9,0,0,9.9,0,22.1s9.9,22.1,22.1,22.1s22.1-9.9,22.1-22.1S34.3,0,22.1,0z M10.9,38.8c0-0.1,0-0.1,0-0.2c0.1-6.1,5.1-11.1,11.2-11c6,0.1,11,5,11,11c0,0.1,0,0.2,0.1,0.3c-3.2,2.1-7,3.4-11.1,3.4C18,42.3,14.1,41,10.9,38.8z M22.1,24.8c-3.6,0-6.6-3-6.6-6.6s3-6.6,6.6-6.6c3.6,0,6.6,3,6.6,6.6S25.8,24.8,22.1,24.8z M35.1,37.4c-0.5-5.4-4.4-9.9-9.6-11.4c3-1.3,5.2-4.4,5.2-7.9c0-4.7-3.9-8.6-8.6-8.6s-8.6,3.9-8.6,8.6c0,3.5,2.1,6.5,5.1,7.9c-5.1,1.4-9.1,5.9-9.6,11.3c-4.3-3.7-7-9.2-7-15.2C2,11,11,2,22.1,2s20.1,9,20.1,20.1C42.3,28.3,39.5,33.8,35.1,37.4z"/></svg>',
            value: 0,
            valueLabel: '$',
            underValue: 0, 
          },
          { 
            color: '#155393', 
            label: 'Widgets.ClientStatistics.AverageCart',
            svgIcon: "<svg viewBox='0 0 50.1 34.7'  xml:space='preserve'><path class='st0' d='M48,6.7l-34.5,0c-0.3,0-0.6,0-0.9,0.1L12.2,5c-0.5-2-1.4-5-5.3-5H1C0.4,0,0,0.4,0,1s0.4,1,1,1h5.9c2,0,2.7,1.1,3.3,3.6l5.5,21.2c0,0,0,0,0,0c0.2,0.7,0.6,1.2,1.3,1.5c-0.5,0.7-0.8,1.5-0.8,2.4c0,2.3,1.8,4.1,4.1,4.1s4.1-1.8,4.1-4.1c0-0.8-0.3-1.6-0.7-2.2h13.1c-0.4,0.6-0.7,1.4-0.7,2.2c0,2.3,1.8,4.1,4.1,4.1c2.3,0,4.1-1.8,4.1-4.1c0-0.8-0.3-1.6-0.7-2.3c0.9-0.1,1.8-0.7,1.9-1.6l4.3-18c0-0.1,0-0.3,0-0.4C49.9,7.3,49.1,6.6,48,6.7z M20.4,32.7c-1.2,0-2.1-1-2.1-2.1s1-2.1,2.1-2.1s2.1,1,2.1,2.1S21.5,32.7,20.4,32.7z M40.4,32.7c-1.2,0-2.1-1-2.1-2.1s1-2.1,2.1-2.1c1.2,0,2.1,1,2.1,2.1S41.6,32.7,40.4,32.7z M43.8,26.3C43.8,26.4,43.8,26.4,43.8,26.3C43.8,26.4,43.7,26.4,43.8,26.3l-26,0.1c0,0-0.1-0.1-0.1-0.1l0,0c0,0,0,0,0,0l0,0L13.5,8.7l34.6,0c0,0,0,0,0,0L43.8,26.3z'/></svg>",
            value: 0,
            valueLabel: '$',
            underValue: 0, 
          },
          { 
            color: '#155393', 
            label: 'Widgets.ClientStatistics.ProductVoid',
            svgIcon: "<svg viewBox='-40 0 427 427.00131' xmlns='http://www.w3.org/2000/svg'><path d='m232.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0'/><path d='m114.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0'/><path d='m28.398438 127.121094v246.378906c0 14.5625 5.339843 28.238281 14.667968 38.050781 9.285156 9.839844 22.207032 15.425781 35.730469 15.449219h189.203125c13.527344-.023438 26.449219-5.609375 35.730469-15.449219 9.328125-9.8125 14.667969-23.488281 14.667969-38.050781v-246.378906c18.542968-4.921875 30.558593-22.835938 28.078124-41.863282-2.484374-19.023437-18.691406-33.253906-37.878906-33.257812h-51.199218v-12.5c.058593-10.511719-4.097657-20.605469-11.539063-28.03125-7.441406-7.421875-17.550781-11.5546875-28.0625-11.46875h-88.796875c-10.511719-.0859375-20.621094 4.046875-28.0625 11.46875-7.441406 7.425781-11.597656 17.519531-11.539062 28.03125v12.5h-51.199219c-19.1875.003906-35.394531 14.234375-37.878907 33.257812-2.480468 19.027344 9.535157 36.941407 28.078126 41.863282zm239.601562 279.878906h-189.203125c-17.097656 0-30.398437-14.6875-30.398437-33.5v-245.5h250v245.5c0 18.8125-13.300782 33.5-30.398438 33.5zm-158.601562-367.5c-.066407-5.207031 1.980468-10.21875 5.675781-13.894531 3.691406-3.675781 8.714843-5.695313 13.925781-5.605469h88.796875c5.210937-.089844 10.234375 1.929688 13.925781 5.605469 3.695313 3.671875 5.742188 8.6875 5.675782 13.894531v12.5h-128zm-71.199219 32.5h270.398437c9.941406 0 18 8.058594 18 18s-8.058594 18-18 18h-270.398437c-9.941407 0-18-8.058594-18-18s8.058593-18 18-18zm0 0'/><path d='m173.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0'/></svg>",
            value: 0,
            valueLabel: '$',
            underValue: 0, 
          },
          { 
            color: '#155393', 
            label: 'Widgets.ClientStatistics.TransactionRefund',
            svgIcon: "<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 512 512' style='enable-background:new 0 0 512 512;' xml:space='preserve'><g><g><path d='M288.502,32.502c-108.328,0-198.827,77.485-219.166,179.899l-42.482-53.107L0,180.784l68.769,85.961 c3.352,4.178,8.338,6.447,13.427,6.447c2.596,0,5.226-0.585,7.685-1.805l103.153-51.577l-15.387-30.757l-75.8,37.892 c14.063-90.5,92.27-160.059,186.655-160.059c104.271,0,189.114,84.843,189.114,189.114s-84.843,189.114-189.114,189.114v34.384 C411.735,479.498,512,379.233,512,256S411.735,32.502,288.502,32.502z'/></g></g></svg>",
            value: 0,
            valueLabel: '$',
            underValue: 0, 
          },
          {}
        ]
      }
    },
    async getData(){
      const currentDate = moment().format('YYYY-MM-DD');
      const request = {
        params: { date: this.date ? this.date : currentDate },
        cancelToken: { cancelToken: this.source.token },
        targetIDSelected: this.targetID
      }
      
      let widget = null;

      try {
        widget = await this.getWidgetClient(request);
      } catch (error) {
        this.setWidget();
      }

      if (widget) {
        this.summaryBlock = widget;
      }
    }
  }
}

</script>
