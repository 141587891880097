import Vue from "vue";
import VueI18n from "vue-i18n";
import { getTwoLettersLanguage } from '@/core/domain/language/language.js';
import { languages } from '@/core/domain/language/language.const.js';

import translationEn from "./i18n/en.json";
import translationFr from "./i18n/fr.json";

Vue.use(VueI18n);

function loadLocaleMessages() {
  return {
    en: translationEn,
    fr: translationFr
  };
}

export default new VueI18n({
  locale: getTwoLettersLanguage(navigator.language) || process.env.VUE_APP_I18N_LOCALE || languages.en,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || languages.en,
  messages: loadLocaleMessages()
});
