<template src="./ws-management.html"></template>

<style lang="scss">
@import './ws-management.scss';
</style>

<script>
export default {
  name: 'Ws-management'
}
</script>
