<template src="./direct-receiving-product.html"></template>

<style lang="scss">
    @import './direct-receiving-product.scss';
</style>

<script>
import { ReceivingStatus } from '@/backoffice/modules/purchase/domain/purchase.js';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { toCurrency } from '@/core/functions';
import prompts from '@/core/tools/notifications/notifications';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import ProductInfoHeader from '@/core/components/layout/side-panel/product-info-header/product-info-header.vue';
import ProductCostsBody from '@/core/components/layout/side-panel/product-costs-body/product-costs-body.vue';
import ProductResultFooter from '@/core/components/layout/side-panel/product-result-footer/product-result-footer.vue';
import receivingProductScan from '@/backoffice/modules/purchase/components/sidePanel/direct-receivings/direct-receiving-product-scan/direct-receiving-product-scan.vue';
import { ReceivingGridTabs } from '@/backoffice/modules/purchase/pages/direct-receivings/direct-receiving/receivingGridTabs.js';
import { permissionsDictionary } from '@/core/security/permissions-dictionary.js';

export default {
    name: 'receiving-product',

    components:{
        ProductInfoHeader,
        ProductCostsBody,
        ProductResultFooter
    },

    data() {
        return {
            edited: false,
            focusedInput: null,
            productInventory: {},
            costs: [],
            costsCopy: [],
            totals: { qty: 0, cost: 0 },
            newReceivingBodyTemplate: {},
            averageLast4WeeksQty: 0
        }
    },

    computed:{
        ...mapGetters('Account', ['getCurrentTargetLayerId', 'hasPermission']),
        ...mapGetters('Product', ['getProductWithVendorCosts']),
        ...mapGetters('Receiving', ['getReceiving']),
        canUpdate(){
            return this.getReceiving.canUpdate && this.getReceiving.receivingStatus == ReceivingStatus.OPEN;
        },
        readProductPermission(){
            return this.hasPermission([permissionsDictionary.PRODUCT_READ])
        },
        readInventoryPermission(){
            return this.hasPermission([permissionsDictionary.INVENTORY_READ])
        }
    },

    watch: {
        'getProductWithVendorCosts.itemUPC'() {
            this.initializeProduct();
            this.fetchAverage();
            this.fetchInventory();
        }
    },

    mounted(){
        this.initializeProduct();
        this.fetchAverage();
        this.fetchInventory();
    },

    methods: {
        ...mapActions('Receiving', ['saveReceiving' ]),
        ...mapActions('Product', ['fetchProductsAverageLastWeeks' ]),
        ...mapActions('Inventory', ['fetchProductInventory']),
        ...mapMutations('Receiving', {
            setReceivingGridTabIndex: 'SET_RECEIVING_GRID_TAB_INDEX'
        }),

        async initializeProduct() {
            this.newPurchaseBodyTemplate = {
                itemID: this.getProductWithVendorCosts._ItemID,
                itemUPC: this.getProductWithVendorCosts.itemUPC,
                itemSKU: this.getProductWithVendorCosts.itemSKU,
                itemDescription: this.getProductWithVendorCosts.description,
                subDepartmentID: this.getProductWithVendorCosts._SdpID,
                subDepartmentCode: this.getProductWithVendorCosts.sdpCode,
                subDepartmentDescription: this.getProductWithVendorCosts.subDepartmentDescription,
                departmentID: this.getProductWithVendorCosts._DepartmentID,
                departmentCode: this.getProductWithVendorCosts.departmentCode,
                departmentDescription: this.getProductWithVendorCosts.departmentDescription,
                format: this.getProductWithVendorCosts.format
            }

            this.costs = cloneDeep(this.getProductWithVendorCosts.costs);
            this.initializeReceivingBody();
        },

        initializeReceivingBody(){
            this.linkCostsToBodies();
            this.recalculateTotals();
            this.selectFirstInput();

            this.costsCopy = cloneDeep(this.costs);
        },

        selectFirstInput(){
            if (this.costs.length > 0) {
                this.$nextTick(() => {
                    this.focusedInput = this.$refs['qty-0'];
                    this.focusedInput.focus();
                    this.focusedInput.select();
                });
            }
            else
                this.$refs.scan.focus();
        },

        scan(){
            if(this.canUpdate && !this.edited){
                this.setReceivingGridTabIndex(ReceivingGridTabs.ReceivingBodiesTabIndex);
                this.$sidePanel.show([{ component: receivingProductScan }], null, { backgroundIsDisabled: true });
            }
        },

        changeInput(index){
            if (this.costs.length > index + 1) {
                this.focusedInput = this.$refs[`qty-${index + 1}`];
                this.focusedInput.focus();
                this.focusedInput.select();
            }
            else if(this.costs.length == index + 1){
                this.focusedInput = this.$refs[`qty-${index}`];
                this.focusedInput.blur();
                this.$refs['scan'].focus();
            }
            else{
                this.focusedInput = null
                this.$refs['scan'].focus();
            }
        },

        setReceivedQty(cost, receivedQty){
            cost.receivedQty = receivedQty || undefined;
            this.validateEdit();
        },

        setReceivedCost(cost, receivedCost) {
            cost.receivedCost = receivedCost || undefined;
            this.validateEdit();
        },

        validateEdit(){
            this.edited = false;
            for (let i = 0; i < this.costs.length; i++) {
                const cost = this.costs[i];
                if(cost.receivedQty != this.costsCopy[i].receivedQty || cost.receivedCost != this.costsCopy[i].receivedCost){
                    this.edited = true;
                }
            }

            this.$sidePanel.isEdit(this.edited);
        },

        validateReceivedQty(cost){
            if (!(cost.receivedQty >= 0))
                cost.receivedQty = undefined;

            cost.ignore = false;

            this.saveProduct();
        },

        validateReceivedCost(cost){
            if (!(cost.receivedCost >= 0))
                cost.receivedCost = undefined;

            cost.ignore = false;

            this.saveProduct();
        },

        recalculateTotals(){
            this.totals = { qty: 0, cost: 0 };

            for (let i = 0; i < this.costs.length; i++){
                if (this.costs[i].receivedQty > 0){
                    if (this.costs[i].costUnitQty > 0)
                        this.totals.qty += parseInt(this.costs[i].receivedQty) * this.costs[i].costUnitQty;
                    else
                        this.totals.qty += parseInt(this.costs[i].receivedQty);

                    if (this.costs[i].receivedCost)
                        this.totals.cost += parseInt(this.costs[i].receivedQty) * this.costs[i].receivedCost;
                }
            }

            this.totals.cost = toCurrency(this.totals.cost);
        },

        linkCostsToBodies(){
            if(this.costs.length == 0)
                return

            for (let i = 0; i < this.costs.length; i++){
                this.linkCostToBody(i);
            }
        },

        linkCostToBody(index){
            const costBody = this.getReceiving.receivingBodies.find(b => b.id == this.costs[index].id || (b.purchaseBodyInfo.itemUPC == this.costs[index].itemUPC && b.purchaseBodyInfo.uomID == this.costs[index].uomid && b.unitQty == this.costs[index].costUnitQty));
            if (costBody){
                this.costs[index].id = costBody.id;
                this.costs[index].receivedQty = costBody.qty;
                this.costs[index].receivedCost = costBody.unitCost;
            }
            else{
                this.costs[index].receivedCost = this.costs[index].costValue;
            }
        },

        async saveProduct() {
            const receiving = cloneDeep(this.getReceiving);

            let executeSave = false;
            let newBodies = false;
            for (let i = 0; i < this.costs.length; i++){
                if ((this.costs[i].receivedQty > 0 || this.costs[i].remove) && !this.costs[i].ignore){
                    executeSave = true;

                    if (this.costs[i].id){
                        const costBody = receiving.receivingBodies.find(b => b.id == this.costs[i].id);
                        if (costBody){
                            costBody.qty = this.costs[i].receivedQty;
                            costBody.unitCost = this.costs[i].receivedCost;
                            costBody.unitQty = this.costs[i].costUnitQty;
                            costBody.cost = this.costs[i].receivedCost ? this.costs[i].receivedQty * this.costs[i].receivedCost : 0;
                            costBody.qtyVariance = 0;
                            costBody.unitCostVariance = this.costs[i].receivedCost - this.costs[i].costValue
                            costBody.ignore = false;
                        }
                    }
                    else{
                        newBodies = true;

                        const newBody = {}
                        newBody.purchaseBodyInfo = cloneDeep(this.newPurchaseBodyTemplate);
                        newBody.purchaseBodyInfo.uomID = this.costs[i].uomid;
                        newBody.purchaseBodyInfo.uomDescription = this.costs[i].uomDescription;

                        newBody.createOn = moment();
                        newBody.vendorID = this.getReceiving.vendorID
                        newBody.vendorCode = this.getReceiving.vendorCode
                        newBody.vendorName = this.getReceiving.vendorName

                        newBody.qty = this.costs[i].receivedQty;
                        newBody.unitCost = this.costs[i].receivedCost;
                        newBody.unitQty = this.costs[i].costUnitQty;
                        newBody.cost = this.costs[i].receivedCost ? this.costs[i].receivedQty * this.costs[i].receivedCost : 0;
                        newBody.qtyVariance = 0;
                        newBody.unitCostVariance = this.costs[i].receivedCost - this.costs[i].costValue
                        newBody.ignore = this.costs[i].ignore;
                        receiving.receivingBodies.push(newBody);
                    }

                    this.costs[i].remove = false;
                    this.costs[i].ignore = true;
                }
            }

            if (executeSave){
                executeSave = false;

                try {
                    await this.saveReceiving(receiving);
                }
                catch(e) {
                    prompts.error({
                        text: this.$tc('Purchase.Messages.SaveReceivingError')
                    });
                }

                if (newBodies)
                    this.linkCostsToBodies();

                this.costsCopy = cloneDeep(this.costs);
                this.validateEdit();
            }
        },
        async fetchAverage(){
            const productsAverage = await this.fetchProductsAverageLastWeeks({ itemUpcs: [this.getProductWithVendorCosts.itemUPC], nbOfWeeks: 4 });
            this.averageLast4WeeksQty = productsAverage[this.getProductWithVendorCosts.itemUPC] ? productsAverage[this.getProductWithVendorCosts.itemUPC].quantity : 0
        },
        async fetchInventory(){            
            if(this.readInventoryPermission)
                this.productInventory = await this.fetchProductInventory(this.getProductWithVendorCosts.itemUPC);
        },

        cancel(){
            this.$sidePanel.close();
        }
    }
}
</script>