<template src="./labels.html"></template>

<style lang="scss">
@import './labels.scss';
</style>

<script>
export default {
  name: 'Labels',
  components: {}
}
</script>
