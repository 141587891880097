<template src="./checkbox-renderer.html"></template>

<style scoped lang="scss">
@import './checkbox-renderer.scss';
</style>

<script>
import checkbox from '@/core/components/common/checkbox/checkbox.vue'

export default{
  name: 'checkboxTreeRenderer',
  components: {
    checkbox
  },
  data: function () {
    return {
      grid:{
        api: null,
        columnApi: null,
        currentNode: null,
      },
      value: null,
      checked: false
    }
  },
  computed: {
    readOnly() { return this.params.readOnly && this.params.readOnly() }
  },
  mounted() {  
    this.grid.api = this.params.api;
    this.value = this.params.value;
  },
  methods: {
    updateCheckbox(params, checked){
      params.node.selectThisNode(checked);
      this.updateTree(params);
    },
    updateTree(params) {
        const selectParent = (node) => {
            let stayChecked = false;

            if (false == params.node.selected) {
                node.childrenAfterGroup.forEach(a=>{
                    if (a.selected) {
                        stayChecked = true;
                    }
                });
            }

            if (!stayChecked) {
                node.selectThisNode(params.node.selected);
                
                this.grid.api.refreshCells({force: true, rowNodes: [node]});

                if (node.parent) {
                    selectParent(node.parent);
                }
            }
        }

        const updateChild = (node) => {
            node.childrenAfterGroup.forEach(a=>{
                a.selectThisNode(params.node.selected);

                if (a.childrenAfterGroup && a.childrenAfterGroup.length > 0) {
                    updateChild(a);
                }
            });
        }

        updateChild(params.node);

        if (params.node.parent) {
            setTimeout(()=> {
                selectParent(params.node.parent);
            });
        }
    }
  }
};
</script>