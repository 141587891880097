import Vue from "vue";
import Vuex from "vuex";

import { Account } from '@/core/store/modules/account.store.js';
import { App } from '@/core/store/modules/app.store.js';
import Breadcrumb from '@/core/store/modules/breadcrumb.store.js';
import { DataModels } from '@/core/store/modules/data-models.store.js';
import { Scope } from '@/core/store/modules/scope.store.js'
import Grid from '@/core/store/modules/grid.store.js'
import { Dashboard, Widgets, Inventory, Reporting, Order, Product, Brand, Receiving, Vendor, RefundableDeposit, Promotion, Role, ReleaseNote, ClientAccount, PriceLevel } from '@/backoffice/store';
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex);

const vuex = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    Account,
    App,
    DataModels,
    Scope,
    Dashboard,
    Widgets,
    Inventory,
    Reporting,
    Order: Order,
    Receiving: Receiving,
    Product,
    Brand,
    Breadcrumb,
    Vendor,
    RefundableDeposit,
    PriceLevel,
    Promotion,
    Role,
    ReleaseNote,
    ClientAccount,
    Grid
  },
  plugins: [createPersistedState()]
});

export default vuex;