import Vue from 'vue';

const submitData = {
    bind: (el, binding) => {
        if (typeof binding.value !== "function")
            throw ('The value of submit-data need to be a function.');

        const asyncFunction = binding.value;
        const handler = async () => {
            el.disabled = true;
            await asyncFunction();
            el.disabled = false;
        };
    
        el.__vueSubmitData__ = handler;
  
        el.addEventListener('click', el.__vueSubmitData__);
    },

    unbind: (el) => {
        el.removeEventListener('click', el.__vueSubmitData__);
    }
};

Vue.directive('submit-data', submitData);