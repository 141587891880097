<template src="./system-dashboard.html"></template>

<style lang="scss">
    @import './system-dashboard.scss';
</style>

<script>
import { mapGetters } from 'vuex';

import mainBlock from '@/backoffice/modules/dashboard/components/main-block/main-block.vue'
import chartBlock from '@/backoffice/modules/dashboard/components/chart-block/chart-block.vue'
import summaryBlock from '@/backoffice/modules/dashboard/components/summary-block/summary-block.vue'
import listingBlock from '@/backoffice/modules/dashboard/components/listing-block/listing-block.vue'
import statsBlock from '@/backoffice/modules/dashboard/components/stats-block/stats-block.vue'

export default {
  name: 'SystemDashboard',
  
  components: {
    mainBlock,
    chartBlock,
    summaryBlock,
    listingBlock,
    statsBlock
  },

  computed: {
    ...mapGetters('Dashboard', ['getDate', 'getTargetID'])
  },

  watch:{
    getDate: {
      handler(newVal, oldVal){
        if (oldVal != newVal) {
          this.chartId++;
        }
      }
    },
    getTargetID: {
      handler(newVal, oldVal){
        if (oldVal != newVal) {
          this.chartId++;
        }
      }
    }
  },

  data() {
    return {
      chartId: 0,
      currentTabKey: 'sales'
    }
  },

  methods: {
    changeTab(tab) {
      this.currentTabKey = tab;
    }
  }
}
</script>
