<template inline-template v-cloak src="./description-input.html"></template>
<style scoped lang="scss" src="./description-input.scss"></style>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import cloneDeep from 'lodash/cloneDeep';
import { getLanguageDescription, hasAnyDescription } from '@/core/functions';
import { descriptionLanguages } from '@/core/domain/language/language.const.js'
import Multiselect from 'vue-multiselect'

const languagesDictionary = {
  'FR': {
    label: 'Language.French',
    value: descriptionLanguages.FR
  },
  'EN': {
    label: 'Language.English',
    value: descriptionLanguages.EN
  }
}

export default {
  name: 'description-input',
  model:{
    prop: 'descriptions'
  },
  props:{
    descriptions: {
      type: Array,
      default: function () {
        return []
      }
    },
    field: {
      type: String,
      default: 'value'
    },
    placeholder: String,
    disabled: {
      type: Boolean,
      default: false
    },
    maxlength: {
      type: Number,
      default: 524288
    }
  },
  components:{
    ValidationObserver,
    ValidationProvider,
    Multiselect
  },
  data() {
    return {
      currentLanguage: languagesDictionary[this.$i18n.locale.toUpperCase()],
      languages: [languagesDictionary.FR, languagesDictionary.EN]
    };
  },
  computed: {
    validate(){
      return hasAnyDescription(this.descriptions, this.field);
    },
    validationRules(){
      return { required: !this.validate, customFunction: { evalFunction: this.validate }}
    },
    currentDescription(){
      return getLanguageDescription(this.descriptions, this.currentLanguage.value) || this.defaultDescription(this.currentLanguage.value, this.field);
    },
    currentDescriptionValue:{
      get() {
        return this.currentDescription ? this.currentDescription[this.field] : null;
      },
      set(value) {
        this.updateDescription(value);
      }
    }
  },

  mounted(){
    this.setCurrentLanguage();
  },

  methods: {
    setCurrentLanguage(){
      if(this.descriptions.some(d => this.compareCI(d.language, this.$i18n.locale) && d[this.field])){
        this.currentLanguage = this.languages.find(l => this.compareCI(l.value, this.$i18n.locale));
      }
      else{
        for (let i = 0; i < this.languages.length; i++) {
          if(this.descriptions.some(d => this.compareCI(d.language, this.languages[i].value) && d[this.field])){
            this.currentLanguage = this.languages[i];
            break;
          }
        }
      }
    },

    updateDescription(description){
      const descriptions = cloneDeep(this.descriptions);
      const descriptionUpdatedIndex = descriptions.findIndex(d => d.language === this.currentLanguage.value);
      const descriptionUpdated = descriptions.find(d => d.language == this.currentLanguage.value);

      if (descriptionUpdated) {
        if (!description) {
          if (descriptionUpdated._DescriptionID > 0)
            descriptionUpdated.remove = true;
          else
            descriptions.splice(descriptionUpdatedIndex, 1);
        }
        else{
          if (descriptionUpdated._DescriptionID > 0)
            delete descriptionUpdated.remove            
          else
            descriptionUpdated.remove = false;
        }

        descriptionUpdated[this.field] = description;
      }
      else {
        if (description) {
          descriptions.push({
            ...this.currentDescription,
            [this.field]: description
          });
        }
      }

      this.$emit('input', descriptions);
    },
    defaultDescription(language, field) { 
      return {
        _DescriptionID: 0, 
        id: 0, 
        language: language,
        [field]: undefined
      };
    }
  }
}

</script>
