<template src="./hierarchy.html"></template>

<style lang="scss">
    @import './hierarchy.scss';
</style>

<script>
import { EntityServices } from '@/core/store/modules/entity.services.js';
import { genericServices } from '@/core/services/generic/generic.services';
import { mapGetters } from 'vuex';
import { ValidationObserver } from "vee-validate";
import { descriptionLanguages } from '@/core/domain/language/language.const.js';

import grid from '@/core/components/common/grid/grid.vue'
import descriptionInput from '@/core/components/common/description-input/description-input.vue'
import searchBar from '@/core/components/common/search-bar/search-bar.vue'

export default {
    name: 'hierarchy-template',
    components: {
        grid,
        descriptionInput,
        searchBar,
        ValidationObserver
    },
    
    props:{
        templatePayload: Object
    },

    data() {
        return {
            gridOptions: {
                rowHeight: 30,
                suppressCellFocus: false,
                suppressRowClickSelection: true,
                treeData: true,
                getDataPath: this.getDataPath,
                excludeChildrenWhenTreeDataFiltering: true,
                groupDefaultExpanded: -1,
                rowSelection: 'multiple',
                defaultColDef: {
                    flex: 1,
                    minWidth: 379
                },
                autoGroupColumnDef: {
                    groupId: 'targetlayer',
                    headerName: this.$t('Target.TargetLayer'),
                    cellRenderer:'agGroupCellRenderer',
                    filter: true,
                    cellRendererParams: {
                        suppressCount: true,
                        innerRenderer : 'checkboxTreeRenderer',
                        readOnly: () => this.readOnly
                    }
                },
                onGridReady: () => {
                    this.gridOptions.api.setColumnDefs([]);

                    // Add selected has none visible columns for filter on it.
                    this.gridOptions.api.setColumnDefs([
                        {
                            field: 'selected',
                            hide: true,
                            filter: 'agNumberColumnFilter',
                            valueGetter: (row) => {
                                return row.node.selected ? 1 : 0;
                            }
                        },
                        { field: 'treeOrder', hide: true, filter: 'agTextColumnFilter'}
                    ]);

                    this.getData();
                },
                onRowSelected: this.onRowSelected
            },
            entity: {},
            entityCopy: {},
            targetLinksBackup: [],
            list: [],
            listCopy: [],
            loaded: false,
            readOnly: true,
            isActiveFilter: false,
            showError: false,
            scopeKey: 0,
            permissions: ["TARGET_CUD"],
            gridReadyPromiseResolver: null,
            loadEntityPromiseResolver: null
        }
    },

    watch: {
        entity: {
            handler(newentity){
                if (!this.readOnly) {
                    const isEdited = JSON.stringify(newentity.descriptions) != JSON.stringify(this.entityCopy.descriptions) || this.hasTargetLinksBeenChanged();
                    this.$sidePanel.isEdit(isEdited);
                }
            },
            deep: true
        }
    },

    computed:{
        ...mapGetters('Account', ['getCurrentTargetLayerId']),
        model(){
            return this.templatePayload;
        }
    },

    created() {
        Promise.all([
            new Promise(resolve => {
                this.gridReadyPromiseResolver = resolve;
            }),
            new Promise(resolve => {
                this.loadEntityPromiseResolver = resolve;
            })
        ]).then(async () => {
            this.gridReadyPromiseResolver = null;
            this.loadEntityPromiseResolver = null;

            this.loaded = true;

            this.$nextTick(() => {
                // Validate if we are not in creation with the _TargetID is not 0.
                if (this.entity._TargetID) {
                    this.setDataSelectedRows(); // Select checkbox for the model "entity".
                    this.filterActive(true); // Filter grid by Selected row only.
                }
            });
        });
    },

    mounted() {
        this.getFullModelData();
    },

    methods: {
        filterActive(active) {
            this.isActiveFilter = active;

            if (this.isActiveFilter) {
                // Get a reference to the 'name' filter instance
                const filterInstance = this.gridOptions.api.getFilterInstance('selected');

                // Set the model for the filter
                filterInstance?.setModel({
                    type: 'equals',
                    filter: 1
                });

                // Tell grid to run filter operation again
                this.gridOptions.api.onFilterChanged();
            }
            else {
                this.gridOptions.api.setFilterModel(null);
                this.gridOptions.api.onFilterChanged();
            }
        },
        searchInGrid(filters) {
            const filterInstance = this.gridOptions.api.getFilterInstance('treeOrder');
            const filter = filters.length > 0 ? filters[0] : '';

            // Set the model for the filter
            filterInstance?.setModel({
                type: 'contains',
                filter: filter
            });

            // Tell grid to run filter operation again
            this.gridOptions.api.onFilterChanged();
        },
        updateDescription() {
            let description = '';
            let findIt = false;
            let emptyDesc = true;
            for (let i = 0; i < this.entity.descriptions.length; i++) {
                // If the description is the same of the current language and is not empty UPDATE/
                if (this.compareCI(this.entity.descriptions[i].language, this.$i18n.locale) && this.entity.descriptions[i].targetDescription1 !== '') {
                    description = this.entity.descriptions[i].targetDescription1;
                    findIt = true;
                } // If the description is not find and the value is not empty set it.
                else if (!findIt && this.entity.descriptions[i].targetDescription1 != '') {
                    description = this.entity.descriptions[i].targetDescription1;
                }
                emptyDesc = !emptyDesc ? emptyDesc : this.entity.descriptions[i].targetDescription1 === '';

                // If its a new description, set the _TargetID.
                if (this.entity.descriptions[i].targetDescription1 !== '' && !this.entity.descriptions[i].id) {
                    this.entity.descriptions[i].id = this.model._TargetID;
                }
                else if (this.entity.descriptions[i].targetDescription1 === '') {
                    this.entity.descriptions.splice(i, 1);
                }
            }

            this.entity.description = description;

            return emptyDesc;
        },
        resetEntity() {
            this.gridOptions.api.deselectAll();
            this.entity.description = this.entityCopy.description;
            this.setDataSelectedRows();
            this.showError = false;
        },
        hasTargetLinksBeenChanged() {
            const targetLinks = this.gridOptions.api.getSelectedNodes().map(node => node.data).filter(node => node);
            return JSON.stringify(targetLinks) !== JSON.stringify(this.targetLinksBackup);
        },
        async saveTarget() {
            const targetLinks = [];

            // Build the new TagetLink.
            this.gridOptions.api.forEachNode((node) => {
                if (node.selected) {
                    targetLinks.push({ '_TargetID': this.entity._TargetID, '_TargetLayerID': node.data.id });
                }
                else {
                    const isDeletedTargetLink = this.entity.targetLinks.some(t => t._TargetLayerID == node.data.id);

                    if (isDeletedTargetLink)
                        targetLinks.push({ '_TargetID': this.entity._TargetID, '_TargetLayerID': node.data.id, 'Remove': true });
                }
            });

            // Update the displayed description with the desciptions property.
            if (!this.updateDescription()) {
                const params = Object.assign({}, this.entity);
                params.targetLinks = targetLinks;

                let result = null;

                if(params._TargetID != 0){
                    result = await EntityServices.saveEntities({ targetModel: 'Target', entities: [params] });
                }
                else{
                    result = await EntityServices.createEntities({ targetModel: 'Target', entities: [params] });
                }

                if (Array.isArray(result) && result.length > 0) {
                    const success = this.entity._TargetID ? { 'save': true, 'row': result[0] } : { 'create': true, 'row': result[0] };

                    this.$sidePanel.submit(success);
                }
            }
            else {
                this.showError = true;
            }
        },
        cancelEdit() {
            if (this.entity._TargetID == 0) {
                this.$sidePanel.close();
                return;
            }

            this.readOnly = !this.readOnly;
            this.resetEntity(); // Reset tree selection and descriptions.
            this.$sidePanel.isEdit(false); // Reset to false onEdit. 
            this.filterActive(true); // Filter grid by Selected row only.
            this.showError = false; // Remove empty desc error.
            this.searchValue = ''; // Reset grid quickfilter.
            this.searchInGrid([]); // Trigger grid search.
        },
        editTarget() {
            this.readOnly = !this.readOnly;
            this.filterActive(false); // Remove filter grid by Selected when edit Target.
        },
        async getFullModelData() {
            if (this.model._TargetID) {
                const apiresponse = await genericServices.getApiEntityByID('Target', this.model._TargetID, true);
                if (apiresponse.status == 200) {
                    this.entity = Object.assign({}, apiresponse.entity);
                    this.entityCopy = Object.assign({}, apiresponse.entity);
                }
            }
            else {
                const newModel = {
                    description: '',
                    descriptions: [],
                    targetLinks: [],
                    _TargetID: 0,
                    _TargetLayerID: this.getCurrentTargetLayerId
                };

                this.entity = Object.assign({}, newModel, { descriptions: [{ language: descriptionLanguages.FR, value: '' }, 
                                                                           { language: descriptionLanguages.EN, value: '' }] });
                this.entityCopy = Object.assign({}, newModel);

                setTimeout(() => {
                    this.readOnly = !this.readOnly;
                    this.$sidePanel.isEdit(true);
                });
            }

            this.loadEntityPromiseResolver();
        },
        async getData() {
            this.loaded = false;

            const entities = await EntityServices.getEntities({ getChildren: true, modelName: 'TargetLayerHierarchy', targetLayerID: this.entity._TargetLayerID, direction: 'Down', filters: [] });

            if(entities){
				this.list = entities.map(l => {
                    return {
                        ...this.deconstruct(Object.assign({}, l), l),
                        selected: 0
                    }
                });
                this.listCopy = Object.assign({}, this.list);

                // Add selected has none visible columns for filter on it.
                this.gridOptions.api.setRowData(this.list);

                this.gridReadyPromiseResolver();
            }
        },
        onRowSelected(row) {
            if (!this.readOnly && row.api.getFocusedCell().rowIndex == row.rowIndex) {
                setTimeout(() => {
                    this.$emit('template-edit', null, JSON.stringify(this.entity.descriptions) != JSON.stringify(this.entityCopy.descriptions) || this.hasTargetLinksBeenChanged());
                }, 200);
            }
        },
        setDataSelectedRows() {
            // With model, select all TargetLayerID is link and check the checkbox.
            const targetLinks = this.entity.targetLinks.reduce((obj, link) => {
                obj[link._TargetLayerID] = true;
                return obj;
            }, {});
            this.gridOptions.api.forEachNode((node) => {
                const findTargetLink = !!targetLinks[node.data.id];

                if (findTargetLink) {
                    node.setSelected(true);
                }
            });

            this.targetLinksBackup = this.gridOptions.api.getSelectedNodes().map(r => r.data);
        },
        getDataPath: function(data) {
            return JSON.parse(data.treeOrder); // The treeOrder is a string and must be parse for get th array.
        }
    }
}
</script>