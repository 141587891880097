<template src="./action-and-information.html"></template>

<script>
import { mapGetters } from 'vuex';

export default{
  name: 'action-and-information',

  props: {
    params: Object
  },

  computed: {
    ...mapGetters('App', ['isMobile']),
    information() {
      return (this.params.getInformation && this.params.getInformation(this.params.data)) || ''; 
    }
  },

  methods: {
    onClick() {
      if (this.params.callback) {
        this.params.callback(this.params.data);
      }
    }
  }
};
</script>
