<template src="./horizontal-list.html"></template>
<style lang="scss" scoped src="./horizontal-list.scss"></style>

<script>
import { getCurrentLanguageDescription } from '@/core/functions';

export default {
  name: "horizontalList",
  model: {
    prop: 'selectedNode'
  },
  props: {
    id: [String, Number],
    list: Array,
    labelField: String,
    canDelete: Boolean,
    canAdd: Boolean,
    selectedNode: Object,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getList() {
      return this.list || [];
    }
  },
  methods: {
    getDescription(node){
      if (!this.labelField)
        return node;

      if (this.labelField != 'descriptions')
        return node[this.labelField] || '';

      const description = getCurrentLanguageDescription(node.descriptions)
      if (description.shortDescription) {
        return description.shortDescription
      }

      const fallbackDescription = node.descriptions.find(desc => !!(desc.shortDescription || desc.description))
      if (fallbackDescription) {
        return fallbackDescription.shortDescription || fallbackDescription.description;
      }

      return '';
    },
    onSelect: function(node) {
      this.$emit('input', node);
    },
    onDelete: function(node) {
      this.$emit('onDelete', node);
    },
    onAdd: function() {
      this.$emit('onAdd');
    }
  }
}
</script>