import router from '@/core/router';
import { reportClient } from '@/backoffice/modules/reporting/services/report.client.js';
import { basicLoginServices, forgotPasswordServices, targetLayerServices, targetServices } from '@/core/services';
import Errors from "@/core/tools/errors/errors";
import prompts from '@/core/tools/notifications/notifications';
import jwtDecode from 'jwt-decode';
import { permissionsDictionary } from '@/core/security/permissions-dictionary.js';
import i18n from '@/i18n';
import { mapUserLanguage } from '@/core/domain/language/language.js';

const getDefaultState = () => {
  return {
    token: null,
    user: null,
    permissions: [],
    targetLayers: null,
    targets: null,
    favoriteReport: {},
    currentTargetLayer: {
      _TargetLayerID: 0,
      _TargetLayerLinkID: 0,
      targetLayerPriority: 0,
      targetLayerType: '',
      description: ''
    },
    currentTarget: {
      _TargetID: 0,
      _TargetLayerID: 0,
      TargetPriority: 0
    }
  };
};

function checkPermission(state, permissions) {
  return permissions && permissions.length > 0 ? permissions.some(p => state.permissions.includes(permissionsDictionary[p]) || state.permissions.includes(p)) : true;
}

export const Account = {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getFirstname: state => {
      return state.user?.firstName;
    },
    getLastname: state => {
      return state.user?.lastName
    },
    getUser: state => {
      return state.user;
    },
    getToken: state => {
      return state.token ?? null;
    },
    getDecodedToken: state => {
      return state.token ? jwtDecode(state.token) : null;
    },
    tokenIsExpired: (state, getters) => {
      return getters.getDecodedToken && Date.now() > (getters.getDecodedToken.exp * 1000);
    },
    getTargetLayers: state => {
      return state.targetLayers;
    },
    getCurrentTargetLayer: state => {
      return state.currentTargetLayer;
    },
    getCurrentTargetLayerId: state => {
      return state.currentTargetLayer._TargetLayerID;
    },
    getTargets: state => {
      return state.targets;
    },
    getCurrentTarget: state => {
      return state.currentTarget;
    },
    getCurrentTargetId: state => {
      return state.currentTarget._TargetID;
    },
    getFavoriteReport: state => {
      return state.favoriteReport;
    },
    getPermissions: state => {
      return state.permissions;
    },
    hasModelPermission: state => model => {
      const modelPermissions = [];
      if (model && model.permissions) {
        if (model.permissions.read)
          modelPermissions.push(model.permissions.read);
        if (model.permissions.report)
          modelPermissions.push(model.permissions.report);
      }

      return checkPermission(state, modelPermissions);
    },
    hasPermission: state => permissions => {
      return checkPermission(state, permissions);
    },
    getReportFavorite: state => {
      return state.favoriteReport;
    }
  },
  mutations: {
    //Reset store
    RESET_STORE(state) {
      Object.assign(state, getDefaultState());
    },

    SET_TARGETLAYERS(state, targetLayers) {
      state.targetLayers = targetLayers;
    },
    SET_TARGETS(state, targets) {
      state.targets = targets;
    },
    SET_CURRENT_TARGET_LAYER(state, targetLayerId) {
      state.currentTargetLayer = state.targetLayers.find(tl => tl._TargetLayerID == targetLayerId);
    },
    SET_CURRENT_TARGET(state, targetLayerId) {
      state.currentTarget = state.targets.find(t => t._TargetLayerID == targetLayerId && t.targetPriority == 1);
    },
    SET_FAVORITE_REPORT(state, favoriteReport) {
      state.favoriteReport = favoriteReport;
    },
    SET_TOKEN(state, token) {
      state.token = token;
      state.permissions = jwtDecode(state.token).permissions || [];
    },
    SET_USER(state, user) {
      state.user = user;
    },
    ADD_PERMISSIONS(state, permissions) {
      state.permissions = state.permissions.concat(permissions);
    }
  },
  actions: {
    logout({ dispatch }) {
      router.push({ name: 'Login' }, function () {
        dispatch('resetStoreAndModules');
      });
    },
    resetStoreAndModules({ commit, dispatch }) {
      //Reset account store
      commit('RESET_STORE');
      //Reset all registered modules
      dispatch('App/resetRegisteredModules', null, { root: true });
    },
    async login({ commit, dispatch }, userSubmit) {
      const response = await basicLoginServices.loginUser(userSubmit);

      if (response.status == 200) {
        const token = response.data.token;
        const user = response.data.payload;
        const tli = parseInt(jwtDecode(token).tli)

        commit('SET_TOKEN', token);
        commit('SET_USER', user);

        //Load configs
        await dispatch("App/loadConfigs", { getChildren: false, filters: [] }, { root: true });
        await dispatch('App/loadWSConfig', null, { root: true });

        //Load targetLayers and targets then set the current targetlayer and target
        await dispatch("fetchTargetLayers");
        await dispatch("fetchTargets");
        commit('SET_CURRENT_TARGET_LAYER', tli);
        commit('SET_CURRENT_TARGET', tli);

        //Set User language
        dispatch("App/setUserLanguage", mapUserLanguage(user.language), { root: true });

        prompts.toast({
          icon: 'success',
          title: i18n.t('Login.Success')
        })

        router.push({ name: 'dashboard' });
      }
      else {
        if (response.status == 400) {
          switch (response.message) {
            case 'LOCKED':
              prompts.error({
                title: i18n.t('Error.Plugins.BasicLogin.Login'),
                text: i18n.t('Error.Plugins.BasicLogin.LockedMsg'),
              });
              break;
            case 'NOACCESS':
              prompts.error({
                title: i18n.t('Error.Plugins.BasicLogin.Login'),
                text: i18n.t('Error.Plugins.BasicLogin.NoAccessMsg'),
              });
              break;
            default:
              prompts.error({
                title: i18n.t('Error.Plugins.BasicLogin.Login'),
                text: i18n.t('Error.Plugins.BasicLogin.LoginMsg'),
              });
              break;
          }
        }
        else {
          Errors.displayApiError(response);
        }     
      }
    },
    async changePassword({ commit }, newPassword) {
      const response = await forgotPasswordServices.changePassword(newPassword);
      if (response.status == 200) {
        prompts.toast({
          icon: 'success',
          title: i18n.t('Register.SuccessChange')
        })
        router.push({ name: 'Login' });
      }
      else {
        Errors.displayApiError(response);
      }
    },
    async forgotPassword({ commit }, email) {
      const response = await forgotPasswordServices.forgotPassword(email);

      if (response.status == 200) {
        prompts.toast({
          icon: 'success',
          title: i18n.t('Register.SuccessEmailSend')
        })
        router.push({ name: 'Login' });
      }
      else {
        const msg = response.message || response.status;
        throw new Error(msg);
      }
    },
    async fetchTargetLayers({ commit }) {
      const response = await targetLayerServices.getTargetLayers();
      if (response.status == 200) {
        commit('SET_TARGETLAYERS', response.data);
      }
      else {
        Errors.displayApiError(response);
      }
    },
    async fetchTargets({ commit }) {
      const response = await targetServices.getTargets(false, []);
      if (response.status == 200) {
        commit('SET_TARGETS', response.data);
      }
      else {
        Errors.displayApiError(response);
      }
    },
    async fetchNewTargetLayerToken({ dispatch, commit }, targetLayer) {
      const response = await targetLayerServices.getNewTargetLayerToken(targetLayer._TargetLayerID);
      if (response.status == 200) {
        commit('SET_TOKEN', response.data.token);
        
        await dispatch('App/loadConfigs', { getChildren: false, filters: [] }, { root: true });
        await dispatch("fetchTargetLayers");
        await dispatch("fetchTargets");
        commit('SET_CURRENT_TARGET_LAYER', targetLayer._TargetLayerID);
        commit('SET_CURRENT_TARGET', targetLayer._TargetLayerID);
      }
      else {
        throw response;
      }
    },
    async fetchReportFavorite({ commit }, reportType) {

      const response = await reportClient.getReportFavorite(reportType);

      if (response.status < 300) {
        commit('SET_FAVORITE_REPORT', response.data);
        return response.data;
      }
      else {
        commit('SET_FAVORITE_REPORT', null);
        throw response
      }
    },
    async manageReportFavorite({ commit }, favoriteReport) {
      const response = await reportClient.manageReportFavorite(favoriteReport);

      if (response.status == 200) {
        commit('SET_FAVORITE_REPORT', favoriteReport);
        return favoriteReport;
      }
      else {
        commit('SET_FAVORITE_REPORT', null);
        throw response;
      }
    },
    async deleteReportFavorite({ commit }, favoriteReport) {
      const response = await reportClient.deleteReportFavorite(favoriteReport);
      if (response.status == 200) {
        commit('SET_FAVORITE_REPORT', null);
      }
      else {
        throw response;
      }
    }
  }
}