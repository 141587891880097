import Vue from "vue";
import Router from 'vue-router';
import backofficeRouter from '@/backoffice/router';
import vuex from '@/core/store';
import qs from 'qs';
import login from '@/core/pages/account/login/login.vue'
import forgotPassword from '@/core/pages/account/forgot-password/forgot-password.vue'
import changePassword from '@/core/pages/account/change-password/change-password.vue'

Vue.use(Router);

const defaultHomePageName = 'dashboard';

export const Routes = [
  {
    path: '/',
    name: 'Login',
    component: login
  },
  backofficeRouter,
  {
    path: '/pwa',
    name: 'pwa',
    component: login
  },
  {
    path: '/forgotpwd',
    name: 'forgotpassword',
    component: forgotPassword
  },
  {
    path: '/chgpwd/:token',
    name: 'changePassword',
    component: changePassword,
    props:true
  }
];


const createRouter = () => new Router({
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  base: process.env.BASE_URL,
  routes: Routes,
  parseQuery(query) {
    return qs.parse(query);
  },
  stringifyQuery(query) {
    const result = qs.stringify(query);
    return result ? `?${result}` : '';
  }
});

const router = createRouter();

router.beforeResolve(async (to, from, next) => {
  vuex.commit('Breadcrumb/RESET_STORE')
  next()
})

router.beforeEach(async (to, from, next) => {
  let nextRouteName = '';
  
  const token = vuex.getters['Account/getDecodedToken'];
  const tokenIsExpired = vuex.getters['Account/tokenIsExpired'];

  const destinationIsLoginOrChangePassword = to.name === 'Login' || to.name === 'changePassword';
  const tokenIsNotSet = !token || token == "";
  const tokenRequired = to.matched.some(record => record.meta.requiresAuth);

  if (!destinationIsLoginOrChangePassword && (tokenIsExpired || (tokenIsNotSet && tokenRequired))) {
      nextRouteName = 'Login';
  }

  if ( nextRouteName!=='Login' && to.meta) {
    if (!nextRouteName && to.meta.permissions) {
      const hasPermission = vuex.getters['Account/hasPermission'];
      if (!hasPermission(to.meta.permissions))
        nextRouteName = defaultHomePageName;
    }

    if (to.meta.modelName) {
      const model = await vuex.dispatch('DataModels/getModel', to.meta.modelName);
      if (!nextRouteName) {
        const hasModelPermission = vuex.getters['Account/hasModelPermission'];
        if (!hasModelPermission(model))
          nextRouteName = defaultHomePageName;
      }
    }
  }

  if (nextRouteName)
    next({ name: nextRouteName });
  else
    next();
});

export default router;