<template src="./tile.html"></template>
<style lang="scss" scoped src="./tile.scss"></style>

<script>
import { ValidationProvider } from "vee-validate";
import cloneDeep from 'lodash/cloneDeep';
import { mapGetters } from 'vuex'
import iconPicker from '@/core/components/common/icon-picker/icon-picker.vue'
import columnAggregatePicker from '@/core/components/common/column-aggregate-picker/column-aggregate-picker.vue'
import modelColumnPicker from '@/core/components/common/model-column-picker/model-column-picker.vue'

const defaultInformationConfig = { groupBy: null, column: null, aggregate: null };

export default {
  name: "tile",
  components:{
    iconPicker,
    columnAggregatePicker,
    modelColumnPicker,
    ValidationProvider
  },
  model: {
    prop: 'config'
  },
  props: {
    config: Object,
    model: Object,
  },
  data(){
    return {
      groupByOptions: [],
      errorPrimaryMsg: this.$t('Widgets.Tile.Messages.PrimaryConfigNeeded')
    }
  },
  watch: {
      'model.name'(newVal, oldVal){
        if(newVal != oldVal){
          this.initializeWidget();
        }
      }
  },
  computed: {
    ...mapGetters('DataModels', ['getModelColumnPickerOptions']),

    validatePrimary: function(){
      return this.config.primaryConfig && this.config.primaryConfig.column && !!this.config.primaryConfig.aggregate;
    }
  },  
  created(){
    this.initializeWidget();
  },
  methods: {
    initializeWidget() {

      this.groupByOptions = this.getModelColumnPickerOptions(this.model.name);

      if(!this.config.primaryConfig || !this.config.secondaryConfig){
        this.$emit("input", 
        {
          ...this.config, 
          groupBy: this.config.groupBy,
          primaryConfig: this.config.primaryConfig || cloneDeep(defaultInformationConfig),
          secondaryConfig: this.config.secondaryConfig || cloneDeep(defaultInformationConfig)
        });
      }
    },
    primaryChanged(primaryConfig) {
      this.$emit("input", {...this.config, primaryConfig: primaryConfig});
    },
    secondaryChanged(secondaryConfig) {
      this.$emit("input", {...this.config, secondaryConfig: secondaryConfig});
    },
    groupByChanged(column) {
      this.$emit("input", {...this.config, groupBy: column.name});
    }
  }
}
</script>