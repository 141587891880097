import Swal from 'sweetalert2';
import i18n from "@/i18n";

export default {
  toast: (options) => Swal.mixin({
    toast: true,
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  }).fire(options),
  
  info: (options) => Swal.mixin({
    heightAuto: false,
    icon: 'info'
  }).fire(options),

  error: (options) => Swal.mixin({
    heightAuto: false,
    icon: 'error',
    title: i18n.t('General.Error'),
  }).fire(options),

  warning: (options) => Swal.mixin({
    heightAuto: false,
    icon: 'warning',
    title: i18n.t('General.Warning'),
    showCancelButton: true,
    cancelButtonText: i18n.t('Button.Cancel').toString()
  }).fire(options)
}