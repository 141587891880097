<template src="./scope-dropdown.html"></template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import multiselect from "vue-multiselect";
import { mapActions, mapMutations } from 'vuex';

export default {
  name: "scopeDropdown",
  props: {
    classes: [String, Object],
    id: Number,
    model: String,
    dataSource: String,
    disabled: Boolean,
    autoRefresh: Boolean
  },
  components: {
    multiselect,
  },
  data() {
    return {
      list: [],
      multiSelect:{
        value: null,
        options: [],
        trackBy: 'id',
        label: 'description',
        tag: false,
        showLabels: false,
        placeholder: this.$t('Target.TargetDescriptionPh'),
        allowEmpty: false,
        onSelect: this.onScopeChanged,
        onRemove: this.onRemove,
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    ...mapActions('Scope', ['getCreationScope']),
    ...mapMutations('Scope', {
      setSelectedScope: 'SET_SELECTED_SCOPE'
    }),
    async getList() {
      this.list = [];

      const request = {
        model: this.model,
        dataSource: this.dataSource
      };

      this.list = await this.getCreationScope(request);

      this.setList();
    },

    setList(){
      this.list.forEach(target => {
        if(target.scopeID === this.id || target.selected || target.canCreate){
          this.multiSelect.options.push({
            id: target.scopeID,
            tlid: target.scopeTargetLayerID,
            description: target.description
          });
        }
        if(target.scopeID === this.id || (!this.id && target.selected)){
          this.multiSelect.value = {
            id: target.scopeID,
            tlid: target.scopeTargetLayerID,
            description: target.description
          }
        }
      });
      
      this.onScopeChanged(this.multiSelect.value)
    },
    onScopeChanged(item) {
      this.setSelectedScope(item);
      this.$emit("scope-changed", item)
    }
  }
};
</script>
