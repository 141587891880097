<template src="./iframe-ws.html"></template>

<style lang="scss">
@import './iframe-ws.scss';
</style>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import moment from 'moment';

export default {
  name: 'iframeWS',
  props: {
    routeName: String
  },
  data() {
    return {
      completeURL: null,
      iframeKey: 0
    }
  },

  computed: {
    ...mapGetters('Account', ['getToken', 'getCurrentTargetLayerId']),
    ...mapGetters('App', ['getWSConfig'])
  },

  mounted(){
    this.updateIframe();
    this.SET_IS_IFRAME(true);
    this.setOnMessageCallback();
  },

  beforeDestroy(){
    this.SET_IS_IFRAME(false);
    window.onmessage = null;
  },

  watch: {
    $route() {
      this.updateIframe();
    },
    getCurrentTargetLayerId: {
      handler(newVal, oldVal) {
        if (newVal != oldVal)
          this.updateIframe();
      }
    }
  },

  methods: {
    ...mapActions('App', [ 'setLastUserAction' ]),
    ...mapMutations('App', ['SET_IS_IFRAME']),
    updateIframe(){
      const url = `${this.getWSConfig.baseUrl.replace('/api', '')}#/SinRedirect`;
      const token = `?token=${this.getToken}`;
      const v2url = `&v2url=${encodeURIComponent(this.getWSConfig.v2BaseUrl)}`;
      const routeURL = `&route=${this.routeName}`;
      const targetlayerID = `&tl=${this.getCurrentTargetLayerId}`;
      const lang = `&lang=${this.$i18n.locale}`;
      this.completeURL = `${url}${token}${v2url}${routeURL}${targetlayerID}${lang}`;
      this.iframeKey++;
    },
    setOnMessageCallback() {
      window.onmessage = (event) => {
        if (this.getWSConfig.baseUrl.replace('/api', '').startsWith(event.origin) && event.data === 'UserActionEvent') {
          this.setLastUserAction(moment())
        }
      }
    }
  }
}
</script>
