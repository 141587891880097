import inventory from '@/backoffice/modules/inventory/inventory.vue'
import main from '@/backoffice/modules/inventory/pages/main/main.vue'
import document from '@/backoffice/modules/inventory/pages/document/document.vue'

export const documentNumberBreadcrumbTailFunc = (ctx) => ctx.document && ctx.document.documentNO;

const inventoryRouter = {
  path: '/inventory',
  name: 'inventory',
  component: inventory ,
  meta: {
    breadcrumb: {
      linkDescription: 'Breadcrumbs.Inventory.Inventory',
      routeNameTo: null,
      explicitTail: null
    }
  },
  children:[
    {
      path: 'documents',
      name: 'documents',
      component: main,
      meta: {
        requiresAuth: true,
        modelName: 'Document',
        associatedParentRouteName: null,
        sideNav: {
          image: 'fal fa-scanner-gun',
          label: 'SideNav.Inventory',
          orderIndex: 6
        },
        permissions: ['INVDOC1', 'INVDOC2', 'INVDOC3'],
        breadcrumb: {
          linkDescription: 'Breadcrumbs.Inventory.Documents',
          routeNameTo: null,
          explicitTail: null
        }
      }
    },
    {
      path: 'documents/:id',
      name: 'document',
      component: document,
      props: true,
      meta: {
        requiresAuth: true,
        modelName: 'Document',
        associatedParentRouteName: 'documents',
        breadcrumb: {
          linkDescription: 'Breadcrumbs.Inventory.Documents',
          routeNameTo: 'documents',
          explicitTail: {
            func: documentNumberBreadcrumbTailFunc
          }
        }
      }
    },
  ]
}

export default inventoryRouter;
