<template src="./dynamic-dashboard.html"></template>

<style scoped lang="scss">
@import './dynamic-dashboard.scss';
</style>

<script>
import VueGridLayout from 'vue-grid-layout';
import { mapGetters, mapMutations } from 'vuex';

import chartWidget from '@/backoffice/modules/dashboard/components/dynamic-dashboard/widgets/chart-widget/chart-widget.vue'
import simpleWidget from '@/backoffice/modules/dashboard/components/dynamic-dashboard/widgets/simple-widget/simple-widget.vue'
import topWidget from '@/backoffice/modules/dashboard/components/dynamic-dashboard/widgets/top-widget/top-widget.vue'

export default {
  name: 'dynamic-dashboard',
  components:{
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    chartWidget,
    simpleWidget,
    topWidget
  },

  data() {
    return {
      dimensions: {
        0: {
          default: {
            x: 0,
            y: 0,
            w: 12,
            h: 12
          },
          mobile: {
            x: 0,
            y: 0,
            w: 12,
            h: 12
          }
        },
        1: {
          default: {
            x: 0,
            y: 12,
            w: 3,
            h: 4
          },
          mobile: {
            x: 0,
            y: 12,
            w: 6,
            h: 4
          }
        },
        2: {
          default: {
            x: 3,
            y: 12,
            w: 3,
            h: 4
          },
          mobile: {
            x: 6,
            y: 12,
            w: 6,
            h: 4
          }
        },
        3: {
          default: {
            x: 0,
            y: 16,
            w: 3,
            h: 4
          },
          mobile: {
            x: 0,
            y: 16,
            w: 6,
            h: 4
          }
        },
        4: {
          default: {
            x: 3,
            y: 16,
            w: 3,
            h: 4
          },
          mobile: {
            x: 6,
            y: 16,
            w: 6,
            h: 4
          }
        },
        5: {
          default: {
            x: 0,
            y: 20,
            w: 3,
            h: 4
          },
          mobile: {
            x: 0,
            y: 20,
            w: 6,
            h: 4
          }
        },
        6: {
          default: {
            x: 3,
            y: 20,
            w: 3,
            h: 4
          },
          mobile: {
            x: 6,
            y: 20,
            w: 6,
            h: 4
          }
        },
        7: {
          default: {
            x: 6,
            y: 12,
            w: 6,
            h: 12
          },
          mobile: {
            x: 0,
            y: 24,
            w: 12,
            h: 12
          }
        }
      }
    }
  },

  computed: {
    ...mapGetters('App', ['isMobile']),
    ...mapGetters('Dashboard', ['getSelectedDashboardWidgets']),
    ...mapGetters('Dashboard', ['getSelectedDashboard']),
    ...mapGetters('Account', ['hasPermission']),

    widgets: {
        get(){
         return this.getSelectedDashboardWidgets || [];
        },
        set(widgets){
          this.setDashboardWidgets(widgets);
        } 
    },
     editable(){
      return this.hasPermission(['DASHBOARD_CUD'])
    }
  },
  methods: {
    ...mapMutations('Dashboard', {
        setDashboardWidgets: 'SET_SELECTED_DASHBOARD_WIDGETS',
    })
  },
}
</script>
