import Api from '@/core/services/_api.config';

async function fetchDashboards(getChildren, filters){
    const params = `?getChildren=${getChildren}&filters=${encodeURIComponent(JSON.stringify(filters))}`;
    return await Api()
                .get(`/api/Dashboard${params}`)
                .then(response => {
                    return ({status: response.status, data: response.data});
                })
                .catch((e) => {
                    if (e.response) {
                        return { status: e.response.status, message: e.response.data, data: null };
                    }
                    else {
                        return { message: e.message, data: null };
                    }
                });
}

async function fetchModelTypes(){
    return await Api()
        .get(`/api/Dashboard/GetModelTypes`)
        .then(response => {
            return ({ status: response.status, data: response.data });
        })
        .catch((e) => {
            if (e.response) {
                return { status: e.response.status, message: e.response.data, data: null };
            }
            else {
                return { message: e.message, data: null };
            }
        });
}

async function addDashboard(dashboard){
    return await Api()
        .post('/api/Dashboard/', [dashboard])
        .then(response => {
            return ({ status: response.status, data: response.data });
        })
        .catch((e) => {
            if (e.response) {
                return { status: e.response.status, message: e.response.data, data: null };
            }
            else {
                return { message: e.message, data: null };
            }
        });
}

async function updateDashboard(dashboard){
    return await Api()
        .put('/api/Dashboard/', [dashboard])
        .then(response => {
            return ({ status: response.status, data: response.data });
        })
        .catch((e) => {
            if (e.response) {
                return { status: e.response.status, message: e.response.data, data: null };
            }
            else {
                return { message: e.message, data: null };
            }
        });
}

async function deleteDashboard(dashboardID){
    return await Api()
        .delete('/api/Dashboard/', { data: [dashboardID] })
        .then(response => {
            return ({ status: response.status, data: response.data });
        })
        .catch((e) => {
            if (e.response) {
                return { status: e.response.status, message: e.response.data, data: null };
            }
            else {
                return { message: e.message, data: null };
            }
        });
}

async function fetchDashboardUserCustomization(getChildren, filters){
    const params = `?getChildren=${getChildren}&filters=${encodeURIComponent(JSON.stringify(filters))}`;

    return await Api()
        .get(`/api/DashboardUserCustomization${params}`)
        .then(response => {
            return ({ status: response.status, data: response.data });
        })
        .catch((e) => {
            if (e.response) {
                return { status: e.response.status, message: e.response.data, data: null };
            }
            else {
                return { message: e.message, data: null };
            }
        });
}


async function addDashboardUserCustomization(dashboardUserCustomization){
    return await Api()
        .post('/api/DashboardUserCustomization/', [dashboardUserCustomization])
        .then(response => {
            return ({ status: response.status, data: response.data });
        })
        .catch((e) => {
            if (e.response) {
                return { status: e.response.status, message: e.response.data, data: null };
            }
            else {
                return { message: e.message, data: null };
            }
        });
}

async function updateDashboardUserCustomization(dashboardUserCustomization){
    return await Api()
        .put('/api/DashboardUserCustomization/', [dashboardUserCustomization])
        .then(response => {
            return ({ status: response.status, data: response.data });
        })
        .catch((e) => {
            if (e.response) {
                return { status: e.response.status, message: e.response.data, data: null };
            }
            else {
                return { message: e.message, data: null };
            }
        });
}

export const dashboardServices = {
    fetchDashboards,
    fetchModelTypes,
    addDashboard,
    updateDashboard,
    deleteDashboard,
    fetchDashboardUserCustomization,
    addDashboardUserCustomization,
    updateDashboardUserCustomization
};