import purchase from '@/backoffice/modules/purchase/purchase.vue'
import orders from '@/backoffice/modules/purchase/pages/orders/main.vue'
import order from '@/backoffice/modules/purchase/pages/orders/order/order.vue'
import receivings from '@/backoffice/modules/purchase/pages/receivings/main.vue'
import receiving from '@/backoffice/modules/purchase/pages/receivings/receiving/receiving.vue'
import directReceivings from '@/backoffice/modules/purchase/pages/direct-receivings/main.vue'
import directReceiving from '@/backoffice/modules/purchase/pages/direct-receivings/direct-receiving/direct-receiving.vue'

export const orderNumberBreadcrumbTailFunc = (ctx) => {
  return ctx.order && ctx.order.orderNO;
}
export const receivingNumberBreadcrumbTailFunc = (ctx) => ctx.receiving && ctx.receiving.receivingNO;

const purchaseRouter = {
  path: '/purchase',
  name: 'purchase',
  component: purchase,
  meta: {
    sideNav: {
      image: 'fal fa-shopping-cart',
      label: 'SideNav.Purchase.Purchases',
      orderIndex: 5
    },
    permissions: ['ORDER_READ', 'ORDER_CUD', 'RECEIVING_READ', 'RECEIVING_CUD'],
    breadcrumb: {
      linkDescription: 'Breadcrumbs.Purchase.Purchases',
      routeNameTo: null,
      explicitTail: null
    }
  },
  children:[
    {
      path: 'orders',
      name: 'orders',
      component: orders,
      meta: {
        requiresAuth: true,
        associatedParentRouteName: null,
        sideNav: {
            image: null,
            label: 'SideNav.Purchase.Orders'
        },
        permissions: ['ORDER_READ', 'ORDER_CUD'],
        breadcrumb: {
          linkDescription: 'Breadcrumbs.Purchase.Orders',
          routeNameTo: null,
          explicitTail: null
        }
      }
    },
    {
      path: 'orders/:id',
      name: 'order',
      component: order,
      props: true,
      meta: {
        requiresAuth: true,
        associatedParentRouteName: 'orders',
        breadcrumb: {
          linkDescription: 'Breadcrumbs.Purchase.Orders',
          routeNameTo: 'orders',
          explicitTail: {
            func: orderNumberBreadcrumbTailFunc
          }
        }
      }
    },
    {
      path: 'receivings',
      name: 'receivings',
      component: receivings,
      meta: {
        requiresAuth: true,
        associatedParentRouteName: null,
        sideNav: {
          image: null,
          label: 'SideNav.Purchase.Receivings'
        },
        permissions: ['RECEIVING_READ', 'RECEIVING_CUD'],
        breadcrumb: {
          linkDescription: 'Breadcrumbs.Purchase.Receivings',
          routeNameTo: null,
          explicitTail: null
        }
      }
    },
    {
      path: 'receivings/:id',
      name: 'receiving',
      component: receiving,
      props: true,
      meta: {
        requiresAuth: true,
        associatedParentRouteName: 'receivings',
        breadcrumb: {
          linkDescription: 'Breadcrumbs.Purchase.Receivings',
          routeNameTo: 'receivings',
          explicitTail: {
            func: receivingNumberBreadcrumbTailFunc
          }
        }
      }
    },
    {
      path: 'directReceivings',
      name: 'directReceivings',
      component: directReceivings,
      meta: {
        requiresAuth: true,
        associatedParentRouteName: null,
        sideNav: {
          image: null,
          label: 'SideNav.Purchase.DirectReceivings'
        },
        permissions: ['RECEIVING_READ', 'RECEIVING_CUD'],
        breadcrumb: {
          linkDescription: 'Breadcrumbs.Purchase.DirectReceivings',
          routeNameTo: null,
          explicitTail: null
        }
      }
    },
    {
      path: 'directReceivings/:id',
      name: 'directReceiving',
      component: directReceiving,
      props: true,
      meta: {
        requiresAuth: true,
        associatedParentRouteName: 'directReceivings',
        breadcrumb: {
          linkDescription: 'Breadcrumbs.Purchase.DirectReceivings',
          routeNameTo: 'directReceivings',
          explicitTail: {
            func: receivingNumberBreadcrumbTailFunc
          }
        }
      }
    },
  ]
}

export default purchaseRouter;
