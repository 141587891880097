<template src="./integer-input-renderer.html"></template>

<style scoped lang="scss">
@import './integer-input-renderer.scss';
</style>

<script>
export default{
  name: 'integerInputRenderer',
  computed: {
    disabled() {
      return this.params.canUserInteractWithUI && !this.params.canUserInteractWithUI();
    },
    secondInfo() {
      if (!this.params.getSecondInfo) {
        return;
      }

      return this.params.getSecondInfo(this.params.data);
    },
    hasSecondInfo() {
      return this.secondInfo != undefined
    },
    placeholder() {
        return this.params.placeholder ?? 'BackendFilter.Placeholder.SetValue'
    },
    minValue() {
      return (this.params.minValue && Number.parseInt(this.params.minValue)) || 0
    },
    maxValue() {
      return (this.params.maxValue && Number.parseInt(this.params.maxValue)) || Number.MAX_SAFE_INTEGER
    },
    maxLength() {
      return (this.params.maxLength && Number.parseInt(this.params.maxLength)) || 16
    }
  },
  methods: {
    change(e){
      const newValue = e.target.value && Number.parseInt(e.target.value) || 0;
      this.params.node.setDataValue(this.params.colDef, newValue)
    },
    validateInput(e) {
      let newValue = e.target.value && Number.parseInt(e.target.value) || 0;
      if(e.target.value.length > this.maxLength){
        const trimedValue = e.target.value.substring(0, this.maxLength)
        newValue = Number.parseInt(trimedValue) > this.maxValue ? this.maxValue : Number.parseInt(trimedValue)
      }
      e.target.value = newValue
    }
  }
};
</script>
