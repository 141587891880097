export class Role {
    constructor() {
        this.roleId = 0
        this.targetId = 0
        this.targetLayerId = 0
        this.targetDescription = null
        this.description = null
        this.permissions = []
        this.canUpdate = false
        this.canDelete = false
    }
}

export class RoleDDto {
    constructor() {
        this.roleId = 0
        this.targetId = 0
        this.targetLayerId = 0
    }
}

export class Permission {
    constructor() {
        this.permissionId = 0
        this.code = null
        this.description = null
        this.isPos = false
        this.selected = false
        this.remove = false
        this.ignore = true
        this.roleElementAccessId = 0
        this.hierarchy = []
    }
}

export const convertApiRoleToRole = (apiRole) => {
	const role = new Role()
    
    role.roleId = apiRole.roleId
    role.targetId = apiRole.targetId
    role.targetLayerId = apiRole.targetLayerId
    role.targetDescription = apiRole.targetDescription
    role.description = apiRole.description
    role.descriptions = apiRole.descriptions?.map(description => ({
        _DescriptionID: description._DescriptionID,
        id: apiRole.roleId,
        shortDescription: description.shortDescription,
        longDescription: description.longDescription,
        language: description.language
    }))
    role.canUpdate = apiRole.canUpdate,
    role.canDelete = apiRole.canDelete

    return role
}

export const convertRoleToRoleDDto = (role) => {
    const roleDDto = new RoleDDto()
    roleDDto.roleId = role.roleId
    roleDDto.targetId = role.targetId,
    roleDDto.targetLayerId = role.targetLayerId

    return roleDDto
}

export const convertApiRolePermissionsToRolePermissions = (apiPermission) => {
    if(apiPermission){
        const permission = new Permission()
        permission.permissionId = apiPermission.permissionId
        permission.code = apiPermission.code
        permission.description = apiPermission.description
        permission.isPos = apiPermission.isPos
        permission.selected = apiPermission.selected
        permission.remove = apiPermission.remove
        permission.ignore = apiPermission.ignore
        if(apiPermission.roleElementAccessId){
            permission.roleElementAccessId = apiPermission.roleElementAccessId
        }
        permission.hierarchy = apiPermission.hierarchy
        return permission
    }
	return null
}