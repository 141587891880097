<template src="./language-input.html"></template>
<style lang="scss" scoped src="./language-input.scss"></style>

<script>
import { descriptionLanguages } from '@/core/domain/language/language.const.js';

export default {
  name: 'language-input',
  props: {
    value: String,
    disabled: Boolean
  },

  data() {
    return {
      current: null,
      show: false,
      languages: [{ label: 'Language.French', value: descriptionLanguages.FR },
                  { label: 'Language.English',value: descriptionLanguages.EN }],
      showButton: false
    }
  },

  computed: {
    language: {
      get() {
        return this.current && this.$t(this.current.label);
      },
      set(lang) {
        this.$emit('input', lang.value);
        this.show = false;
        this.current = lang;
      }
    }
  },

  mounted(){
    if (this.value) {
      this.language = this.languages.find(l => this.compareCI(l.value, this.value));
    }
  },

  methods: {
    open(){
      if(!this.disabled){
        this.show = true;
      }
    },

    hide(){
      this.show = false;
    }
  }
}
</script>