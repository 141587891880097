<template src="./backend-filters.html"></template>
<style lang="scss" scoped src="./backend-filters.scss"></style>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { mapGetters } from 'vuex'
import modelColumnPicker from '@/core/components/common/model-column-picker/model-column-picker.vue'
import filterInput from '@/core/components/common/backend-filters/filter-input/filter-input.vue'

export default {
  name: 'backendFilters',
  model: {
    prop: 'filters'
  },
  props:{
    filters: {
      type: Array,
      default: function () { 
        return [];
      }
    },
    model: {
      type: Object,
      default: null
    },
  },
  components:{
    modelColumnPicker,
    filterInput
  },
  data() {
    return {
      localFilters: cloneDeep(this.filters),
      options: [],
      filterCounter: 0
    }
  },

  computed:{
    ...mapGetters('DataModels', ['getModelColumnPickerOptions'])
  },

  watch:{
    'model.name': {
      handler(newVal, oldVal) {
        if(newVal != oldVal) {
          this.localFilters = cloneDeep(this.filters);
          this.setColumnsFromModels();
          this.setFilters();
          this.addFilter();
        }
      }
    }
  },
  mounted() {
    this.setColumnsFromModels();
    this.setFilters();
    this.addFilter();
  },
  methods:{      
    setColumnsFromModels(){
      if(this.model){
        this.options = this.getModelColumnPickerOptions(this.model.name);
      }
    },
    setFilters(){
      for (let i = 0; i < this.localFilters.length; i++) {
        let property = null;
        const model = this.options.find(op => op.columns.find(col => col.name == this.localFilters[i].column));
        if (model){
          property = model.columns.find(col => col.name == this.localFilters[i].column);
        }

        this.localFilters[i].type = property ? property.type : null;
        this.localFilters[i].id = this.filterCounter++;
      }
    },


    updateSelectedColumn(filter, index, selectedColumn){
      filter.column = selectedColumn.name
      filter.type = selectedColumn.type;

      if (index == (this.localFilters.length - 1))
        this.addFilter();

      this.updateGenericListFilter();
    },

    addFilter(){
      this.localFilters.push({ column: null, operator: '=', value: [], type: null, id: this.filterCounter++ });
    },

    removeFilter(index){
      this.localFilters.splice(index, 1);
      this.updateGenericListFilter();
    },
    
    updateGenericListFilter(){
      const filters = [];
      this.localFilters.forEach(filter => {
        if (filter && filter.column)
          filters.push({
            column: filter.column,
            operator: filter.operator,
            value: filter.value
          }); 
      });

      this.$emit("input", filters);
    }
  }
}
</script>