<template src="./context-menu.html"></template>

<style scoped lang="scss">
@import './context-menu.scss';
</style>

<script>
export default {
  name: "context-menu",
  
  props: {
    componentID: null,
    buttonList: null,
    menuIcon: null
  },

  computed: {
    dotsID() {
      return `${this.componentID}-dots-id`;
    },
    dotsRef() {
      return `${this.componentID}-dots`;
    },
    contentID() {
      return `${this.componentID}-id`;
    },
    contentRef() {
      return this.componentID;
    }
  },

  data: function() {
    return {
      contextMenuClass: [],
      deleteIcon: ['fal', 'fa-times'],
      editIcon: ['fal', 'fa-pencil-alt'],
      show: false
    };
  },

  methods: {
    setOpenPosition() {
      setTimeout(() => {
        this.contextMenuClass = [];
        const content = this.$refs[this.contentRef];
        const dots = this.$refs[this.dotsRef];

        const rect = dots.getBoundingClientRect();
        const top = rect.top;
        const left = rect.left;
        const windowHeight = window.innerHeight;
        const windowWidth = window.innerWidth;
        const contentHeight = content.offsetHeight;
        const contentWidth = content.offsetWidth;

        if ((top + contentHeight) > windowHeight && top > ((windowHeight / 3) * 2)) {
          this.contextMenuClass.push('open-top');
        }

        if ((left + contentWidth) > windowWidth) {
          this.contextMenuClass.push('open-right');
        }
      });
    },

    execButton(callback) {
      this.show = !this.show;
      callback();
    },

    open() {
      this.setOpenPosition();
      this.show = !this.show;
    },

    close() {
      this.show = false;
    }
  }
};
</script>

