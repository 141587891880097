import Api from '../_api.config';

async function getTargetLayers(){

  return await Api()
              .get('/api/TargetLayer/Location')
              .then(response => {
                return {status: response.status, data: response.data };
              })
              .catch((e) => {
                if (e.response) {
                  return { status: e.response.status, message: e.response.data, data: null };
                }
                else {
                  return { message: e.message, data: null };
                }
              });
}

async function getNewTargetLayerToken(_TargetLayerID){

  return await Api()
              .get(`/api/SecurityFacade/ChangeTargetLayer/?targetLayerId=${_TargetLayerID}`)
              .then(response => {
                return {status: response.status, data: response.data };
              })
              .catch((e) => {
                if (e.response) {
                  return { status: e.response.status, message: e.response.data, data: null };
                }
                else {
                  return { message: e.message, data: null };
                }
              });
}

export const targetLayerServices = {
  getTargetLayers,
  getNewTargetLayerToken
};

