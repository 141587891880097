<template src="./options-renderer.html"></template>

<style scoped lang="scss">
@import './options-renderer.scss';
</style>

<script>
export default{
  name: 'optionsRenderer',
  data: function() {
    return {
      openTop: false,
      show: false,
      canUpdate: false,
      canDelete: false      
    };
  },
  mounted(){
    this.setOpenPosition();

    if(this.params.node && this.params.node.data){
      this.canUpdate = this.params.node.data.canUpdate;
      this.canDelete = this.params.node.data.canDelete;
    }
  },
  methods: {
    change(){
      this.params.toggle(this.params.data);
    },
    setOpenPosition(){
      const index = this.params.api.getLastDisplayedRow();
      if (this.params.node.rowIndex > 0 && this.params.node.rowIndex == index && index > 1){
        this.openTop = true;
      }
      else{
        this.openTop = false;
      }
    },
    open() {
      this.setOpenPosition();
      this.show = !this.show;
    },
    close(){
      this.show = false;
    },
    invokeParentEditMethod() {
      this.params.editEntity(this.params.node);
    },
    invokeParentDelete() {
      this.params.delete();
    },
  }
};
</script>
