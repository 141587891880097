<template src="./spin-loader.html"></template>

<script>
import { Spinner } from 'spin.js';

export default {
    name: 'spin-loader',
    props: {
        scale: {
          type: Number,
          default: 1.0
        }
    },
    data() {
        return {
            // see additionals options @ https://github.com/fgnass/spin.js/
            options: {
                scale: this.scale,
                lines: 8,
                length: 5,
                width: 3,
                radius: 5,
                corners: 0.6,
                animation: 'spinner-line-fade-more',
                color: '#818993'
            }
        }
    },

    mounted() {
        new Spinner(this.options).spin(this.$refs['spinner']);
    }
}
</script>
