import { render, staticRenderFns } from "./labels.html?vue&type=template&id=659c8c6d&"
import script from "./labels.vue?vue&type=script&lang=js&"
export * from "./labels.vue?vue&type=script&lang=js&"
import style0 from "./labels.vue?vue&type=style&index=0&id=659c8c6d&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports