<template inline-template v-cloak src="./change-password.html"></template>

<style scoped lang="scss">
@import '@/core/pages/account/account.scss';
</style>

<script>
  import { mapActions } from 'vuex';
  import { TokenTools } from '@/core/tools/token/token-tools';
  import prompts from '@/core/tools/notifications/notifications';

  export default {
    name: 'change-password',
    props: {
      token: String
    },
    data() {
        return {
            confirmPassword: '',
            password: '',
            passwordLength: 0,
            typed: false,
            containsLowercase: false,
            containsNumber: false,
            containsUppercase: false,
            containsSpecial: false,
            validPasswordLength: false,
            validPassword: false,
            samePasswords: false,
        }
    },

    created() {
      this.$i18n.locale =  this.$route.query.lang.toString().toLowerCase();
      if (TokenTools.isTokenExpired(this.token)){
        this.$router.push({name: 'Login'});

        prompts.error({
          title: this.$t('Error.TokenError'),
          text: this.$t('Error.TokenExpired')
        });
      }
    },

    methods: {
      ...mapActions('Account', ['changePassword']),
        passwordSecurity() {
            this.containsLowercase = /[a-z]/.test(this.password);
            this.containsNumber = /\d/.test(this.password);
            this.containsUppercase = /[A-Z]/.test(this.password);
            this.containsSpecial = /[!@#$%^&*(),.?":{}|<>+-]/.test(this.password);

            this.validPasswordLength = this.password.length > 7;
            this.typed = this.password.length > 0;
            this.samePasswords = this.password == this.confirmPassword;

            this.validPassword = this.containsLowercase 
                                    && this.containsNumber 
                                    && this.containsUppercase 
                                    && this.validPasswordLength 
                                    && this.containsSpecial 
                                    && this.samePasswords;
        },

        checkForm(e) {
            e.preventDefault();
            const token = this.token;
            const password = this.password;
            this.changePassword({ token, password });
        }
    }
  }
</script>