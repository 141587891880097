<template src="./checkbox-renderer.html"></template>

<style scoped lang="scss">
@import './checkbox-renderer.scss';
</style>

<script>
import checkbox from '@/core/components/common/checkbox/checkbox.vue'

export default{
  name: 'checkboxRenderer',
  components: {
    checkbox
  },
  computed: {
    readOnly() { return this.params.readOnly && this.params.readOnly() }
  },
  methods: {
    updateCheckbox(params, checked){
      params.node.selectThisNode(checked);
      if(this.params.callback)
        this.params.callback(params.data)
    }
  }
};
</script>