import { configServices } from '@/core/services';
import Errors from '@/core/tools/errors/errors';
import { getTwoLettersLanguage } from '@/core/domain/language/language.js';

export const App = {
  namespaced: true,
  name: 'App',
  state: {
    isNewVersionServiceWorker: false,
    configs: [],
    wsConfig: null,
    modules: ['DataModels', 'Dashboard', 'Widgets', 'Inventory', 'Reporting', 'Order', 'Receiving', 'Promotion'],
    appLanguage: null,
    userLanguage: null,
    resize: false,
    sideNavStatus: false,
    isMobile: false,
    rememberMe:{
      rememberLogin: null,
      username: null
    },
    isIframe: false
  },
  getters: {

    isMobile: state => {
      return state.isMobile;
    },
    
    getRememberMe: state =>{
      return state.rememberMe;
    },

    getWSConfig: state => {
      return state.wsConfig;
    },

    getConfigs: state => {
      return state.configs;
    },    

    getModules: state => {
      return state.modules;
    },

    getSideNavStatus: state => {
      return state.sideNavStatus;
    },

    getIsNewVersionServiceWorker: state => {
      return state.isNewVersionServiceWorker;
    },

    getLanguage: state => {
      return state.appLanguage ?? state.userLanguage;
    },

    getTwoLettersLanguage: state => getTwoLettersLanguage(state.appLanguage ?? state.userLanguage),

    getResize: state => {
      return state.resize;
    },

    getLastUserAction: state => {
      return state.lastUserAction;
    },

    isIframe: state => {
      return state.isIframe
    }
  },
  mutations: {
    SET_CONFIGS(state, configs){
      state.configs = configs;
    },

    SET_CONFIG(state, config){
      if(config && !state.configs.some(c => c._ConfigID == config._ConfigID)){
        state.configs.push(config);
      }
    },

    SET_WSCONFIG(state, wsConfig){
      state.wsConfig = wsConfig;      
    },

    ADD_LOADED_MODULES(state, module){
      state.modules.push(module);
    },

    NEW_VERSION_SERVICE_WORKER(state, isNewVersion){
      state.isNewVersionServiceWorker = isNewVersion;
    },

    SET_APP_LANGUAGE(state, appLanguage){      
      state.appLanguage = appLanguage;
    },

    SET_USER_LANGUAGE(state, userLanguage){
      state.userLanguage = userLanguage;
    },

    SET_RESIZE(state, value){
      state.resize = value;
    },

    SET_SIDE_NAV_STATUS(state, status){
      state.sideNavStatus = status;
    },

    SET_REMEMBER_ME(state, rememberMe){
      state.rememberMe = rememberMe;
    },

    SET_IS_MOBILE(state, isMobile){
      state.isMobile = isMobile;
    },

    SET_LAST_USER_ACTION(state, actionTime){
      state.lastUserAction = actionTime;
    },

    SET_IS_IFRAME(state, isIframe){
      state.isIframe = isIframe;
    }
  },
  actions: {
    resetRegisteredModules({ state, commit }){
      state.modules.forEach(module => {
        if(`${module}` !== 'Session')
          commit(`${module}/RESET_STORE`, null, { root: true });
      });
    },

    isModuleLoaded({ state }, module){
      return state.modules.indexOf(module) != -1;
    },

    addLoadedModule({ state, commit }, module){
      if (state.modules.indexOf(module) == -1) {
        commit('ADD_LOADED_MODULES', module);
        return true;
      }
      else {
        return false;
      }
    },

    setNewVersionServiceWorker({commit}, isNewVersion){
      commit('NEW_VERSION_SERVICE_WORKER', isNewVersion);
      return isNewVersion;
    },

    setAppLanguage({commit}, appLanguage){
      commit('DataModels/RESET_STORE', null, { root: true });
      commit('SET_APP_LANGUAGE', appLanguage);
      window.location.reload();    
    },

    setUserLanguage({commit}, userLanguage){
      commit('SET_USER_LANGUAGE', userLanguage);
    },

    setResize({commit}, value){
      commit('SET_RESIZE', value);
    },

    setSideNavStatus({commit}, status){
      commit('SET_SIDE_NAV_STATUS', status);
    },

    setRememberMe({commit}, rememberMe){
      commit('SET_REMEMBER_ME', rememberMe);
    },

    setIsMobile({commit}, isMobile){
      commit('SET_IS_MOBILE', isMobile);
    },

    setLastUserAction({commit}, actionTime){
      commit('SET_LAST_USER_ACTION', actionTime);
    },

    async loadConfigs({ commit }, { getChildren, filters, _ReportDataModelID }){
      const response = await configServices.getConfigs(getChildren, filters, _ReportDataModelID);
      
      if(response.status == 200) {
        commit('SET_CONFIGS', response.data);
      }
      else {
        Errors.displayApiError(response);
      }
      return response.data;
    },

    async loadWSConfig({ commit }){
      const response = await configServices.getWSConfig();
      if(response.status == 200){
        commit('SET_WSCONFIG', response.data);
        return response.data;
      }
      else {
        Errors.displayApiError(response);
        return null;
      }
    }
  }
}
