import dashboardRouter from '@/backoffice/modules/dashboard/router';
import { reportingRouter, managementRouter } from '@/backoffice/modules/reporting/router/router.js';
import inventoryRouter from '@/backoffice/modules/inventory/router';
import promotionRouter from '@/backoffice/modules/promotion/router';
import purchaseRouter from '@/backoffice/modules/purchase/router';
import { legacyParameters, legacyOperation } from '@/backoffice/modules/ws-management/router';
import { releaseNoteRouter } from '@/backoffice/modules/release-note/router'
import backOffice from '@/backoffice/backoffice.vue'

const backofficeRouter = {   
  path: "/",
  name: 'backoffice',
  component: backOffice,
  children:
  [
    dashboardRouter,
    promotionRouter,
    reportingRouter,
    managementRouter,
    inventoryRouter,
    purchaseRouter,
    legacyParameters,
    legacyOperation,
    releaseNoteRouter
  ]
}

export default backofficeRouter;
