<template src="./product-search.html"></template>

<style lang="scss">
    @import './product-search.scss';
</style>

<script>
import { mapGetters, mapActions } from 'vuex';
import prompts from '@/core/tools/notifications/notifications';
import { SearchLimitOptionEnum } from '@/core/components/layout/side-panel/product-search/product-search.const.js';
import { ItemService } from '@/backoffice/modules/item/item.service.js'
import searchList from '@/core/components/common/search-list/search-list.vue'
import { createItemWithCostSearchCriteriaByScope } from '@/backoffice/modules/item/itemWithCostSearchCriteria'

export default {
    name: 'product-search',

    components: {
        searchList
    },

    props:{
        icon: String,
        vendorCode: String,
        isMultiSelect: {
            type: Boolean,
            default: false
        },
        btnAddToText: String,
        searchScope: {
            type: Boolean,
            default: false
        },
        mustIncludeItemCost: {
            type: Boolean,
            default: false
        },
        mustIncludeItemPrice: {
            type: Boolean,
            default: false
        },
        selected: {
            type: [Object, Array],
            default: null
        },
        itemCategory: {
            type: String,
            default: null
        }
    },

    watch: {
        selected: function(){
            if(this.lastSearchFilter !== null && this.lastScope !== null){
                this.searchProducts(this.lastSearchFilter, this.lastScope)
            }
        }
    },

    data() {
        return {
            productList: [],
            productSearchText: null,
            limitSearchOption: null,
            loadingProducts: false,
            lastSearchFilter: null,
            lastScope: null
        }
    },

    computed:{
        ...mapGetters('Account', ['getCurrentTargetLayerId', 'hasPermission']),

        categoryLabel() {
            return this.itemCategory ? this.$t(`Promotion.ItemCategory.${this.itemCategory}`) : ''
        }
    },

    methods: {
        ...mapActions('Product', ['fetchProducts']),

        async searchProducts(searchFilter, scope){
            this.lastSearchFilter = searchFilter
            this.lastScope = scope
            this.limitSearchOption = scope
            
            if (searchFilter.length == 0) {
                this.productList = [];
                return;
            }

            this.productSearchText = searchFilter[0];
            this.loadingProducts = true;

            try{
                //TODO: This 3 seperates calls could be managed by a factory instead of if/else
                if(this.mustIncludeItemCost){
                    const itemWithCostSearchCriteria = createItemWithCostSearchCriteriaByScope(scope, this.vendorCode, this.productSearchText)
                    this.productList = await ItemService.getItemsWithCosts(itemWithCostSearchCriteria);                 
                }
                else{
                    const params = { getChildren: false, search: this.createProductSearch() };
                    if(this.mustIncludeItemPrice)
                        this.productList = await ItemService.getItemsWithPrice(params)
                    else
                        this.productList = await this.fetchProducts(params);
                }
            }
            catch(error){
                prompts.error({
                    text: this.$t('Product.Messages.SearchProducts')
                })
            }
            
            this.loadingProducts = false;
        },

        createProductSearch(){
            let searchCondition = { '$or': [] };
            switch (this.limitSearchOption) {
                case SearchLimitOptionEnum.ITEM:
                    searchCondition['$or'].push({ 'Description': { '$lk': this.productSearchText } },{ 'ItemUPC': { '$lk': this.productSearchText } })
                    break;
                case SearchLimitOptionEnum.DEPARTMENT:
                    searchCondition['$or'].push({ 'DepartmentCode': { '$lk': this.productSearchText } }, { 'DepartmentDescription': { '$lk': this.productSearchText } })
                    break;
                case SearchLimitOptionEnum.SUBDEPARTMENT:
                    searchCondition['$or'].push({ 'SdpCode': { '$lk': this.productSearchText } }, { 'SubDepartmentDescription': { '$lk': this.productSearchText } })
                    break;
                default:
                    searchCondition['$or'].push(
                        { 'Description': { '$lk': this.productSearchText } },
                        { 'ItemUPC': { '$lk': this.productSearchText } },
                        { 'SdpCode': { '$lk': this.productSearchText } },
                        { 'SubDepartmentDescription': { '$lk': this.productSearchText } },
                        { 'DepartmentCode': { '$lk': this.productSearchText } },
                        { 'DepartmentDescription': { '$lk': this.productSearchText } }
                    )
                    break;
            }
            
            if (this.vendorCode){
                const additionalConditions = [{ 'VendorCode': { '$eq': this.vendorCode } }]
                additionalConditions.push(searchCondition);
                searchCondition = {
                    '$and': additionalConditions
                }
            }
            return searchCondition;
        },

        select(product){
            this.$emit('onSelectProduct', product);
        },

        back() {
            this.$emit('onBack');
        },
        selectionChanged(selectedElements){
            this.$emit('selectedItemChanged',selectedElements)
        },

        addCurrentToParent(selectedElements){
            this.$emit('addCurrentToList', selectedElements)
        }
    }
}
</script>