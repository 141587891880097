<template src="./clientAccount.html"></template>

<script>

import { mapGetters, mapActions, mapMutations } from 'vuex'
import grid from '@/core/components/common/grid/persisted-grid.vue'
import searchBar from '@/core/components/common/search-bar/search-bar.vue'
import { stringFunction, toCurrency } from '@/core/functions'
import creationButton from '@/core/components/common/creation-button/creation-button.vue';
import clientAccountManagement from '@/backoffice/modules/clientAccount/components/clientAccountManagement/clientAccountManagement.vue'
import accountManagement from '@/backoffice/modules/clientAccount/components/accountManagement/accountManagement.vue'
import OptionsManagement from '@/backoffice/modules/clientAccount/components/optionsManagement/optionsManagement.vue'
import { OperationType } from '@/backoffice/domain/backoffice.const.js'
import prompts from '@/core/tools/notifications/notifications'
import ClientAccountsClient from '@/backoffice/modules/clientAccount/clients/clientAccount.client.js'
import { permissionsDictionary } from '@/core/security/permissions-dictionary.js';
import { GridId, FilterParams } from '@/core/components/common/grid/grid.const.js'
import agGridGetters from '@/core/components/common/grid/getters/getters.js';
import { getDescription } from '@/core/functions/descriptions-helper.js'

export default {
    name: 'ClientAccounts',

    components: {
        grid,
        searchBar,
        creationButton,
        clientAccountManagement,
        accountManagement
    },

    data() {
        return {
            clients: [],
             gridOptions: {
                getRowId: (params) => params?.data?.id,
                groupIncludeTotalFooter: true,
            },
            filters: [],
            multiTextFilterParams: {
                filters: [
                    {
                        filter: 'agTextColumnFilter',
                        filterParams: {
                            textFormatter: stringFunction.normalize
                        } 
                    },
                    {
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            textFormatter: stringFunction.normalize
                        } 
                    }
                ]
            }
        }
    },

    computed: {
        ...mapGetters('Account', ['getCurrentTargetLayerId', 'hasPermission']),
        ...mapGetters('PriceLevel', ['getPriceLevels']),

        canCreate(){
            return this.hasPermission([permissionsDictionary.CLIENT_CREATE])
        },
        getGridId() {
            return GridId.Clients
        },
        columnDefs() {
            return [
                { 
                    field: 'fullName',
                    headerName: this.$t('ClientAccount.Columns.FullName'),
                    headerTooltip: this.$t('ClientAccount.Columns.FullName'),
                    filterParams: this.multiTextFilterParams,
                    cellClass:'ag-cell-overflow',
                    cellRenderer: 'menuRenderer',
                    cellRendererParams: {
                        canUpdate : () => true,
                        editEntity: params => this.editClient(params.data),
                        clickMenuLink: params => this.editClient(params.data),
                    }
                },
                { field: 'email', headerName: this.$t('ClientAccount.Columns.Email'), headerTooltip: this.$t('ClientAccount.Columns.Email'), filterParams: this.multiTextFilterParams },
                { field: 'company', minWidth: 200, headerName: this.$t('ClientAccount.Columns.Company'), headerTooltip: this.$t('ClientAccount.Columns.Company'), filterParams: this.multiTextFilterParams },
                { field: 'accountNumber', minWidth: 200, headerName: this.$t('ClientAccount.Columns.AccountNumber'), headerTooltip: this.$t('ClientAccount.Columns.AccountNumber'), filterParams: this.multiTextFilterParams },
                { field: 'fileNumber', minWidth: 200, headerName: this.$t('ClientAccount.Columns.FileNumber'), headerTooltip: this.$t('ClientAccount.Columns.FileNumber'), filterParams: this.multiTextFilterParams },
                {
                    field: 'isChargeToAccountEnabled',
                    minWidth: 100,
                    headerName: this.$t('ClientAccount.Columns.ChargeToAccount'),
                    headerTooltip: this.$t('ClientAccount.Columns.ChargeToAccount'),
                    filterParams: this.multiTextFilterParams,
                    cellRenderer: 'booleanRenderer',
                    valueGetter: (params) => params?.data?.isChargeToAccountEnabled ? params.data.isChargeToAccountEnabled.toString() : 'false',
                },
                {
                    field: 'balance',
                    minWidth: 200,
                    headerName: this.$t('ClientAccount.Balance'),
                    headerTooltip: this.$t('ClientAccount.Balance'),
                    aggFunc: 'sum',
                    valueFormatter :  (params) => { 
                         if(params?.node?.footer &&  !params.value)
                            return

                        return params.value ? toCurrency(params.value) : this.$t('General.NotAvailable')
                    },
                    filterParams: FilterParams.NumberFilter,
                    filterValueGetter: (params) =>  params.data?.balance ? toCurrency(params.data?.balance ) : this.$t('General.NotAvailable')
                },
                 {
                    field: 'lastPayment',
                    minWidth: 200,
                    headerName: this.$t('ClientAccount.Columns.LastPayment'),
                    headerTooltip: this.$t('ClientAccount.Columns.LastPayment'),
                    valueGetter: agGridGetters.dateGetter,
                    filterValueGetter: (params) => params?.data?.lastPayment && new Date(params.data.lastPayment)
                },
                {
                    field: 'creditLimit',
                    minWidth: 200,
                    headerName: this.$t('ClientAccount.Columns.CreditLimit'),
                    headerTooltip: this.$t('ClientAccount.Columns.CreditLimit'),
                    aggFunc: 'sum',
                    filterValueGetter: (params) => params.data?.creditLimit ? toCurrency(params.data?.creditLimit ) : this.$t('General.NotAvailable'),
                    valueFormatter :  (params) => { 
                         if(params?.node?.footer &&  !params.value)
                            return

                        return params.value ? toCurrency(params.value) : this.$t('General.NotAvailable')
                    }
                },
                { field: 'targetDescription', headerName: this.$t('ClientAccount.Columns.Target'), headerTooltip: this.$t('ClientAccount.Columns.Target'), filterParams: this.multiTextFilterParams },
                {
                    field: 'priceLevelID',
                    headerName: this.$t('ClientAccount.Columns.PriceLevel'),
                    headerTooltip: this.$t('ClientAccount.Columns.PriceLevel'),
                    filterParams: this.multiTextFilterParams,
                    valueFormatter: (params) => {
                          if(params?.node?.footer &&  !params.value)
                            return
                        if (!params?.data?.priceLevelID)
                            return this.$t('ClientAccount.PriceLevel.None')

                        const priceLevel = this.priceLevels.find(pl => pl.id === params.data.priceLevelID)
                        if (!priceLevel)
                            return ''

                        return `${priceLevel.code} - ${priceLevel.description}`
                    }
                }
            ]
        },
        priceLevels() {
            return this.getPriceLevels.map(pl => ({
                ...pl,
                description: getDescription(pl.descriptions)
            }))
        },
        rowData() {
            return this.clients
        }
    },

    watch: {
        getCurrentTargetLayerId() {
            this.loadClients()
        }
    },

    beforeDestroy() {
        this.setPriceLevels()
    },

    mounted() {
        this.loadClients()
    },

    methods: {
        ...mapActions('PriceLevel', ['fetchPriceLevels']),
        ...mapMutations('ClientAccount', { setClient: 'SET_CLIENT', setOperationType: 'SET_OPERATION_TYPE' }),
        ...mapMutations('PriceLevel', { setPriceLevels: 'SET_PRICE_LEVELS' }),

        async loadClients() {
            try {
                await this.fetchPriceLevels()
                this.clients = await ClientAccountsClient.getClients()
            } catch (error) {
                prompts.error({
                    text: this.$t('ClientAccount.Messages.FetchClientsError')
                });
            }
        },

        filtersChanged(filters) {
            this.filters = filters
        },

        async createClient(){
            this.setClient(null)
            this.setOperationType(OperationType.Creation)
            await this.$sidePanel.show([
                { component: clientAccountManagement, tab: 'ClientAccount.Tabs.Client' },
                { component: accountManagement, tab: 'ClientAccount.Tabs.Account' },
                { component: OptionsManagement, tab: 'ClientAccount.Tabs.Options' }
            ], null, { backgroundIsDisabled: true });

            this.loadClients();
        },

        async editClient(value){
            this.setClient(value)
            this.setOperationType(OperationType.Edition)
            await this.$sidePanel.show([
                { component: clientAccountManagement, tab: 'ClientAccount.Tabs.Client' },
                { component: accountManagement, tab: 'ClientAccount.Tabs.Account' },
                { component: OptionsManagement, tab: 'ClientAccount.Tabs.Options' }
            ], null, { backgroundIsDisabled: true });

            this.loadClients();
        }
    }
}
</script>