import { descriptionLanguages } from '@/core/domain/language/language.const.js';
import { Description } from '@/core/domain/description.js'

export class BrandDto {
    constructor({ id, targetID, description, descriptions, targetDescription, createdOn, modifiedOn, isDeleted, canUpdate, canDelete }) {
        this.id = id
        this.targetID = targetID
        this.description = description
        this.descriptions = descriptions
        this.targetDescription = targetDescription
        this.createdOn = createdOn
        this.modifiedOn = modifiedOn
        this.isDeleted = isDeleted
        this.canUpdate = canUpdate
        this.canDelete = canDelete
    }
}

export class Brand {
    constructor({ id, targetID, description, descriptions, targetDescription, createdOn, modifiedOn, isDeleted, canUpdate, canDelete }) {
        this.id = id
        this.targetID = targetID
        this.description = description
        this.descriptions = descriptions
        this.targetDescription = targetDescription
        this.createdOn = createdOn
        this.modifiedOn = modifiedOn
        this.isDeleted = isDeleted
        this.canUpdate = canUpdate
        this.canDelete = canDelete
    }
}

export function mapDtoToBrand(dto){
    if (!dto)
        return null

    return new Brand({
        id: dto.id,
        targetID: dto.targetID,
        description: dto.description,
        descriptions: dto.descriptions ? 
            dto.descriptions.map(d => new Description(d._DescriptionID, d.id, d.language, d.value)) : 
            [new Description(0, dto.id, descriptionLanguages.FR, null), new Description(0, dto.id, descriptionLanguages.EN, null)],
        targetDescription: dto.targetDescription,
        createdOn: dto.createdOn,
        modifiedOn: dto.modifiedOn,
        isDeleted: dto.isDeleted,
        canUpdate: dto.canUpdate,
        canDelete: dto.canDelete
    })
}

export function mapBrandToDto(brand){
    if (!brand)
        return null

    return new BrandDto({
        id: brand.id,
        targetID: brand.targetID,
        description: brand.description,
        descriptions: brand.descriptions.filter(b => !!b.value).map(d => new BrandDescriptionDto(d.descriptionID, d.id, d.language, d.value)), 
        createdOn: brand.createdOn,
        modifiedOn: brand.modifiedOn,
        isDeleted: brand.isDeleted,
        canUpdate: brand.canUpdate,
        canDelete: brand.canDelete
    })
}

export function newBrand(){
    return new Brand({
        id: 0,
        targetID: 0,
        description: null,
        descriptions: [new Description(0, 0, descriptionLanguages.FR, null), new Description(0, 0, descriptionLanguages.EN, null)], 
        createdOn: null,
        modifiedOn: null,
        isDeleted: false,
        canUpdate: true,
        canDelete: true
    })
}

class BrandDescriptionDto {
    constructor(descriptionID, id, language, value) {
        this.descriptionID = descriptionID,
        this.id = id,
        this.language = language,
        this.value = value  
    }
}