<template src="./address.html"></template>
<style lang="scss" src="./address.scss"></style>

<script>
import { EntityServices } from '@/core/store/modules/entity.services.js';
import { mapGetters, mapActions } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import prompts from '@/core/tools/notifications/notifications';
import { ValidationObserver, ValidationProvider, localize } from "vee-validate";

export default {
  name: 'address-template-form',
  props:{
    templatePayload: Object
  },
  components:{
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      associatedUserModel: {},
      address: {},
      addressCopy: {},
      loaded: false,
      valid: false
    }
  },

  computed:{
    ...mapGetters('DataModels', ['isPropertyRequired', 'getModelObjectDefinition']),
    model(){
      return this.templatePayload;
    },
    isEdited() {
      if(!this.address || !this.addressCopy)
        return false

      return !isEqual(this.address, this.addressCopy);
    },
  },

  watch: {
    isEdited(edited){
      this.$sidePanel.isEdit(edited);
    }
  },

  async mounted() {
    localize(this.$i18n.locale);

    if (this.model) {
      await this.loadAddressModel();
      await this.getAssociatedUser();
      await this.fetchAddress();
    }
  },

  methods: {
    ...mapActions('DataModels', ['getModel']),

    async loadAddressModel() {
      await this.getModel('Address');
    },

    async getAssociatedUser() {
      const modelID = this.model._UserID;
      if (modelID) {
        const user = await EntityServices.getEntity({ getChildren: true,  modelName: 'User', filters: [{ 'Column': '_UserID', 'Value': [modelID] }] });
        if(user){
          this.associatedUserModel = cloneDeep(user);
        }
      }
    },

    async fetchAddress() {
      this.loaded = false;

      const addressDefault = this.getModelObjectDefinition('Address');

      if (this.associatedUserModel._AddressID) {        
        const addressEntity = await EntityServices.getEntity({ getChildren: true, modelName: 'Address', filters: [{ Column: '_AddressID', Value: [this.associatedUserModel._AddressID] }] });

        if(addressEntity){
          const address = { ...addressDefault, ...addressEntity };
          this.setAddress(address);
        }
      }
      else {
        this.setAddress(addressDefault);
      }

      this.loaded = true;
    },

    setAddress(address){
      this.address = address;
      this.addressCopy = cloneDeep(this.address);
    },

    async save(){
      const updatedAddress = { ...this.associatedUserModel.address, ...this.addressCopy };
      const updatedDataModel = { ...this.associatedUserModel, ...{ address: updatedAddress } };
      
      const result = await EntityServices.saveEntities({ targetModel: 'User', entities: [updatedDataModel] });

      if (Array.isArray(result) && result.length > 0) {
        const address = result[0].address;
        this.setAddress(address);
        this.$sidePanel.update({ 'save': true, 'row': address });
        this.$sidePanel.disabledTabs(false);
      }
    },
    async cancel(){
      const result = await prompts.warning({
        html: this.$t('General.Messages.Revert')
      });

      if (result.isConfirmed) {
        this.addressCopy = cloneDeep(this.address);
      }
    }
  }
}
</script>