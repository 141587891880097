import moment from 'moment';
import { mapGetters } from 'vuex';
import { languages } from '@/core/domain/language/language.const.js';

export default {
  data() {
    return {
      dayofweek: 0
    }
  },

  computed: {
    ...mapGetters('App', ['getConfigs'])
  },

  mounted(){
    if(this.getConfigs){
      const config = this.getConfigs.find(c => c.section == 'General' && c.variable == 'WeekStartDay');
      this.dayofweek = config ? parseInt(config.value) : 0;
    }
    //Update day of week to user config
    moment.updateLocale(languages.en, {
      week: {
        dow: this.dayofweek,
      }
    });
  },

  methods: {
    extractStartDatetime(date, output){
      return date.startOf(output).format('YYYY-MM-DD');
    },
    extractEndDatetime(date, output) {
      return date.endOf(output).format('YYYY-MM-DD');
    },
    ajustDateWithValue(date, sectionDetail){
      return moment(date).add(sectionDetail.value, sectionDetail.operator);
    },
    ajustDateWeek(date, weekofyear) {
      return date.week(weekofyear);
    },
    ajustDateWeekDay(date, dayofweek) {
      return date.weekday(dayofweek);
    },
    convert(period, date){
      const toReturn = {};

      if (period) {
        const referenceDate = date || moment().format('YYYY-MM-DD');
        const sections = this.getSectionsFromPeriod(period);

        const sectionStartDetail = this.getSectionDetails(sections[0]);
        const startReferenceDate = this.ajustDateWithOperator(referenceDate, sectionStartDetail);
        toReturn.start = this.extractStartDatetime(startReferenceDate, sectionStartDetail.output);

        const sectionEndDetail = this.getSectionDetails(sections[(sections.length > 1) ? 1 : 0]);
        const endReferenceDate = this.ajustDateWithOperator(referenceDate, sectionEndDetail);
        toReturn.end = this.extractEndDatetime(endReferenceDate, sectionEndDetail.output);
      }

      return toReturn;
    },
    getSectionDetails(section){
      const ret = {};
      const letters = section.replace(/[0-9-]/g, '');

      ret.value = parseInt(section.replace(/[^\d-]/g, ''));
      ret.operator = this.formatOperator(letters[0]);
      ret.output = this.formatOutput((letters.length > 1) ? letters[1] : letters[0]);
      ret.ajusting = (letters.length > 2 && letters[2] == 'r');

      return ret;
    },
    getSectionsFromPeriod(period) {
      const re = /-?\d{1,5}[dwmyqr]{1,3}/g;
      return period.match(re);
    },
    ajustDateWithOperator(referenceDate, sectionDetail){
      let modifiedDate = this.ajustDateWithValue(referenceDate, sectionDetail);
      if (sectionDetail.ajusting && sectionDetail.operator == 'years' && ['day', 'week'].includes(sectionDetail.output)) {
        modifiedDate = this.ajustDateWeek(modifiedDate, moment(referenceDate).week());
        if (sectionDetail.output == 'day')
          modifiedDate = this.ajustDateWeekDay(modifiedDate, moment(referenceDate).weekday());
      }

      return modifiedDate;
    },
    formatOperator(operator) {
      switch (operator) {
        case 'd':
          return 'days';
        case 'w':
          return 'weeks';
        case 'm':
          return 'months';
        case 'q':
          return 'quarters';
        case 'y':
          return 'years';
        default:
          return '';
      }
    },
    formatOutput(output) {
      switch (output) {
        case 'd':
          return 'day';
        case 'w':
          return 'week';
        case 'm':
          return 'month';
        case 'q':
          return 'quarter';
        case 'y':
          return 'year';
        default:
          return '';
      }
    }
  }
};
