<template src="./backoffice.html"></template>

<style lang="scss">
@import './backoffice.scss';
</style>

<script>

import { mapGetters } from 'vuex';
import headerNav from '@/core/components/layout/header/header.vue'
import sideNav from '@/core/components/layout/side-nav/side-nav.vue'

export default{
  name: 'backoffice',

  components: {
    headerNav,
    sideNav
  },

  data() {
    return {
      updateAvailable: false
    }
  },

  computed: {
    ...mapGetters('App', ['getSideNavStatus', 'isMobile']),
    mustHideHeaderNav(){
      return this.isMobile && this.getSideNavStatus
    }
  },
}
</script>