import comparators from '@/core/components/common/grid/comparators/comparators.js';
import formatters from '@/core/components/common/grid/formatters/formatters.js';
import getters from '@/core/components/common/grid/getters/getters.js';

export default {
  methods: {
    /* 
      Parameters
        columns                  (required): represent model ag-grid columns for ag-grid properties
        genericListColumn        (required): represent the selected column to return as ag-grid column 
        genericListGroup         (required): represent the grouped selected column to return as ag-grid column
        model                    (optional): used for setRenderer to loop through properties for side-panel template
        groupSet                 (optional): used to know if we use parent-child ag-grid columns
        menuRenderer             (optional): used to know if we want to automatically manage cell-renderer on columns
        cellRendererParams       (optional): used to set params to the cellRenderer. Need to be set when menuRenderer is true
    */

    setAgGridColumns(columns, genericListColumn, genericListGroup, model = null, groupSet = false, menuRenderer = false, cellRendererParams = null){
      const params = {
        model: model,
        groupSet: groupSet,
        menuRenderer: menuRenderer,
        cellRendererParams: cellRendererParams
      }

      const cols = [];
      for (let i = 0; i < genericListGroup.length; i++) {
        const genericGroup = genericListGroup[i];

        for (let j = 0; j < columns.length; j++) {
          const column = columns[j];

          if (this.compareCI(genericGroup, column.field)) {
            //build parent/children
            if (column.groupingSet && params.groupSet){
              this.findAndSetGenericColumn(cols, column, params);
            } 
            else{
              cols.push(this.setGenericColumn(column, params));
            }

            break;
          }
        }
      }

      for (let i = 0; i < genericListColumn.length; i++) {
        const genericColumn = genericListColumn[i];

        for (let j = 0; j < columns.length; j++) {
          const column = columns[j];
          
          if (this.compareCI(genericColumn.name, column.field)) {
            //build parent/children
            if (column.groupingSet && params.groupSet){
              this.findAndSetGenericColumn(cols, column, params);
            } 
            else{
              const setFirstColumnMenuRenderer = (params.menuRenderer && cols.length == 0);
              cols.push(this.setGenericColumn(column, params, setFirstColumnMenuRenderer));
            }

            break;
          }
        }
      }
      return cols;
    },

    setGenericListFromModel(columns, params) {
      const cols = [];

      for (let i = 0; i < columns.length; i++) {
        const column = columns[i];

        if (column.groupingSet && params.groupSet) {
          this.findAndSetGenericColumn(cols, column);
        }
        else {
          const setFirstColumnMenuRenderer = (params.menuRenderer && cols.length == 0); 
          cols.push(this.setGenericColumn(column, params, setFirstColumnMenuRenderer));
        }        
      }

      return cols;
    },

    findAndSetGenericColumn(cols, column, params) {
      let found = false;
      for (let i = 0; i < cols.length; i++) {
        const col = cols[i];
        if (this.compareCI(column.groupingSet, col.field)) {
          col.children.push(this.setGenericColumn(column, params));
          found = true;
          break;
        }
      }

      if (!found) {
        const length = cols.push(this.setParentColumn(column));
        cols[length - 1].children.push(this.setGenericColumn(column, params));
      }
    },

    setParentColumn(column){
      return {
        colKey: column.groupingSet,
        field: column.groupingSet,
        enableRowGroup: column.enableRowGroup,
        enablePivot: column.pivot,
        enableValue: column.enableValue,
        headerTooltip: this.$t('Reporting.Columns.' + column.groupingSet),
        headerName: this.$t('Reporting.Columns.' + column.groupingSet),
        children: []
      }
    },

    setGenericColumn(column, params, setFirstColumnMenuRenderer) {
      const col = {
        colKey: column.field,
        field: column.field,
        headerTooltip: this.$t(column.headerName),
        headerName: this.$t(column.headerName),
        type: column.type,
        filter: this.setFilter(column),
        hide: column.hide,
        sortable: true,
        enableRowGroup: column.enableRowGroup,
        enablePivot: column.pivot,
        enableValue: column.enableValue,
        valueGetter: this.assignGetter(column.valueGetter),
        valueFormatter: this.assignFormatter(column.valueFormatter),
        pivotComparator: this.assignComparator(column.comparator),
        comparator: this.assignComparator(column.comparator),
        keyCreator: this.keyCreator
      }

      if(column.cellClass){
        col.cellClass = column.cellClass;
      }
      
      if(setFirstColumnMenuRenderer){
        col.cellRenderer = 'menuRenderer';
        col.cellRendererParams = params.cellRendererParams;
        col.cellClass = 'ag-cell-overflow';
      }
      else{
        const renderer = this.setcellRenderer(column, params);
        col.cellRenderer = renderer.cellRenderer;
        col.cellRendererParams = renderer.cellRendererParams;
      }


      return col;
    },

    setFilter(col){
      switch(col.filter){
        case "agDateColumnFilter":
          return col.filter;
        case "agNumberColumnFilter":
          return col.filter;
        default:
          return 'agSetColumnFilter';
      }
    },

    assignGetter(str) {
      const fn = getters[str];
      if (typeof fn === 'function')
        return fn;
      else
        return null;
    },

    assignFormatter(str) {
      const fn = formatters[str];
      if (typeof fn === 'function')
        return fn;
      else
        return null;
    },

    assignComparator(str) {
      const fn = comparators[str];
      if (typeof fn === 'function')
        return fn;
      else
        return null;
    },

    keyCreator(params){
      return params.value || this.$t('grid.blanks'); 
    },
    
    setcellRenderer(column, params){
      const renderer = { cellRenderer: null, cellRendererParams: null };
      //If the column already have a cellRenderer, use it.
      if(column.cellRenderer){
        renderer.cellRenderer = column.cellRenderer;
      }
      else{      
        /* If the model is set, we must loop through properties to know if 
           the prop representing the column have a linked sidepanel template */
        if(params.model){
          const prop = params.model.properties.find(p => this.compareCI(p.name, column.field));

          if (prop && prop.cellRenderer && prop.cellRenderer == 'panelRenderer') {
            renderer.cellRenderer = prop.cellRenderer;
            renderer.cellRendererParams = { openSidePanel: params.cellRendererParams.openSidePanel };
          }
        }
      }
      return renderer;
    }
  }
}