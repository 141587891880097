const blankspaceChar = String.fromCharCode(160); // The whitespace char is represented by un blank space  and is char code is 160.
const spacebarChar = String.fromCharCode(32); // The spacebar char is represented by ◦ and is char code is 32.

const stringFunction = {
    isStringOrInstanceOfString: (str) => {

        if (!str) {
            return false;
        }

        return typeof str === 'string' || str instanceof String;
    },
    normalize: (str) => {
        if (!stringFunction.isStringOrInstanceOfString(str)) {
            return str;
        }

        return stringFunction.replaceNoneSpacebarSpaceCharacter(stringFunction.removeAccent(str).toLowerCase());
    },
    removeAccent: (str) => {

        if (!stringFunction.isStringOrInstanceOfString(str)) {
            return str;
        }

        let newStr = str.toLowerCase();
        newStr = newStr.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        newStr = newStr.replace(new RegExp("æ", 'g'), "ae");
        newStr = newStr.replace(new RegExp("[ø]", 'g'), "o");
        newStr = newStr.replace(new RegExp("œ", 'g'), "oe");
  
        return newStr;
    },
    replaceNoneSpacebarSpaceCharacter: (str) => {
        
        if (!stringFunction.isStringOrInstanceOfString(str)) {
            return str;
        }

        return str.replace(blankspaceChar, spacebarChar);
    },
    camelize: (str) => {
        if (!(str && str.length > 0))
            return str;

        const chars = [...str];

        const isUpperStr = !chars.some(c => {
            return c == c.toLowerCase();
        });

        if (isUpperStr)
            return str.toLowerCase();
        
        return chars.reduce((value, char, index) => {
            const isPartOfStartUpperChar = ((index > 0 && chars[index - 1] == chars[index - 1].toUpperCase()) && (index + 1 < chars.length && chars[index + 1] != chars[index + 1].toLowerCase()) && char == char.toUpperCase());
            const isFirstChar = index == 0 && char == char.toUpperCase();
            const lowerChar = isPartOfStartUpperChar || isFirstChar;

            if (lowerChar) {
                return `${value}${char.toLowerCase()}`;
            } else {
                return `${value}${char}`;
            }
        }, "");
    },
    pascalize: (str) => {
        return (str && str.length > 0) ? str.charAt(0).toUpperCase() + str.slice(1) : str;
    }
};

const stringConst = {
    blankspaceChar,
    spacebarChar
};

export { stringFunction, stringConst };