<template src="./brand.html"></template>

<script>
import manageColumns from '@/backoffice/modules/reporting/mixins/manage-columns.js'
import { mapGetters, mapActions } from 'vuex'
import prompts from '@/core/tools/notifications/notifications'
import { getColumnDefintions } from '@/backoffice/domain/column-definitions.js'
import errors from '@/core/tools/errors/errors'
import { BrandPermissions } from '@/backoffice/modules/brand/domain/brand.const.js'

import creationButton from '@/core/components/common/creation-button/creation-button.vue'
import searchBar from '@/core/components/common/search-bar/search-bar.vue'
import grid from  '@/core/components/common/grid/grid.vue'
import descriptionTitle from '@/core/components/common/description-title/description-title.vue'

export default {
    mixins: [manageColumns],
    name: 'BrandList',

    components: {
        creationButton,
        searchBar,
        grid,
        descriptionTitle
    },
    
    data() {
        return {
            title: '',
            model: {},
            sidePanelTemplate: null,
            rowData: null,
            gridOptions: {
                headerHeight: 50,
                groupHeaderHeight: 50,
                defaultColDef: {
                    resizable: true,
                    headerCheckboxSelectionFilteredOnly: false,
                },
                suppressDragLeaveHidesColumns: true,
                rowSelection: 'single',
                onGridReady: this.getData
            },
            filters: [],
            currentNode: null
        }
    },

    watch: {
        getCurrentTargetLayerId() {
            this.$router.go();
        }
    },
    
    computed: {
        ...mapGetters('App', ['isMobile']),
        ...mapGetters('Account', ['getCurrentTargetLayerId', 'hasPermission']),

        canCreate() {
            return this.hasPermission([BrandPermissions.Create])
        },
        columnDefs() {
            const options = {
                clickMenuLink: this.editBrand,
                editEntity: this.editBrand,
                delete: this.delete,
                openSidePanel: this.openSidePanel
            }
            
            return getColumnDefintions('Brand', this.isMobile, options)
        }
    },
    
    beforeDestroy() {
        if(this.gridOptions.api) {
            this.gridOptions.api.setRowData([]);
            this.gridOptions.api.destroy();
            this.grid = null;
        }
    },
    
    methods: {
        ...mapActions('Brand', ['getBrands', 'deleteBrand']),

        editBrand(node){
            this.currentNode = node;
            this.openSidePanel(this.currentNode.data);
        },

        createModel() {
            this.gridOptions.api.deselectAll()
            this.currentNode = null;
            this.openSidePanel();
        },

        async delete(node) {
            this.currentNode = node;

            const promptResult = await prompts.warning({
                html: this.$t('Brand.Messages.DeleteMsg')
            });

            if (!promptResult.isConfirmed) {
                return
            }

            let result;
            try {
                result = await this.deleteBrand(this.currentNode.data.id);
            }
            catch (error) {
                prompts.error({
                    text: this.$t('Error.CannotDeleteEntity')
                });
            }

            if (result) {
                this.gridOptions.api.applyTransaction({remove: [this.currentNode.data] });
            }
        },

        onSaveComplete(data){
            if (!data) {
                return;
            }

            let row = null;
            if (this.currentNode) {
                Object.assign(this.currentNode.data, data.row);
                this.gridOptions.api.applyTransaction({ update: [this.currentNode.data] });
            }
            else {
                row = this.gridOptions.api.applyTransaction({ add: [data.row] });
                if (row && row.add && row.add.length > 0)
                    row.add[0].setSelected(true);
            }
        },

        openSidePanel(data) {
            this.$sidePanel.show(this.$route.meta.sidePanelComponents, data).then(this.onSaveComplete);
        },

        async getData() {
            let data = []
            
            try {
                data = await this.getBrands()
            } catch (error) {
                errors.displayApiError(error)
            }

            this.rowData = data
            this.gridOptions.api.sizeColumnsToFit()
        },

        filtersChanged(filters){
            this.filters = filters
        }
    }
}
</script>