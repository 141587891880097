export const permissionsDictionary = {
  ADMIN : 'Admin',
  CLIENT_CREATE: 'CLIENT_CREATE',
  CLIENT_UPDATE: 'CLIENT_UPDATE',
  CLIENT_READ: 'CLIENT_READ',
  CONFIG_READ : 'C1',
  CONFIG_CUD : 'C2',
  CURRENT_INVENTORY_REPORT_READ: 'RCI1',
  DEPARTMENT_CUD : 'D1',
  DEPARTMENT_READ : 'D2',
  DEPARTMENT_RDM_CUD : 'D3',
  DASHBOARD_CUD : 'D4',
  GROUP_CUD : 'G1',
  GROUP_READ : 'G2',
  GROUP_RDM_CUD : 'G3',
  ITEM_PRICE_CREATE: "ItemItemPriceAsCreate",
  ITEM_PRICE_READ: "ItemItemPriceAsRead",
  ITEM_PRICE_UPDATE: "ItemItemPriceAsUpdate",
  ITEM_PRICE_DELETE: "ItemItemPriceAsDelete",
  INVENTORY_CUD: 'I1',
  INVENTORY_READ: 'I2',
  INVENTORY_RDM_CUD: 'I3',
  INVENTORYDOCUMENT_READ: 'INVDOC1',
  INVENTORYDOCUMENT_CUD: 'INVDOC2',
  INVENTORYDOCUMENT_RDM_CUD: 'INVDOC3',
  INVENTORYDOCUMENT_FORCEOPEN: 'INVDOC4',
  LABEL_READ: 'LABEL_READ',
  LABEL_UPDATE: 'LABEL_UPDATE',
  ORDER_READ: 'ORDER_READ',
  ORDER_CUD: 'ORDER_CUD',
  ORDER_SEND: 'ORDER_SEND',
  PRODUCT_CUD : 'P1',
  PRODUCT_READ : 'P2',
  PRODUCT_COST_CUD : 'P3',
  PRODUCT_COST_READ : 'P4',
  PRODUCT_RDM_CUD : 'P5',
  PRODUCT_COST_RDM_CUD : 'P6',
  RECEIVING_CUD : 'RECEIVING_CUD',
  RERPORT_ACOUNTING_AS_READ : 'ReportComptabilityReportAsRead',
  REPORT_EXCEPTION: 'REPORT_EXCEPTION',
  REPORT_MOVEMENT: 'REPORT_MOVEMENT',
  REPORT_PROMOTION: 'REPORT_PROMOTION',
  REPORT_READ : 'R2',
  REPORT_CUD : 'R3',
  ROLE_READ : 'RL2',
  ROLE_CUD : 'RL3',
  ROLE_RDM_CUD : 'RL4',
  SALE_READ : 'S3',
  SUBDEPARTMENT_CUD : 'S4',
  SUBDEPARTMENT_READ : 'S5',
  SALE_RDM_CUD : 'S7',
  SUBDEPARTMENT_RDM_CUD : 'S8',
  TARGET_CUD : 'T1',
  TARGET_READ : 'T2',
  TARGETLAYER_CUD : 'T3',
  TARGETLAYER_READ : 'T4',
  TARGET_RDM_CUD : 'T5',
  TARGETLAYER_RDM_CUD : 'T6',
  TAXEXEMPTION_READ : 'TAXEXEMPTION_READ',
  USER_READ : 'U2',
  USER_CUD : 'U3',
  USER_ACCESS_READ : 'U4',
  USER_ACCESS_CUD : 'U5',
  UNIT_OF_MEASURE_READ : 'U6',
  UNIT_OF_MEASURE_CUD : 'U7',
  USER_RDM_CUD : 'U8',
  USER_ACCESS_RDM_CUD : 'U9',
  UNIT_OF_MEASURE_RDM_CUD : 'U10',
  VENDOR_READ : 'V1'
}