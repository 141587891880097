<template src="./detail-renderer.html"></template>

<style lang="scss">
  @import './detail-renderer.scss';
</style>

<script>
import Default from './components/default-template/default-template.vue'
import ActionAndInformation from './components/action-and-information/action-and-information.vue'

export default{
  name: 'detail-renderer',

  components: {
    Default,
    ActionAndInformation
  },

  computed: {
    template() {
      return this.params.template || 'Default';
    }
  }
};
</script>
