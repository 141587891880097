<template src="./main.html"></template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import prompts from '@/core/tools/notifications/notifications';
import { ReceivingStatus } from '@/backoffice/modules/purchase/domain/purchase.js';
import directReceivingManangement from '@/backoffice/modules/purchase/components/sidePanel/direct-receivings/direct-receiving-management/direct-receiving-management.vue';
import creationButton from '@/core/components/common/creation-button/creation-button.vue';
import grid from '@/core/components/common/grid/persisted-grid.vue'
import { GridId, AgFilterType, AgFilter, AgAllowedAggFuncs } from '@/core/components/common/grid/grid.const.js'
import sliderList from '@/core/components/common/slider-list/slider-list.vue'
import information from '@/core/components/common/slider-list/templates/information/information.vue'
import searchBar from '@/core/components/common/search-bar/search-bar.vue'

export default {
  name: '',
  components: {
    sliderList,
    information,
    searchBar,
    grid,
    creationButton
  },
  mounted(){
    this.loadDirectReceivings()
  },
  data() {
    return {
      gridOptions: {
        getRowId: (params) => params.data?.receivingID,
      },
      filters: [],
      defaultFilters: {
        receivingStatus: {
          filterType: AgFilterType.Set,
          values: [ReceivingStatus.UNDEFINED, ReceivingStatus.OPEN, ReceivingStatus.CLOSED]
        }
      }
    }
  },

  watch: {
    getCurrentTargetLayerId() {
      this.loadDirectReceivings();
    }
  },
  beforeDestroy(){
    this.resetDirectReceivings()
  },
  computed: {
    ...mapGetters('App', ['isMobile']),
    ...mapGetters('Account', ['getCurrentTargetLayerId', 'hasPermission']),
    ...mapGetters('Receiving', ['getDirectReceivings']),

    canCreateReceiving() {
      return this.hasPermission(['RECEIVING_CUD'])
    },
    openReceivings(){
      if(!this.getDirectReceivings || this.getDirectReceivings.length == 0)
        return []

      return this.getDirectReceivings?.filter(r => r.receivingStatus === ReceivingStatus.OPEN)
    },
    gridId(){
      return GridId.DirectReceivings
    },
    columnDefs(){
      return [
        {
          field: 'receivingNO',
          sort: 'desc',
          headerName: !this.isMobile ? this.$t('Purchase.Receiving.Columns.ReceivingNO') : '#',
          headerTooltip: this.$t('Purchase.Receiving.Columns.ReceivingNO'),
          cellRenderer: 'linkRenderer',
          cellRendererParams: (params) => ({
              route: { name: 'directReceiving', params: { id: params.data?.receivingID } }
          }),
          filter: AgFilter.NumberColumnFilter,
          enableRowGroup: false,
        },
        { 
            field: 'firstVendor',
            headerName: this.$t('Purchase.Receiving.Columns.VendorName'),
            headerTooltip: this.$t('Purchase.Receiving.Columns.VendorName'),
            filter: AgFilter.SetColumnFilter
        },
        { 
            field: 'nbOfBodies',
            headerName: this.$t('Purchase.Receiving.Columns.ReceivingBodySums'),
            headerTooltip: this.$t('Purchase.Receiving.Columns.ReceivingBodySums'),
            filter: AgFilter.NumberColumnFilter,
        },
        { 
            valueFormatter: params => params.value instanceof Date ? params.value.toLocaleDateString() : '',
            headerName: this.$t('Purchase.Receiving.Columns.CreatedDate'),
            headerTooltip: this.$t('Purchase.Receiving.Columns.CreatedDate'),
            filter: AgFilter.DateColumnFilter,
            valueGetter: params => params.data?.createdDate ? new Date(params.data?.createdDate) : null
        },
        { 
            hide: this.isMobile,
            field: 'userUsername',
            headerName: this.$t('Purchase.Receiving.Columns.UserUsername'),
            headerTooltip: this.$t('Purchase.Receiving.Columns.UserUsername'),
            filter: AgFilter.SetColumnFilter
        },
        { 
            hide: this.isMobile,
            field: 'receivingStatus',
            headerName: this.$t('Purchase.Receiving.Columns.ReceivingStatus'),
            headerTooltip: this.$t('Purchase.Receiving.Columns.ReceivingStatus'),
            filter: AgFilter.SetColumnFilter,
            valueFormatter: params => params.value ? this.$t(`Purchase.Receiving.Status.${params.value}`): '',
            filterParams: {
              valueFormatter: (params) => params.value ? this.$t(`Purchase.Receiving.Status.${params.value}`): ''
            },
            allowedAggFuncs: AgAllowedAggFuncs.Text 
          
        }          
      ]
    }
  },

  methods: {
    ...mapActions('Receiving', ['fetchDirectReceivings']),
    ...mapMutations('Receiving',{ resetDirectReceivings: 'RESET_DIRECT_RECEIVINGS'}),
    
    async loadDirectReceivings(){
      try{
        await this.fetchDirectReceivings();
      }
      catch(error){
        const codeTranslate = error?.status === 403 ? 'Purchase.Messages.ForbiddenAction' : 'Purchase.Messages.GetReceivingsError'
        prompts.error({
          text: this.$t(codeTranslate)
        });
      }
    },

    newReceiving(){
      this.$sidePanel.show([{ component: directReceivingManangement }], null, { backgroundIsDisabled: true });
    },
    filtersChanged(filters){
      this.filters = filters;
    }
  }
}
</script>