<template src="./optionsManagement.html"></template>

<style lang="scss">
    @import './optionsManagement.scss';
</style>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate"
import { mapGetters, mapMutations } from 'vuex'
import { clientAccountServices } from '@/backoffice/modules/clientAccount/services/clientAccount.services.js'
import prompts from '@/core/tools/notifications/notifications'
import { permissionsDictionary } from '@/core/security/permissions-dictionary.js'
import { OperationType } from '@/backoffice/domain/backoffice.const.js'
import cloneDeep from 'lodash/cloneDeep'
import toggle from '@/core/components/common/toggle/toggle.vue'
import { NonePriceLevel } from '@/backoffice/modules/priceLevel/domain/priceLevel.const.js'
import { getDescription } from '@/core/functions/descriptions-helper.js'
import multiselect from 'vue-multiselect'


export default {
    name: 'OptionsManagement',
    components: {
        ValidationObserver,
        ValidationProvider,
        toggle,
        multiselect
    },
    data () {
        return {
            client: {},
            invalidForm: true,
            preventSave: false
        }
    },
    mounted() {
       this.client = cloneDeep(this.getClient)
    },
    computed: {
        ...mapGetters('ClientAccount', ['getClient', 'getOperationType']),
        ...mapGetters('Account', ['hasPermission', 'getCurrentTargetId']),
        ...mapGetters('PriceLevel', ['getPriceLevels']),

        readOnly() {
            if(this.getOperationType === OperationType.Creation)
                return !this.hasPermission([permissionsDictionary.CLIENT_CREATE])

            return !this.hasPermission([permissionsDictionary.CLIENT_UPDATE])
        },
        priceLevel: {
            get() {
                return this.priceLevels.find(pl => pl.id === (this.client.priceLevelID || NonePriceLevel.id))
            },
            set(option) {
                this.client.priceLevelID = NonePriceLevel.id === option.id ? null : option.id
            }
        },
        priceLevels() {
            return [
                { ...NonePriceLevel, description: this.$t('ClientAccount.PriceLevel.None') }, 
                ...this.getPriceLevels.map(pl => ({
                    ...pl,
                    description: getDescription(pl.descriptions)
                }))
            ]
        }
    },
    watch: {
        getOperationType(){
            const disabledTab = this.invalidForm || this.getOperationType === OperationType.Creation
            this.$sidePanel.disabledTabs(disabledTab)
        }
    },

    methods: {
        ...mapMutations('ClientAccount', { setOperationType: 'SET_OPERATION_TYPE', setClient: 'SET_CLIENT' }),
        
        getPriceLevelDescription(option) {
            if (!option.id)
                return option.description
            
            return `${option.code} - ${option.description}`
        },
        async save() {
            this.preventSave = true

            try {
                const savedClient = await clientAccountServices.createOrUpdate(this.client, this.getOperationType)
                this.setOperationType(OperationType.Edition)
                this.setClient(savedClient)
				this.client = cloneDeep(savedClient)


                prompts.toast({
                    icon: 'success',
                    title: this.$t('ClientAccount.Messages.SaveClientSuccess', { email: this.client.email }),
                    position: 'bottom-end'
                })
            }
            catch(error){
                prompts.error({ text: this.$t('ClientAccount.Messages.SaveClientError') })
            }

            this.preventSave = false
        },

        cancel() {
            this.$sidePanel.close()
        },

        isInvalid(invalid) {
            this.invalidForm = invalid
            return invalid
        }
    }
}
</script>