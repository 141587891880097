<template inline-template v-cloak src="./calendar.html"></template>
<style src="@pluquetheluxe/vue-ctk-date-time-picker/dist/@pluquetheluxe/vue-ctk-date-time-picker.css">
</style>

<style lang="scss">
@import './calendar.scss';
</style>

<script>
import VueCtkDateTimePicker from '@pluquetheluxe/vue-ctk-date-time-picker';
import moment from 'moment';
import { mapGetters } from 'vuex';

export default{
  name: 'calendar',
  props: {
    value: [String, Object],
    icon: Boolean,
    navigation: Boolean,
    range: {
      type: Boolean,
      default: false
    },
    right: Boolean,
    disabled: Boolean
  },
  components: {
    VueCtkDateTimePicker
  },
  data() {
    return {   
      eventTimeout: null,      
      id: this._uid,
      autoClose: false,
      noButton: false,
      noButtonNow: true,
      buttonNowTranslation: this.$t('Button.Now'),
      color: '#005394',
      format: 'YYYY-MM-DD',
      formatted: 'ddd D MMM YYYY',
      label: this.$t('Calendar.Label'),
      locale: this.$i18n.locale,
      noClearButton: true,
      noHeader: false,
      noShortcuts: true,
      onlyDate: true,
      firstDayOfWeek: 0,
      configs: []
    };
  },

  computed: {
    ...mapGetters('App', ['getConfigs']),
    date:{
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },

  mounted(){
    this.setCalendarConfigs();
    this.noButton = !this.range;
    this.autoClose = !this.range;
  },
  methods:{
    setCalendarConfigs(){
      if(this.getConfigs){
        const config = this.getConfigs.find(c => c.section == 'General' && c.variable == 'WeekStartDay');
        this.firstDayOfWeek = config ? config.value : 0;
      }
    },

    navigationClick(dir) {
      this.date = moment(this.date).add((dir == 'left') ? -1 : 1, 'days').format('YYYY-MM-DD');  
    },

    //Need to delay input and is hidden event because both are called when
    //you select a value we we want to prevent emitting our input / dateChange event twice
    eventDelay(){
      if(!this.range){
        if (this.eventTimeout){
          clearTimeout(this.eventTimeout);
        }

        this.eventTimeout = setTimeout(() => {
          clearTimeout(this.eventTimeout);
          this.$emit('input', this.date);
        });
      }  
    },

    validate(){
      this.$emit('validate');
    }
  }
}
</script>
