import api from '@/core/services/_api.config'
import { mapClientToDto, mapDtoToClient } from '@/backoffice/modules/clientAccount/domain/client.js'

export default {
    getClients: async () => {
        const response = await api()
                    .get(`/api/Client/`)
                    .catch((error) => {
                        throw error.response
                    })

        return response.data.map(d => mapDtoToClient(d))
    },
    createClient: async (client) => {
        const response = await api()
                    .post(`/api/Client/`, mapClientToDto(client))
                    .catch((error) => {
                        throw error.response
                    })

        return mapDtoToClient(response.data)
    },
    updateClient: async (client) => {
        const response = await api()
                    .put(`/api/Client/${client.id}`, mapClientToDto(client))
                    .catch((error) => {
                        throw error.response
                    })

        return mapDtoToClient(response.data)
    },
    getClientTargetConfig: async () => {
        const response = await api()
                    .get(`/api/Client/ClientTargetConfig`)
                    .catch((error) => {
                        throw error.response
                    })

        return response.data
    },
    sendClientAccountStatement: async (clientId) => {
        const response = await api()
                    .post(`/api/Client/SendClientAccountStatement/${clientId}`)
                    .catch((error) => {
                        throw error.response
                    })

        return mapDtoToClient(response.data)
    }
}