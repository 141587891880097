export const languages = {
    fr: 'fr',
    en: 'en'
}

export const languageMapping = {
    fr: 'fr-CA',
    en: 'en-CA'
}

export const descriptionLanguages = {
    FR: 'FR',
    EN: 'EN'
}