import ClientAccount from '@/backoffice/modules/clientAccount/clientAccount.vue'

import { permissionsDictionary } from '@/core/security/permissions-dictionary.js';

export default {
  path: 'clientAccount',
  name: 'clientAccounts',
  component: ClientAccount,
  meta: {
    requiresAuth: true,
    associatedParentRouteName: null,
    sideNav: {
      image: null,
      label: 'SideNav.Management.ClientAccounts'
    },
    permissions: [permissionsDictionary.CLIENT_READ, permissionsDictionary.CLIENT_CREATE, permissionsDictionary.CLIENT_UPDATE],
    breadcrumb: {
      linkDescription: 'Breadcrumbs.Management.ClientAccounts',
      routeNameTo: null,
      explicitTail: null
    }
  }
}