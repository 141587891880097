<template src="./select-input-renderer.html"></template>

<style scoped lang="scss">
@import './select-input-renderer.scss';
</style>

<script>
export default{
  name: 'selectInputRenderer',
  computed: {
    disabled() {
      return this.params.canUserInteractWithUI && !this.params.canUserInteractWithUI();
    },
    renderSelectHtml(){
      return this.params.renderSelectHtml ? this.params.renderSelectHtml(this.params) : true
    }
  },
  methods: {
    change(e){
      const newValue = this.params.valueConverter? this.params.valueConverter(e.target.value) : e.target.value
      this.params.node.setDataValue(this.params.colDef, newValue)
    }
  }
};
</script>
