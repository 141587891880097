<template inline-template v-cloak src="./column-picker.html"></template>
<style lang="scss" src="./column-picker.scss"></style>

<script>
import { stringFunction } from '@/core/functions';
import cloneDeep from 'lodash/cloneDeep';
import draggable from "vuedraggable"
import nestedDraggable from '@/core/components/common/nested-draggable/nested-draggable.vue'


export default {
  name: 'columnPicker',
  props:{
    hasView: Boolean,
    availableColumns: Array,
    selectedColumns: Array,
    groupedColumns: Array
  },
  components:{    
    draggable,
    nestedDraggable
  },
  data() {
    return {  
      columnFilter: "",
      colPickedFilter: "",
      colGroupedFilter: "",
      isGroupable: true,
      columns: [],      
      originalColumns: [],
      colPicked: [],
      colGrouped: [],
      columnIndex: null,
      columnPickedIndex: null,
      columnGroupIndex: null
    }
  },
  watch: {
    colPicked: {
      handler: function() {
        this.$emit("columns-picked", this.colPicked)
      },
      deep: true
    },
    colGrouped: {
      handler: function() {
        this.$emit("columns-grouped", this.colGrouped)
      },
      deep: true
    }
  },
  mounted() {
    this.setColumns();
  },
  methods:{    
    setColumns(){
      this.columns = cloneDeep(this.availableColumns);

      this.columns.forEach(c => {
        c.aggFunc = null;
        c.show = true;
      });

      this.originalColumns = cloneDeep(this.columns);
      let found = false;
      let j = 0;
      // we have to group by first since we add group by list to column at the end, always fill group by first
      for (let i = 0; i < this.groupedColumns.length; i++) {
        found = false;
        j = 0;
        while (j < this.columns.length && !found) {
          if (this.compareCI(this.columns[j].field, this.groupedColumns[i])) {
            this.colGrouped.push({
              enableRowGroup: this.columns[j].enableRowGroup,
              enablePivot: this.columns[j].pivot,
              enableValue: this.columns[j].enableValue,
              field: this.columns[j].field,
              headerName: this.columns[j].headerName,
              aggregates: this.columns[j].aggregates,
              groupingSet: this.columns[j].groupingSet,
              type: this.columns[j].type,
              show: true
            });
            this.columns.splice(j, 1);
            found = true;
          }
          j++;
        }
      }
      for (let i = 0; i < this.selectedColumns.length; i++) {
        found = false;
        j = 0;
        while (j < this.columns.length && !found) {
          if (this.compareCI(this.columns[j].field, this.selectedColumns[i].name)) {
            this.colPicked.push({
              enableRowGroup: this.columns[j].enableRowGroup,
              enablePivot: this.columns[j].pivot,
              enableValue: this.columns[j].enableValue,
              field: this.columns[j].field,
              headerName: this.columns[j].headerName,
              aggregates: this.columns[j].aggregates,
              aggFunc: this.selectedColumns[i].aggFunc,
              groupingSet: this.columns[j].groupingSet,
              type: this.columns[j].type,
              show: true
            });
            this.columns.splice(j, 1);
            found = true;
          }
          j++;
        }
      }

      //Available columns
      this.columns = this.nestedColumns(this.columns);
      this.sort(this.columns, 'headerName', false, 'elements', true);   
    },

    searchCol(){    
      const tempColumns = cloneDeep(this.columns);  

      if(this.columnFilter.trim() == "")
      {
        tempColumns.forEach(c => {
          c.show = true;
          if(c.elements){
            c.elements.forEach(e => {
              e.show = true;
            });
          }
        });

        this.columns = tempColumns;

        return null;
      }
      let translation = '';

      //Loop through all parent column
      for(let i = 0; i < tempColumns.length; i++){
        translation = this.$t(tempColumns[i].headerName);
        //If the parent column fit the filter, do not loop through child
        if (!translation || !stringFunction.removeAccent(translation).toLowerCase().includes(stringFunction.removeAccent(this.columnFilter).toLowerCase())) {
          tempColumns[i].show = false;

          if(tempColumns[i].elements){          
            //Else, loop through child to see if each translation fit the filter
            for(let j = 0; j < tempColumns[i].elements.length; j++){
              translation = this.$t(tempColumns[i].elements[j].headerName);
              //If not, set show to false to hide the column
              if (!translation || !stringFunction.removeAccent(translation).toLowerCase().includes(stringFunction.removeAccent(this.columnFilter).toLowerCase())) {
                tempColumns[i].elements[j].show = false;
              }
              else{
                tempColumns[i].elements[j].show = true;
                tempColumns[i].show = true;
              }
            }
          }
        }
        else{
          tempColumns[i].show = true;
          tempColumns[i].elements.forEach(e => {
              e.show = true;
          });
        }
      }

      this.columns = tempColumns;
    },
    searchColPicked(){
      const tempColumns = cloneDeep(this.colPicked);

      if(this.colPickedFilter.trim() == "")
      {
        tempColumns.forEach(c => {
          c.show = true;
        });

        this.colPicked = tempColumns;

        return null;
      }

      tempColumns.forEach(c => {
        const translation = this.$t(c.headerName);
        c.show = stringFunction.removeAccent(translation).includes(stringFunction.removeAccent(this.colPickedFilter));
      });

      this.colPicked = tempColumns;
    },
    searchColGrouped(){
      const tempColumns = cloneDeep(this.colGrouped);

      if(this.colGroupedFilter.trim() == "")
      {
        tempColumns.forEach(c => {
          c.show = true;
        });

        this.colGrouped = tempColumns;

        return null;
      }

      tempColumns.forEach(c => {
        const translation = this.$t(c.headerName);
        c.show = stringFunction.removeAccent(translation).includes(stringFunction.removeAccent(this.colGroupedFilter));
      });

      this.colGrouped = tempColumns;
    },

    setColumn(value) {
      this.columnPickedIndex = null;
      this.columnGroupIndex = null;
      this.columnIndex = value.item.id + value.oldIndex;
    },
    setColumnPicked(value) {
      this.columnIndex = null;
      this.columnGroupIndex = null;
      this.columnPickedIndex = value.oldIndex;
    },
    setColumnGroup(value){
      this.columnIndex = null;
      this.columnPickedIndex = null;
      this.columnGroupIndex = value.oldIndex;
    },

    nestedColumns(columns) {
      let ncolumns = [];
      const noNestedColumns = [], nestedColumnsDone = [];
      let groupingSet = '';

      for (let i = 0, c = columns.length; i < c; i++) {
        if (columns[i].groupingSet !== undefined && nestedColumnsDone.indexOf(columns[i].groupingSet) == -1) {
          groupingSet = columns[i].groupingSet;
          nestedColumnsDone.push(groupingSet);

          const nested = { 'id': groupingSet, 'headerName': 'Reporting.Columns.' + groupingSet, 'field': groupingSet, 'groupingSet': groupingSet, 'elements': [], isNested: true, show: true };

          for (let j = i, cc = columns.length; j < cc; j++) {
            if (columns[j].groupingSet !== undefined && columns[j].groupingSet == groupingSet) {
              columns[j].id = columns[j].field;
              nested.elements.push(columns[j]);
            }
          }

          ncolumns.push(nested);
        }
        else if (columns[i].groupingSet == undefined) {
          columns[i].id = columns[i].field;
          columns[i].isSingle = true;
          noNestedColumns.push(columns[i]);
        }
      }

      ncolumns = ncolumns.concat(noNestedColumns);
      return ncolumns;
    },
    formatNestedColumns(columns) {
      let ncolumns = [];
      const noNestedColumns = [], nestedColumnsDone = [];
      let groupingSet = '';

      for (let i = 0, c = columns.length; i < c; i++) {
        if (columns[i].groupingSet !== undefined && nestedColumnsDone.indexOf(columns[i].groupingSet) == -1 && columns[i].elements != undefined) {
          ncolumns.push(columns[i]);
          nestedColumnsDone.push(columns[i].groupingSet);
        }
        else if (columns[i].groupingSet !== undefined && nestedColumnsDone.indexOf(columns[i].groupingSet) > -1) {
          let index = 0;
          let find = false;

          while (!find && index < ncolumns.length) {
            if (ncolumns[index].groupingSet == columns[i].groupingSet) {
              find = true;

              if (columns[i].elements != undefined && columns[i].elements.length > 0) {
                ncolumns[index].elements = ncolumns[index].elements.concat(columns[i].elements);
              }
              else {
                ncolumns[index].elements.push(columns[i]);
              }
            }
            else {
              index++;
            }
          }
        }
        if (columns[i].groupingSet !== undefined && nestedColumnsDone.indexOf(columns[i].groupingSet) == -1) {
          groupingSet = columns[i].groupingSet;
          nestedColumnsDone.push(groupingSet);

          const nested = { 'id': 'N.' + groupingSet, 'headerName': 'Reporting.Columns.' + groupingSet, 'field': groupingSet, 'groupingSet': groupingSet, 'elements': [columns[i]], isNested: true, 'type': columns[i].type, show: true };

          groupingSet = '';

          ncolumns.push(nested);
        }
        else if (columns[i].groupingSet == undefined) {
          noNestedColumns.push(columns[i]);
        }
      }

      ncolumns = ncolumns.concat(noNestedColumns);
      return ncolumns;
    },
    reNestedColumns(columns) {
      const unNested = [];

      for (let i = 0, c = columns.length; i < c; i++) {
        if (columns[i].elements && columns[i].elements.length > 0) {
          for (let j = 0, cc = columns[i].elements.length; j < cc; j++) {
            unNested.push(columns[i].elements[j]);
          }
        }
        else if (columns[i].elements == undefined){
          unNested.push(columns[i]);
        }
      }
      return this.nestedColumns(unNested);
    },

    add(col, addAll = false){
      if (col.elements && col.elements.length > 0) {
        for (let i = 0, c = col.elements.length; i < c; i++) {
          this.colPicked.push(col.elements[i]);
        }
      }
      else {
        this.colPicked.push(col);
      }

      let find = false;
      let i = 0;

      while (!find && i < this.columns.length) {
        if (col.isNested && this.compareCI(this.columns[i].field, col.field)) {
          this.columns.splice(i, 1);
          find = true;
        }

        if (!find && this.columns[i].elements && this.columns[i].elements.length > 0) {
          let j = 0;

          while (!find && j < this.columns[i].elements.length) {
            if (this.compareCI(this.columns[i].elements[j].field, col.field)) {
              this.columns[i].elements.splice(j, 1);
              find = true;

              if (this.columns[i].elements.length == 0) {
                this.columns.splice(i, 1);
              }
            }
            else {
              j++;
            }
          }
        }

        i++;
      }

      if(!addAll){
        this.searchColPicked();
      }
    },
    addAll(){
      for (let i = this.columns.length - 1; i > -1; i--) {
        this.add(this.columns[i], true);
      }
      this.columns = [];
      this.searchColPicked();
    },

    remove(col, type, removeAll = false){
      if(type == "colPicked"){
        let find = false;
        let i = 0;

        while (!find && i < this.colPicked.length) {
          if (this.compareCI(this.colPicked[i].field, col.field)) {
            this.colPicked.splice(i, 1);
            find = true;
          }

          if (!find && this.colPicked[i].elements && this.colPicked[i].elements.length > 0) {
            let j = 0;

            while (!find && j < this.colPicked[i].elements.length) {
              if (this.compareCI(this.colPicked[i].elements[j].field, col.field)) {
                this.colPicked[i].elements.splice(j, 1);
                find = true;

                if (this.colPicked[i].elements.length == 0) {
                  this.colPicked.splice(i, 1);
                }
              }
              else {
                j++;
              }
            }
          }

          i++;
        }
      }
      else if(type == "colGrouped"){
        let find = false;
        let i = 0;

        while (!find && i < this.colGrouped.length) {
          if (this.compareCI(this.colGrouped[i].field, col.field)) {
            this.colGrouped.splice(i, 1);
            find = true;
          }

          if (!find && this.colGrouped[i].elements && this.colGrouped[i].elements.length > 0) {
            let j = 0;

            while (!find && j < this.colGrouped[i].elements.length) {
              if (this.compareCI(this.colGrouped[i].elements[j].field, col.field)) {
                this.colGrouped[i].elements.splice(j, 1);
                find = true;

                if (this.colGrouped[i].elements.length == 0) {
                  this.colGrouped.splice(i, 1);
                }
              }
              else {
                j++;
              }
            }
          }

          i++;
        }
      }

      delete col.type;
      this.columns.push(col);

      if(!removeAll){
        this.columns = this.formatNestedColumns(this.columns);
        this.searchCol();
        clearTimeout(this.sort_timer);
        this.sortTimer = setTimeout(() => {
          this.sort(this.columns, 'headerName', false, 'elements', true);
        }, 500);
      }

    },
    removeAll(type){
      if(type == "colPicked"){
        for (let i = this.colPicked.length - 1; i > -1; i--) {
          this.remove(this.colPicked[i], type, true);
        }

        this.colPicked = [];
      }
      else if(type == "colGrouped"){
        for (let i = this.colGrouped.length - 1; i > -1; i--) {
          this.remove(this.colGrouped[i], type, true);
        }

        this.colGrouped = [];
      }

      this.columns = this.formatNestedColumns(this.columns);
      this.searchCol();
      this.sort(this.columns, 'headerName', false, 'elements', true);
    },
    showRemoveAggregates(){
      return this.colGrouped.length == 0 && this.colPicked.some(fcp => fcp.aggFunc);
    },
    removeAllAggregates(){
      this.colPicked.forEach(fcp => fcp.aggFunc = null);
    },

    IsColumnGroupableStart(value){
      let index = 0;
      let find = false;

      while (!find && index < this.originalColumns.length) {
        if (this.compareCI(this.originalColumns[index].field, value.item.id)) {
          if (this.originalColumns[index].enableRowGroup != undefined) {
            this.isGroupable = true;
          }
          else {
            this.isGroupable = false;
          }

          find = true;
        }
        else {
          index++;
        }
      }

      if (!find) {
        this.isGroupable = false;
      }
    },
    IsColPickedGroupableStart(value){
      let index = 0;
      let find = false;
      while (!find && index < this.originalColumns.length) {
        if (this.compareCI(this.originalColumns[index].field, value.item.id)) {
          if (this.originalColumns[index].enableRowGroup != undefined) {
            this.isGroupable = true;
          }
          else {
            this.isGroupable = false;
          }

          find = true;
        }
        else {
          index++;
        }
      }

      if (!find) {
        this.isGroupable = false;
      }
    },

    onAdd() {
      this.columns = this.reNestedColumns(this.columns);
      
      this.searchCol();

      this.isGroupable = true;
      this.columnIndex = null;
      this.columnPickedIndex = null;
      this.columnGroupIndex = null;
    },
    IsGroupableEnd(){
      this.isGroupable = true;
      this.columnIndex = null;
      this.columnPickedIndex = null;
      this.columnGroupIndex = null;
    },
    
    onColumnsChange(event) {
      if (event.added) {
        for (let i = this.columns.length - 1; i > -1; i--) {
          if (this.columns[i].elements && this.columns[i].elements.length == 0) {
            this.columns.splice(i, 1);
          }
        }
      } 
    },
    onPickedChange(event) {
      if (event.added && event.added.element.elements && event.added.element.elements.length > 0) {
        for (let i = 0, c = event.added.element.elements.length; i < c; i++) {
          this.colPicked.push(event.added.element.elements[i]);
        }
        //Used to remove the parent column from the list and keep children only
        this.colPicked.splice(event.added.newIndex, 1);
      }
      this.onColumnsChange(event);
      this.searchColPicked();
    },
    onGroupedChange(event) {
      if (event.added && event.added.element.elements && event.added.element.elements.length > 0) {
        for (let i = 0, c = event.added.element.elements.length; i < c; i++) {
          this.colGrouped.push(event.added.element.elements[i]);
        }
        //Used to remove the parent column from the list and keep children only
        this.colGrouped.splice(event.added.newIndex, 1);
      }
      this.setAggregation();
      this.onColumnsChange(event);
      this.searchColGrouped();
    },
    
    setAggregation(){
      //Set default agg function for all picked columns
      for (let i = 0; i < this.colPicked.length; i++) {
        if(!this.colPicked[i].aggFunc){
          if(this.colPicked[i].aggregates.includes("sum")){
            this.colPicked[i].aggFunc = "sum";
          }
          else{
            this.colPicked[i].aggFunc = "max";
          }
        }
      }
    }
  }
}
</script>
