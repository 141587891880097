import Api from '../_api.config';

async function getConfigs(getChildren, filters, _ReportDataModelID){
  const reportDataModelIDParam = (_ReportDataModelID ? (`&_ReportDataModelID=${_ReportDataModelID}`) : '');
  const response = await Api()
  .get(`/api/Config?getChildren=${getChildren}&filters=${encodeURIComponent(JSON.stringify(filters))}${reportDataModelIDParam}`)
  .catch((e) => {
    if (e.response) {
      return { status: e.response.status, message: e.response.data, data: null };
    }
    else {
      return { message: e.message, data: null };
    }
  });
  return { status: response.status, data: response.data};
}

async function getConfig(request){
  const response = await Api()
  .get('/api/Config/' + request.getChildren + '?filters=' + request.filters)
  .catch((e) => {
    if (e.response) {
      return { status: e.response.status, message: e.response.data, data: null };
    }
    else {
      return { message: e.message, data: null };
    }
  });
  return { status: response.status, data: response.data };
}


async function getWSConfig() {
  const response = await Api()
  .get('/api/WSConfig/GetWSConfig')
  .catch((e) => {
    if (e.response) {
      return { status: e.response.status, message: e.response.data, data: null };
    }
    else {
      return { message: e.message, data: null };
    }
  });

  return { status: response.status, data: response.data };
}

export const configServices = {
  getConfigs,
  getConfig,
  getWSConfig
};

