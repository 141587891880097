<template src="./order-related-receivings.html"></template>

<script>
import { mapGetters } from 'vuex';
import grid from '@/core/components/common/grid/persisted-grid.vue';
import searchBar from '@/core/components/common/search-bar/search-bar.vue';
import formatters from '@/core/components/common/grid/formatters/formatters.js';
import { GridId, AgFilter, AgAllowedAggFuncs, MultiFilterParams, FilterParams } from '@/core/components/common/grid/grid.const.js'

export default {
    name: 'orderRelatedReceivings',
    components: {
        grid,
        searchBar
    },
    data() {
        return {
            gridOptions: {
                getRowId: (params) => params.data?.id
            },
            filters: []
        }
    },
    computed:{        
        ...mapGetters('App', ['isMobile']),
        ...mapGetters('Account', ['getCurrentTargetLayerId']),
        ...mapGetters('Order', ['getOrderRelatedReceivingBodies']),
        columnDefs(){ 
            const cellRenderer = this.isMobile ? 'dropdownRenderer' : 'clickCallbackRenderer';
            const cellRendererParams = this.isMobile ? {
                openSidePanel: (row) => this.$emit('onReceivingClick', row),
                canDelete: false
            } : { callback: (row) => this.$emit('onReceivingClick', row) }
            
            return [
                { field: 'receivingNO', headerName: this.$t('Purchase.Receiving.Columns.ReceivingNO'), headerTooltip: this.$t('Purchase.Receiving.Columns.ReceivingNO'), cellRenderer: cellRenderer, cellRendererParams: cellRendererParams, cellClass: this.isMobile ? "dropdown-cellrenderer" : "", filter: AgFilter.NumberColumnFilter },
                { hide: this.isMobile, field: 'receivedDate', headerName: this.$t('Purchase.Order.Columns.ReceivedDate'), headerTooltip: this.$t('Purchase.Order.Columns.ReceivedDate'), filter: AgFilter.DateColumnFilter, valueFormatter: formatters.dateFormatter, filterValueGetter: (params) => params?.data?.receivedDate && new Date(params.data.receivedDate) },
                { hide: this.isMobile, field: 'status', headerName: this.$t('Purchase.Receiving.Columns.ReceivingStatus'), headerTooltip: this.$t('Purchase.Receiving.Columns.ReceivingStatus'), valueGetter: params => { return params?.data?.status ? this.$t(`Purchase.Receiving.Status.${params.data.status}`) : null; }, allowedAggFuncs: AgAllowedAggFuncs.Text, filter: AgFilter.SetColumnFilter },
                { hide: this.isMobile, field: 'itemCount', headerName: this.$t('Purchase.Receiving.Columns.ReceivingBodySums'), headerTooltip: this.$t('Purchase.Receiving.Columns.ReceivingBodySums'), filter: AgFilter.NumberColumnFilter },
                { hide: this.isMobile, field: 'qty', headerName: this.$t('Purchase.Order.ReceivedQty'), headerTooltip: this.$t('Purchase.Order.ReceivedQty'), filter: AgFilter.NumberColumnFilter },
                { hide: this.isMobile, field: 'username', headerName: this.$t('Purchase.Receiving.Columns.UserUsername'), headerTooltip: this.$t('Purchase.Receiving.Columns.UserUsername'), allowedAggFuncs: AgAllowedAggFuncs.Text, filterParams: MultiFilterParams.NormalizeTextFilters },
                { hide: this.isMobile, field: 'total', headerName: this.$t('Purchase.Receiving.Columns.Cost'), headerTooltip: this.$t('Purchase.Receiving.Columns.Cost'), valueFormatter: formatters.currencyFormatter, filterParams: FilterParams.NumberFilter },
            ];
        },
        rowData(){
            if(!this.getOrderRelatedReceivingBodies)
                return []

            const groupedReceivings = {}

            this.getOrderRelatedReceivingBodies.forEach(rb => {
                const key = rb.receivingID
                
                if(!groupedReceivings[key]) {
                    groupedReceivings[key] = Object.assign({}, 
                        { 
                            id: rb.receivingID,
                            receivingNO: rb.receivingNO,
                            receivedDate: rb.lastModifiedDate,
                            status: rb.receivingStatus,
                            itemCount: 1,
                            username: rb.userUsername,
                            qty: rb.qty, 
                            total: rb.cost 
                        }
                    );
                } 
                else {
                    groupedReceivings[key].itemCount++;
                    groupedReceivings[key].qty += rb.qty;
                    groupedReceivings[key].total += rb.cost;
                }        
            });
            
            return Object.values(groupedReceivings)
        },
        agTheme() {
            return this.isMobile ? 'ag-theme-material' : 'ag-theme-alpine'
        },
        getGridId() {
            return GridId.OrderRelatedReceivings
        },
    },
    methods: {
        onGridReady(params) {
            this.gridOptions.api = params.api        
        },
        filtersChanged(filters){
            this.filters = filters;
        }
    }
}
</script>
