<template src="./step.html"></template>
<style scoped lang="scss" src="./step.scss"></style>

<script>
export default {
  name: 'Step',
  props:{
    steps: Array,
    currentStepIndex: Number
  },
  methods: {
    onStepChanged(stepIndex) {
      this.$emit("step-changed", stepIndex)
    }
  }
}
</script>
