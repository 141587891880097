<template src="./receiving-info.html"></template>

<style lang="scss">
    @import './receiving-info.scss';
</style>

<script>
import collapse from '@/core/components/common/collapse/collapse.vue';
import { mapGetters, mapActions } from 'vuex';
import prompts from '@/core/tools/notifications/notifications';
import { ReceivingStatus } from '@/backoffice/modules/purchase/domain/purchase.js';

export default {
    name: 'Receiving-Info',
    components: {
        collapse
    },
    data() {
        return {
            receivingData: {}
        }
    },

    computed: {
        ...mapGetters('Receiving', ['getReceiving']),
        ...mapGetters('App', ['isMobile']),
        totalReceivedQty(){
            if(!this.receiving?.receivingBodies)
                return 0

            return this.receiving.receivingBodies.reduce((acc, val) => val.qty + acc, 0)
        },
        totalReceivedCost(){
            if(!this.receiving?.receivingBodies)
                return 0

            return this.receiving.receivingBodies.reduce((acc, val) => val.cost + acc, 0)
        },
        totalExpectedCost(){
            if(!this.receiving?.receivingBodies)
                return 0

            return this.receiving.receivingBodies.reduce((acc, val) => {
                if(val.unitCostVariance && val.unitCostVariance != 0)
                    return (val.cost - (val.unitCostVariance * val.qty)) + acc
                else
                    return val.cost + acc
            }, 0)
        },
        isReadOnly() {
            if (!this.receiving)
                return true;

            return !this.receiving.canUpdate || this.receiving.receivingStatus != ReceivingStatus.OPEN;
        },
        status(){
            if(!this.receiving.receivingStatus)
                return null

            return this.$t(`Purchase.Receiving.Status.${this.receiving.receivingStatus}`)
        },
        receiving: {
            get() {
                if (this.getReceiving && this.getReceiving.id != this.receivingData.id) {
                    return { ...this.getReceiving }
                }
                
                return { ...this.getReceiving, ...this.receivingData };
            },
            set(obj) {
                this.receivingData = { ...this.getReceiving, ...this.receivingData, ...obj };
            }
        }
    },
    methods: {
        ...mapActions('Receiving', ['saveReceiving']),

        async onRefNumberKeypress(e) {
            if (e.keyCode == 13) {
                e.preventDefault();
                
                this.receiving = { referenceNO: e.target.value };
                
                await this.updateReceiving('referenceNO', e.target.value, true);

                this.$refs.comment.focus();
            }
        },

        async onCommentKeyPress(e) {
            if (e.keyCode == 13 && !e.shiftKey) {
                e.preventDefault();

                this.receiving = { comment: e.target.value };

                await this.updateReceiving('comment', e.target.value, true);
            }
        },

        async updateReceiving(prop, value) {
            if (!value || this.getReceiving[prop] == value) {
                return;
            }

            try {
                await this.saveReceiving(this.receiving);
            } catch (error) {
                prompts.error({
                    text: this.$tc('Purchase.Messages.SaveReceivingError')
                })
            }
        }
    }
}
</script>