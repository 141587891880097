<template src="./main.html"></template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import prompts from '@/core/tools/notifications/notifications';
import systemDashboard from  '@/backoffice/modules/dashboard/components/dashboard/system-dashboard/system-dashboard.vue';
import dynamicDashboard from  '@/backoffice/modules/dashboard/components/dynamic-dashboard/dynamic-dashboard.vue';
import dashboardManagement from  './dashboard-management/dashboard-management.vue';
import favoriteTitle from "@/core/components/common/favorite-title/favorite-title.vue"
import calendar from '@/core/components/common/calendar/calendar.vue'
import targetDropdown from '@/core/components/common/drop-down/target-dropdown/target-dropdown.vue'
import multiselect from 'vue-multiselect'
import contextMenu from '@/core/components/common/context-menu/context-menu.vue'
export default {
  name: 'MainDashboard',

  components: {
    favoriteTitle,
    calendar, 
    targetDropdown,
    multiselect,
    contextMenu
  },

  data() {
    return {
      dashboardSelectKey: 0,
      currentTargetID: null,
      targetDropdownKey: 0,
      dashboardTitleOptions: {
        id: 'dashboard-selector',
        options: [],
        trackBy: 'id',
        label: 'description',
        multiple: false,
        placeholder: '',
        showLabels: false,
        searchable: false,
        allowEmpty: false
      },
      editDashboardButton: {
        button: 'editDashboard',
        icon: ['fal', 'fa-pencil'],
        text: 'Button.Edit',
        isDisabled: false,
        callback: () => { this.openSidePanel(); }
      },
      createDashboardButton: {
        button: 'createDashboard',
        icon: ['fal', 'fa-layer-plus'],
        text: 'Button.Create',
        isDisabled: false,
        callback: () => { this.openSidePanel(true); }
      },
      listDashboardButton: {
        button: 'listDashboard',
        icon: ['fal', 'fa-layer-group'],
        text: 'Button.List',
        callback: () => { this.$router.push({ name: 'dashboardList' }); }
      },
      dashboardComponent: {
        systemDashboard: systemDashboard,
        dynamicDashboard: dynamicDashboard
      }
    }
  },

  computed:{
    ...mapGetters('Account', ['hasPermission', 'getCurrentTargetLayerId']),
    ...mapGetters('Dashboard', ['getDate', 'getTargetID', 'getDashboards', 'getSelectedDashboard', 'getFavoriteDashboard', 'getDashboardComponentName', 'getDashboardUserCustomization']),
    selectedDashboardComponent() {
      if (!this.getDashboardComponentName)
        return null

      return this.dashboardComponent[this.getDashboardComponentName];
    },
    contextMenuButtons() {
      return [
        this.editDashboardButton,
        this.createDashboardButton,
        this.listDashboardButton
      ];
    },
    date:{
      get: function () {
        return this.getDate;
      },
      set: function (newValue) {
        this.setDate(newValue);        
      }
    },
    dashboardKey(){
      return this.getSelectedDashboard ? this.getSelectedDashboard.id : 0;
    }
  },

  watch: {
    getCurrentTargetLayerId(){
      this.targetDropdownKey++;
    }
  },
  
  mounted(){
    this.loadData();
    this.setContextMenuPermissions();
  },

  methods: {
    ...mapMutations('Dashboard', {
      setSelectedDashboard: 'SET_SELECTED_DASHBOARD',
      setFavoriteDashboard: 'SET_FAVORITE_DASHBOARD',
      setDashboardWidgets: 'SET_SELECTED_DASHBOARD_WIDGETS',
      setTargetID: 'SET_TARGETID',
      setDate: 'SET_DATE'
    }),
    ...mapActions('Dashboard', [
      'fetchDashboards',
      'fetchDashboardUserCustomization',
      'saveDashboardUserCustomization'
    ]),
    setContextMenuPermissions(){
      this.createDashboardButton.isDisabled = !this.hasPermission(['DASHBOARD_CUD']);
    },
    async loadData(){
      try {
        await this.fetchDashboardUserCustomization();
      } 
      catch (error) {
        prompts.error({
          text: this.$t('Dashboard.Messages.FetchDashboardUserCustomizationError')
        });
      }

      try {
        await this.fetchDashboards();
      } 
      catch (error) {
        prompts.error({
          text: this.$t('Dashboard.Messages.FetchDashboardsError')
        });
      }

      this.dashboardTitleOptions.options = this.getDashboards;

      this.autoSelectDashboard();
    },
    loadSidePanelDashboard(response) {
      if (response.row && response.row.id){
        const dashboard = this.getDashboards.find(d => d.id == response.row.id);
        this.changeDashboard(dashboard);
        this.dashboardSelectKey++;
      }
    },
    changeDashboard(dashboard) {
      this.setSelectedDashboard(dashboard);
      this.editDashboardButton.isDisabled = !this.getSelectedDashboard.canUpdate;
    },
    autoSelectDashboard() {
      const currentSelectedDashboardId = (this.getSelectedDashboard && this.getSelectedDashboard.id) || (this.getFavoriteDashboard && this.getFavoriteDashboard.id)
      const dashboard = this.getDashboards.find(d => d.id == currentSelectedDashboardId)
      if (dashboard) {
        this.changeDashboard(dashboard);
      }
      else {
        this.changeDashboard(this.getDashboards[0]);
      }
    },
    async selectFavorite(dashboardID) {
      const dashboardUserCustomization = {
        id: this.getDashboardUserCustomization ? this.getDashboardUserCustomization.id : null,
        dashboardID: dashboardID
      }

      try {
        await this.saveDashboardUserCustomization(dashboardUserCustomization);
      } 
      catch (error) {
        prompts.error({
          text: this.$t('Dashboard.Messages.SaveDashboardUserCustomizationError')
        });
      }
    },
    openSidePanel(create){
      this.$sidePanel.show([{component: dashboardManagement}], ((!create) ? this.getSelectedDashboard : {})).then(resp => {
        if (resp) {
          this.loadSidePanelDashboard(resp);
        }
      });
    },
    onTargetChanged(target){
      this.setTargetID(target.targetId);
      this.loadData();
    }
  }
}
</script>
