import jwtDecode from 'jwt-decode';

function isTokenExpired(token) { 
  const decoded = jwtDecode(token);
  return Date.now() > (decoded.exp * 1000)
}

function getTokenExpirationDateInSeconds(token){
  const decoded = jwtDecode(token);
  return decoded.exp;
}

export const TokenTools = {
  isTokenExpired,
  getTokenExpirationDateInSeconds
};