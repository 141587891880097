import brandManagement from  '@/backoffice/modules/brand/components/brand-management/brand-management.vue';
import brand from '@/backoffice/modules/brand/brand.vue'

export const BrandRouter = {
    path: 'brands',
    name: 'brands',
    component: brand,
    meta: {
        requiresAuth: true,
        modelName: 'Brand',
        sidePanelComponents: [{ component: brandManagement }],
        sideNav: {
            image: null,
            label: 'SideNav.Management.Brands'
        },
        permissions: ['B1', 'B2'],
        breadcrumb: {
            linkDescription: 'Breadcrumbs.Management.Brands',
            routeNameTo: null,
            explicitTail: null
        }
    }
}