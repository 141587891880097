<template src="./toggle-renderer.html"></template>

<style scoped lang="scss">
@import './toggle-renderer.scss';
</style>

<script>
import toggle from '@/core/components/common/toggle/toggle.vue';

export default{
  name: 'toggleRenderer',
  components: {
    toggle
  },
  computed: {
    checked: {
      get() {
        return this.params.value;
      },
      set(checked) {
        this.params.node.setDataValue(this.params.colDef, checked);
      }
    },
    disabled() {
      return this.params.canUserInteractWithUI && !this.params.canUserInteractWithUI();
    }
  }
};
</script>