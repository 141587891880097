<template src="./top-widget.html"></template>

<style scoped lang="scss">
@import '@/backoffice/modules/dashboard/components/dynamic-dashboard/widgets/widget.scss';
@import './top-widget.scss';
</style>

<script>
import { mapGetters, mapActions } from 'vuex';
import widgetConfig from '@/backoffice/modules/dashboard/components/dynamic-dashboard/widgets/widget-config/widget-config.vue';
import genericWidget from '@/backoffice/modules/dashboard/components/dynamic-dashboard/widgets/generic-widget';
import extractTime from '@/backoffice/modules/reporting/mixins/extract-time.js';
import axios from 'axios';
import errors from '@/core/tools/errors/errors';
import isEqual from 'lodash/isEqual';
import { stringFunction } from '@/core/functions';
import horizontalList from '@/core/components/common/horizontal-list/horizontal-list.vue'
import spinLoader from '@/core/components/common/spin-loader/spin-loader.vue'


export default {
  mixins: [extractTime],
  name: 'top-widget',
  components:{
    horizontalList,
    spinLoader
  },
  props:{
    widget: Object,
    editable: Boolean
  },
  data() {
    return {
      config: this.widget && this.widget.configs && this.widget.configs[0],
      columnProperties: {},
      dataSet: [],
      loading: false,
      reportDataModel: null,
      errorResponse: null,
      model: null,
      cancelToken: null
    }
  },
  mounted(){
    this.initializeData();
  },
  computed: {
    ...mapGetters('Dashboard', ['getDate', 'getTargetID']),
    ...mapGetters('DataModels', ['getScopeProperty', 'getMandatoryDateProperty', 'getPropertyByName'])
  },
  watch:{
    getDate: {
      handler(newVal, oldVal){
        if (oldVal != newVal) {
          this.initializeData();
        }
      }
    },
    getTargetID: {
      handler(newVal, oldVal){
        if (oldVal != newVal) {
          this.initializeData();
        }
      }
    },
    'widget.configs': {
      handler(newVal, oldVal){
        const hasChange = !isEqual(newVal, oldVal);
        if (hasChange) {
          const config = (this.config && this.widget.configs.find(c => c.id == this.config.id)) || (this.widget.configs && this.widget.configs[0]);

          this.updateSelectedConfig(config);
        }
      },
      deep: true
    }
  },
  beforeDestroy() {
    if (this.cancelToken)
      this.cancelToken.cancel('OPERATION_CANCELED');
  },
  methods: {
    ...mapActions('DataModels', ['getModel']),
    ...mapActions('Reporting', {
      fetchReportDataFromRdm: 'getReportDataFromRdm',
    }),
    initializeData() {
      if (this.cancelToken)
        this.cancelToken.cancel('OPERATION_CANCELED');

      this.dataSet = [];
      this.periodProperty = null;
      this.reportDataModel = null;
      this.errorResponse = null;
      this.model = null;

      if (this.config)
        this.createDynamicReportDataModel();
    },
    createDynamicReportDataModel() {
      this.reportDataModel = genericWidget.createReportDataModel(this.config);

      if (this.config.widgetTypeConfig.group)
        this.reportDataModel.genericListGroup.push(stringFunction.camelize(this.config.widgetTypeConfig.group));
        
      if (this.config.widgetTypeConfig.columns && this.config.widgetTypeConfig.columns.length > 0) {
        for (let i = 0; i < this.config.widgetTypeConfig.columns.length; i++) {
          this.reportDataModel.genericListColumn.push({ name: stringFunction.camelize(this.config.widgetTypeConfig.columns[i].column), aggFunc: this.config.widgetTypeConfig.columns[i].aggregate });

          if (this.config.widgetTypeConfig.columns[i].order) {
            this.reportDataModel.genericListOrder.push({ column: stringFunction.camelize(this.config.widgetTypeConfig.columns[i].column), aggFunc: this.config.widgetTypeConfig.columns[i].aggregate, direction: this.config.widgetTypeConfig.columns[i].order });
          }
        }
      }

      this.reportDataModel.topLines = this.config.widgetTypeConfig.top;

      this.getData();
    },
    async getData() {
      if (this.reportDataModel.dataSource){
        this.loading = true;
        
        const model = await this.getModel(this.reportDataModel.dataSource);
        if (model) {

          this.model = model;
          
          this.periodProperty = this.getMandatoryDateProperty(this.model.name);        
          this.config.widgetTypeConfig.columns.forEach(c => {
            this.columnProperties[stringFunction.camelize(c.column)] = this.getPropertyByName(this.model.name, c.column);
          });


          this.cancelToken = axios.CancelToken.source();
          let responseData = null;

          try{
            responseData = await this.fetchReportDataFromRdm({ params: { modelName: this.config.model, filters: this.getFilters(), reportDataModel: this.reportDataModel}, cancelToken: { cancelToken: this.cancelToken.token }});            
          }
          catch(response){
            this.errorResponse = response;
          }

          if (responseData && responseData.length > 0) {
            this.cancelToken = null;
            const camelizedColumns = [];
            
            for (let i = 0; i < this.config.widgetTypeConfig.columns.length; i++)
              camelizedColumns.push(stringFunction.camelize(this.config.widgetTypeConfig.columns[i].column));

            for (let i = 0; i < responseData.length; i++) {
              const rowObj = {};
              for (let j = 0; j < camelizedColumns.length; j++)
                rowObj[camelizedColumns[j]] = genericWidget.formatValue(this.columnProperties[camelizedColumns[j]], responseData[i][camelizedColumns[j]]);
              
              this.dataSet.push(rowObj);
            }
          }

        }

        this.loading = false;
      }
    },
    getFilters() {
      const filters = [];

      if (this.periodProperty && this.reportDataModel.period) {
        const dates = this.convert(this.reportDataModel.period, this.getDate);
        filters.push({ column: this.periodProperty.name, operator: '>=', value: [dates.start] });
        filters.push({ column: this.periodProperty.name, operator: '<=', value: [dates.end] });
      }

      const scopeProperty = this.getScopeProperty(this.model.name);
      if(scopeProperty && scopeProperty.name){
        filters.push({ Model: 'TargetLink', MainColumn: scopeProperty.name, JoinedColumn: '_TargetLayerID', Column: '_TargetID', Value: [this.getTargetID] });
      }

      return encodeURIComponent(JSON.stringify(filters));
    },
    displayApiError() {
      errors.displayApiError(this.errorResponse);
    },
    updateSelectedConfig(config) {
      this.config = config;

      this.initializeData();
    },
    openSidePanel(){
      this.$sidePanel.show([{ component: widgetConfig }], { widget: this.widget, selectedConfig: this.config });
    }
  }
}
</script>
