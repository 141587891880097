<template src="./send-order.html"></template>

<style lang="scss" scoped>
    @import './send-order.scss';
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import multiselect from 'vue-multiselect';
import prompts from '@/core/tools/notifications/notifications';
import { OrderStatus } from '@/backoffice/modules/purchase/domain/purchase.js';
import { mapGetters, mapActions } from 'vuex';
import transmissionTypeFactory from './TransmissionTypes/TransmissionTypeFactory'
import { transmissionTypeEmail, transmissionTypes } from './TransmissionTypes/TransmissionTypes'

export default {
    name: 'send-order-template',
    components: {
        multiselect
    },
    data() {
        return {
            transmissionTypes,
            transmissionType: null,
            isSaving: false,
            transmissionData: null
        }
    },
    computed:{
        ...mapGetters('Order', ['getOrder']),
        ...mapGetters('Account', ['getUser']),
        ...mapGetters('App', ['isMobile']),
        canSave(){ 
            return !!this.transmissionType && !!this.transmissionData && !this.isSaving;
        },
        sendByEmail() { 
            return this.transmissionType == transmissionTypeEmail && !!this.transmissionData;
        },
        order() {
            return {
                sendDate: new Date(),
                sender: `${this.getUser.firstName} ${this.getUser.lastName}`,
                vendor: this.getOrder.vendorName
            }
        }
    },
    mounted(){
        this.$sidePanel.isEdit(true);
    },
    methods: {
        ...mapActions('Vendor', ['fetchVendor']),
        ...mapActions('Order', ['saveOrder']),

        cancel(){
            this.$sidePanel.close();
        },
        async send() {
            
            const orderSaver = async () => {
                const order = {
                        ...this.getOrder,
                        orderStatus: OrderStatus.PENDING
                    }
                try {
                    await this.saveOrder(order);    
                }
                catch(e) {
                    await prompts.error({
                        text: this.$tc('Purchase.Messages.SaveOrderError')
                    });
    
                    return false;
                }
                return true;
            };

            const orderTransmitter = async () => {
                const transmitter = transmissionTypeFactory[this.transmissionType]
                return await transmitter(this.transmissionData);
            };
            
            this.isSaving = true;
            
            for (const func of [orderSaver,orderTransmitter]) {
                if (!await func()) {
                    this.isSaving = false;
                    return;
                }
            }

            this.$sidePanel.close();
        },
        async onSelectTransmissionType(transmissionType) {

            if (transmissionType == transmissionTypeEmail) {
                try {
                    const vendor = await this.fetchVendor(this.getOrder.vendorCode)
                    this.transmissionData = {
                        to: vendor && vendor.email,
                        bcc: this.getUser.email,
                        orderId: this.getOrder.id,
                        language: vendor.language
                    }
                }
                catch(e) {
                    await prompts.error({
                        text: this.$tc('Purchase.Messages.GetVendorInformationsError')
                    });
                }
            }
        },
        onRemoveTransmissionType() {

            this.transmissionData = null;
        }
    }
}
</script>