import vuex from '@/core/store';
import { rdmBreadcrumbTailFormatter, rdmCreateBreadcrumbTailFormatter, rdmUpdateBreadcrumbTailFormatter } from './breadcrumbTailFormatter.js'
import { RouteType } from '@/core/router/route-type.js'
import { Action } from './action.js'
import { permissionsDictionary } from '@/core/security/permissions-dictionary.js'

import reporting from '@/backoffice/modules/reporting/reporting.vue'
import mainReport from '@/backoffice/modules/reporting/pages/main-report/main-report.vue'
import readReport from '@/backoffice/modules/reporting/pages/read-report/read-report.vue'
import exceptionReport from '@/backoffice/modules/reporting/pages/exception-report/exception-report.vue'
import promotionReport from '@/backoffice/modules/reporting/pages/promotion-report/promotion-report.vue'
import createUpdate from '@/backoffice/modules/reporting/pages/create-update/create-update.vue'
import movementReport from '@/backoffice/modules/reporting/pages/movement-report/movement-report.vue'
import currentInventoryReport from '@/backoffice/modules/reporting/pages/current-inventory-report/current-inventory-report.vue'
import iframeWs from '@/backoffice/modules/ws-management/components/iframe-ws/iframe-ws.vue'
import taxExemptionReport from '@/backoffice/modules/reporting/pages/tax-exemption-report/tax-exemption-report.vue'

const reportingRouter = {
    path: '/report',
    name: 'report',
    component: reporting,
    meta: {
        title: 'report',
        sideNav: {
            image: 'fal fa-chart-column',
            label: 'SideNav.Reporting.Reportings',
            orderIndex: 7
        },
        permissions: [permissionsDictionary.SALE_READ, permissionsDictionary.SALE_RDM_CUD, permissionsDictionary.REPORT_MOVEMENT],
        breadcrumb: {
            linkDescription: 'Breadcrumbs.Reporting.Reportings',
            routeNameTo: null,
            explicitTail: null
        }
    },
    children:[
        {
            path: 'sales',
            name: 'reportSales',
            component: mainReport,
            props: route => ({ reportType: route.meta.modelName }),
            meta: {
                requiresAuth: true,
                modelName: 'Sales',
                associatedParentRouteName: null,
                sideNav: {
                    image: null,
                    label: 'SideNav.Reporting.Sales'
                },
                permissions: [permissionsDictionary.SALE_READ, permissionsDictionary.SALE_RDM_CUD],
                breadcrumb: {
                    linkDescription: 'Breadcrumbs.Reporting.Sales',
                    routeNameTo: null,
                    explicitTail: null
                }
            }
        },
        {
            path: 'sales/read/:_ReportDataModelID',
            name: `reportSales${RouteType.Read}`,
            component: readReport,
            props: route => ({ reportType: route.meta.modelName,  _ReportDataModelID: route.params._ReportDataModelID }),
            meta: {
                requiresAuth: true,
                modelName: 'Sales',
                associatedParentRouteName: 'reportSales',
                breadcrumb: {
                    linkDescription: 'Breadcrumbs.Reporting.Sales',
                    routeNameTo: 'reportSales',
                    routeQueryString: {
                      bypassFavoriteOrDefaultRedirect: true
                    },
                    explicitTail: {
                        func: rdmBreadcrumbTailFormatter
                    }
                }
            }
        },
        {
            path: 'exceptionsReport',
            name: 'exceptionsReport',
            component: exceptionReport,
            meta: {
                requiresAuth: true,
                associatedParentRouteName: null,
                sideNav: {
                    image: null,
                    label: 'SideNav.Reporting.Exceptions'
                },
                permissions: [permissionsDictionary.REPORT_EXCEPTION],
                breadcrumb: {
                    linkDescription: 'Breadcrumbs.Reporting.Exceptions',
                    routeNameTo: null,
                    explicitTail: null
                }
            }
        },
        {
            path: 'promotionsReport',
            name: 'promotionsReport',
            component: promotionReport,
            meta: {
                requiresAuth: true,
                associatedParentRouteName: null,
                sideNav: {
                    image: null,
                    label: 'SideNav.Reporting.Promotions'
                },
                permissions: [permissionsDictionary.REPORT_PROMOTION],
                breadcrumb: {
                    linkDescription: 'Breadcrumbs.Reporting.Promotions',
                    routeNameTo: null,
                    explicitTail: null
                }
            }
        },
        {
            path: 'sales/create',
            name: `reportSales${RouteType.Create}`,
            component: createUpdate,
            props: route => ({ reportType: route.meta.modelName,  _ReportDataModelID: 0, action: Action.Create }),
            meta: {
                requiresAuth: true,
                modelName: 'Sales',
                associatedParentRouteName: 'reportSales',
                breadcrumb: {
                    linkDescription: 'Breadcrumbs.Reporting.Sales',
                    routeNameTo: 'reportSales',
                    explicitTail: {
                        func: rdmCreateBreadcrumbTailFormatter
                    }
                }
            }
        },
        {
            path: 'sales/update/:_ReportDataModelID',
            name: `reportSales${RouteType.Update}`,
            component: createUpdate,
            props: route => ({ reportType: route.meta.modelName,  _ReportDataModelID: route.params._ReportDataModelID, action: Action.Update }),
            meta: {
                requiresAuth: true,
                modelName: 'Sales',
                associatedParentRouteName: 'reportSales',
                breadcrumb: {
                    linkDescription: 'Breadcrumbs.Reporting.Sales',
                    routeNameTo: 'reportSales',
                    explicitTail: {
                        func: rdmUpdateBreadcrumbTailFormatter 
                    }
                }
            }
        },
        {
            path: 'movements',
            name: 'movements',
            component: movementReport,
            meta: {
                requiresAuth: true,
                associatedParentRouteName: null,
                sideNav: {
                    image: null,
                    label: 'SideNav.Reporting.InventoryTotals'
                },
                permissions: [permissionsDictionary.REPORT_MOVEMENT],
                breadcrumb: {
                    linkDescription: 'Breadcrumbs.Reporting.InventoryTotals',
                    routeNameTo: null,
                    explicitTail: null
                }
            }
        },
        {
            path: 'currentInventory',
            name: 'currentInventory',
            component: currentInventoryReport,
            meta: {
                requiresAuth: true,
                associatedParentRouteName: null,
                sideNav: {
                    image: null,
                    label: 'SideNav.Reporting.CurrentInventory'
                },
                permissions: [permissionsDictionary.CURRENT_INVENTORY_REPORT_READ],
                breadcrumb: {
                    linkDescription: 'Breadcrumbs.Reporting.CurrentInventory',
                    routeNameTo: null,
                    explicitTail: null
                }
            }
        },
        {
          name: 'ComptabilityReport',
          path: 'ComptabilityReport',
          component: iframeWs,
          props: { routeName: 'Dashboard.ComptabilityReport' },
          meta: {
            sideNav: {
              image: null,
              label: 'SideNav.Reporting.ComptabilityReport'
            },
            permissions: [permissionsDictionary.RERPORT_ACOUNTING_AS_READ]
          }
        },
        {
            path: 'taxExemptionReport',
            name: 'taxExemptionReport',
            component: taxExemptionReport,
            meta: {
                requiresAuth: true,
                associatedParentRouteName: null,
                sideNav: {
                    image: null,
                    label: 'SideNav.Reporting.TaxExemption'
                },
                permissions: [permissionsDictionary.TAXEXEMPTION_READ],
                breadcrumb: {
                    linkDescription: 'Breadcrumbs.Reporting.TaxExemption',
                    routeNameTo: null,
                    explicitTail: null
                }
            }
        }
    ],
    beforeEnter: (async (to, from, next) => {
        const meta = to.meta;
        const model = await vuex.dispatch('DataModels/getModel', meta.modelName);
        const hasModelPermission = vuex.getters['Account/hasModelPermission'];
        next(hasModelPermission(model) ? {} : { name: 'dashboard' });
    })
};

export { reportingRouter }