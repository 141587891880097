<template src="./direct-receiving-management.html"></template>

<style lang="scss">
    @import './direct-receiving-management.scss';
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import multiselect from 'vue-multiselect';
import { mapGetters, mapActions } from 'vuex';
import prompts from '@/core/tools/notifications/notifications';
import { ReceivingStatus } from '@/backoffice/modules/purchase/domain/purchase.js';
import { ValidationObserver, ValidationProvider, localize } from "vee-validate";
import { stringFunction } from '@/core/functions';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import receivingProductScan from '@/backoffice/modules/purchase/components/sidePanel/direct-receivings/direct-receiving-product-scan/direct-receiving-product-scan.vue';

export default {
    name: 'order-template',
    components: {
        multiselect,
        ValidationObserver,
        ValidationProvider
    },

    data() {
        return {
            preventSave: false,
            vendorSearchValue: '',
            selectedVendor: null,
            directReceiving: {},
            directReceivingCopy: {}
        }
    },
    computed:{
        ...mapGetters('Receiving', ['getReceiving']),
        ...mapGetters('Vendor', ['getVendors']),
        ...mapGetters('Account', ['getCurrentTargetLayerId', 'getUser']),
        ...mapGetters('App', ['isMobile']),

        filteredVendorList() {
            return this.getVendors
                    .slice()
                    .filter(v => {
                        const matchVendorName = stringFunction.normalize(v.vendorName).includes(stringFunction.normalize(this.vendorSearchValue));
                        const matchVendorCode = stringFunction.normalize(v.vendorCode).includes(stringFunction.normalize(this.vendorSearchValue));

                        return matchVendorName || matchVendorCode;
                    })
                    .sort((objA, objB) => {
                        const descA = stringFunction.removeAccent(objA.vendorName);
                        const descB = stringFunction.removeAccent(objB.vendorName);

                        if (descA < descB) { return -1; }
                        if (descA > descB) { return 1; }
                        return 0;
                    });
        },
        blockSaveAction() {
            return this.preventSave;
        },
        edited(){
            return !isEqual(this.directReceivingCopy, this.directReceiving);
        }
    },

    watch:{
        edited(){
            this.$sidePanel.isEdit(this.edited);
        }
    },

    async created() {
        try {
            await this.fetchVendors();
        }
        catch (error) {
            prompts.error({
                text: this.$t('Purchase.Messages.GetReceivingError')
            });
        }
    },

    mounted() {
        localize(this.$i18n.locale);
        this.init();
    },

    methods: {
        ...mapActions('Receiving', ['saveReceiving']),
        ...mapActions('Vendor', ['fetchVendors']),

        async init(){
            await this.fetchVendors();
            this.setDirectReceiving();
            this.setVendorFocus();
        },

        setDirectReceiving() {
            this.directReceiving = {
                targetLayerID: this.getCurrentTargetLayerId,
                receivingStatus: ReceivingStatus.OPEN,
                createdDate: moment().format('YYYY-MM-DD'),
                createdDatetime: moment().format('YYYY-MM-DD HH:mm:ss'),
                userID: this.getUser._UserID,
                userUsername: this.getUser.username,
                userEmail: this.getUser.email,
                userFullName: `${this.getUser.firstName} ${this.getUser.lastName}`,
                vendorID: null, 
                vendorCode: null, 
                vendorName: null, 
                referenceNO: null, 
                comment: null, 
                isDirectReceiving: true
            };
            
            this.directReceivingCopy = cloneDeep(this.directReceiving);
        },

        setVendorFocus() {
            this.$nextTick(() => {
                if(!this.isMobile){
                    this.$refs.vendor.$refs.search.focus();
                }
                
            });
        },

        async save() {
            this.preventSave = true;

            try {
                await this.saveReceiving(this.directReceiving);
            }
            catch(e) {
                prompts.error({
                    text: this.$tc('Purchase.Messages.SaveReceivingError')
                });
            }

            if (this.getReceiving.id) {
                this.$sidePanel.show([{ component: receivingProductScan }], null, { backgroundIsDisabled: true, isPreventAutoClose: true });
                this.$router.push({ name: 'directReceiving', params: { id: this.getReceiving.id } });
            }

            this.preventSave = false;
        },

        searchVendor(value) {
            this.vendorSearchValue = value;
        },

        async selectVendor(vendor) {
            this.directReceiving.vendorID = vendor._VendorID;
            this.directReceiving.vendorCode = vendor.vendorCode;
            this.directReceiving.vendorName = vendor.vendorName;
        },

        validateVendor() {
            this.$refs.vendorValidator.validate();
        },

        cancel() {
            this.$sidePanel.close();
        }
    }
}
</script>